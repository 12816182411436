import Welcome from "../pages/welcome";
// import ChangePassword from "../pages/changePassword/index";
// private office
import PrivateOffice from "../pages/welcome/privateOffice";
import RequestSent from "../pages/welcome/privateOffice/monthly/requestSent";
import SelectDuration from "../pages/welcome/privateOffice/monthly/selectDuration";
import SelectOffice from "../pages/welcome/privateOffice/monthly/selectOffice";
import PrivateSummary from "../pages/welcome/privateOffice/monthly/summary";
import YearlyRequestSent from "../pages/welcome/privateOffice/yearly/requestSent";
import YearlySelectDuration from "../pages/welcome/privateOffice/yearly/selectDuration";
import YearlySelectOffice from "../pages/welcome/privateOffice/yearly/selectOffice";
import YearlyPrivateSummary from "../pages/welcome/privateOffice/yearly/summary";
// ddedicated plans

import DedicatedOffice from "../pages/welcome/dedicatedOffice";
import MonthlyDedicatedSelectDuration from "../pages/welcome/dedicatedOffice/monthly/selectDuration";
import MonthlyDedicatedRequestSent from "../pages/welcome/dedicatedOffice/monthly/requestSent";
import MonthlyDedicatedPrivateSummary from "../pages/welcome/dedicatedOffice/monthly/summary";
import YearlyDedicatedSelectDuration from "../pages/welcome/dedicatedOffice/yearly/selectDuration";
import YearlyDedicatedRequestSent from "../pages/welcome/dedicatedOffice/yearly/requestSent";
import YearlyDedicatedPrivateSummary from "../pages/welcome/dedicatedOffice/yearly/summary";

// hybrid plans
import HybridOffice from "../pages/welcome/hybridOffice";
import MonthlyHybridSelectDuration from "../pages/welcome/hybridOffice/monthly/selectDuration";
import MonthlyHybridSelectOffice from "../pages/welcome/hybridOffice/monthly/selectOffice";
import MonthlyHybridSelectTeam from "../pages/welcome/hybridOffice/monthly/selectHybridTeamDuration";
import MonthlyHybridSummary from "../pages/welcome/hybridOffice/monthly/summary";
import MonthlyHybridRequestSent from "../pages/welcome/hybridOffice/monthly/requestSent";
import YearlyHybridSelectDuration from "../pages/welcome/hybridOffice/yearly/selectDuration";
import YearlyHybridSelectOffice from "../pages/welcome/hybridOffice/yearly/selectOffice";
import YearlyHybridSelectTeam from "../pages/welcome/hybridOffice/yearly/selectHybridTeamDuration";
import YearlyHybridSummary from "../pages/welcome/hybridOffice/yearly/summary";
import YearlyHybridRequestSent from "../pages/welcome/hybridOffice/yearly/requestSent";

import { MeetingRoomApp } from "../pages/meetingRoomApp";
import MeetingRoom from "../pages/meetingRoomApp/meetingRoom";
import ScheduleMeetingRoom from "../pages/meetingRoomApp/meetingRoom/scheduleMeetingRoom";
import ScheduleInProgress from "../pages/meetingRoomApp/meetingRoom/scheduleInProgress";
import MeetingRoomBookingSummary from "../pages/meetingRoomApp/meetingRoom/meetingRoomBookingSummary";
import EditParticipents from "../pages/meetingRoomApp/meetingRoom/rescheduleMeetingRoom/editParticipents";
// import ViewAllDaypass from "../pages/dayPass/dayPassDashboard/viewAllDaypass";
import DaypassSchduleMeetingRoom from "../pages/dayPass/dayPassDashboard/viewAllDaypass/daypassScheduleMeetinRoom";
import DaypassSchduleDaypass from "../pages/dayPass/dayPassDashboard/viewAllDaypass/daypassSchduleDaypass";
import RescheduleMeetingRoom from "../pages/meetingRoomApp/meetingRoom/rescheduleMeetingRoom";
import PendingPage from "../pages/redirectMainPage/pendingPage";
import InactivePage from "../pages/redirectMainPage/inactivePage";
import Notifications from "../pages/notifications";

// reschdule meeting room flow

// meeting Room

const Pages = [
  {
    id: "1",
    path: "/welcome",
    element: <Welcome />,
  },
  {
    id: "2",
    path: "/private-office",
    element: <PrivateOffice />,
  },
  {
    id: "3",
    path: "/select-duration",
    element: <SelectDuration />,
  },
  {
    id: "4",
    path: "/select-office",
    element: <SelectOffice />,
  },
  {
    id: "5",
    path: "/private-summary",
    element: <PrivateSummary />,
  },
  {
    id: "6",
    path: "/private_request-sent",
    element: <RequestSent />,
  },
  ,
  {
    id: "7",
    path: "/yearly-select-duration",
    element: <YearlySelectDuration />,
  },
  {
    id: "8",
    path: "/yearly-select-office",
    element: <YearlySelectOffice />,
  },
  {
    id: "9",
    path: "/yearly-private-summary",
    element: <YearlyPrivateSummary />,
  },
  {
    id: "10",
    path: "/yearly-private-request-sent",
    element: <YearlyRequestSent />,
  },
  {
    id: "11",
    path: "/dedicated-office",
    element: <DedicatedOffice />,
  },
  {
    id: "12",
    path: "/hybrid-office",
    element: <HybridOffice />,
  },

  {
    id: "13",
    path: "/monthly-dedicated-select-duration",
    element: <MonthlyDedicatedSelectDuration />,
  },
  {
    id: "14",
    path: "/monthly-dedicated-private-summary",
    element: <MonthlyDedicatedPrivateSummary />,
  },
  {
    id: "15",
    path: "/monthly-dedicated-private-request-sent",
    element: <MonthlyDedicatedRequestSent />,
  },

  {
    id: "13",
    path: "/yearly-dedicated-select-duration",
    element: <YearlyDedicatedSelectDuration />,
  },
  {
    id: "14",
    path: "/yearly-dedicated-private-summary",
    element: <YearlyDedicatedPrivateSummary />,
  },
  {
    id: "15",
    path: "/yearly-dedicated-private-request-sent",
    element: <YearlyDedicatedRequestSent />,
  },
  {
    id: "16",
    path: "/monthly-hybrid-select-duration",
    element: <MonthlyHybridSelectDuration />,
  },
  {
    id: "17",
    path: "/monthly-hybrid-select-office",
    element: <MonthlyHybridSelectOffice />,
  },
  {
    id: "18",
    path: "/monthly-hybrid-select-team",
    element: <MonthlyHybridSelectTeam />,
  },
  {
    id: "19",
    path: "/monthly-hybrid-select-summary",
    element: <MonthlyHybridSummary />,
  },
  {
    id: "20",
    path: "/monthly-hybrid-request-sent",
    element: <MonthlyHybridRequestSent />,
  },

  {
    id: "21",
    path: "/yearly-hybrid-select-duration",
    element: <YearlyHybridSelectDuration />,
  },
  {
    id: "22",
    path: "/yearly-hybrid-select-office",
    element: <YearlyHybridSelectOffice />,
  },
  {
    id: "23",
    path: "/yearly-hybrid-select-team",
    element: <YearlyHybridSelectTeam />,
  },
  {
    id: "24",
    path: "/yearly-hybrid-select-summary",
    element: <YearlyHybridSummary />,
  },
  {
    id: "25",
    path: "/yearly-hybrid-request-sent",
    element: <YearlyHybridRequestSent />,
  },

  // meeting Room
  { id: "26", path: "/meeting-room-app", element: <MeetingRoomApp /> },

  {
    id: "27",
    path: "/meeting-room",
    element: <MeetingRoom />,
  },
  {
    id: "28",
    path: "/schdule-meeting-room",
    element: <ScheduleMeetingRoom />,
  },
  {
    id: "29",
    path: "/schedule-meeting-room-in-progress",
    element: <ScheduleInProgress />,
  },
  {
    id: "30",
    path: "/meeting-room-booking-summary",
    element: <MeetingRoomBookingSummary />,
  },
  {
    id: "31",
    path: "/edit-participents-rescheduled",
    element: <EditParticipents />,
  },
  // {
  //   id: "32",
  //   path: "/view-all-daypass-meetingsplus",
  //   element: <ViewAllDaypass />,
  // },
  {
    id: "33",
    path: "/daypass-reschedule-meeting-room",
    element: <DaypassSchduleMeetingRoom />,
  },
  {
    id: "34",
    path: "/daypass-reschedule-daypass",
    element: <DaypassSchduleDaypass />,
  },
  {
    id: "35",
    path: "/reschedule-meeting-room",
    element: <RescheduleMeetingRoom />,
  },
  {
    id: "36",
    path: "/pending-page",
    element: <PendingPage />,
  },
  {
    id: "37",
    path: "/inactive-page",
    element: <InactivePage />,
  },

  // /daypass-reschedule-meeting-room
];

export default Pages;
