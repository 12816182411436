import React, { useState } from "react";
import { Button, Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const PurchaseCreditsModel = (props) => {
  const navigate = useNavigate();

  return (
    <Modal isOpen={props.show} centered={true} className='fintechModel'>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelHeader'>
                  <h1>{props.header}</h1>
                  <p className='mt-3'>{props.body}</p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='d-flex justify-content-between'>
                  <Button
                    className='w-100 requestCancelModelBtn '
                    onClick={() => props.cancelModel(false)}
                  >
                    No
                  </Button>
                  <Button
                    className='w-100 requestUpdateModelBtn'
                    onClick={() => props.creddedAddedFunc()}
                  >
                    Yes
                  </Button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export default PurchaseCreditsModel;
