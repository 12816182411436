import React from "react";
import "../././css/styles.css";
import { Card, Col, Label, Row, Button, Spinner } from "reactstrap";
import summaryCredits from "./../../.././images/selectPackageImg.svg";
import creditExplementory from "./../../.././images/creditExplementory.svg";
import UseCreditSummaryForAdmin from "./useCreditSummaryForAdmin";
import CreditsAddedModel from "../../../components/molicules/creditsModel/creditsAdded";
import PurchaseCreditsModel from "../../../components/molicules/creditsModel/purchaseCredits";

const CreditSummaryForAdmin = (props) => {
  const {
    creditAddedModel,
    setCreditAddedModel,
    setPurchaseCreditState,
    purchaseCreditState,
    teamLoading,
    selectedArr,
    postCreditFunc,
    allteamListData,
    selectedCceck,
    checkFunc,
    selectedName,
    singlePersonData,
  } = UseCreditSummaryForAdmin(props);
  return (
    <>
      <Row className='printingCreditPage'>
        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
          <Row>
            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
              <h1>Summary</h1>
            </Col>
          </Row>

          <Row>
            <Col md={5} lg={5} xl={5} sm={12} xs={12} className=''>
              <Card className='select-pkg-c1 p-3 my-2 h-100'>
                <Row>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    <p className='sum-l1'>Purchasing for</p>
                    {teamLoading === true ? (
                      <div className='card-body d-flex align-items-center justify-content-center mt-5'>
                        <Spinner className='mt-5' />
                      </div>
                    ) : (
                      <Row className='mt-3'>
                        {allteamListData &&
                          allteamListData?.map((e, index) => {
                            return (
                              <Col
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                key={e?.Id} // Use a unique identifier as the key
                              >
                                <div className={"inputRadioGroup1 mt-2 mb-4"}>
                                  <input
                                    type='checkbox'
                                    id={index}
                                    name={e?.VisitorFullName}
                                    value={e?.VisitorFullName}
                                    checked={selectedCceck[index]}
                                    onChange={(ele) => {
                                      // selectedCceck[index] = ele.target.checked;
                                      checkFunc(ele, index);

                                      selectedName(e);
                                    }}
                                  />
                                  <label
                                    htmlFor={index} // Use 'htmlFor' instead of 'for' for label association
                                    className='checkLabel sip_teams_bg'
                                  >
                                    {e?.VisitorFullName}
                                  </label>
                                </div>
                              </Col>
                            );
                          })}
                      </Row>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md={5} lg={5} xl={5} sm={12} xs={12}>
              <Card className='select-pkg-c1 p-3 my-2 h-100'>
                <Row>
                  <div className='d-flex justify-content-center'>
                    <img
                      src={summaryCredits}
                      alt='img'
                      className='select-pkag-img '
                    />
                  </div>
                  <div className='d-flex justify-content-center'>
                    <p className='select-pkag-l5 '>{singlePersonData?.Name}</p>
                  </div>

                  <div className='d-flex justify-content-between'>
                    <p className=' '></p>
                    <p className='select-pkag-l7'>Amount </p>
                    <p className='select-pkag-l7'>Credits </p>
                    <p></p>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <p className=' '></p>
                    <p className='select-pkag-l8'>
                      {singlePersonData?.CurrencyCode} {singlePersonData?.Price}
                      <span className='select-pkag-l9'>
                        x{selectedArr?.length}
                      </span>
                    </p>
                    <p className='select-pkag-l10'>
                      {singlePersonData?.Price}{" "}
                      <span className='select-pkag-l9'>
                        x{selectedArr?.length}
                      </span>
                    </p>
                    <p></p>
                  </div>

                  <div className='d-flex'>
                    <img src={creditExplementory} alt='img' />
                    <p className=' mx-3 select-pkag-l6 mt-3'>
                      This amount will be charged in your next team’s invoice{" "}
                    </p>
                  </div>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    <Button
                      color='dark'
                      className='w-100'
                      onClick={(e) => {
                        e?.preventDefault();
                        setPurchaseCreditState(true);
                      }}
                    >
                      {" "}
                      Purchase ({singlePersonData?.CurrencyCode}{" "}
                      {singlePersonData?.Price * selectedArr?.length})
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <PurchaseCreditsModel
        show={purchaseCreditState}
        body={`Are you sure you want to purchase credits for ${
          singlePersonData?.CurrencyCode
        } ${singlePersonData?.Price * selectedArr?.length}? `}
        header='Purchase credits '
        cancelModel={() => setPurchaseCreditState(false)}
        creddedAddedFunc={() => postCreditFunc()}
      />

      <CreditsAddedModel
        show={creditAddedModel}
        header='Credits added '
        body='You have successfully purchased the printing credits package '
      />
    </>
  );
};

export default CreditSummaryForAdmin;
