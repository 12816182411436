import React, { useState } from "react";
import { Button, Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import creditAdded from "./../../.././images/creditsAdd.svg";

const CreditsAddedModel = (props) => {
  const navigate = useNavigate();
  return (
    <Modal isOpen={props.show} centered={true} className='fintechModel'>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='d-flex justify-content-center mt-4'>
                  <img src={creditAdded} alt='logo' />
                </div>
                <div className='modelHeader mt-4'>
                  <h1>{props.header}</h1>
                  <p className='mt-3'>{props.body}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <Button
                  className='w-100 requestModelBtn'
                  onClick={() => navigate("/team-credits")}
                >
                  Done
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export default CreditsAddedModel;
