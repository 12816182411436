import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Sidebar from "../.././components/verticalLayout";
import Header from "../../components/header";
import "./css/style.css";
import { useEffect } from "react";

export const MeetingRoomApp = () => {
  const [navShow, setNavShow] = useState(false);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='scroller'>
      <Row className='header-row'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          {" "}
          <Header
            navshow={navShow}
            setNavShow={setNavShow}
            className='header-row'
            // fixed='top'
          />
        </Col>
      </Row>
      <Row>
        <Col md={3} xl={3} lg={3} sm={12} xs={12} className='sidebar-width'>
          <div className={` ${navShow ? "tab-show" : "nav-tab"}`}>
            <Sidebar
              navshow={navShow}
              setNavShow={setNavShow}
              className='sidebar'
            />
          </div>
        </Col>
        <Col
          md={9}
          xl={9}
          lg={9}
          sm={12}
          xs={12}
          // style={{ width: "80%" }}
          className='col-header'
        >
          <Row>
            <Col md={12} xl={12} lg={12} sm={12} xs={12}>
              <div className=' maincontainercss1 px-5 py-5'>
                <Outlet />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

// return <div>MeetingRoomBook</div>;
// };
