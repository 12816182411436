import { useQuery } from "react-query";
import welcomeApi from "../../services/welcomeApi";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

// import React, { useState } from "react";
export const useWelcomeInfo = (props) => {
  const Navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("progressBarLength", "");
    localStorage.setItem("progressBarStart", false);
    localStorage.setItem("privateBar", false);
    localStorage.setItem("hybridBar", false);
  }, []);

  const { data: welcomeFintechData, isLoading: membershipLoading } = useQuery(
    ["wellcomeFintech"],
    () => welcomeApi.welcomeList(),
    {
      retry: 0,
    }
  );

  const welcomeFintechDataa = welcomeFintechData?.data;
  

  const resourceFunc = (e) => {
    if (e === Number(process.env.REACT_APP_PRIVATE_OFFICE)) {
      const privateOfficeData = welcomeFintechDataa?.filter((ele) => {
        if (ele?.ResourceId === Number(process.env.REACT_APP_PRIVATE_OFFICE)) {
          return ele;
        }
      });


      Navigate("/private-office", {
        state: { privateOfficeData: privateOfficeData },
      });
     
    } else if (e === Number(process.env.REACT_APP_DEDICATED_OFFICE)) {
      const dedicatedDeskData = welcomeFintechDataa?.filter((ele) => {
        if (
          ele?.ResourceId === Number(process.env.REACT_APP_DEDICATED_OFFICE)
        ) {
          return ele;
        }
      });

      Navigate("/dedicated-office", {
        state: { dedicatedDeskData: dedicatedDeskData },
      });
    } else {
      const hybridPlanData = welcomeFintechDataa?.filter((ele) => {
        if (
          ele?.ResourceId !== Number(process.env.REACT_APP_DEDICATED_OFFICE) &&
          ele?.ResourceId !== Number(process.env.REACT_APP_PRIVATE_OFFICE)
        ) {
          return ele;
        }
      });

      Navigate("/hybrid-office", { state: { hybridPlanData: hybridPlanData } });
    }
  };
  

  return { welcomeFintechDataa, resourceFunc, membershipLoading };
};
