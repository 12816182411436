import axios from "axios";
import { Suadi_fintech_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "https://spaces.nexudus.com",
});

const authApi = {
  login: async (body) => {
    const data = await request.post(
      `/nexudus-login`,
      { ...body },
      {
        headers: {
          Accept: "application/json, text/plain",
        },
      }
    );
    return data;
  },

  findUserLocally: async (userId) => {
    const data = await request.get(`/find-user/${userId}`, {
      headers: {
        Accept: "application/json, text/plain",
      },
    });

    return data?.data;
  },

  delAccount: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const userType = localStorage.getItem("userTypeRedir");

    const data = await request.patch(
      userType === "daypassUser"
        ? `/delete-account/null/${body?.userId}`
        : `/delete-account/${body?.TeamId}/${body?.userId}`,
      {},
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );
    return data;
  },

  forgotPassword: async (body) => {
    return await request.patch(`/forget-password/${body}`);
  },

  fcmTokenPost: async (token, userId) => {
    // if (userId && token) {

    if (token?.userId) {
      if (token?.fcmTokenState) {
        const data = await request.patch(
          `user-fcm/${token?.userId}`,
          { FCMToken: token?.fcmTokenState },
          {
            headers: {
              Accept: "application/json, text/plain",
            },
          }
        );
        return data;
      }
    }
  },
  // },
};

export default apiWrapper(authApi, [
  "login",
  "forgotPassword",
  "delAccount",
  "findUserLocally",
  "fcmTokenPost",
]);
