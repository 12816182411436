import React from "react";
import { Col, Row, Card, Label } from "reactstrap";
import TickImg from "../../.././images/tick.svg";
import InfoIcon from "../../.././images/infoIcon.svg";
import NoteIcon from "../../.././images/note_icon.svg";
import BtmPic from "../../.././images/privateroom_btm_pic.svg";
import UseHybridOffice from "./useHybridOffice";
import "./css/styles.css";

const HybridOffice = () => {
  const { hybridPlanData, monthlyFunc, yearlyFunc } = UseHybridOffice();

  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <div className='p-4'>
          <p className='pv_main_h'>Select Payment plan</p>
          <Row className=''>
            {hybridPlanData[0]?.isMonthAllow ? (
              <Col xl={5} lg={5} md={5} sm={12} xs={12} className='pb-3'>
                <Card
                  className='p-4   w-100 h-100 pvn_card '
                  onClick={(f) => monthlyFunc(f)}
                >
                  <span className='pvn_space'>
                    <Label className='pvn_card_h1  mb-4'>Monthly</Label>
                    <Label className='pvn_card_h2'>
                      SAR
                      {hybridPlanData[0]?.minmonth
                        ? hybridPlanData[0]?.minmonth
                        : 0}{" "}
                      <span className='pvn_card_h3 px-2'>/desk</span>
                    </Label>
                  </span>
                  <Label className='pvn_card_h4'>
                    <img src={TickImg} alt='img' className='pvn_tick_m' />
                    Access to book meeting rooms
                  </Label>
                  <Label className='pvn_card_h4 '>
                    <img src={TickImg} alt='img' className='pvn_tick_m' />
                    Printing credits
                  </Label>
                  <Label className='pvn_card_h4'>
                    <img src={TickImg} alt='img' className='pvn_tick_m' />
                    Hub events
                  </Label>
                  <Label className='pvn_card_h5'>
                    <img src={InfoIcon} alt='img' className='pvn_tick_m' />
                    The minimum commitment for this plan is of 3 months.
                    Cancellation request must be submitted with a 30 day notice
                    period.
                  </Label>
                </Card>
              </Col>
            ) : (
              ""
            )}

            {hybridPlanData[0]?.isYearAllow ? (
              <Col xl={5} lg={5} md={5} sm={12} xs={12} className='pb-3'>
                <Card
                  className='p-4   w-100 h-100 pvn_card '
                  onClick={(f) => yearlyFunc(f)}
                >
                  <span className='pvn_space'>
                    <Label className='pvn_card_h1  mb-4'>Yearly</Label>
                    <Label className='pvn_card_h2'>
                      SAR{" "}
                      {hybridPlanData[0]?.minyear
                        ? hybridPlanData[0]?.minyear
                        : 0}{" "}
                      <span className='pvn_card_h3 px-2'>/desk</span>
                    </Label>
                  </span>
                  <Label className='pvn_card_h4'>
                    <img src={TickImg} alt='img' className='pvn_tick_m' />
                    Access to book meeting rooms
                  </Label>
                  <Label className='pvn_card_h4 '>
                    <img src={TickImg} alt='img' className='pvn_tick_m' />
                    Printing credits
                  </Label>
                  <Label className='pvn_card_h4'>
                    <img src={TickImg} alt='img' className='pvn_tick_m' />
                    Hub events
                  </Label>
                  <Label className='pvn_card_h5'>
                    <img src={InfoIcon} alt='img' className='pvn_tick_m' />
                    The minimum commitment for this plan is of 3 months.
                    Cancellation request must be submitted with a 30 day notice
                    period.
                  </Label>
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col xl={10} lg={10} md={10} sm={12} xs={12} className='pt-3 '>
              <Card className='pv_btm_card  mt-3  w-100  px-2 pt-2 pb-2 '>
                <Row>
                  <Col xl={1} lg={1} md={1} sm={3} xs={3}>
                    <img src={NoteIcon} alt='img' className=' pv_note' />
                  </Col>
                  <Col xl={1} lg={1} md={1} sm={1} xs={1}>
                    <div className='noteDIv'>
                      {" "}
                      <h6>Note</h6>{" "}
                    </div>{" "}
                  </Col>
                  <Col xl={9} lg={9} md={9} sm={12} xs={12}>
                    <div className='noteDIv'>
                      <p className='pv_btm_h '>
                      The final price will depend upon number of members and type of resource(s) selected. However, currently Fintech Hub is empowering Team with a 100% discount on all services.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xl={1} lg={1} md={1} sm={12} xs={12}></Col>
          </Row>
        </div>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className=' ImageContainer'>
              <img src={BtmPic} alt='img' className='pv_pic ' />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HybridOffice;
