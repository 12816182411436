import { QueryClient, QueryCache } from "react-query";
import { toast } from "react-toastify";

//If a user leaves your application and returns to stale data, React Query automatically requests fresh data for you in the background. You can disable this globally or per-query using the refetchOnWindowFocus

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: (error) => {
        const title =
          error instanceof Error
            ? error.toString().replace(/^AxiosError\s*/, "")
            : "Error Connecting to server";

        toast.error(title, {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
    },
  },
});
export const queryCache = new QueryCache();
