import React from "react";
import UseHelpDesk from "./useHelpDesk";
import classnames from "classnames";
import PlusIcon from "../.././images/plusIcon1.svg";
import BottomImage from "../.././images/fintechBgImg1.svg";
import helpdeskHandfree from "../.././images/helpdeskHandfree.svg";
import helpdeskHandfree1 from "../.././images/helpdeskHandfree1.svg";
import moment from "moment";
import { Col, Row, NavLink, NavItem, Nav, Spinner, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { HelpDeskModel } from "../../components/molicules/userModel";
import "./css/styles.css";

const HelpDesk = () => {
  const Navigate = useNavigate();
  const {
    customerOpenListLoading,
    customerCloseListLoading,
    modelData,
    setModelData,
    customerOpenData,
    customerCloseData,
    closeFunc,
    modelShow1,
    setModelShow1,
    activeTabJustify,
    toggleCustomJustified,
  } = UseHelpDesk();

  return (
    <>
      <Row className=''>
        <div className='px-5 mb-5'>
          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
            <Row>
              <Col md={6} xl={6} lg={6} sm={12} xs={12}>
                <div className='px-5 navContainer'>
                  <Nav className=' nav-justified nav-width nav-btn'>
                    <NavItem className='nav-item d-sm-block1'>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={
                          ("nav-request-bar",
                          classnames({
                            active: activeTabJustify === "1",
                          }))
                        }
                        onClick={() => {
                          toggleCustomJustified("1");
                        }}
                      >
                        <span className='tabDeskCss'>Open </span>
                      </NavLink>
                    </NavItem>
                    <NavItem className='d-sm-block2'>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={
                          ("nav-request-bar",
                          classnames({
                            active: activeTabJustify === "2",
                          }))
                        }
                        onClick={() => {
                          toggleCustomJustified("2");
                        }}
                      >
                        <span className='tabDeskCss'>Closed</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>

              <Col md={3} xl={3} lg={3} sm={12} xs={12}></Col>

              <Col
                md={3}
                xl={3}
                lg={3}
                sm={12}
                xs={12}
                className=' d-flex justify-content-end mb-2'
              >
                <Button
                  type='button'
                  color='primary'
                  size='md'
                  // className='d_sm_report_cr'
                  onClick={() => {
                    Navigate("/create-desk");
                  }}
                >
                  <img src={PlusIcon} alt='logo' /> Ask for Help
                </Button>
              </Col>

              <hr className='w-100' />
              {/* <Col md={4} xl={6} lg={6} sm={12} xs={12}></Col> */}
            </Row>
            {activeTabJustify === "1" ? (
              customerOpenListLoading ? (
                <Row className='d-flex align-items-center justify-content-center align-middle mt-5'>
                  <Spinner />
                </Row>
              ) : (
                <Row className='mt-4 '>
                  {customerOpenData?.map((ele) => {
                    return (
                      <Col
                        md={4}
                        xl={4}
                        lg={4}
                        sm={12}
                        xs={12}
                        className=' mb-3'
                        onClick={() => {
                          Navigate("./help-desk-chat-support", { state: ele });
                        }}
                      >
                        <div className='hd_d1 p-2 h-100 '>
                          <Row>
                            <Col md={2} xl={2} lg={2} sm={2} xs={2}>
                              <img
                                src={helpdeskHandfree}
                                className='cardimgCentered'
                                alt='logo'
                              />
                            </Col>
                            <Col md={10} xl={10} lg={10} sm={10} xs={10}>
                              <span className='hd_p1 mt-3'>
                                <b>{ele?.Subject}</b>
                              </span>
                              <p className='hd_p3'>
                                <b>{ele?.MessageText}</b>
                              </p>
                              <p className='hd_p2'>
                                {" "}
                                {moment(ele?.CreatedOn).format(
                                  "D-MM-yyyy - hh:mm a"
                                )}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              )
            ) : customerCloseListLoading ? (
              <Row className='d-flex align-items-center justify-content-center align-middle mt-5'>
                <Spinner />
              </Row>
            ) : (
              <Row className='mt-4 invoicesContainer '>
                {customerCloseData?.map((ele) => {
                  return (
                    <Col
                      md={4}
                      xl={4}
                      lg={4}
                      sm={12}
                      xs={12}
                      className='mb-3'
                      onClick={() => {
                        Navigate("./help-desk-chat-support", { state: ele });
                      }}
                    >
                      <div className='hd_d1 p-2 h-100 '>
                        <Row>
                          <Col md={2} xl={2} lg={2} sm={2} xs={2}>
                            <img
                              src={helpdeskHandfree1}
                              className='mt-4'
                              alt='logo'
                            />
                          </Col>
                          <Col md={10} xl={10} lg={10} sm={10} xs={10}>
                            <span className='hd_p1 mt-2'>
                              <b>{ele?.Subject}</b>
                            </span>
                            <p className='hd_p3'>
                              <b>{ele?.MessageText}</b>
                            </p>
                            <p className='hd_p2'>
                              {" "}
                              {moment(ele?.CreatedOn).format(
                                "D-MM-yyyy - hh:mm a"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            )}
          </Col>
        </div>
        <div className='bottomImageContainer w-100 '>
          <img
            src={BottomImage}
            alt='backgroundbottomImg'
            className=' bottomImage'
          />
        </div>
      </Row>

      <HelpDeskModel
        show={modelShow1}
        cancelApiFunc={closeFunc}
        setModelShow1={setModelShow1}
        header={modelData?.Subject}
        resolved={modelData?.Closed}
        date={modelData?.CreatedOn}
        body={modelData?.MessageText}
        img={modelData?.Id}
        // link={`/meeting-room-app/meeting-room`}
      />
    </>
  );
};

export default HelpDesk;
