import axios from "axios";
import { Suadi_fintech_URL } from "../constants/config";
import ChangePassword from "../pages/changePassword";
import { apiWrapper } from "./interceptors";

const request = axios.create({
  baseURL: process.env.REACT_APP_NEXDUS_BASE_URL,
  // baseURL: "https://spaces.nexudus.com",
});
const fintech = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const ChangePasswordApi = {
  newPassword: async (body) => {
    const jwt = localStorage.getItem("access Token");

    const data = await request.put(
      `/api/sys/users/setPassword`,
      {
        newPassword: body.confirmPass,
        token: localStorage.getItem("loginToken"),
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );

    return data;
  },

  updatePassword: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const data = await fintech.patch(
      `update-password/${body.userId}`,
      {
        password: body.confirmPass,
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );
    return data;
  },
};

export default apiWrapper(ChangePasswordApi, ["newPassword", "updatePassword"]);
