import axios from "axios";
import { Suadi_fintech_URL, Saudi_App_Base_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import { stageToken } from "../constants/local";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_App_Base_URL,
});

const fintechRequest = axios.create({
  baseURL: Suadi_fintech_URL,
});

const membershipInvoices = {
  membershipDetail: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/membership-details/${body}`, {
      headers: headers,
    });

    return data?.data?.data;
  },

  getUserInvoices: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    if (body) {
      const data = await request.get(`/customer-invoices/${body}`, {
        headers: headers,
      });

      return data?.data?.data;
    }
  },

  getInvoiceProducts: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/nexudus-invoice/${body}`, {
      headers: headers,
    });

    return data?.data?.data;
  },

  updateInvoice: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.post(`/invoices`, body, {
      headers: headers,
    });

    return data;
  },

  editPageHelpDesk: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.post(`/supports`, body, {
      headers: headers,
    });

    return data;
  },
};

export default apiWrapper(membershipInvoices, [
  "membershipDetail",
  "getUserInvoices",
  "getInvoiceProducts",
  "updateInvoice",
  "editPageHelpDesk",
]);
