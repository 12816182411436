import axios from "axios";
import { Suadi_fintech_URL, Saudi_App_Base_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import { stageToken } from "../constants/local";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_App_Base_URL,
});

const notificationsApi = {
  notificationSettings: async (data) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    const notification = await request.patch(
      `/users/notification-setting/${data?.userId}`,
      data?.requestData,
      {
        headers: headers,
      }
    );

    return notification;
  },

  getNotificationSettings: async (userId) => {
    if (userId) {
      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
      };
      const notification = await request.get(
        `/users/notification-setting/${userId}`,
        {
          headers: headers,
        }
      );

      return notification?.data?.notifications;
    }
  },
};

export default apiWrapper(notificationsApi, [
  "notificationSettings",
  "getNotificationSettings",
]);
