import { DayPassApp } from "../pages/dayPass/dayPassApp";
import DaypassBeforeDashboard from "../pages/dayPass/daypassBeforeDashboard";
import DayPassDashboard from "../pages/dayPass/dayPassDashboard";
import DaypassApproval from "../pages/dayPass/dayPassDashboard/daypassApproval";
import DayPassAvailability from "../pages/dayPass/dayPassDashboard/dayPassAvailability";
import PaymentUploadPhoto from "../pages/dayPass/dayPassDashboard/paymentUploadPhoto";
import ScheduleAvailability from "../pages/dayPass/dayPassDashboard/scheduleAvailability";
import DeleteAccount from "../pages/deleteAccount";
import DelAndLogout from "../pages/deleteAccount/delAndLogout";
import DeletionTerms from "../pages/deleteAccount/deletionTerms";
import ManageAccount from "../pages/deleteAccount/manageAccount";
import FAQPage from "../pages/FAQ";
import HelpDesk from "../pages/helpDesk";
import ChatSupport from "../pages/helpDesk/chatSupportFolder/chatSupport";
import CreateDesk from "../pages/helpDesk/createDesk";
import DetailFeed from "../pages/meetingRoomApp/meetingRoom/detailFeed";
import MembershipInvoice from "../pages/membershipInvoice/detailPage";
import EditPlanRequest from "../pages/membershipInvoice/editPlanRequest";
import InvoiceDetail from "../pages/membershipInvoice/invoiceDetail";
import UploadInvoice from "../pages/membershipInvoice/uploadInvoice";
import AddTeamMember from "../pages/myTeam/addTeamMemberPage";
import TeamMainPage from "../pages/myTeam/mainPage";
import TeamManagemant from "../pages/myTeam/teamManagementPage";
import TeamMembers from "../pages/myTeam/teamMembersPage";
import Settings from "../pages/settings";
import CreditPage from "../pages/teamCredits/creditsPage";
import SelectPackage from "../pages/teamCredits/creditsPage/selectPackage";
import CreditsSummary from "../pages/teamCredits/creditsSummary";
import CreditSummaryForAdmin from "../pages/teamCredits/creditSummaryForAdmin";
import UpdateProfile from "../pages/updateProfileInfo";

// daypass

const Pages = [
  { id: "136", path: "/day-pass-app", element: <DayPassApp /> },
  {
    id: "137",
    path: "/day-pass-app/day-pass-dashboard",
    element: <DayPassDashboard />,
  },
  {
    id: "138",
    path: "/day-pass-app/day-pass-availability",
    element: <DayPassAvailability />,
  },
  {
    id: "139",
    path: "/day-pass-app/day-pass-schedule-availability",
    element: <ScheduleAvailability />,
  },
  // {
  //   id: "140",
  //   path: "/day-pass-app/day-pass-schedule-availability",
  //   element: <ScheduleAvailability />,
  // },
  {
    id: "141",
    path: "/day-pass-app/payment-upload-photo",
    element: <PaymentUploadPhoto />,
  },
  // {
  //   id: "142",
  //   path: "/day-pass-app/payment-uploaded-photo",
  //   element: <PaymentUploadedPhoto />,
  // },
  {
    id: "142",
    path: "/day-pass-app/daypass-approval",
    element: <DaypassApproval />,
  },

  {
    id: "143",
    path: "/membership-invoice",
    element: <MembershipInvoice />,
  },

  {
    id: "144",
    path: "/invoice-detail",
    element: <InvoiceDetail />,
  },
  {
    id: "145",
    path: "/day-pass-app/daypass-before-dashboard",
    element: <DaypassBeforeDashboard />,
  },
  {
    id: "146",
    path: "/invoice-detail/upload",
    element: <UploadInvoice />,
  },
  {
    id: "147",
    path: "/edit-plan-reqeust",
    element: <EditPlanRequest />,
  },
  {
    id: "148",
    path: "/my-team",
    element: <TeamMainPage />,
  },
  {
    id: "149",
    path: "/my-team-management",
    element: <TeamManagemant />,
  },
  {
    id: "150",
    path: "/my-team-members",
    element: <TeamMembers />,
  },
  {
    id: "151",
    path: "/add-team-member",
    element: <AddTeamMember />,
  },

  {
    id: "152",
    path: "delete-account",
    element: <DeleteAccount />,
  },
  {
    id: "153",
    path: "deletion-terms",
    element: <DeletionTerms />,
  },
  {
    id: "154",
    path: "del-and-logout",
    element: <DelAndLogout />,
  },
  {
    id: "155",
    path: "manage-account",
    element: <ManageAccount />,
  },

  {
    id: "156",
    path: "help-desk",
    element: <HelpDesk />,
  },

  {
    id: "157",
    path: "create-desk",
    element: <CreateDesk />,
  },
  {
    id: "158",
    path: "/team-credits",
    element: <CreditPage />,
  },
  {
    id: "159",
    path: "/settings",
    element: <Settings />,
  },
  {
    id: "160",
    path: "/update-profile",
    element: <UpdateProfile />,
  },
  {
    id: "161",
    path: "/detail-feed",
    element: <DetailFeed />,
  },

  {
    id: "162",
    path: "/help-desk/help-desk-chat-support",
    element: <ChatSupport />,
  },

  {
    id: "163",
    path: "/help-desk/select-credit-package",
    element: <SelectPackage />,
  },
  {
    id: "164",
    path: "/help-desk/credits-summary",
    element: <CreditsSummary />,
  },

  {
    id: "164",
    path: "/help-desk/credit-summary-for-admin",
    element: <CreditSummaryForAdmin />,
  },

  {
    id: "165",
    path: "/faq",
    element: <FAQPage />,
  },
];

export default Pages;
