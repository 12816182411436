import { useUpdateProfileInfo } from "./useUpdateProfile";
import { Button, Col, FormGroup, Input, Row, Form, Spinner } from "reactstrap";
import LoginImg from "../../images/LoginImage.svg";
import Profile from "../../images/updateProfile.svg";
import PhoneInput from "react-phone-input-2";
import RequestModel from "../../components/molicules/requestModel";
import "react-phone-input-2/lib/style.css";
import "font-awesome/css/font-awesome.min.css";
import "./css/styles.css";

const UpdateProfile = (props) => {
  const {
    name,
    email,
    phone,
    companyName,
    data,
    confirmModel,
    updateMutation,
    handleUpdateProfile,
    setConfirmModel,
    setName,
    setEmail,
    setPhone,
    setCompanyName,
  } = useUpdateProfileInfo(props);
  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row>
            <Col
              md={5}
              lg={5}
              xl={5}
              sm={12}
              xs={12}
              className='loginImageCol '
            >
              <img src={LoginImg} alt='img' className='invoiceDetailImg ' />
            </Col>
            <Col md={7} lg={7} xl={7} sm={12} xs={12}>
              <div className='loginForm'>
                <Row className='sideCardView '>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    <Form
                    // onSubmit={}
                    >
                      <FormGroup>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <div className='lgLogoContainer'>
                              <img src={Profile} alt='img' className='lgLogo' />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <p>Personal information</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <h6>Name</h6>
                            <Input
                              id='example-date-input-text'
                              className='inputField'
                              value={name}
                              maxLength={30}
                              onChange={(e) => {
                                const input = e.target.value;
                                const filteredInput = input.replace(
                                  /[^A-Za-z\s]/g,
                                  ""
                                ); // Filter out non-alphabetic characters except spaces
                                setName(filteredInput);
                              }}
                              type='text'
                            ></Input>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <h6>Email</h6>
                            <Input
                              id='example-date-input-text'
                              className='inputField'
                              value={email}
                              disabled={true}
                              onChange={(e) => setEmail(e.target.value)}
                              type='text'
                            ></Input>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <h6>Phone</h6>

                            <PhoneInput
                              placeholder='e.g. +966 500 - 500 - 550'
                              inputProps={{ maxLength: 16, readOnly: false }}
                              disableDropdown={true}
                              countryCodeEditable={false}
                              country={"sa"}
                              name='pName'
                              containerClass='pi_PhoneInput'
                              inputStyle={{
                                borderRadius: "4px",
                                width: "100%",
                                height: "48px",
                              }}
                              style={{ width: "100%", height: "48px" }}
                              value={phone}
                              onChange={(e) => setPhone(e)}
                              required={true}
                            />
                          </Col>
                        </Row>
                        {localStorage.getItem("userTypeRedir") ===
                        "daypassUser" ? (
                          ""
                        ) : (
                          <Row>
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              <h6>Company Name</h6>
                              <Input
                                id='example-date-input-text'
                                className='inputField'
                                value={companyName}
                                disabled={!data?.IsTeamAdministrator}
                                onChange={(e) => setCompanyName(e.target.value)}
                                type='text'
                              ></Input>
                              <p>
                                Only an administrator can update company name
                              </p>
                            </Col>
                          </Row>
                        )}

                        <Row className='mt-4'>
                          {updateMutation.isLoading ? (
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              <Button
                                className='loginBtn w-100'
                                disabled={true}
                              >
                                <Spinner
                                  as='span'
                                  animation='border'
                                  size='sm'
                                  role='status'
                                  aria-hidden='true'
                                  variant='light'
                                />{" "}
                                Submitting
                              </Button>
                            </Col>
                          ) : (
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              <Button
                                className='loginBtn w-100'
                                onClick={() => handleUpdateProfile()}
                                disabled={
                                  !name ||
                                  !email ||
                                  !phone ||
                                  data?.IsTeamAdministrator
                                    ? !companyName
                                    : ""
                                    ? true
                                    : false
                                }
                              >
                                Save Details
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <RequestModel
        show={confirmModel}
        cancelModel={setConfirmModel}
        header={"Profile Update"}
        body={"Your profile has been updated"}
        request={"profileUpdate"}
      />
    </>
  );
};

export default UpdateProfile;
