import moment from "moment";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  usePostApiBookMeetingRoom,
  usePostApiReschduleMeetingRoom,
} from "../../../../rc-query/meetinRoomBookingHook";
import BookMeetingRoom from "../../../../services/bookMeetingRoomApi";
import { useEffect, useState } from "react";
import { usePostApiDayPassRoom } from "../../../../rc-query/dayPassHook";
import UseValueValue from "../../../vatValue/useValueValue";

const UseMeetingRoomBookingSummary = () => {
  const bookingDayPassRoom = usePostApiDayPassRoom();
  const queryClient = useQueryClient();
  const reschduleMode = localStorage.getItem("reschduleMode");
  const [wholePageLoading, setWholePageLoading] = useState();
  const userTypeRedirect = localStorage.getItem("userTypeRedir");
  const bookingMeetingRoom = usePostApiBookMeetingRoom();
  const reschduleMeetingRoom = usePostApiReschduleMeetingRoom();
  const [paymentprice, setPaymentprice] = useState({});
  const { vatValue } = UseValueValue();
  const Navigate = useNavigate();
  const state = useLocation();
  const {
    dataOfSelectedObject,
    bookingroomsData,
    scheduleDate,
    scheduleTime,
    scheduleNewTime,
    repeatbookingShow,
    repeatDaysState,
    durationColorState,
    otherInvitesArr,
    selectedTeamArr,
    selectedBookingRoomData,
    teamName,
  } = state?.state;

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectedBookingRoomDataa = selectedBookingRoomData
    ? selectedBookingRoomData
    : bookingroomsData[0];

  const combinedSelectedTeamArray = selectedTeamArr.concat(otherInvitesArr);
  const fromTimeCombined = moment(
    scheduleDate + " " + scheduleTime,
    "DD/MM/YYYY HH:mm "
  );

  const toTimeCombined = moment(
    scheduleDate + " " + scheduleNewTime,
    "DD/MM/YYYY HH:mm "
  );

  // ********* booking-settings start ***********
  const { data: bookingSettingsData, isLoading: bookingSettingsLoading } =
    useQuery(["bookingSettings"], () => BookMeetingRoom.getbookingSettings(), {
      retry: 0,
    });
  const daypassConfirm = bookingSettingsData?.data?.setting?.daypassConfirm;
  const passUserMeetingRoomConfirmation =
    bookingSettingsData?.data?.setting?.passUserMeetingRoomConfirmation;
  const memberMeetingRoomConfirmation =
    bookingSettingsData?.data?.setting?.memberMeetingRoomConfirmation;

  // ********* booking-settings end ***********

  // ******coworker List start *********

  const { data: coworkerListData } = useQuery(
    ["coworkerListData"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );

  // ********* coworker List end ***********

  // ******payment List start *********

  const { data: paymentListData, isLoading: paymentLoading } = useQuery(
    ["paymentListData", bookingroomsData[0]?.Id],
    () => BookMeetingRoom.getMeetingBookingspayment(bookingroomsData[0]?.Id),
    {
      retry: 0,
    }
  );

  // const paymentprice = paymentListData?.data?.resource;

  // Api changed in the inteructions of haider same meeting room as day pass price Api

  useEffect(() => {
    const toNewTt = moment(scheduleNewTime).format("HH:mm");

    const toTime = scheduleDate + `T${toNewTt}:00.000Z`;
    const toTimeDateString = new Date(toTimeCombined?._i);
    const isoToTimeString = toTime;
    const fromTimeDateString = moment.utc(
      fromTimeCombined?._i,
      "YYYY-MM-DD HH:mm"
    );
    const isoFromTimeString = fromTimeDateString.toISOString();

    bookingDayPassRoom
      .mutateAsync({
        TypeName: "booking",
        ResourceId: selectedBookingRoomData
          ? selectedBookingRoomData?.Id
          : bookingroomsData[0]?.Id,
        CoworkerId: coworkerListData?.Id,
        FromTime: isoFromTimeString,
        ToTime: isoToTimeString,
      })
      .then((data) => {
        if (data?.status === 200 && data?.data?.statusCode === 200) {
          // setPricingData(data?.data);

          setPaymentprice(data?.data);
        } else if (data?.data?.statusCode === 400) {
          toast.error(data?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {});
  }, [coworkerListData?.Id]);

  // ******payment List end *********

  const bookFunc = () => {
    setWholePageLoading("true");
    const toNewTt = moment(scheduleNewTime).format("HH:mm");

    const toTime = scheduleDate + `T${toNewTt}:00.000Z`;
    const toTimeDateString = new Date(toTimeCombined?._i);
    // const isoToTimeString = toTime;
    const fromTimeDateString = moment.utc(
      fromTimeCombined?._i,
      "YYYY-MM-DD HH:mm"
    );
    // const isoFromTimeString = fromTimeDateString.toISOString();

    const isoToTimeString = moment(toTime).subtract(
      moment().utcOffset(),
      "minutes"
    );
    const isoFromTimeString = moment(fromTimeDateString).subtract(
      moment().utcOffset(),
      "minutes"
    );

    const obj1 = {
      vatPercentage :Number(vatValue?.setting?.taxRate),

      unitPrice:Number((paymentprice?.Price?.EstimatedCost)+ (paymentprice?.Price?.DiscountAmount ?paymentprice?.Price?.DiscountAmount :0))/(durationColorState / 60),

      totalPayable:Number((Number(
        ( paymentprice?.Price?.EstimatedCost)
         +
           (vatValue?.setting?.isTaxEnable === "true"
             ? (Number(vatValue?.setting?.taxRate) /
                 100) *
               paymentprice?.Price?.EstimatedCost
             : 0)
       ).toFixed(2)
       )
       ), 

      subTotal:Number(paymentprice?.Price?.EstimatedCost+
        (paymentprice?.Price?.DiscountAmount ?paymentprice?.Price?.DiscountAmount :0)), 

      vat:Number(vatValue?.setting?.isTaxEnable === "true"
      ? Number(
          (Number(vatValue?.setting?.taxRate) / 100) *
            paymentprice?.Price?.EstimatedCost
        ).toFixed(2)
      : 0),
      ResourceId: selectedBookingRoomData
        ? selectedBookingRoomData?.Id
        : bookingroomsData[0]?.Id,
      isRepeatBooking: repeatDaysState?.value ? true : false,
      isTeamBooking: true,
      FromTime: isoFromTimeString,
      ToTime: isoToTimeString,
      BookingVisitors: combinedSelectedTeamArray,
      Team: teamName,
      // BookedOn: new Date().toISOString(),
      BookedOn: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString(),
      ResourceName: selectedBookingRoomData
        ? selectedBookingRoomData?.Title
        : bookingroomsData[0]?.Title,
      // Price: durationColorState + paymentprice?.Price?.EstimatedCost,
      Price: Number(
        Number(
          paymentprice?.Price?.EstimatedCost +
            (vatValue?.setting?.isTaxEnable === "true"
              ? (Number(vatValue?.setting?.taxRate) / 100) *
                paymentprice?.Price?.EstimatedCost
              : 0)
        ).toFixed(2)
      ),
      CoworkerId: coworkerListData?.Id,
      CoworkerName: coworkerListData?.FullName,
    };

    if (
      userTypeRedirect === "meetingroomAdminstrator" ||
      userTypeRedirect === "meetingroomTeamMember"
    ) {
      obj1.Tentative = memberMeetingRoomConfirmation;
    } else if (userTypeRedirect === "daypassUser") {
      obj1.Tentative = passUserMeetingRoomConfirmation;
    }

    if (repeatDaysState?.value) {
      if (repeatDaysState?.label === "Every Day") {
        obj1.RepeatEvery = 1;
        obj1.Repeats = "1";
        obj1.contractId = Number(coworkerListData?.CoworkerContractIds);
      } else if (repeatDaysState?.label === "Every Week") {
        obj1.RepeatEvery = 1;
        obj1.Repeats = "2";
        obj1.contractId = Number(coworkerListData?.CoworkerContractIds);
      } else if (repeatDaysState?.label === "Every Two Weeks") {
        obj1.RepeatEvery = 2;
        obj1.Repeats = "2";
        obj1.contractId = Number(coworkerListData?.CoworkerContractIds);
      } else if (repeatDaysState?.label === "Every Month") {
        obj1.RepeatEvery = 1;
        obj1.Repeats = "3";
        obj1.contractId = Number(coworkerListData?.CoworkerContractIds);
      }
    }

    // **********if reschedule mode then this Api will be run else below *********

    if (reschduleMode === "true" && dataOfSelectedObject) {
      obj1.isRescheduleRequest = true;
      obj1.rescheduleId = dataOfSelectedObject?.id;

      // first we are creting new booking and then we will call the reschedule booking Api

      // first

      reschduleMeetingRoom
        .mutateAsync(obj1)
        .then((data1) => {
          // setWholePageLoading("false");

          if (
            data1?.data?.statusCode === 200 &&
            data1?.data?.message !== "Meeting Booking cannot be rescheduled"
          ) {
            // if condition
            //second

            bookingMeetingRoom
              .mutateAsync(obj1)
              .then((data) => {
                setWholePageLoading("false");
                if (
                  (data?.status === "200" && data?.data?.statusCode === 200) ||
                  (data?.status === 200 && data?.data?.statusCode === 200)
                ) {
                  if (
                    paymentprice?.Price?.EstimatedCost > 0 &&
                    localStorage.getItem("daypassMode") === "modeIsMeetingroom"
                  ) {
                    Navigate("/meeting-room-app/payment-upload-photo", {
                      state: {
                        confirmData: data?.data,
                      },
                    });
                    // Navigate("/meeting-room-app/meeting-room-confirmed");
                  } else {
                    Navigate("/meeting-room-app/meeting-room-confirmed");
                  }
                  queryClient.invalidateQueries("customerbookingroomsData");

                  // toast.success(`${data?.data?.Message}`, {
                  //   position: toast.POSITION.TOP_RIGHT,
                  // });
                } else if (
                  data?.data?.message ===
                  "Meeting Booking cannot be rescheduled"
                ) {
                  toast.error("Meeting Booking cannot be rescheduled", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else if (data?.data?.statusCode === 400) {
                  toast.error(data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else if (
                  data?.data?.message === "Failed to Book Meeting Room"
                ) {
                  toast.error("Failed to Book Meeting Room", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch((err) => {
                setWholePageLoading("false");
                toast.error(err?.response?.data?.error?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          } else if (data1?.data?.statusCode === 400 || data1?.data?.statusCode === "400") {
            toast.error(data1?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          setWholePageLoading("false");
          toast.error(err?.response?.data?.error?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
    // **********if simple mode mode then this Api will be run else below *********
    else {
      bookingMeetingRoom
        .mutateAsync(obj1)
        .then((data) => {
          setWholePageLoading("false");
          if (
            // data?.data?.access_token &&
            data?.data?.statusCode === "200" ||
            data?.data?.statusCode === 200
          ) {
            if (
              paymentprice?.Price?.EstimatedCost > 0 &&
              localStorage.getItem("daypassMode") === "modeIsMeetingroom"
            ) {
              Navigate("/meeting-room-app/payment-upload-photo", {
                state: {
                  confirmData: data?.data,
                },
              });
              queryClient.invalidateQueries("customerbookingroomsData");
            } else {
              Navigate("/meeting-room-app/meeting-room-confirmed");
            }

            // toast.success(`${data?.data?.Message}`, {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
          } else if (data?.data?.statusCode === 400) {
            toast.error(data?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          setWholePageLoading("false");
          toast.error(err?.response?.data?.error?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return {
    wholePageLoading,
    setWholePageLoading,
    Navigate,
    bookingroomsData,
    scheduleDate,
    scheduleTime,
    scheduleNewTime,
    repeatbookingShow,
    repeatDaysState,
    durationColorState,
    otherInvitesArr,
    selectedTeamArr,
    combinedSelectedTeamArray,
    selectedBookingRoomData,
    selectedBookingRoomDataa,
    paymentprice,
    bookFunc,
    paymentLoading,
  };
};

export default UseMeetingRoomBookingSummary;
