import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MessageBox } from "react-chat-elements";
import HelpDeskApi from "../../../services/helpDeskApi";
import { useQuery, useQueryClient } from "react-query";
import BookMeetingRoom from "../../../services/bookMeetingRoomApi";
import { useState } from "react";
import { usePostChatHelpDesk } from "../../../rc-query/helpDeskHook";
import { toast } from "react-toastify";

const UseChatSupport = () => {
  const queryClient = useQueryClient();
  const postChatHelpDesk = usePostChatHelpDesk();
  const { state } = useLocation();
  const [inputValue, setInputValue] = useState("");
  const [postLoading, setPostLoading] = useState(false);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ****** Api of coworker Id *********

  const { data: coworkerListData, isLoading: coworkerLoading } = useQuery(
    ["coworkerListData"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );

  const coworkerId = coworkerListData?.Id;

  // *********  get user chat data and admin chat data ***********

  const { data: clientAndAdminData } = useQuery(
    ["clientAndAdminData"],
    () => HelpDeskApi.clientAndAdminDataApi(state.Id),
    {
      retry: 0,
    }
  );

  const clientAndAdminDataa = clientAndAdminData?.data?.data;

  const getImageUrl = () => {
    const imgSource = process.env.REACT_APP_IMG_SOURCE;
    if (imgSource && state?.Id) {
      return `${imgSource}${state.Id}`;
    }
    return null;
  };

  const renderMessageBox = () => {
    const imageUrl = getImageUrl();
    if (imageUrl) {
      return (
        <div>
          <MessageBox
            date={state?.CreatedOn}
            position='right'
            type='photo'
            data={{
              uri: imageUrl,
            }}
          />
        </div>
      );
    }
    return <div>No image available.</div>;
  };

  const PostChatFunc = (e) => {
    setPostLoading(true);
    e.preventDefault();
    postChatHelpDesk
      .mutateAsync({
        HelpDeskMessage: state?.Id,
        MessageText: inputValue,
      })
      .then((data) => {
        setInputValue("");
        if (data?.data?.SuccessMessage === "YourMessageHasBeenPosted") {
          queryClient.invalidateQueries("clientAndAdminData");
          setPostLoading(false);
        }
      })
      .catch((err) => {
        // err?.response?.data?.error?.message;
        setPostLoading(false);
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  return {
    postLoading,
    state,
    renderMessageBox,
    coworkerId,
    clientAndAdminDataa,
    PostChatFunc,
    inputValue,
    setInputValue,
  };
};

export default UseChatSupport;
