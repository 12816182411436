import React from "react";
import { Row, Col, Card, Label } from "reactstrap";
import Mem_active from "../../.././images/mem_active.svg";
import { Image, Breathing } from "react-shimmer";
import UserImg from "../../.././images/user.svg";
import PrivateOfficeImg from "../../.././images/private_office.svg";
import SummaryDuration from "../../.././images/summary_duration.svg";
import SummaryPayment from "../../.././images/summary_payment.svg";
import SummaryTeam from "../../.././images/summary_team.svg";
import BottomImage from "../../.././images/fintechBgImg1.svg";
import UsersImg from "../../.././images/user.svg";
import UseRequestStatusPage from "./useRequestStatusPage";
import moment from "moment";
import ".././css/styles.css";
import UseValueValue from "../../vatValue/useValueValue";

const RequestStatusPage = () => {
  const { vatValue } = UseValueValue();
  const { summaryData, setHideDetails, hideDetails } = UseRequestStatusPage();

  return (
    <div>
      <Row className='mb-5'>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}></Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
          <Row className='u_r_row_color pt-3 mt-5'>
            <Col className='mx-2' xl={2} lg={2} md={2} sm={12} xs={12}>
              <img src={Mem_active} alt='logo' />
            </Col>
            <Col
              className='mx-3  my-2 px-2'
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <div className='d-flex justify-content-between'>
                <p className='u_r_l1'>Request status </p>
                {!hideDetails ? (
                  <p
                    className='u_r_hide_details '
                    onClick={() => {
                      setHideDetails(true);
                    }}
                  >
                    {" "}
                    Hide Details
                  </p>
                ) : (
                  <p
                    className='u_r_hide_details '
                    onClick={() => {
                      setHideDetails(false);
                    }}
                  >
                    {" "}
                    Show Details
                  </p>
                )}
              </div>
              <p className='u_r_l2'>
                Please wait, your team membership request is in processing.
              </p>
            </Col>
          </Row>
        </Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}></Col>
      </Row>

      {!hideDetails ? (
        <div>
          <Row className='mt-5'>
            <Col className='mb-4' xl={2} lg={2} md={2} sm={12} xs={12}></Col>
            <Col className='mb-4' xl={4} lg={4} md={4} sm={12} xs={12}>
              <Card className='p-3  db_card  w-100 h-100 '>
                <Image
                  src={PrivateOfficeImg}
                  fallback={<Breathing width='100' />}
                  className='w-100 h-100'
                />
                <span className='d-flex pos_cardusersicon mt-3 '>
                  <Label className='pos_h2  '>
                    {summaryData?.planType}
                    {/* {selectedResourceName} */}
                  </Label>
                  <span className=''>
                    <img className='mx-2 ' alt='img' src={UsersImg} />
                    <Label className='pos_total_users '>
                      {summaryData?.teamMembers?.length}
                    </Label>
                  </span>
                </span>
              </Card>
            </Col>
            <Col className='mb-4' xl={4} lg={4} md={4} sm={12} xs={12}>
              <Card className='p-2 pt-4  db_card  w-100 h-100 '>
                <Row>
                  <Col className='mx-3' xl={1} lg={1} md={1} sm={1} xs={1}>
                    <img src={SummaryDuration} alt='logo' className='sum_img' />
                  </Col>
                  <Col xl={10} lg={10} md={10} sm={12} xs={12}>
                    <p className='pos_h1'>Duration</p>
                    <p className='pos_h3'>
                      <b>Expected Dates</b>
                    </p>
                    <span className='pos_display_inline1'>
                      <Label className='pos_h2  '>
                        {moment(summaryData?.startDate).format("DD-MM-YYYY")}
                      </Label>

                      <Label className='pos_h2 mx-4 '>
                        {moment(
                          moment(summaryData?.startDate).format("DD-MM-YYYY"),
                          "DD-MM-YYYY"
                        )
                          .add(summaryData?.RenewalMonths, "months")
                          .format("DD-MM-YYYY")}
                      </Label>
                    </span>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xl={2} lg={2} md={2} sm={12} xs={12}></Col>
          </Row>

          <Row>
            <Col className='mb-4' xl={2} lg={2} md={2} sm={12} xs={12}></Col>
            <Col className='mb-4' xl={4} lg={4} md={4} sm={12} xs={12}>
              <Card className='p-2 pt-4 db_card  w-100 h-100 '>
                {/* {leadSumaryLoading ? (
              <div className='d-flex justify-content-center align-items-center my-auto'>
                <Spinner />
              </div>
            ) : ( */}
                <Row>
                  <Col className='mx-3' xl={1} lg={1} md={1} sm={1} xs={1}>
                    <img
                      src={SummaryPayment}
                      alt='logo'
                      // className='mt-2'
                      className='sum_img'
                    />
                  </Col>
                  <Col className='' xl={10} lg={10} md={10} sm={10} xs={10}>
                    <p className='pos_h1'>Payment</p>
                    <span className='pos_display_inline'>
                      <Label className='pos_h2 mt-2'>Plan</Label>
                      <Label className='pos_h4 mt-2'>
                        {summaryData?.planDuration}
                      </Label>
                    </span>

                    <span className='pos_display_inline'>
                      <Label className=' pos_h2'>                       {summaryData?.planType}
                      </Label>
                      
                      <Label className='pos_h4'>SAR {summaryData?.privateOfficePrice + summaryData?.dedicatedDeskPrice}</Label>
                    </span>
                    <hr />
                    <span className='pos_display_inline'>
                      <Label className='pos_h2 '>Plan fee</Label>
                      <Label className='pos_h4'>0</Label>
                    </span>

                    <hr />
                    <span className='pos_display_inline'>
                      <Label className='pos_h2 '>Vat</Label>
                      <Label className='pos_h4'>
                        {vatValue?.setting?.isTaxEnable === "true"
                          ? Number(
                              (Number(vatValue?.setting?.taxRate) / 100) *
                                (summaryData?.privateOfficePrice + summaryData?.dedicatedDeskPrice)
                            ).toFixed(2)
                          : 0}
                      </Label>
                    </span>

                    <span className='pos_display_inline'>
                      <Label className='pos_h4'>Total payable</Label>
                      <Label className='pos_h5'>
                        SAR{" "}
                        {((summaryData?.privateOfficePrice + summaryData?.dedicatedDeskPrice) +
                          (vatValue?.setting?.isTaxEnable === "true"
                            ? Number(
                                (Number(vatValue?.setting?.taxRate) / 100) *
                                  (summaryData?.privateOfficePrice + summaryData?.dedicatedDeskPrice)
                              )
                            : 0)).toFixed(2)}
                      </Label>
                    </span>
                  </Col>
                </Row>
                {/* )} */}
              </Card>
            </Col>
            <Col className='mb-4' xl={4} lg={4} md={4} sm={12} xs={12}>
              <Card className='p-2  pt-4 db_card  w-100 h-100 '>
                <Row>
                  <Col className='mx-3' xl={1} lg={1} md={1} sm={1} xs={1}>
                    <img src={SummaryTeam} alt='logo' className='sum_img' />
                  </Col>
                  <Col className='mb-4' xl={10} lg={10} md={10} sm={10} xs={10}>
                    <p className='pos_h1'>Team</p>

                    <h5 className=' pos_h4 '>
                      {" "}
                      {summaryData?.teamMembers?.map((e, i) => {
                        if (e?.isAdministrator === true) {
                          return (
                            <span className='d-flex px-2' key={i + 3892}>
                              <span className=' pos_h4'>{e?.fullName}</span>
                            </span>
                          );
                        }
                      })}
                    </h5>
                    {summaryData?.teamMembers?.map((e, i) => {
                      if (e?.isAdministrator === true) {
                        return (
                          <Label className='pos_administrator'>
                            Administrator
                          </Label>
                        );
                      }
                    })}
                    {summaryData?.teamMembers?.map((e, i) => {
                      if (e?.isPayingMember === true) {
                        return (
                          <Label className='pos_paying_member'>
                            Paying member
                          </Label>
                        );
                      }
                    })}
                  </Col>
                </Row>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    {summaryData?.teamMembers?.map((e, i) => {
                      if (e?.isAdministrator === false) {
                        return (
                          <p className=' px-2'>
                            <span className=' pos_h4'>
                              <Label className='d-flex px-2' key={i + 3892}>
                                <img className='mx-2' alt='img' src={UserImg} />
                                <span className=' pos_h4'>{e?.fullName}</span>
                              </Label>
                            </span>
                          </p>
                        );
                      }
                    })}
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xl={2} lg={2} md={2} sm={12} xs={12}></Col>
          </Row>
        </div>
      ) : (
        ""
      )}
      <div className='bottomImageContainer w-100 '>
        <img src={BottomImage} alt='backgroundbottomImg' className=' w-100' />
      </div>
    </div>
  );
};

export default RequestStatusPage;
