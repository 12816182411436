import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, Col, Input, Label, Row, Spinner } from "reactstrap";
import Select from "react-select";
import moment from "moment/moment";
import UsersImg from "./../../../.././images/users.svg";
import UseSchduleMeetingRoom from "./useSchduleMeetingRoom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "./css/style.css";
import CloseButtonComp from "../../../../components/molicules/closeButton";
import { toast } from "react-toastify";

const ScheduleMeetingRoom = () => {
  // const [isFirstBox , setIsFirstBox] = useState(true)
  var errorTrue = false;
  var isFirstBox = true;
  var isLastBox = false;

  const leftBorderFunc = (n) => {
    // if (isFirstBox) {

    //   isFirstBox = false;
    //   return true;
    // } else {
    //   return false;
    // }

    const isAtRightBorder = selectedBorderArr[0];
    const allValuesInNExist = Object.keys(n).every(
      (key) =>
        isAtRightBorder.hasOwnProperty(key) && isAtRightBorder[key] === n[key]
    );
    return allValuesInNExist;
  };

  const rightBorderFunc = (n) => {
    const isAtRightBorder = selectedBorderArr[selectedBorderArr.length - 1];
    const allValuesInNExist = Object.keys(n).every(
      (key) =>
        isAtRightBorder.hasOwnProperty(key) && isAtRightBorder[key] === n[key]
    );
    return allValuesInNExist;
  };

  const {
    dayPassActivityAvail,
    reschduleMode,
    dataOfSelectedObject,
    coworkerListData,
    timeLineBar,
    repeatDaysState,
    setSelectedBookingRoomData,
    setRepeatDaysState,
    bookingroomsData,
    scheduleDate,
    setScheduleDate,
    scheduleTime,
    setScheduleTime,
    scheduleNewTime,
    setScheduleNewTime,
    timeLineBar2,
    bookingroomsTimeLineDataaLoading,
    durationColorState,
    setDurationColorState,
    repeatbookingShow,
    setReapetBookingShow,
    customStyles,
    repeatDaysObj,
    selectedBorderArr,
    selectedBookingRoomData,
    Navigate,
  } = UseSchduleMeetingRoom();

  const isValidDate = (current) => {
    // const businessHoursStart = 6; // 9:00 AM
    // const businessHoursEnd = 17; // 5:00 PM
    // if (!current) return false;
    // const currentHour = current.hours();
    // // Allow dates within business hours
    // return currentHour >= businessHoursStart && currentHour <= businessHoursEnd;
  };

  // try of auto scoll

  const userType = localStorage.getItem("userTypeRedir");

  const newDate = new Date();

  const [selectingStateOfCard, setSelectingStateOfCard] = useState(
    dataOfSelectedObject
      ? dataOfSelectedObject?.ResourceId
      : bookingroomsData[0]?.Id
  );

  useEffect(() => {
    setSelectingStateOfCard(bookingroomsData[0]?.Id);
  }, []);

  // start and end time for show in input
  const displayValue =
    // scheduleTime
    // ? moment(scheduleTime).format("hh:mm a")
    // : "" +
    " " + scheduleNewTime !== undefined
      ? " - " + moment(scheduleNewTime).format("hh:mm a")
      : "";

  const TextExtractor = (description) => {
    if (description) {
      const match = description.match(/<span[^>]*>([^<]*)<\/span>/);
      const text = match && match[1];

      return text;
    }
  };

  var porceedVer = 0;
  const timeConstraints = {
    minutes: {
      step: 15,
    },
  };

  const inputProps = {
    placeholder: `${
      reschduleMode === "true" || scheduleTime ? scheduleTime : "select Time"
    }`,
    readOnly: false,
    className: "smr_dateTime w-100 px-3",
    style: { minHeight: "62px" },
  };

  const wrapperStyle = {
    width: "100%",
    innerWidth: "100%",
  };
  // const calendarStyle = {
  //   minWidth: "100%", // Set the minimum width of the calendar
  //   width: "auto", // Allow the calendar to expand to fit its contents
  // };

  const scrollableDiv = document.getElementById("scrollable-div");

  const FirstMintsLoop = (ele) => {
    if (
      selectedBorderArr?.some(
        (k) =>
          Number(ele?.minutes) === Number(k?.minutes) &&
          Number(ele?.hours) === Number(k?.hours) &&
          ele?.hourDesignation === k?.hourDesignation
      )
    ) {
      // exp of auto scroll
      // myDivRef.current.scrollLeft = Number(ele?.hours);

      return true;
    } else {
      return false;
    }
  };

  // const SecondMintsLoop = () => {
  //   if (
  //     selectedBorderArr?.some((p, i) =>
  //       timeLineBar2?.some(
  //         (item, ind) =>

  //           Number(item.minutesBackend) === Number(p?.minutes) &&
  //           Number(item.hoursBackend) === Number(p?.hours) &&
  //           item.hourDesignation2 === p?.hourDesignation
  //         //   &&
  //         // ind === 0
  //       )
  //     )
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const SecondMintsLoop = () => {
    if (
      selectedBorderArr?.some((p, i) =>
        timeLineBar2?.some((item, ind) => {
          // setIsFirstBox( ( Number(timeLineBar2[0].minutesBackend) === Number(p?.minutes) &&
          // Number(timeLineBar2[0].hoursBackend) === Number(p?.hours) &&
          // timeLineBar2[0].hourDesignation2 === p?.hourDesignation) ? true : false);
          return (
            Number(item.minutesBackend) === Number(p?.minutes) &&
            Number(item.hoursBackend) === Number(p?.hours) &&
            item.hourDesignation2 === p?.hourDesignation
          );
        })
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const thirdMintsLoop = (ele) => {
    if (
      timeLineBar2?.some(
        (item) =>
          Number(item.minutesBackend) === Number(ele?.minutes) &&
          Number(item.hoursBackend) === Number(ele?.hours) &&
          item.hourDesignation2 === ele?.hourDesignation
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const thirdHoursLoop = (ele) => {
    if (
      timeLineBar2?.some(
        (item) =>
          Number(item.tt) === Number(ele?.hours) &&
          item.hourDesignation2 === ele?.hourDesignation
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const firrstHoursLoop = (ele) => {
    if (
      selectedBorderArr?.some(
        (k) =>
          Number(ele?.hours) === Number(k?.hours) &&
          ele?.hourDesignation === k?.hourDesignation &&
          // i have added this check in the later to remove exra hour that was showing in that case of 15,30,45
          k?.minutes === 0
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const secondHoursLoop = (ele) => {
    if (
      selectedBorderArr?.some((l, i) =>
        timeLineBar2?.some(
          (m) =>
            Number(m.tt) === Number(l?.hours) &&
            m.hourDesignation2 === l?.hourDesignation
        )
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className='p-4'>
      <Row>
        <Col xl={11} lg={11} md={11} sm={10} xs={10}>
          <Label className='smr_h1'>Schedule</Label>
        </Col>
        <Col xl={1} lg={1} md={1} sm={2} xs={2}>
          <div>{<CloseButtonComp />}</div>
        </Col>
      </Row>

      <Row>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
          <Label className='smr_h2'>Date</Label>
        </Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
          <Label className='smr_h2 kforhide'>Start Time</Label>
        </Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
          <Label className='smr_h2 kforhide'>Duration</Label>
        </Col>
      </Row>

      <Row>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
          <Label className='smr_h2'>
            <Input
              type='date'
              className='smr_dateTime'
              style={{ minHeight: "62px" }}
              value={scheduleDate}
              // calendarStyle={calendarStyle}
              // innerWidth='500px'
              min={new Date().toISOString().slice(0, 10)}
              onChange={(e) => {
                setScheduleDate(e?.target?.value);
              }}
            />
          </Label>
        </Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
          <Label className='smr_h2 '>
            <div style={wrapperStyle}>
              <Datetime
                isValidDate={isValidDate}
                timeFormat={true}
                dateFormat={false}
                inputProps={inputProps}
                timeConstraints={timeConstraints}
                onChange={(e) => {
                  const currentDateFormatted =
                    moment().format("YYYY-MM-DD HH:mm A");
                  const scheduleDateFormatted =
                    moment(scheduleDate).format("YYYY-MM-DD") +
                    " " +
                    e.format("HH:mm A");

                  if (currentDateFormatted < scheduleDateFormatted) {
                    errorTrue = false;
                    const formattedTime = e.format("HH:mm");
                    setScheduleTime(formattedTime);
                    setDurationColorState();
                  } else {
                    if (errorTrue === false) {
                      toast.error("Time should be greater then current time", {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                      errorTrue = true;
                    }
                  }
                  // porceedVer = 1;
                }}
              />
            </div>
          </Label>
        </Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
          <span className='d-flex mt-3'>
            <span
              className={` mx-1 ${
                durationColorState === 30 ? "smr_dur_h01" : "smr_dur_h1"
              }`}
              onClick={() => {
                var date = moment(scheduleTime, "hh:mm:ss A").add(
                  30,
                  "minutes"
                );

                setScheduleNewTime(date?._d);
                setDurationColorState(30);
              }}
            >
              30 m
            </span>

            <span
              className={` mx-1 ${
                durationColorState === 60 ? "smr_dur_h01" : "smr_dur_h1"
              }`}
              onClick={() => {
                var date = moment(scheduleTime, "hh:mm:ss A").add(
                  60,
                  "minutes"
                );

                setScheduleNewTime(date?._d);
                setDurationColorState(60);

                // if (scheduleNewTime !== null) {
                //   scrollableDiv.scrollLeft = moment(scheduleNewTime, "hh A");
                // }
              }}
            >
              1 h
            </span>
            <span
              className={` mx-1 ${
                durationColorState === 90 ? "smr_dur_h01" : "smr_dur_h1"
              }`}
              onClick={() => {
                var date = moment(scheduleTime, "hh:mm:ss A").add(
                  90,
                  "minutes"
                );

                setScheduleNewTime(date?._d);
                setDurationColorState(90);
              }}
            >
              1.5 h
            </span>
            <span
              className={` mx-1 ${
                durationColorState === 120 ? "smr_dur_h01" : "smr_dur_h1"
              }`}
              onClick={() => {
                var date = moment(scheduleTime, "hh:mm:ss A").add(
                  120,
                  "minutes"
                );

                setScheduleNewTime(date?._d);
                setDurationColorState(120);
              }}
            >
              2 h
            </span>
          </span>
        </Col>
      </Row>

      {!dayPassActivityAvail && userType === "daypassUser" ? (
        <>
          <hr />
          <p className='my-3 smr_h1_red'>
            Sorry! You must have a day pass booking for this date before you can
            book a meeting room.
          </p>
        </>
      ) : (
        ""
      )}

      <hr />
      {(coworkerListData?.TeamIds === "" ||
        coworkerListData?.TeamIds === null ||
        coworkerListData?.TeamIds === undefined) &&
      coworkerListData?.isAdministrator === false ? (
        ""
      ) : userType === "daypassUser" ? (
        ""
      ) : (
        <>
          <Row>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <Label className='smr_h2'>Repeat Booking</Label>
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              {repeatbookingShow ? (
                <Label className='kforhide'>Select Repeat Days</Label>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            <Col xl={1} lg={1} md={1} sm={6} xs={6}>
              <Button
                color={`${repeatbookingShow ? "dark" : "light"}`}
                className={`${
                  repeatbookingShow ? "w-100" : "w-100 smr_yes_btn"
                }`}
                onClick={() => setReapetBookingShow(true)}
              >
                Yes
              </Button>
            </Col>
            <Col xl={1} lg={1} md={1} sm={6} xs={6}>
              <Button
                color={`${!repeatbookingShow ? "dark" : "light"}`}
                className={`${
                  !repeatbookingShow ? "w-100" : "w-100 smr_yes_btn"
                }`}
                onClick={() => setReapetBookingShow(false)}
              >
                No
              </Button>
            </Col>
            <Col xl={2} lg={2} md={2} sm={12} xs={12}></Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12} className='sm_top_margn'>
              {repeatbookingShow ? (
                <Select
                  value={{
                    label: repeatDaysState?.label,
                    value: repeatDaysState?.value,
                  }}
                  styles={customStyles}
                  options={repeatDaysObj}
                  onChange={(e) => setRepeatDaysState(e)}
                />
              ) : (
                ""
              )}
            </Col>
          </Row>

          <hr />
        </>
      )}

      <Row className='mb-3'>
        <Label className='smr_h2'>Select meeting room</Label>
        {/* // card  */}

        {bookingroomsData?.map((z) => {
          return (
            <Col
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              key={z?.Id + 33}
              onClick={() => {
                setSelectedBookingRoomData(z);
                setSelectingStateOfCard(z?.Id);
              }}
            >
              <Card
                id={z?.Id}
                className={`   w-100 mt-3 ${
                  selectingStateOfCard === z?.Id ? "s_o_cc_Selected" : "s_o_cc"
                }`}
              >
                <div className='p-2'>
                  <img
                    src={`https://nexudus.spaces.nexudus.com//en/publicresources/getimage/${z?.Id}?w=565&h=210&anchor=middlecenter&cache=2023-03-16T07:23:02Z`}
                    alt='img'
                    className='w-100 h-100'
                  />

                  <span className='d-flex pos_cardusersicon mt-3 '>
                    <Label className='so_h2  '>{z?.Title}</Label>

                    <span className=''>
                      <img className='mx-2 ' alt='img' src={UsersImg} />
                      <Label className='so_total_users '>{z?.Allocation}</Label>
                    </span>
                  </span>
                  <Label className='m_rB_l3 mt-2 '>
                    {TextExtractor(z?.Description)}
                  </Label>
                </div>
                {bookingroomsTimeLineDataaLoading ? (
                  <div className='d-flex justify-content-center'>
                    <Spinner
                      type='grow'
                      color='info'
                      className='justify-item-center mx-2'
                    />
                    <Spinner
                      type='grow'
                      color='info'
                      className='justify-item-center mx-2'
                    />
                    <Spinner
                      type='grow'
                      color='info'
                      className='justify-item-center mx-2'
                    />
                  </div>
                ) : (
                  <div
                    className={`
               timeline_parent
               `}
                  >
                    <div           
                      className='timeline_child '
                    >
                      {timeLineBar?.map((ele, n) => {
                        if (ele?.minutes !== 0) {
                          return (
                            <div
                              key={n + n}
                              className={`${
                                FirstMintsLoop(ele)
                                  ? SecondMintsLoop()
                                    ? ((porceedVer = 1),
                                      // `
                                      `selectedTimeBorderWrong
                                      ${
                                        ele?.minutes !== 0 &&
                                        leftBorderFunc(ele)
                                          ? `first-box-red`
                                          : ""
                                      } ${
                                        ele?.minutes !== 0 &&
                                        rightBorderFunc(ele)
                                          ? `last-box-red`
                                          : ""
                                      }
                                      `)
                                    : ((porceedVer = 2),
                                      `selectedTimeBorder ${
                                        ele?.minutes !== 0 &&
                                        leftBorderFunc(ele)
                                          ? `first-box`
                                          : ""
                                      } ${
                                        ele?.minutes !== 0 &&
                                        rightBorderFunc(ele)
                                          ? `last-box`
                                          : ""
                                      } `)
                                  : ""
                              }
                             
                              
                              `}
                            >
                              <div
                                key={n + 39204}
                                className={`  px-2 pt-3  ${
                                  thirdMintsLoop(ele)
                                    ? "timeline_minutes-not "
                                    : `timeline_minutes 
                                    `
                                } 
                                `}
                              >
                                {ele?.minutes}
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              // border color red or blue
                              className={`${
                                firrstHoursLoop(ele)
                                  ? secondHoursLoop(ele)
                                    ? //  timeLineBar2?.totaltime ===
                                      //   secondHoursLoop[0]?.totaltime
                                      //   ? "selectedTimeLeftBorderWrong"
                                      //   :
                                      ((porceedVer = 1),
                                      `selectedTimeBorderWrong  ${
                                        ele?.minutes === 0 &&
                                        leftBorderFunc(ele)
                                          ? `first-box-red`
                                          : ""
                                      } ${
                                        ele?.minutes === 0 &&
                                        rightBorderFunc(ele)
                                          ? `last-box-red`
                                          : ""
                                      }`)
                                    : ((porceedVer = 2),
                                      `selectedTimeBorder ${
                                        ele?.minutes === 0 &&
                                        leftBorderFunc(ele)
                                          ? `first-box`
                                          : ""
                                      } ${
                                        ele?.minutes === 0 &&
                                        rightBorderFunc(ele)
                                          ? `last-box`
                                          : ""
                                      }`)
                                  : ""
                              }`}
                            >
                              <div
                                key={n + 3932034}
                                // class loop for styling hours
                                className={`px-2 pt-4 
                       ${
                         thirdHoursLoop(ele)
                           ? "timeline_hours_not"
                           : "timeline_hours"
                       }
                    
                        `}
                              >
                                {timeLineBar2?.some((item) => {})}

                                <Label>
                                  {ele?.hours}
                                  <p className='timeline_format '>
                                    {ele?.hourDesignation}
                                  </p>

                                  {/* // try of auto scrollPosition */}
                                  {/* {isDivRendered
                                    ? (myDivRef.current.scrollLeft = ele?.hours)
                                    : ""} */}
                                </Label>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                )}
                <div className='d-flex mt-3 mb-2'>
                  <span className='dot_Unavail mx-2'></span>
                  <Label className='avail_unavail_text mt-1 mx-2'>
                    Unavailable
                  </Label>
                  <span className='dot_Avail mx-2'></span>
                  <Label className='avail_unavail_text mt-1 mx-2'>
                    Available
                  </Label>
                </div>
              </Card>
            </Col>
          );
        })}
        {/* card end  */}
      </Row>

      <Row>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}></Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}></Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
          <Button
            disabled={
              porceedVer !== 2 
               ||
               (!dayPassActivityAvail && userType === "daypassUser")
            
            }
            color='dark'
            className='w-100 mb-5 mt-3'
            onClick={() => {
              Navigate("/schedule-meeting-room-in-progress", {
                state: {
                  bookingroomsData: bookingroomsData ? bookingroomsData : "",
                  scheduleDate: scheduleDate ? scheduleDate : "",
                  scheduleTime: scheduleTime ? scheduleTime : "",
                  scheduleNewTime: scheduleNewTime ? scheduleNewTime : "",
                  repeatbookingShow: repeatbookingShow ? repeatbookingShow : "",
                  repeatDaysState: repeatDaysState ? repeatDaysState : "",
                  durationColorState: durationColorState
                    ? durationColorState
                    : "",
                  selectedBookingRoomData: selectedBookingRoomData
                    ? selectedBookingRoomData
                    : "",
                  dataOfSelectedObject: dataOfSelectedObject
                    ? dataOfSelectedObject
                    : "",
                },
              });
            }}
          >
            Proceed
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ScheduleMeetingRoom;
