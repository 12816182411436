import { useQuery, useQueryClient } from "react-query";
import { useEffect } from "react";
import bookMeetingRoomApi from "../../../services/bookMeetingRoomApi";

const UseManageAccount = () => {
  const queryClient = useQueryClient();

  const { data: coworkerListData } = useQuery(
    ["coworkerDate"],
    () => bookMeetingRoomApi.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );
  // ********* coworker end ***********
  const UserData = coworkerListData;

  useEffect(() => {
    queryClient.invalidateQueries("coworkerDate");
  }, []);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return { UserData };
};

export default UseManageAccount;
