import { useLoginInfo } from "./useLogin";
import { Button, Col, FormGroup, Input, Row, Form, Spinner } from "reactstrap";
import Header from "../../components/header";
import LoginImg from "../../images/LoginImage.svg";
import LoginLogo from "../../images/lgLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  ForgotModel,
  EmailShow,
} from "../../components/molicules/forgotPasswordModel/index";
import "font-awesome/css/font-awesome.min.css";
import "./css/styles.css";

const baseURL = process.env.REACT_APP_WEB_FORMS;
const Login = (props) => {
  const Navigate = useNavigate();
  const {
    emptyfields,
    loginLoader,
    passwordShown,
    validEye,
    showModel,
    emailShowModel,
    loader,
    handleForgotFunc,
    setEmailShowModel,
    setShowModel,
    togglePassword,
    emailFunc,
    passwordFunc,
    loginFunc,
  } = useLoginInfo(props);

  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row>
            <Col md={12} lg={12} sm={12} xs={12} xl={12}>
              <Header />
            </Col>
          </Row>

          <Row className='loginRow'>
            <Col
              md={5}
              lg={5}
              xl={5}
              sm={12}
              xs={12}
              className='loginImageCol '
            >
              <img src={LoginImg} alt='img' className='loginImage ' />
            </Col>
            <Col md={7} lg={7} xl={7} sm={12} xs={12}>
              <div className='loginForm'>
                <Row>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    <Form onSubmit={loginFunc}>
                      <FormGroup>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <div className='lgLogoContainer'>
                              <img
                                src={LoginLogo}
                                alt='img'
                                className='lgLogo'
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <h4>Login to your account</h4>
                            <p>
                              Please check your email, we have sent your
                              credentials
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <h6>Username</h6>
                            <Input
                              onChange={(e) => emailFunc(e)}
                              id='example-date-input-text'
                              className='inputField'
                              placeholder='Enter Email'
                              // className={`${validation}`}
                              type='email'
                            ></Input>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <h6>Password</h6>
                            <div className='input-icons '>
                              <i
                                onClick={() => togglePassword()}
                                className={`fa  ${validEye} ${
                                  !passwordShown ? "fa-eye-slash" : "fa-eye"
                                }`}
                              ></i>
                              <Input
                                onChange={(e) => passwordFunc(e)}
                                id='example-date-text'
                                className='inputField'
                                placeholder='********'
                                type={passwordShown ? "text" : "password"}
                              ></Input>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            {loginLoader ? (
                              <Button color='dark' className='w-100  modelBtn2'>
                                <Spinner />
                              </Button>
                            ) : (
                              <Button
                                id='9e3i0'
                                disabled={emptyfields}
                                color='dark'
                                className='loginBtn w-100'
                              >
                                Login
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </FormGroup>
                    </Form>
                    <Row>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        <div
                          className='d-flex justify-content-center'
                          role='button'
                        >
                          <a
                            className='forgotAncher'
                            onClick={() => setShowModel(true)}
                          >
                            Forgot Password?
                          </a>
                        </div>
                        <p className='d-flex justify-content-center  mt-2'>
                          <a
                            className='text-reset textcolorolink'
                            href='https://hub.fintechsaudi.com/'
                          >
                            Apply for Membership.
                          </a>
                          {/* <span className='mx-2 textcolorolink'></span> */}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <ForgotModel
        show={showModel}
        header={"Forgot password"}
        body={
          "Please enter your email address, we will send you a temporary password"
        }
        cancelModel={setShowModel}
        loader={loader}
        forgorRequest={handleForgotFunc}
        confirmModel={setEmailShowModel}
      />
      <EmailShow
        show={emailShowModel}
        header={"Check Email"}
        body={"A temporary password has been sent to your email address. "}
        cancelModel={setEmailShowModel}
      />

      <div className=' ftCss d-flex justify-content-center pt-3 '>
        <p className='ftCss1'>
          فنتك السعودية - جميع الحقوق محفوظة | Fintech Saudi - All Rights
          Reserved <span>&reg;</span>
        </p>
      </div>
    </>
  );
};

export default Login;
