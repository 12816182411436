import React, { useState } from "react";
import { Button, Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import User from "../../../images/userBlue.svg";
import User2 from "../../../images/UserRed.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./css/styles.css";

const RemoveTeamMember = (props) => {
  const navigate = useNavigate();

  const handleRequestModelFunc = () => {
    props.removeModelFunc(props.ID);
  };
  return (
    <Modal isOpen={props.show} centered={true} className='fintechModel'>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelHeader'>
                  <h1>{props.header}</h1>
                  <p className='mt-3'>{props.body}</p>
                </div>
              </Col>
            </Row>
            <Row className='model-body'>
              <div className='d-flex justify-content-between align-items-center removeModelHeader pt-1 mb-2'>
                {/* <h2>{props?.NAME}</h2> */}
                <span>
                  {props?.resourceId ===
                  Number(process.env.REACT_APP_DEDICATED_OFFICE) ? (
                    <img src={User2} alt='userPic' />
                  ) : props?.resourceId ===
                    Number(process.env.REACT_APP_PRIVATE_OFFICE) ? (
                    <img src={User} alt='userPic' />
                  ) : (
                    ""
                  )}
                  <span className='mx-3 memberNameHeading'>{props?.NAME}</span>
                </span>
                {props?.resourceId ===
                Number(process.env.REACT_APP_DEDICATED_OFFICE) ? (
                  <span className='DDmyTeamSpan px-2'>
                    <span>Dedicated Desk</span>
                  </span>
                ) : props?.resourceId ===
                  Number(process.env.REACT_APP_PRIVATE_OFFICE) ? (
                  <span className='POmyTeamSpan px-2'>
                    <span>Private Office</span>
                  </span>
                ) : (
                  ""
                )}
              </div>
            </Row>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='d-flex justify-content-between'>
                  <Button
                    className='w-100 requestCancelModelBtn '
                    onClick={() => props.cancelModel(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='w-100 requestUpdateModelBtn'
                    onClick={() => handleRequestModelFunc()}
                  >
                    Remove
                  </Button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export default RemoveTeamMember;
