import React from "react";
import { Col, Row, Card, Label, Button, Spinner } from "reactstrap";
import TodayClock from "./../../../../images/blue_calender.svg";
import DurationClock from "./../../../../images/blue_clock.svg";
import SummaryPayment from "./../../../../images/summary_payment.svg";
import SummaryTeam from "./../../../../images/summary_team.svg";
import Private_office from "./../../../../images/private_office.svg";
import UsersImg from "./../../../../images/users.svg";
import UserImg from "./../../../../images/user.svg";
import RepeatBookingIcon from "./../../../.././images/repeatBookingIcon.svg";
import moment from "moment";
import UseMeetingRoomBookingSummary from "./useMeetingRoomBookingSummary";
import "./.././css/style.css";
import CloseButtonComp from "../../../../components/molicules/closeButton";
import UseValueValue from "./../../../vatValue/useValueValue";

const MeetingRoomBookingSummary = () => {
  const {
    wholePageLoading,
    bookFunc,
    scheduleDate,
    scheduleTime,
    scheduleNewTime,
    repeatDaysState,
    durationColorState,
    selectedBookingRoomDataa,
    combinedSelectedTeamArray,
    selectedBookingRoomData,
    paymentprice,
    bookingroomsData,
    paymentLoading,
  } = UseMeetingRoomBookingSummary();
  const { vatValue } = UseValueValue();

  const TextExtractor = (description) => {
    if (description) {
      const match = description.match(/<span[^>]*>([^<]*)<\/span>/);
      const text = match && match[1];

      return text;
    }
  };

  return (
    <>
      {wholePageLoading === "true" ? (
        <div className='d-flex justify-content-center whole_page_loader h-100'>
          <Spinner className='justify-item-center mx-1 ' />
        </div>
      ) : (
        <div className='p-4'>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Row>
                <Col xl={11} lg={11} md={11} sm={10} xs={10}>
                  <p className='smr_h1'>Summary</p>
                </Col>
                <Col xl={1} lg={1} md={1} sm={2} xs={2}>
                  <div>{<CloseButtonComp />}</div>
                </Col>
              </Row>

              <Row className='mb-5'>
                <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mb-5'>
                  <Card className='p-2 s_o_cc  w-100 h-100 '>
                    <img
                      src={`https://nexudus.spaces.nexudus.com//en/publicresources/getimage/${
                        selectedBookingRoomData?.Id
                          ? selectedBookingRoomData?.Id
                          : bookingroomsData[0]?.Id
                      }?w=565&h=210&anchor=middlecenter&cache=2023-03-16T07:23:02Z`}
                      alt={Private_office}
                      className='w-100 h-100'
                    />
                    <span className='d-flex pos_cardusersicon mt-3 '>
                      <Label className='so_h2  '>
                        {selectedBookingRoomDataa?.Title}
                      </Label>

                      <span className=''>
                        <img className='mx-2 ' alt='img' src={UsersImg} />
                        <Label className='so_total_users '>
                          {selectedBookingRoomDataa?.Allocation}
                        </Label>
                      </span>
                    </span>
                    <Label className='m_rB_l3 '>
                      {TextExtractor(selectedBookingRoomDataa?.Description)}
                    </Label>

                    <Row>
                      <Col
                        className='flex-column'
                        xl={4}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={6}
                      >
                        <img src={TodayClock} alt='today' className='' />
                        <span className='mr_today_dur1'>
                          <b className='mx-2'>
                            {moment(scheduleDate).format("dddd")}
                          </b>
                          <span className='mr_Time1 mx-4 '>
                            {scheduleTime} -{" "}
                            {moment(scheduleNewTime).format("hh:mm")}
                          </span>
                        </span>
                      </Col>

                      <Col className='' xl={4} lg={4} md={4} sm={6} xs={6}>
                        <img src={DurationClock} alt='duration' />

                        <span className=' mr_today_dur1'>
                          <b className='mx-2'>Duration</b>
                          <p className='mr_Time1 mx-4'>
                            {durationColorState} min
                          </p>
                        </span>
                      </Col>
                      {localStorage.getItem("daypassMode") ===
                      "modeIsMeetingroom" ? (
                        ""
                      ) : (
                        <Col className='' xl={4} lg={4} md={4} sm={12} xs={12}>
                          <img src={RepeatBookingIcon} alt='duration' />

                          <span className=' mr_today_dur1'>
                            <b className='mx-2'>Repeat</b>
                            <p className='mr_Time1 mx-4'>
                              {repeatDaysState?.label
                                ? repeatDaysState?.label
                                : "No"}
                            </p>
                          </span>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </Col>

                <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mb-5'>
                  <Card className='p-2  pt-4 db_card  w-100 h-100 '>
                    <Row>
                      <Col className='mx-3' xl={2} lg={2} md={2} sm={1} xs={1}>
                        <span className='d-flex'>
                          <img
                            src={SummaryTeam}
                            alt='logo'
                            className='sum_img'
                          />
                          <p className='pos_h1  mt-4 mx-3'>Invitees</p>
                        </span>
                      </Col>
                      <Col
                        className='mb-4 mt-4'
                        xl={10}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={10}
                      >
                        {combinedSelectedTeamArray?.map((ele, i) => {
                          return (
                            <p className='d-flex mx-3' key={i + 3892}>
                              <img className='' alt='img' src={UserImg} />

                              <span className='mx-3 pos_h4'>
                                {ele?.VisitorFullName}
                              </span>
                            </p>
                          );
                        })}
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mb-5'>
                  <Card className='p-2 pt-4 db_card  w-100 h-100 '>
                    <Row>
                      <Col className='mx-3' xl={2} lg={2} md={2} sm={1} xs={1}>
                        <span className='d-flex'>
                          <img
                            src={SummaryPayment}
                            alt='logo'
                            // className='mt-2'
                            className='sum_img'
                          />
                          <p className='pos_h1 mt-4 mx-3'>Payment</p>
                        </span>
                      </Col>
                    </Row>
                    {paymentLoading ? (
                      <div className='d-flex justify-content-center mt-5'>
                        <Spinner className='justify-item-center mx-2 mt-5' />
                      </div>
                    ) : (
                      <Row className=''>
                        <Col
                          className=''
                          xl={2}
                          lg={2}
                          md={2}
                          sm={1}
                          xs={1}
                        ></Col>
                        <Col className=' ' xl={9} lg={9} md={9} sm={10} xs={10}>
                          <span className='pos_display_inline'>
                            <Label className='pos_h2 mt-2'>
                              Meeting room / hr
                            </Label>
                            <Label className='pos_h4 mt-2'>
                              SAR {((paymentprice?.Price?.EstimatedCost)+ (paymentprice?.Price?.DiscountAmount ?paymentprice?.Price?.DiscountAmount :0))/(durationColorState / 60)
                              }
                            </Label>
                          </span>

                          <span className='pos_display_inline'>
                            <Label className=' pos_h2'>Number of hrs</Label>
                            <Label className='pos_h4'>
                              
                              {durationColorState / 60}
                            </Label>
                          </span>
                          <hr />
                          <span className='pos_display_inline'>
                            <Label className='pos_h2 '>Subtotal</Label>
                            <Label className='pos_h4'>
                              {paymentprice?.Price?.EstimatedCost+
                              (paymentprice?.Price?.DiscountAmount ?paymentprice?.Price?.DiscountAmount :0)}
                            </Label>
                          </span>
                          <span className='pos_display_inline'>
                            <Label className='pos_h2 '>VAT {vatValue?.setting?.taxRate}%</Label>
                            <Label className='pos_h4'>
                              {vatValue?.setting?.isTaxEnable === "true"
                                ? Number(
                                    (Number(vatValue?.setting?.taxRate) / 100) *
                                      paymentprice?.Price?.EstimatedCost
                                  ).toFixed(2)
                                : 0}
                            </Label>
                          </span>
                          <span className='pos_display_inline'>
                            <Label className='pos_h2 '>Discount ({paymentprice?.Price?.DiscountCode?paymentprice?.Price?.DiscountCode:"-"})</Label>
                            <Label className='pos_h4'>
                          {  paymentprice?.Price?.DiscountAmount ?paymentprice?.Price?.DiscountAmount :"-"}
                            </Label>
                          </span>

                          <span className='pos_display_inline'>
                            <Label className='pos_h4'>Total payable</Label>
                            <Label className='pos_h5'>
                              SAR{" "}
                              {
                              // (
                                (Number(
                               ( paymentprice?.Price?.EstimatedCost)
                                +
                                  (vatValue?.setting?.isTaxEnable === "true"
                                    ? (Number(vatValue?.setting?.taxRate) /
                                        100) *
                                      paymentprice?.Price?.EstimatedCost
                                    : 0)
                              ).toFixed(2)
                              )
                           
                              }
                            </Label>
                            {/* {console.log("paymentprice",paymentprice?.Price?.EstimatedCost)} */}
                          </span>
                        </Col>
                      </Row>
                    )}
                  </Card>
                </Col>
              </Row>

              <Row className='mt-5'>
                <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Button color='dark' disabled={paymentprice?.Price?.EstimatedCost === undefined || paymentprice?.Price?.EstimatedCost === null} className='w-100' onClick={bookFunc}>
                    Book
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default MeetingRoomBookingSummary;
