import { useMutation } from "react-query";
import { PLANS_SUMMARY_MUTATION } from "./keys";

import authApi from "../services/loginApi";
import bookMeetingRoomApi from "../services/bookMeetingRoomApi";
import bookDayPassApi from "../services/bookDayPassApi";

export const usePostApiDayPassRoom = (history) => {
  const response = useMutation(bookDayPassApi.getPrice, {
    mutationKey: PLANS_SUMMARY_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const usePostApischeduleDayPassRoom = (history) => {
  const response = useMutation(bookDayPassApi.schduledaypass, {
    mutationKey: PLANS_SUMMARY_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const usePostApiRescheduleDayPassRoom = (history) => {
  const response = useMutation(bookDayPassApi.reschduledaypass, {
    mutationKey: PLANS_SUMMARY_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useUploadPhotoDayPassRoom = (history) => {
  const response = useMutation(bookDayPassApi.uploadPhotoDaypass, {
    mutationKey: PLANS_SUMMARY_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const useUploadPhotoRoom = (history) => {
  const response = useMutation(bookDayPassApi.nexudusUploadPhoto, {
    mutationKey: "nexudusImg",
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useConfirmPassRoom = (history) => {
  const response = useMutation(bookDayPassApi.confirmdaypass, {
    mutationKey: PLANS_SUMMARY_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
