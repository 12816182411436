import React from "react";
import "../././css/styles.css";
import { Card, Col, Label, Row, Button } from "reactstrap";
import UseCreditsSummary from "./useCreditsSummary";
import summaryCredits from "./../../.././images/selectPackageImg.svg";
import creditExplementory from "./../../.././images/creditExplementory.svg";
import PurchaseCreditsModel from "../../../components/molicules/creditsModel/purchaseCredits";
import CreditsAddedModel from "../../../components/molicules/creditsModel/creditsAdded";

const CreditsSummary = (props) => {
  const {
    Navigate,
    singlePersonData,
    postCreditFunc,
    creditAddedModel,
    setCreditAddedModel,
    setPurchaseCreditState,
    purchaseCreditState,
  } = UseCreditsSummary(props);
  return (
    <>
      <Row className='printingCreditPage'>
        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
          <Row>
            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
              <h1>Summary</h1>
            </Col>
          </Row>

          <Row>
            <Col md={5} lg={5} xl={5} sm={12} xs={12}>
              <Card className='select-pkg-c1 p-3 my-3'>
                <Row>
                  <div className='d-flex justify-content-center'>
                    <img
                      src={summaryCredits}
                      alt='img'
                      className='select-pkag-img '
                    />
                  </div>
                  <div className='d-flex justify-content-center'>
                    <p className='select-pkag-l5 '>{singlePersonData?.Name}</p>
                  </div>

                  <div className='d-flex justify-content-between'>
                    <p className=' '></p>
                    <p className='select-pkag-l7'>Amount </p>
                    <p className='select-pkag-l7'>Credits </p>
                    <p></p>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <p className=' '></p>
                    <p className='select-pkag-l8'>
                      {singlePersonData?.CurrencyCode} {singlePersonData?.Price}
                      <span className='select-pkag-l9'>x1</span>
                    </p>
                    <p className='select-pkag-l10'>
                      {singlePersonData?.Price}{" "}
                      <span className='select-pkag-l9'>x1</span>
                    </p>
                    <p></p>
                  </div>

                  <div className='d-flex'>
                    <img src={creditExplementory} alt='img' />
                    <p className=' mx-3 select-pkag-l6 mt-3'>
                      This amount will be charged in your next team’s invoice{" "}
                    </p>
                  </div>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    <Button
                      color='dark'
                      className='w-100'
                      onClick={() => {
                        setPurchaseCreditState(true);
                      }}
                    >
                      {" "}
                      Purchase ({singlePersonData?.CurrencyCode}{" "}
                      {singlePersonData?.Price})
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <PurchaseCreditsModel
        show={purchaseCreditState}
        body={`Are you sure you want to purchase credits for ${singlePersonData?.CurrencyCode} ${singlePersonData?.Price}? `}
        header='Purchase credits '
        cancelModel={() => setPurchaseCreditState(false)}
        creddedAddedFunc={() => postCreditFunc()}
      />

      <CreditsAddedModel
        show={creditAddedModel}
        header='Credits added '
        body='You have successfully purchased the printing credits package '
      />
    </>
  );
};

export default CreditsSummary;
