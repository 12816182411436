import { useState, useEffect } from "react";
import { useMainPageInfo } from "../../myTeam/mainPage/useMainPage";
import { useQuery } from "react-query";
import TeamCredits from "../../.././services/teamCreditsApi";
import { useNavigate } from "react-router-dom";
// import teamCredits from "../../.././services/teamCreditsApi";

export const useCreditPageInfo = (props) => {
  const [crditModel, setCrditModel] = useState(false);
  const [member, setMember] = useState();
  const [othersCredit, setOthersCredit] = useState(false);
  const { coworkerListData } = useMainPageInfo(props);
  const Navigate = useNavigate();
  const [historyEle, setHistoryEle] = useState([]);
  const userData = member ? member : coworkerListData?.Id;

  // ********* get user credits ***********
  const { data: memberCredits, isLoading: memberCreditsLoading } = useQuery(
    ["memberCredits", member],
    () => TeamCredits.getSingleMemberCredits(userData),
    {
      retry: 0,
    }
  );

  // ********* get feed from fintech ***********

  useEffect(() => {
    setMember(Number(coworkerListData?.Id));
  }, [coworkerListData]);

  const { data: teamMembers, isLoading: teamMembersLoading } = useQuery(
    ["teamMembers", coworkerListData],
    () =>
      TeamCredits.getTeamMembers(
        coworkerListData?.TeamIds,
        coworkerListData?.IsTeamAdministrator
      ),
    {
      retry: 0,
    }
  );

  // ********* get user credits ***********
  const { data: historyCredits, isLoading: historyCreditsLoading } = useQuery(
    ["historyCredits", coworkerListData],
    () => TeamCredits.gethistoryCreditsCredits(coworkerListData?.Id),
    {
      retry: 0,
    }
  );

  const { data: leadCredits } = useQuery(
    ["leadCredits", coworkerListData],
    () => TeamCredits.getLeadCredits(coworkerListData?.IsTeamAdministrator),
    {
      retry: 0,
    }
  );

  // const { data: teamCredits, isLoading: teamCreditsLoading } = useQuery(
  //   ["teamCredits", coworkerListData, othersCredit],
  //   () =>
  //     TeamCredits.getTeamCredits(
  //       coworkerListData?.Id,
  //       coworkerListData?.IsTeamAdministrator,
  //       member,
  //       othersCredit
  //     ),
  //   {
  //     retry: 0,
  //   }
  // );

  // ******credit permission Api

  const {
    data: teamCreditsPermission,
    isLoading: teamCreditsPermissionLoading,
  } = useQuery(
    ["teamCreditsPermission", coworkerListData?.TeamIds],
    () => TeamCredits.getTeamCreditsPermission(coworkerListData?.TeamIds),
    {
      retry: 0,
    }
  );

  const handleTeamMember = (e) => {
    setMember(e.target.value);

    if (Number(e.target.value) === coworkerListData?.Id) {
      setOthersCredit(false);
    } else {
      setOthersCredit(true);
    }
  };

  return {
    historyCredits,
    crditModel,
    setCrditModel,
    coworkerListData,
    teamMembers,
    teamMembersLoading,
    member,
    TeamCredits,
    Navigate,
    leadCredits,
    othersCredit,
    handleTeamMember,
    setMember,
    teamCreditsPermission,
    memberCredits,
    memberCreditsLoading,
    setHistoryEle,
    historyEle,
  };
};
