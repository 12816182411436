import { useMainPageInfo } from "./useMainPage";
import { Col, Row, Card, Button, Spinner } from "reactstrap";
import TeamManagemant from "../../../images/teamManagement.svg";
import Switch from "react-switch";
import User from "../../../images/userBlue.svg";
import User2 from "../../../images/UserRed.svg";
import "../css/styles.css";

const TeamMainPage = (props) => {
  const {
    poolTeam,
    creditPermission,
    coworkerListData,
    teamInfoLoading,
    teamSettingLoading,
    coworkerLoading,
    teamInfo,
    navigate,
    handleCreditPermissions,
    handlePoolCredits,
    setPoolTeam,
    setCreditPermission,
  } = useMainPageInfo(props);
  return (
    <>
      {!teamInfoLoading && !teamSettingLoading && !coworkerLoading ? (
        <div>
          <Row>
            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
              <Row className='myTeamMainPage'>
                <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                  <h1>Team {(teamInfo?.companyName && teamInfo?.companyName.length > 50)
  ? teamInfo?.companyName.slice(0, 50) + '...'
  : teamInfo?.companyName}</h1>
                  <Card className='myTeamCard mt-4'>
                    <Row>
                      <Col md={1} lg={1} xl={1} sm={1} xs={1}>
                        <div className=' d-flex justify-content-end'>
                          <img
                            src={TeamManagemant}
                            alt='teamManagement '
                            className='myTeamImg'
                          />
                        </div>
                      </Col>
                      <Col
                        md={11}
                        lg={11}
                        xl={11}
                        sm={11}
                        xs={11}
                        className='mt-3'
                      >
                        <div className='d-flex justify-content-between align-items-end'>
                          <h2>Team management</h2>
                          {coworkerListData?.IsTeamAdministrator === true ? (
                            <Button
                              className='myTeamEditBtn'
                              onClick={() =>
                                navigate("/my-team-management", {
                                  state: {
                                    data: {
                                      teamInfo: teamInfo,
                                      email: coworkerListData?.Email,
                                    },
                                  },
                                })
                              }
                            >
                              Edit
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* for hybrid plan showing dedicated members */}
                        {teamInfo?.dedicatedMembers?.map((member, index) => {
                          if (
                            member?.isAdministrator === true ||
                            member?.isPayingMember === true
                          ) {
                            return (
                              <div key={member?.Id}>
                                <div className='d-flex justify-content-between mt-2'>
                                  <span>
                                    <img src={User2} alt='userPic' />
                                    <span className='mx-3 memberList'>
                                      {member?.fullName}
                                    </span>
                                  </span>

                                  <span className='DDmyTeamSpan px-2'>
                                    {/* <span>Dedicated Desk</span> */}
                                    <span>{member?.deskName}</span>
                                  </span>
                                </div>
                                <span className='sd_administrator_paying'>
                                  {member?.isAdministrator === true ? (
                                    <span className='team_administrator   px-2'>
                                      Administrator
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {member?.isPayingMember === true ? (
                                    <span className='sd_payingMember px-2'>
                                      Paying member
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            );
                          }
                        })}
                        {/* for hybrid plan showing Private members members */}
                        {teamInfo?.privateMembers?.map((member, index) => {
                          if (
                            member?.isAdministrator === true ||
                            member?.isPayingMember === true
                          ) {
                            return (
                              <div key={member?.Id}>
                                <div className='d-flex justify-content-between mt-2'>
                                  <span>
                                    <img src={User} alt='userPic' />
                                    <span className='mx-3 memberList'>
                                      {member?.fullName}
                                    </span>
                                  </span>

                                  <span className='POmyTeamSpan px-2 '>
                                    {/* <span>Private Office </span> */}
                                    <span>{teamInfo?.deskName} </span>
                                  </span>
                                </div>
                                <span className='sd_administrator_paying'>
                                  {member?.isAdministrator === true ? (
                                    <span className='team_administrator   px-2'>
                                      Administrator
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {member?.isPayingMember === true ? (
                                    <span className='sd_payingMember px-2'>
                                      Paying member
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            );
                          }
                        })}
                        {teamInfo?.allActiveMembers?.map((member, index) => {
                          if (
                            (member.isPayingMember === true ||
                              member?.isAdministrator === true) &&
                            !teamInfo?.isMultiple
                          ) {
                            return (
                              <div key={member?.Id}>
                                <div className='d-flex justify-content-between mt-2'>
                                  <span>
                                    {teamInfo?.ResourceTypeId ===
                                    Number(
                                      process.env.REACT_APP_DEDICATED_OFFICE
                                    ) ? (
                                      <img src={User2} alt='userPic' />
                                    ) : teamInfo?.ResourceTypeId ===
                                      Number(
                                        process.env.REACT_APP_PRIVATE_OFFICE
                                      ) ? (
                                      <img src={User} alt='userPic' />
                                    ) : (
                                      ""
                                    )}
                                    <span className='mx-3 memberList'>
                                      {member?.fullName}
                                    </span>
                                  </span>

                                  {teamInfo?.ResourceTypeId ===
                                  Number(
                                    process.env.REACT_APP_DEDICATED_OFFICE
                                  ) ? (
                                    <span className='DDmyTeamSpan px-2'>
                                      <span>Dedicated Desk</span>
                                    </span>
                                  ) : teamInfo?.ResourceTypeId ===
                                    Number(
                                      process.env.REACT_APP_PRIVATE_OFFICE
                                    ) ? (
                                    <span className='POmyTeamSpan px-2 '>
                                      {/* <span>Private Office </span> */}
                                      <span>{teamInfo?.deskName} </span>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <span className='sd_administrator_paying'>
                                  {member?.isAdministrator === true ? (
                                    <span className='team_administrator   px-2'>
                                      Administrator
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {member?.isPayingMember === true ? (
                                    <span className='sd_payingMember px-2'>
                                      Paying member
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            );
                          }
                        })}
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                  <Card className='myTeamCard mt-4'>
                    <Row>
                      <Col md={1} lg={1} xl={1} sm={1} xs={1}>
                        <div className=' d-flex justify-content-end'>
                          <img
                            src={TeamManagemant}
                            alt='teamManagement '
                            className='myTeamImg'
                          />
                        </div>
                      </Col>
                      <Col
                        md={11}
                        lg={11}
                        xl={11}
                        sm={11}
                        xs={11}
                        className='mt-3'
                      >
                        <div className='d-flex justify-content-between align-items-end'>
                          <div className='teamManagementHeader'>
                            <h2>Team Members </h2>
                          </div>
                          {coworkerListData?.IsTeamAdministrator === true ? (
                            <Button
                              className='myTeamEditBtn'
                              onClick={() =>
                                navigate("/my-team-members", {
                                  state: {
                                    data: teamInfo,
                                  },
                                })
                              }
                            >
                              Edit
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                        <Row className='mt-3'>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            {teamInfo?.isMultiple === false ? (
                              !teamInfo?.allActiveMembers?.some(
                                (member) =>
                                  !member.isAdministrator &&
                                  !member.isPayingMember
                              ) ? (
                                ""
                              ) : (
                                <h4>Active</h4>
                              )
                            ) : !teamInfo?.dedicatedMembers?.some(
                                (member) =>
                                  !member.isAdministrator &&
                                  !member.isPayingMember
                              ) ||
                              !teamInfo?.privateMembers?.some(
                                (member) =>
                                  !member.isAdministrator &&
                                  !member.isPayingMember
                              ) ? (
                              ""
                            ) : (
                              <h4>Active</h4>
                            )}

                            

                            {/* for hybrid plan showing dedicated members */}
                            {teamInfo?.dedicatedMembers?.map(
                              (member, index) => {
                                

                                if (
                                  member?.isAdministrator === true ||
                                  member?.isPayingMember === true
                                ) {
                                } else {
                                  return (
                                    <div
                                      className='AvtiveMembers  d-flex justify-content-between mt-3'
                                      key={member?.Id}
                                    >
                                      <span>
                                        <img src={User2} alt='userPic' />
                                        <span className='mx-3 memberList'>
                                          {member?.fullName}
                                        </span>
                                      </span>
                                      <span className='DDmyTeamSpan px-2'>
                                        {/* <span>Dedicated Desk</span> */}
                                        <span>{member?.deskName}</span>
                                      </span>
                                    </div>
                                  );
                                }
                              }
                            )}

                            {/* for hybrid plan showing Private members members */}
                            {teamInfo?.privateMembers?.map((member, index) => {
                               
                              if (
                                member?.isAdministrator === true ||
                                member?.isPayingMember === true
                              ) {
                              } else {
                                return (
                                  <div
                                    className='AvtiveMembers  d-flex justify-content-between mt-3'
                                    key={member?.Id}
                                  >
                                    <span>
                                      <img src={User} alt='userPic' />
                                      <span className='mx-3 memberList'>
                                        {member?.fullName}
                                      </span>
                                    </span>
                                    <span className='POmyTeamSpan px-2'>
                                      {/* <span>Private Office</span> */}
                                      <span>{teamInfo?.deskName} </span>
                                    </span>
                                  </div>
                                );
                              }
                            })}

                            {teamInfo?.allActiveMembers?.map(
                              (member, index) => {
                             
                                if (
                                  teamInfo?.isMultiple === false ||
                                  !teamInfo?.isMultiple
                                ) {
                                  if (
                                    member?.isAdministrator === true ||
                                    member?.isPayingMember == true
                                  ) {
                                  } else {
                                    if (
                                      teamInfo?.ResourceTypeId ===
                                      Number(
                                        process.env.REACT_APP_PRIVATE_OFFICE
                                      )
                                    ) {
                                      return (
                                        <div
                                          className='AvtiveMembers  d-flex justify-content-between mt-3'
                                          key={member?.Id}
                                        >
                                          <span>
                                            <img src={User} alt='userPic' />
                                            <span className='mx-3 memberList'>
                                              {member?.fullName}
                                            </span>
                                          </span>
                                          <span className='POmyTeamSpan px-2'>
                                            {/* <span>Private Office</span> */}
                                            <span>{teamInfo?.deskName} </span>
                                          </span>
                                        </div>
                                      );
                                    } else if (
                                      teamInfo?.ResourceTypeId ===
                                      Number(
                                        process.env.REACT_APP_DEDICATED_OFFICE
                                      )
                                    ) {
                                      return (
                                        <div
                                          className='AvtiveMembers  d-flex justify-content-between mt-3'
                                          key={member?.Id}
                                        >
                                          <span>
                                            <img src={User2} alt='userPic' />
                                            <span className='mx-3 memberList'>
                                              {member?.fullName}
                                            </span>
                                          </span>
                                          <span className='DDmyTeamSpan px-2'>
                                            <span>Dedicated Desk</span>
                                          </span>
                                        </div>
                                      );
                                    }
                                  }
                                }
                              }
                            )}
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          {coworkerListData?.IsTeamAdministrator === true ? (
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              {teamInfo?.inactive?.length === 0 ? (
                                ""
                              ) : (
                                <h4>Inactive</h4>
                              )}
                              {teamInfo?.inactive?.map((member, index) => {
                                return (
                                  <div
                                    className='AvtiveMembers mt-3'
                                    key={member?.Id}
                                  >
                                    <span>
                                      <img src={User2} alt='userPic' />
                                      <span className='mx-3 memberList'>
                                        {member.fullName}
                                      </span>
                                    </span>
                                  </div>
                                );
                              })}
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                {coworkerListData?.IsTeamAdministrator === true ? (
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    <div className='CreditContainer mt-4'>
                      <Row>
                        <Col md={1} lg={1} xl={1} sm={1} xs={1}>
                          <div className=' d-flex justify-content-end'>
                            <img
                              src={TeamManagemant}
                              alt='teamManagement '
                              className='myTeamImg'
                            />
                          </div>
                        </Col>
                        <Col
                          md={11}
                          lg={11}
                          xl={11}
                          sm={11}
                          xs={11}
                          className='mt-3'
                        >
                          <div className='d-flex justify-content-between align-items-end'>
                            <h2>Credit Usage </h2>
                          </div>
                        </Col>
                        <Col md={6} lg={6} xl={6} sm={12} xs={12}>
                          <Card className='CreditCard px-3 pt-2 mt-3'>
                            <Row>
                              <Col md={10} lg={10} xl={10} sm={10} xs={10}>
                                <h1>Pool team credits </h1>
                                <p>
                                  All team members credits will be pooled and
                                  can be used by anyone in the team.
                                </p>
                              </Col>
                              <Col md={2} lg={2} xl={2} sm={2} xs={2}>
                                <div className='creditSwitch mt-4'>
                                  <Switch
                                    id={"" + 12443}
                                    checked={poolTeam}
                                    onChange={(e) => {
                                      setPoolTeam(e);
                                      handlePoolCredits(e);
                                    }}
                                    // disabled={samePlanResources}
                                    width={40}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    // checkedHandleIcon={false}
                                    height={20}
                                    onColor='#35D7A1'
                                    className='mr-1 mx-2 d-flex  mt-1'
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                        <Col md={6} lg={6} xl={6} sm={12} xs={12}>
                          <Card className='CreditCard px-3 pt-2 mt-3'>
                            <Row>
                              <Col md={10} lg={10} xl={10} sm={10} xs={10}>
                                <h1>Credits purchase permission</h1>
                                <p>
                                  Allow members to purchase credit packages.
                                  Amount will be charged in team memo.
                                </p>
                              </Col>
                              <Col md={2} lg={2} xl={2} sm={2} xs={2}>
                                <div className='creditSwitch mt-4'>
                                  <Switch
                                    id={"" + 123}
                                    checked={creditPermission}
                                    onChange={(e) => {
                                      setCreditPermission(e);
                                      handleCreditPermissions(e);
                                    }}
                                    // disabled={samePlanResources}
                                    width={40}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    // checkedHandleIcon={false}
                                    height={20}
                                    onColor='#35D7A1'
                                    className='mr-1 mx-2 d-flex  mt-1'
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <div className='spinnerLoader'>
          <Spinner />
        </div>
      )}
    </>
  );
};

export default TeamMainPage;
