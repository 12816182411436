import { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import durationApi from "../../../.././services/selectDurationTeam";

const UseScheduleInProgress = () => {
  const [selectedArrAdministrator, setSelectedArrAdministrator] = useState({});
  const [otherInvitesState, setOtherInvitesState] = useState(false);
  const [otherInvitesArr, setOtherInvitesArr] = useState([]);
  const [selectedArr, setSelectedArr] = useState([]);
  const [otherInvitesName, setOtherInvitesName] = useState("");
  const [otherInvitesEmail, setOtherInvitesEmail] = useState("");
  const [selectedCceck, setSelectedCceck] = useState([]);
  const reschduleMode = localStorage.getItem("reschduleMode");
  const Navigate = useNavigate();
  const state = useLocation();
  const {
    bookingroomsData,
    scheduleDate,
    scheduleTime,
    scheduleNewTime,
    repeatbookingShow,
    repeatDaysState,
    durationColorState,
    selectedBookingRoomData,
    dataOfSelectedObject,
  } = state?.state;

  // ************modified team for backend **********
  const { data: teamListData, isLoading: teamLoading } = useQuery(
    ["teamListDatass"],
    () => durationApi.durationForMeetingRoomApi(),
    {
      retry: 0,
    }
  );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const allteamListData = teamListData;

  // ********* end *********

  // ************whole team start ********
  const { data: wholeTeamListData, isLoading: wholeTeamLoading } = useQuery(
    ["teamListDatas"],
    () => durationApi.TeamMemberList(),
    {
      retry: 0,
    }
  );

  const teamName = wholeTeamListData?.data[0]?.Team?.Name;

  //  ************end**********

  const selectedName = (e) => {
    let indexToDelete = -1;
    for (let i = 0; i < selectedArr.length; i++) {
      if (selectedArr[i].VisitorEmail === e?.VisitorEmail) {
        indexToDelete = i;
        break; // Stop looping since we found the matching object
      }
    }

    // If we found the matching object, delete it from the array
    if (indexToDelete !== -1) {
      const abc = selectedArr;
      abc.splice(indexToDelete, 1);
      setSelectedArr(abc);
    } else {
      // else add it into array
      setSelectedArr([
        ...selectedArr,
        {
          VisitorFullName: e?.VisitorFullName,
          // isAdministrator: e?.isAdministrator,
          VisitorEmail: e?.VisitorEmail,
          TypeName: "bookingVisitor",
        },
      ]);
    }
  };

  useEffect(() => {
    const admininstratorData = allteamListData?.find((e) => {
      if (e?.isAdministrator === true) {
        return e;
      }
    });
    setSelectedArrAdministrator({
      VisitorFullName: admininstratorData?.VisitorFullName,
      isAdministrator: admininstratorData?.isAdministrator,
      TypeName: "bookingVisitor",
    });
  }, [allteamListData]);

  useEffect(() => {
    if (reschduleMode === "true" && dataOfSelectedObject) {
      // setSelectedArr(dataOfSelectedObject?.BookingVisitors);

      let check = false;
      allteamListData?.map((e) => {
        check = false;
        dataOfSelectedObject?.BookingVisitors?.map((f) => {
          // If we found the matching object then add to selected array

          if (f?.VisitorEmail === e?.VisitorEmail) {
            selectedCceck.push(true);
            check = true;
            setSelectedArr([
              ...selectedArr,
              {
                VisitorFullName: e?.VisitorFullName,
                // isAdministrator: e?.isAdministrator,
                VisitorEmail: e?.VisitorEmail,
                TypeName: "bookingVisitor",
              },
            ]);
          }
        });

        if (!check) {
          selectedCceck.push(false);
        }
      });
      setOtherInvitesState(true);
    }
  }, [allteamListData]);

  useEffect(() => {
    if (reschduleMode === "true" && dataOfSelectedObject) {
      const filteredArray = dataOfSelectedObject?.BookingVisitors?.filter(
        (member) => {
          // Return true if the member's id is not present in the selectedMembers array
          return !selectedArr.some(
            (selected) => selected.VisitorEmail === member.VisitorEmail
          );
        }
      );

      setOtherInvitesArr(filteredArray);
    }
  }, [selectedArr, allteamListData]);

  const checkFunc = (ele, index) => {
    const newArr = [...selectedCceck];
    newArr[index] = ele.target.checked;
    setSelectedCceck(newArr);
  };

  return {
    selectedArrAdministrator,
    setSelectedArrAdministrator,
    otherInvitesState,
    setOtherInvitesState,
    otherInvitesArr,
    setOtherInvitesArr,
    selectedArr,
    setSelectedArr,
    otherInvitesName,
    setOtherInvitesName,
    otherInvitesEmail,
    teamName,
    teamLoading,
    setOtherInvitesEmail,
    Navigate,
    checkFunc,
    teamListData,
    allteamListData,
    // onlyTeam,
    selectedName,
    repeatDaysState,
    scheduleNewTime,
    scheduleTime,
    scheduleDate,
    checkFunc,
    bookingroomsData,
    repeatbookingShow,
    durationColorState,
    selectedBookingRoomData,
    dataOfSelectedObject,
    selectedCceck,
    setSelectedCceck,
  };
};

export default UseScheduleInProgress;
