import React from "react";
import UseCreateDesk from "./useCreateDesk";
import { Button, Col, FormGroup, Input, Row, Form, Spinner } from "reactstrap";
import LoginImg from "../../../images/LoginImage.svg";
import PlanLogo from "../../../images/editPlanLogo.svg";
import CloseIcon from "../../../images/icons8-close-48.svg";
import UploadLoo from "../../../images/uploadLoo.svg";
import Dropzone from "react-dropzone";
import { CreatedDeskModel } from "../../../components/molicules/userModel";
import "font-awesome/css/font-awesome.min.css";
// import Spinner from "react-bootstrap/Spinner";

const CreateDesk = () => {
  const {
    title,
    description,
    photoDataByApi,
    submitFunc,
    setTitle,
    setDescription,
    handleAcceptedFiles,
    selectedFiles,
    setSelectedFiles,
    imgLoading,
    createLoading,
    modelShow1,
    setModelShow1,
  } = UseCreateDesk();
  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row>
            <Col
              md={5}
              lg={5}
              xl={5}
              sm={12}
              xs={12}
              className='loginImageCol '
            >
              <img src={LoginImg} alt='img' className='invoiceDetailImg ' />
            </Col>
            <Col md={7} lg={7} xl={7} sm={12} xs={12}>
              <div className='loginForm'>
                <Row className='sideCardView '>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    <Form
                    // onSubmit={}
                    >
                      <FormGroup>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <div className='lgLogoContainer'>
                              <img
                                src={PlanLogo}
                                alt='img'
                                className='lgLogo'
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <p>
                              Open a request. Get in touch with our customer
                              support for any kind of assistance.
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <h6>Report subject</h6>
                            <Input
                              id='example-date-input-text'
                              className='inputField'
                              onChange={(e) => setTitle(e.target.value)}
                              type='text'
                            ></Input>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <h6>Report details</h6>
                            <Input
                              id='example-date-input-text'
                              className='planChangeTextArea'
                              onChange={(e) => setDescription(e.target.value)}
                              type='textarea'
                              maxLength={700}
                            ></Input>
                          </Col>
                        </Row>

                        <Row className='mt-4'>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <h6>
                              {imgLoading ? (
                                <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                                  <Spinner
                                    as='span'
                                    animation='border'
                                    size='sm'
                                    role='status'
                                    aria-hidden='true'
                                    variant='light'
                                  />{" "}
                                  uploading
                                </Col>
                              ) : (
                                " Upload image"
                              )}
                            </h6>
                            <div className='w-100'>
                              <Form
                                className='p_day_img_border mt-3  p-4 '
                                hidden={selectedFiles.length > 0}
                              >
                                <Dropzone
                                  accept={".png ,.jpeg,.jpg,.pdf"}
                                  onDrop={(acceptedFiles) => {
                                    handleAcceptedFiles(acceptedFiles);
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div>
                                      <div
                                        className='dz-message needsclick'
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className=' d-flex justify-content-center align-items-center'>
                                          <img src={UploadLoo} alt='logo' />
                                        </div>

                                        <p className='p_day_l8 mt-3 d-flex justify-content-center align-items-center'>
                                          Tap to Upload
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                              </Form>
                              <div
                                className='dropzone-previews mt-3'
                                id='file-previews'
                              >
                                {selectedFiles.length > 0 && (
                                  <div className='mt-1  p_day_img_border'>
                                    <div className='p-2'>
                                      <Row className='align-items-center'>
                                        <Col md={4}></Col>
                                        <Col
                                          md={7}
                                          className=' d-flex  justify-content-between align-items-center'
                                        >
                                          <img
                                            data-dz-thumbnail=''
                                            height='80'
                                            className='text-center avatar-sm rounded bg-light d-flex justify-content-center'
                                            alt='img'
                                            src={selectedFiles[0].preview}
                                          />
                                          {/* </Col>
                          <Col md={1}> */}
                                          <span
                                            onClick={() => setSelectedFiles([])}
                                          >
                                            <img
                                              src={CloseIcon}
                                              alt='logo'
                                              width={24}
                                              height={24}
                                            />
                                          </span>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row className='mt-4'>
                          {createLoading ? (
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              <Button
                                className='loginBtn w-100'
                                disabled={true}
                              >
                                <Spinner
                                  as='span'
                                  animation='border'
                                  size='sm'
                                  role='status'
                                  aria-hidden='true'
                                  variant='light'
                                />{" "}
                                Submitting
                              </Button>
                            </Col>
                          ) : (
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              <Button
                                disabled={
                                  !title || !description || !photoDataByApi
                                }
                                className='loginBtn w-100'
                                onClick={() => submitFunc()}
                              >
                                Submit
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <CreatedDeskModel
        show={modelShow1}
        // setModelShow1={setModelShow1}
        setModelShow2={setModelShow1}
        header={`Report Submitted`}
        body={`A member from our customer support team will contact you shortly via email. Thank you for your pateince`}
        link={`/help-desk`}
      />
    </>
  );
};

export default CreateDesk;
