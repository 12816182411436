import axios from "axios";
import { Suadi_fintech_URL, Saudi_App_Base_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import { stageToken } from "../constants/local";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_App_Base_URL,
});

const planSummary = {
  leadSumary: async (id, filter) => {
    if (id) {
      var leadId = 0;
      if (filter === "monthly") {
        leadId = id?.TeamLeadMonthlyPlan;
      } else if (filter === "yearly") {
        leadId = id?.TeamLeadYearlyPlan;
      }

      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
      };
      if (leadId !== 0) {
        const data = await request.get(`/plans/${leadId}`, {
          headers: headers,
        });

        return data;
      }
    }
  },
  membershipSumary: async (id, filter) => {
    if (id) {
      var MembershipId = 0;
      if (filter === "monthly") {
        MembershipId = id?.TeamMemberMonthlyPlan;
      } else if (filter === "yearly") {
        MembershipId = id?.TeamMemberYearlyPlan;
      }

      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
      };

      if (MembershipId !== 0) {
        const data = await request.get(`/plans/${MembershipId}`, {
          headers: headers,
        });

        return data;
      }
    }
  },

  postPrivateSumary: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const data = await request.post(
      `/plan-requests`,
      { ...body },
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );
    return data;
  },
};

export default apiWrapper(planSummary, [
  "leadSumary",
  "membershipSumary",
  "postPrivateSumary",
]);
