import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row, Card, Label, Modal, ModalBody } from "reactstrap";
import RepeatBookingIcon from "./../../../../.././images/repeatBookingIcon.svg";
import moment from "moment";
import InvCrossBtn from "./../../../../.././images/invCrossBtn.svg";
import DownloadInvoiceBtn from "./../../../../.././images/downloadInvoiceBtn.svg";
import TodayClock from "./../../../../.././images/blue_calender.svg";
import SummaryTeam from "./../../../../.././images/summary_team.svg";
import DurationClock from "./../../../../.././images/blue_clock.svg";
import UsersImg from "./../../../../.././images/users.svg";
import UserImg from "./../../../../.././images/user.svg";
import VeiwInvoiceLogo from "./../../../../.././images/veiwInvoiceLogo.svg";
import {
  CancelModel,
  CanceledModel,
  RescheduleModel,
} from "../../../../.././components/molicules/userModel";
import UseDaypassSchduleMeetingRoom from "./useDaypassSchduleMeetingRoom";
import { Spinner } from "react-bootstrap";
import "./css/styles.css";
import CloseButtonComp from "../../../../../components/molicules/closeButton";
import UseValueValue from "../../../../vatValue/useValueValue";

const DaypassSchduleMeetingRoom = () => {
  const { vatValue } = UseValueValue();
  const {
    TextExtractor,
    objectedFunc,
    cancelApiFunc,
    invLoading,
    downloadFunc,
    bookingroomsData,
    invData,
    invData1,
    invoiceModel,
    downliadIncId,
    setInvoiceModel,
    bookingroomsLoading,
    dataOfSelectedObject,
    modelShow2,
    setModelShow2,
    modelShow1,
    setModelShow1,
    modelShow,
    setModelShow,
    userEmail,
  } = UseDaypassSchduleMeetingRoom();
  const Navigate = useNavigate();

  const hoursDur = moment.duration(
    moment(dataOfSelectedObject?.ToTime).diff(
      moment(dataOfSelectedObject?.FromTime),
      "minutes"
    ),
    "minutes"
  );

  const futureDate =
    dataOfSelectedObject?.FromTime >= new Date().toISOString();
    

  return (
    <div>
      <div className='p-4'>
        <Row>
          <Col>
            <Row>
              <Col xl={11} lg={11} md={11} sm={10} xs={10}>
                <p className='smr_h1 '>Scheduled meetings</p>
              </Col>
              <Col xl={1} lg={1} md={1} sm={2} xs={2}>
                <div>{<CloseButtonComp />}</div>
              </Col>
            </Row>

            <Row className='mb-5'>
              <Col className='mb-2' xl={5} lg={5} md={5} sm={12} xs={12}>
                <Card
                  className='p-2 s_o_cc  w-100 h-100 '
                  // onClick={() => {
                  //   Navigate("/reschedule-meeting-room", {
                  //     state: { dataOfSelectedObject: ele },
                  //   });
                  // }}
                >
                  <img
                    src={`https://nexudus.spaces.nexudus.com//en/publicresources/getimage/${dataOfSelectedObject?.ResourceId}?w=565&h=210&anchor=middlecenter&cache=2023-03-16T07:23:02Z`}
                    alt='img'
                    className='w-100 h-100 imgCorners'
                  />

<Col
                              xl={3}
                              lg={3}
                              md={3}
                              sm={3}
                              xs={3}
                              className={`mx-2 ${
                                dataOfSelectedObject?.status === "cancelled" ||
                                dataOfSelectedObject?.status === "deny"
                                  ? "mr_duration_Cancel"
                                  : ""
                              }

                            ${
                              dataOfSelectedObject?.status === "approved"
                                ? "mr_duration_confirmed"
                                : ""
                            }

                            ${
                              dataOfSelectedObject?.status === "pending" ||
                              dataOfSelectedObject?.status === "rescheduled"
                                ? "mr_duration_pending"
                                : ""
                            }
                            
                            
                            
                            `}
                            >
                              <span className='mr_coming_font'>
                                {dataOfSelectedObject?.status === "approved"
                                  ? "Confirmed"
                                  : dataOfSelectedObject?.paymentStatus === "objected"
                                  ? dataOfSelectedObject?.paymentStatus
                                  : dataOfSelectedObject?.status}
                              </span>
                            </Col>
                  <span className='d-flex pos_cardusersicon mt-3 '>
                    <Label className='so_h2  '>
                      {dataOfSelectedObject?.ResourceName}
                    </Label>

                    <span className=''>
                      <img className='mx-2 ' alt='img' src={UsersImg} />
                      <Label className='so_total_users '>
                        {dataOfSelectedObject?.BookingVisitors?.length + 1}
                      </Label>
                    </span>
                  </span>
                  <Label className='m_rB_l3 mt-4'>
                    {TextExtractor(dataOfSelectedObject?.Description)}
                  </Label>

                  <Row>
                    <Col
                      className='flex-column'
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                    >
                      <img src={TodayClock} alt='today' className='' />
                      <span className='mr_today_dur1'>
                        <b className='mx-2'>
                          {moment(dataOfSelectedObject?.FromTime).format(
                            "DD-MM-YYYY"
                          )}
                        </b>
                        <span className='mr_Time1 mx-4 '>
                          <b>
                            {" "}
                            {moment(dataOfSelectedObject?.FromTime).format(
                              "hh:mm a"
                            )}{" "}
                            -{" "}
                            {moment(dataOfSelectedObject?.ToTime).format(
                              "hh:mm a"
                            )}
                          </b>
                        </span>
                      </span>
                    </Col>
                    <Col className='' xl={4} lg={4} md={4} sm={12} xs={12}>
                      <img src={DurationClock} alt='duration' />
                      <span className=' mr_today_dur1'>
                        <b className='mx-2'>Duration</b>
                        <p className='mr_Time1 mx-4'>
                          {/* {moment(dataOfSelectedObject?.ToTime).diff(
                            moment(dataOfSelectedObject?.FromTime),
                            "minutes"
                          )}{" "} */}
                          <b>
                            {" "}
                            {`${hoursDur.hours()}h 
                           ${hoursDur.minutes()}`}
                            m
                          </b>
                        </p>
                      </span>
                    </Col>
                    {/* <Col className='' xl={4} lg={4} md={4} sm={12} xs={12}>
                      <img
                        src={RepeatBookingIcon}
                        alt='duration'
                        // className='mr_cal1'
                      />

                      <span className=' mr_today_dur1'>
                        <b className='mx-2'>Repeat</b>
                        <p className='mr_Time1 mx-4'>
                          <b>
                            {" "}
                            {dataOfSelectedObject?.isRepeatBooking
                              ? "Yes"
                              : "No"}
                          </b>
                        </p>
                      </span>
                    </Col> */}
                  </Row>
                </Card>
              </Col>
              {/* this section is hidden due to daypass  */}
              {
                !dataOfSelectedObject?.isCancellable || dataOfSelectedObject?.status === "cancelled" ||
                dataOfSelectedObject?.status === "deny" ||
                !futureDate ? "" :
              
              (<Col xl={3} lg={3} md={3} sm={12} xs={12}>
                <Card className='p-2    pt-4 db_card  w-100 h-100 '>
                  <Row>
                    <Col className=' ' xl={9} lg={9} md={9} sm={12} xs={12}>
                      <span className='d-flex mx-3'>
                        <img src={SummaryTeam} alt='logo' className='sum_img' />

                        <p className='pos_h1  mt-4 mx-3'>Participants</p>
                      </span>
                    </Col>

                    <Col className=' ' xl={3} lg={3} md={3} sm={12} xs={12}>
                      <div className='w-100 edit_btn_justify '>
                        <Button
                          className='w-100'
                          color='light'
                          onClick={() => {
                            Navigate("/edit-participents-rescheduled", {
                              state: {
                                dataOfSelectedObject: dataOfSelectedObject,
                              },
                            });
                          }}
                        >
                          <p className='edit_btn_participent'>Edit</p>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className='mb-4 mt-4'
                      xl={10}
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                    >
                      {dataOfSelectedObject?.BookingVisitors?.map((ele, ll) => {
                        return (
                          <p className='d-flex mx-3' key={ll + 3892}>
                            <img className='' src={UserImg} />

                            <span className='mx-3 pos_h4'>
                              {ele?.VisitorFullName}
                            </span>
                          </p>
                        );
                      })}
                    </Col>
                  </Row>
                </Card>
              </Col>)
}

{/* uncommented by me  */}
              <Col className='mb-2' xl={3} lg={3} md={3} sm={12} xs={12}>
                <Card className='p-2 pt-4 db_card  w-100 h-100 '>
                  <Row className='px-2'>
                    <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                      <p className='dmr_h4'>
                        <b>Payment</b>

                      
                        
                        
                        { 
                        // ((dataOfSelectedObject?.vat
                        //     ? dataOfSelectedObject?.vat
                        //     : 0) + invData1?.EstimatedCost ?((dataOfSelectedObject?.vat
                        //       ? dataOfSelectedObject?.vat
                        //       : 0) + invData1?.EstimatedCost+(invData1?.DiscountAmount?invData1?.DiscountAmount:0)):0) 
                        dataOfSelectedObject?.totalPayable
                              === 0 
                              ?
                               (
                          ""
                        ) : dataOfSelectedObject?.paymentStatus ===
                          "objected" ? (
                          <Label className='mx-3  dmr_statsad px-2'>
                            {dataOfSelectedObject?.paymentStatus}
                          </Label>
                        ) : (
                          <Label className='mx-3 dmr_stats px-2'>
                            {dataOfSelectedObject?.status}
                          </Label>
                        )}
                      </p>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                      {
                      // downliadIncId 
                      dataOfSelectedObject?.status === "approved"
                      ? (
                        <span
                        type="button"
                          className='d-flex dmr_right '
                          onClick={() => {
                            setInvoiceModel(true);
                          }}
                        >
                          <img
                            className='dmr_h6  mx-2'
                            alt='img'
                            src={VeiwInvoiceLogo}
                          />

                          <p className='dmr_h5'>View Memo</p>
                        </span>
                       ) : (
                        ""
                      )} 
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12}></Col>
                  </Row>

                  <Row className='px-2'>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                      <p className='dmr_h7'>
                        {dataOfSelectedObject?.ResourceName}
                      </p>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                      <p className='dmr_h8 dmr_right'>
                        <b> SAR {
                        // invData?.SubTotal?invData?.SubTotal+
                        
                          // Number(invData1?.EstimatedCost+invData1?.DiscountAmount||0).toFixed(2)
                          Number(dataOfSelectedObject?.subTotal).toFixed(2)
                        }
                          </b>
                      </p>
                    </Col>
                    <hr className='dmr_h3' />
                  </Row>

                  <Row className='px-2'>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                      <p className='dmr_h9'> Sub Total</p>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                      <p className='dmr_h8 dmr_right dmr_right'>
                        <b>
                          {/* {Number(invData1?.EstimatedCost+invData1?.DiscountAmount||0).toFixed(2)} */}
                         
                          {Number(dataOfSelectedObject?.subTotal).toFixed(2)}
                            
                        </b>
                      </p>
                    </Col>
                  </Row>

                  <Row className='px-2'>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                      <p className='dmr_h9'>VAT ({ dataOfSelectedObject?.vatPercentage }%)</p>
                     
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                      <p className='dmr_h9 dmr_right dmr_right'>
                        {/* {vatValue?.setting?.isTaxEnable === "true"
                          ? Number(
                              (Number(vatValue?.setting?.taxRate) / 100) *
                              
                                invData1?.EstimatedCost
                            ).toFixed(2)
                          : 0} */}
                          {dataOfSelectedObject?.vat}
                          
                      </p>
                     
                    </Col>
                  </Row>
                  <Row className='px-2'>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                
                      <p className='dmr_h9'> Discount (
                        {/* {invData1?.DiscountCode?invData1?.DiscountCode:"-"} 
                        */}
                        {dataOfSelectedObject?.discountCode}
                        )</p>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                     
                      <p className='dmr_h9 dmr_right dmr_right'>
                      {/* {invData1?.DiscountAmount? Number(invData1?.DiscountAmount).toFixed(2):"-"} */}
                      {dataOfSelectedObject?.discountedAmount}
                      </p>
                    </Col>
                    
                  </Row>
                  <Row className='px-2'>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                      <p className='dmr_h9'> Total Amount</p>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                      <p className='dmr_h8 dmr_right dmr_right'>
                        <b>
                          {/* {((vatValue?.setting?.isTaxEnable === "true"
                          ? Number(
                              (Number(vatValue?.setting?.taxRate) / 100) *
                                // dataOfSelectedObject?.Price
                                invData1?.EstimatedCost
                            )
                          : 0) + invData1?.EstimatedCost ?((vatValue?.setting?.isTaxEnable === "true"
                          ? Number(
                              (Number(vatValue?.setting?.taxRate) / 100) *
                                // dataOfSelectedObject?.Price
                                invData1?.EstimatedCost
                            )
                          : 0) + invData1?.EstimatedCost ):0).toFixed(2)} */}
                         
                             {dataOfSelectedObject?.totalPayable?dataOfSelectedObject?.totalPayable:0}
                            
                        </b>
                      </p>
                    </Col>
                  </Row>
                  <hr className='dmr_h2' />
                  <Row>
                    <Col
                      className='mx-3'
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <span className='d-flex'>
                        <p className='dmr_h1 mt-4 '>
                          <b>Booking details</b>
                        </p>
                      </span>
                    </Col>
                  </Row>

                  <Row className='mt-2'>
                    <Col className='' xl={2} lg={2} md={2} sm={2} xs={2}>
                      <img src={TodayClock} alt='today' className='mx-3' />
                    </Col>
                    <Col className=' ' xl={9} lg={9} md={9} sm={10} xs={10}>
                      <span className='pos_display_inline'>
                        <Label className='pos_h2 mt-1'>Booked on:</Label>
                        <Label className=' dmr_inv_float_right dmr_inv_vat  mt-2'>
                          <b>
                            {" "}
                            {moment(dataOfSelectedObject?.FromTime).format(
                              "DD-MM-YYYY"
                            )}
                          </b>
                        </Label>
                      </span>
                    </Col>
                  </Row>
                  <Row className=''>
                    <Col className='' xl={2} lg={2} md={2} sm={2} xs={2}>
                      <img src={TodayClock} alt='today' className='mx-3' />
                    </Col>
                    <Col className=' ' xl={9} lg={9} md={9} sm={10} xs={10}>
                      <span className='pos_display_inline'>
                        <Label className='pos_h2 mt-1'>Booked by:</Label>
                        <Label className='dmr_inv_float_right dmr_inv_vat  mt-2'>
                          <b> {dataOfSelectedObject?.CoworkerName}</b>
                        </Label>
                      </span>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col className='mb-2' xl={3} lg={3} md={3} sm={12} xs={12}>
                {dataOfSelectedObject?.paymentStatus === "objected" ? (
                  <Card className='p-2 pt-4 db_card  w-100 h-100 '>
                    <p className='dmr_h4 m-4'>
                      <b>Objection Details </b>
                    </p>

                    <p className='dmr_h4 mx-4'>
                      {dataOfSelectedObject?.ObjectionReason}
                    </p>
                  </Card>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Col>
        </Row>

       
     

        {!dataOfSelectedObject?.isCancellable || dataOfSelectedObject?.status === "cancelled" ||
          dataOfSelectedObject?.status === "deny" ||
          !futureDate ? (
          ""
        ) : (
          <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}></Col>
            <Col xl={3} lg={3} md={3} sm={12} xs={12} className='px-2 mb-2'>
              <Button
                color='light'
                style={{ minHeight: "62px" }}
                // className='w-100 my-4'
                className='w-100 smr_yes_btn'
                onClick={() => setModelShow2(true)}
              >
                Cancel
              </Button>
            </Col>
            <Col xl={3} lg={3} md={3} sm={12} xs={12} className='px-2 mb-2'>
              {dataOfSelectedObject?.paymentStatus === "objected" ? (
                <Button
                  color='dark'
                  style={{ minHeight: "62px" }}
                  className='w-100  mb-2'
                  onClick={objectedFunc}
                >
                  Re-submit
                </Button>
              ) : (
                <Button
                  disabled={bookingroomsLoading}
                  color='dark'
                  style={{ minHeight: "62px" }}
                  className='w-100 '
                  onClick={() => setModelShow(true)}
                >
                  Reschedule
                </Button>
              )}
            </Col>
          </Row>
        )}
      </div>

      <RescheduleModel
        show={modelShow}
        setModelShow={setModelShow}
        header={`Reschedule Meeting`}
        body={`Do you want to reschdule this meeting?`}
        link={`/schdule-meeting-room`}
        dataOfSelectedObject={dataOfSelectedObject}
        bookingroomsData={bookingroomsData}
        state={{
          dataOfSelectedObject: dataOfSelectedObject
            ? dataOfSelectedObject
            : "",
          bookingroomsData: bookingroomsData ? bookingroomsData : "",
        }}
      />

      <CancelModel
        show={modelShow2}
        setModelShow1={setModelShow1}
        setModelShow2={setModelShow2}
        cancelApiFunc={cancelApiFunc}
        header={`Cancel Meeting`}
        body={`Do you want to cancel this meeting?`}
        // onClick={() => {
        //   setModelShow1(true);
        // }}
        // link={`/wellcome`}
      />

      <CanceledModel
        show={modelShow1}
        // setModelShow1={setModelShow1}
        setModelShow2={setModelShow2}
        header={`Meeting Cancelled`}
        body={`Do you want to reschedule this meeting?`}
        link={`/redirect-main-page`}
      />

      <Modal isOpen={invoiceModel} centered={true}>
        <Row className='model-body'>
          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
            <ModalBody>
              <Row>
                <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                  <div className='modelBody mt-2'>
                    {" "}
                    <Row className='px-2'>
                      <Col xl={11} lg={11} md={11} sm={12} xs={12}>
                        <p className=' dmr_inv_h1'>Meeting Room</p>
                      </Col>
                      <Col xl={1} lg={1} md={1} sm={12} xs={12}>
                        <img
                          src={InvCrossBtn}
                          alt='cross button'
                          onClick={() => {
                            setInvoiceModel(false);
                          }}
                        />
                      </Col>
                      <hr className='dmr_h3' />
                    </Row>
                    <Row className='mt-2'>
                      <Col className='' xl={5} lg={5} md={5} sm={12} xs={12}>
                        <span className='pos_display_inline'>
                          <img src={TodayClock} alt='today' className='mx-3' />

                          <Label className=' mt-2 dmr_inv_h2'>
                            <b>
                            {moment(dataOfSelectedObject?.FromTime).format(
                              "DD-MM-YYYY"
                            )}
                            </b>
                          </Label>
                        </span>
                      </Col>
                      <Col
                        className=''
                        xl={3}
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                      
                      ></Col>

                      <Col
                        className='  '
                    
                        
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        
                      >
                        <>
                          {" "}
                        
                          {invLoading ? <Spinner style={{height:19, width:19}} /> :   <img
                             
                             onClick={() => {
                              downloadFunc();
                              // testDownloadLink();
                            }}
                            src={DownloadInvoiceBtn}
                            className=''
                            alt='download pdf'
                          />}
                        </>
                      </Col>
                    </Row>
                    <Label className='dmr_inv_float_left mx-3 dmr_inv_h4 mt-3'>
                      <b>{dataOfSelectedObject?.CoworkerName}</b>
                    </Label>
                    <label className='dmr_inv_float_left  mx-3 dmr_inv_mail'>
                      {userEmail}
                    </label>
                    <hr />
                    <Row className='px-2'>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <p className='dmr_inv_h1'>Product</p>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <p className='dmr_h8 dmr_right'>
                          <Label className=' dmr_stats px-3'>
                            {dataOfSelectedObject?.status}{" "}
                          </Label>
                        </p>
                      </Col>
                    </Row>
                    <Row className='px-2'>
                      <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                      <p className='dmr_inv_float_left dmr_inv_h4'>
                          Booked hours
                        </p>
                        <p className='dmr_inv_float_left dmr_inv_h4'>
                          {/* {invData?.Description} */}
                          {TextExtractor(dataOfSelectedObject?.Description)}
                          
                        </p>
                        
                      </Col>
                      {/* <Col xl={1} lg={1} md={1} sm={12} xs={12}></Col> */}
                      <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                      <p className='dmr_inv_float_right dmr_inv_h4'>
                          <b>
                            {" "}
                            {`${hoursDur.hours()}h ${hoursDur.minutes()}
                            m`}
                          </b>
                        </p>
                        <p className='dmr_inv_float_right dmr_inv_h4'>
                          <b>
                            {" "}
                            {/* {invData?.CoworkerInvoiceCurrencyCode}{" "}
                            {Number(invData?.UnitPrice+(invData?.DiscountAmount?invData?.DiscountAmount:0)).toFixed(2)} */}
                            {Number(dataOfSelectedObject?.subTotal).toFixed(2)}
                          </b>
                        </p>
                        
                      </Col>
                    </Row>
                    <hr className='dmr_h3' />
                    <Row className='px-2'>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <p className='dmr_inv_float_left dmr_inv_h4'>
                          <b>Subtotal</b>
                        </p>
                        <p className='dmr_inv_float_left dmr_inv_vat'>
                          VAT 
                          {/* ({invData?.TaxRate}%) */}
                          ({ dataOfSelectedObject?.vatPercentage }%)
                        </p>
                        <p className='dmr_inv_float_left dmr_inv_vat'>
                          Discount 
                          {/* ({invData?.DiscountCode?invData?.DiscountCode:"-"}) */}
                          {dataOfSelectedObject?.discountCode} 
                        </p>
                        
                      
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <p className='dmr_inv_float_right dmr_inv_h4'>
                          <b>
                            {" "}
                            {/* {invData?.CoworkerInvoiceCurrencyCode}{" "}
                            {Number(invData?.SubTotal+(invData?.DiscountAmount?invData?.DiscountAmount:0)).toFixed(2)} */}
                            {Number(dataOfSelectedObject?.subTotal).toFixed(2)}
                          </b>
                        </p>
                        <p className='dmr_inv_float_right dmr_inv_vat'>
                          {/* {Number(invData?.TaxAmount).toFixed(2)} */}
                          {dataOfSelectedObject?.vat}
                        </p>
                        <p className='dmr_inv_float_right dmr_inv_vat'>
                          {/* {invData?.DiscountAmount?Number(invData?.DiscountAmount).toFixed(2):"-"} */}
                          {dataOfSelectedObject?.discountedAmount}
                        </p>
                      </Col>
                    </Row>
                  </div>{" "}
                </Col>
              </Row>
            </ModalBody>
          </Col>
        </Row>

        <Row className='model-body2 dmr_btm pt-4'>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <p className='dmr_inv_float_left dmr_inv_paid'>
              <b>Amount paid</b>
            </p>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <p className='dmr_inv_float_right dmr_inv_paid'>
              <b>
                {" "}
                {/* {invData?.CoworkerInvoiceCurrencyCode}{" "}
                {
                  Number(invData?.SubTotal + invData?.TaxAmount).toFixed(2)
                } */}
                {dataOfSelectedObject?.totalPayable?dataOfSelectedObject?.totalPayable:0}
                
              </b>
            </p>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default DaypassSchduleMeetingRoom;
