import { useCreditPageInfo } from "./useCreditPage";
import { Col, Row, Card, Button } from "reactstrap";
import Artboard from "./../../.././images/Artboard.svg";
import "../css/styles.css";
import creditsArrow from "./../../.././images/creditsArrow.svg";
import CreditTransactionModel from "../../../components/molicules/creditTransactionModel";
import moment from "moment";

const CreditPage = (props) => {
  const {
    historyEle,
    setHistoryEle,
    historyCredits,
    crditModel,
    setCrditModel,
    memberCredits,
    memberCreditsLoading,
    coworkerListData,
    teamMembers,
    teamMembersLoading,
    member,
    teamCredits,
    othersCredit,
    teamCreditsPermission,
    leadCredits,
    Navigate,
    handleTeamMember,
  } = useCreditPageInfo(props);
  const userType = localStorage.getItem("userTypeRedir");

  return (
    <>
      <Row className='printingCreditPage'>
        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
          <Row>
            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
              <h1>Printing credits </h1>
            </Col>
          </Row>
          <Row>
            <Col md={5} lg={5} xl={5} sm={12} xs={12} className='h-100 pt-4'>
              <h2>Balance</h2>

              <Card className='addCreditCard w-100 px-2 py-1'>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className='d-flex justify-content-between align-items-center '>
                      <div className='d-flex align-items-center px-2'>
                        <img src={Artboard} alt='img' width={43} />
                        {othersCredit ? (
                          <div className='d-flex px-2'>
                            <span className='px-1 '>SAR</span>
                            <h4>
                              {/* {teamCredits?.credits ? teamCredits?.credits : 0} */}
                              {memberCredits?.credits
                                ? memberCredits?.credits
                                : 0}
                            </h4>
                          </div>
                        ) : coworkerListData?.IsTeamAdministrator === false ? (
                          <div className='d-flex px-2'>
                            <span className='px-1 '>SAR</span>
                            <h4>
                              {/* {teamCredits?.credits ? teamCredits?.credits : 0} */}
                              {memberCredits?.credits
                                ? memberCredits?.credits
                                : 0}
                            </h4>
                          </div>
                        ) : (
                          <div className='d-flex px-2'>
                            <span className='px-1 '>SAR</span>
                            <h4>
                              {/* {leadCredits?.ExtraServices[0]?.MinutesLeft
                                ? leadCredits?.ExtraServices[0]?.MinutesLeft
                                : 0} */}
                              {memberCredits?.credits
                                ? memberCredits?.credits
                                : 0}
                            </h4>
                          </div>
                        )}
                      </div>
                      {userType === "meetingroomAdminstrator" ? (
                        <div
                          onClick={() =>
                            Navigate("/help-desk/select-credit-package")
                          }
                        >
                          <Button className='addCreditBtn px-4'>
                            Add Credits
                          </Button>
                        </div>
                      ) : userType === "meetingroomTeamMember" &&
                        teamCreditsPermission?.creditPermission ? (
                        // userType === "meetingroomTeamMember" && true ? (
                        <div
                          onClick={() =>
                            Navigate("/help-desk/select-credit-package")
                          }
                        >
                          <Button className='addCreditBtn px-4'>
                            Add Credits
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            {coworkerListData?.IsTeamAdministrator === true ? (
              <Col md={4} lg={4} xl={4} sm={12} xs={12} className='pt-4'>
                <h2>Team Member</h2>

                <select
                  id='input-members-select'
                  className='add-member-field w-100'
                  type='select'
                  value={member}
                  onChange={(e) => handleTeamMember(e)}
                  required
                >
                  {" "}
                  {!teamMembersLoading ? (
                    teamMembers?.map((member, index) => {
                      return (
                        <option value={member?.Id} key={member?.Id}>
                          {member?.FullName}
                        </option>
                      );
                    })
                  ) : (
                    <option disabled>Loading</option>
                  )}
                </select>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Col>

        <Row className='pt-5'>
          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
            <h1>Credits History </h1>
          </Col>

          {historyCredits?.data?.map((ele, index) => {
            return (
              <div id={index + 34}>
                <Row className='h-100 w-100'>
                  <Col
                    md={12}
                    lg={12}
                    xl={12}
                    sm={12}
                    xs={12}
                    onClick={(e) => {
                      setCrditModel(true);
                      setHistoryEle([
                        { element: ele },
                        {
                          whole: historyCredits,
                        },
                      ]);
                    }}
                    className='h-100 w-100 pt-3'
                  >
                    <div className='d-flex justify-content-between creditHistoryListcSS'>
                      <div className='d-flex '>
                        <img src={creditsArrow} alt='logo' />
                        <p className='mt-3 mx-2 credit_h_p1'>
                          count {ele?.count}
                        </p>
                      </div>
                      <div className='mt-3'>
                        <p className='credit_h_p2'>
                          <b>{ele?.type}</b>
                        </p>
                      </div>

                      <div className='mt-3'>
                        <p className='credit_h_p3'>
                          <b>
                            {moment(ele?.timestamp, "MM/DD/YYYY ").format(
                              "Do MMMM, YYYY"
                            )}
                          </b>{" "}
                          <span className='credit_h_p4'>
                            {" "}
                            {moment(ele?.timestamp, "MM/DD/YYYY HH:mm").format(
                              " hh:mm A"
                            )}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className='d-flex justify-content-center align-items-center h-100 w-100'>
                      {/* <h6>Credit history not available yet</h6> */}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        </Row>
      </Row>
      <CreditTransactionModel
        data={historyEle}
        show={crditModel}
        cancelModel={() => setCrditModel(false)}
      />
    </>
  );
};

export default CreditPage;
