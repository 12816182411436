import { useMutation } from "react-query";
import { PLANS_SUMMARY_MUTATION } from "./keys";

import authApi from "../services/loginApi";
import PlansSummaryApi from "../services/PlansSummaryApi";

export const useSumaryMutation = (history) => {
  const response = useMutation(PlansSummaryApi.postPrivateSumary, {
    mutationKey: PLANS_SUMMARY_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
