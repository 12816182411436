import { useFintechHubInfo } from "./useFintechHub";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import FT_LOGO from "../../images/explorePageLogo.svg";
import FT_LOGO1 from "../../images/explrePageLogoGif.gif";
import "./css/styles.css";

const Fintech = (props) => {
  const { gifLogo } = useFintechHubInfo(props);

  return (
    <>
      <Row className='loginPage'>
        {gifLogo ? (
          <div>
            <img src={FT_LOGO1} alt='img' className='exploreLogoGif ' />
          </div>
        ) : (
          <Col md={12} lg={12} sm={12} xs={12} xl={12}>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                <img src={FT_LOGO} alt='img' className='exploreLogo pb-5' />
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                <h2>Fintech Hub</h2>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                <p>
                  We seek to achieve this by supporting the development of the
                  <br />
                  infrastructure required for the growth of the fintech industry
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                <a href={`${process.env.REACT_APP_WEB_FORMS}/#/wellcome`}>
                  <Button className='exploreBtn btn  '>Apply</Button>
                </a>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12} xl={12}>
                <Link to='/login'>
                  <Button className='exploreLoginBtn btn '>Login</Button>
                </Link>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default Fintech;
