import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useUploadPhotoDayPassRoom,
  useConfirmPassRoom,
} from "../../../../rc-query/dayPassHook";
import { useDelUploadinDaypassMutation } from "../../../../rc-query/meetinRoomBookingHook";

const UsePaymentUploadPhoto = () => {
  const userType = localStorage.getItem("userTypeRedir");
  const delDayPassMutation = useDelUploadinDaypassMutation();
  const [modelShow1, setModelShow1] = useState(false);
  const [wholePageLoading, setWholePageLoading] = useState();
  const useUploadPhoto = useUploadPhotoDayPassRoom();
  const useConfirmPass = useConfirmPassRoom();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [photoDataByApi, setPhotoDataByApi] = useState();
  const [comentData, setComentData] = useState();

  const Navigate = useNavigate();
  const state = useLocation();
  const {
    // bookingDayPassData,
    // bookingDayAvailbityData,
    // scheduleDate,
    scheduledData,
    textDesp,
  } = state?.state;

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Prevent user from going back to previous page using the browser back button
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
    window.addEventListener("beforeunload", function (e) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = "";
    });
  }, []);

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        htmlFormattedSize: formatBytes(file.size),
      })
    );

    setSelectedFiles(files);

    // upload Api
    useUploadPhoto
      .mutateAsync(files)
      .then((data) => {
        if (data?.status === 200) {
          setPhotoDataByApi(data);
        } else
          toast.error("something went wrong to upload photo", {
            position: toast.POSITION.TOP_RIGHT,
          });
      })
      .catch((err) => {
        // err?.response?.data?.error?.message;
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const confirmFunc = () => {
    setWholePageLoading("true");
    useConfirmPass
      .mutateAsync({
        photoDataByApi: photoDataByApi,
        scheduledData: scheduledData,
        comentData: comentData,
      })

      .then((data) => {
        setWholePageLoading("false");
        if (data?.data?.status === 200) {
          Navigate("/day-pass-app/daypass-approval", {
            state: { textDesp: textDesp },
          });
          toast.success(data?.data?.data, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (data?.data?.statusCode === 400) {
          toast.error(data?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        setWholePageLoading("false");
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const delPlanFunc = () => {
    delDayPassMutation
      .mutateAsync(scheduledData)
      .then((data) => {
        setModelShow1(false);
        if (data?.data?.message === "Daypass Booking has been Cancelled") {
          Navigate(
            `${
              userType === "daypassUser"
                ? "/day-pass-app/day-pass-dashboard"
                : "/meeting-room-app/meeting-room"
            }`
          );
          // ******this will work after Api call under lines ******
          // Navigate("/day-pass-app/day-pass-dashboard");
        } else {
          toast.error(data?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        setModelShow1(false);
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return {
    delPlanFunc,
    modelShow1,
    setModelShow1,
    wholePageLoading,
    setWholePageLoading,
    scheduledData,
    confirmFunc,
    handleAcceptedFiles,
    selectedFiles,
    setSelectedFiles,
    setComentData,
    textDesp,
  };
};

export default UsePaymentUploadPhoto;
