import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useUploadPhotoRoom } from "../../../rc-query/dayPassHook";
import { useCreateHelpDesk } from "../../../rc-query/helpDeskHook";
import { useQuery } from "react-query";
import BookMeetingRoom from "../../../services/bookMeetingRoomApi";

const UseCreateDesk = () => {
  const createHelpDesk = useCreateHelpDesk();
  const [subLoading, setSubLoading] = useState(false);
  const useUploadPhoto = useUploadPhotoRoom();
  const [title, setTitle] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [photoDataByApi, setPhotoDataByApi] = useState();
  const [imgLoading, setImgLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleAcceptedFiles = (files) => {
    setImgLoading(true);
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        htmlFormattedSize: formatBytes(file.size),
      })
    );

    setSelectedFiles(files);

    // upload Api
    useUploadPhoto
      .mutateAsync(files)
      .then((data) => {
        if (data?.status === 200) {
          setImgLoading(false);
          setPhotoDataByApi(data);
        } else {
          toast.error("something went wrong to upload photo", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setImgLoading(false);
        }
      })
      .catch((err) => {
        setImgLoading(false);
        // err?.response?.data?.error?.message;
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    if (!selectedFiles[0]) {
      setPhotoDataByApi("");
    }
  }, [selectedFiles]);

  // ********* coworker start ***********

  const { data: coworkerListData } = useQuery(
    ["coworkersData"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );

  // ********* coworker end ***********
  const coworkerId = coworkerListData?.Id;

  const submitFunc = () => {
    setCreateLoading(true);
    // useCreateHelpDesk

    createHelpDesk
      .mutateAsync({
        subject: title,
        description: description,
        CoworkerId: coworkerId,
        ImageFileName: selectedFiles ? selectedFiles[0]?.path : "",
        NewImageUrl: `https://storage.nexudus.com/api/content/uploads/files${photoDataByApi?.data?.files[0]}`,
      })
      .then((data) => {
        if (data?.data?.statusCode === 200) {
          toast.success(data?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCreateLoading(false);
          setModelShow1(true);
        } else {
          toast.error(data?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCreateLoading(false);
        }
      })
      .catch((err) => {
        setCreateLoading(false);
        // err?.response?.data?.error?.message;
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return {
    modelShow1,
    setModelShow1,
    setTitle,
    setDescription,
    handleAcceptedFiles,
    selectedFiles,
    setSelectedFiles,
    submitFunc,
    title,
    description,
    photoDataByApi,
    imgLoading,
    subLoading,
    createLoading,
  };
};

export default UseCreateDesk;
