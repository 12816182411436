import React from "react";
import { Card, Col, Label, Row } from "reactstrap";
import UseMeetingRoom from "../useMeetingRoom";
import useWhatHappeningInfo from "./useWhatHappening";
import BottomImage from "../../../.././images/fintechBgImg1.svg";
import Clock from "../../../.././images/yellowClock.svg";
import Dot from "../../../.././images/yellowDot.svg";
import moment from "moment";
import "./css/style.css";

const WhatHappening = () => {
  const { feeds, feedsLoading } = UseMeetingRoom();
  const { extimateTimeFunc, Navigate } = useWhatHappeningInfo();

  return (
    <>
      <Row className='pt-4 whatHappeningPage'>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Label className='m_rB_l1'>
            <b>What’s happening</b>
          </Label>
          <Row>
            {!feedsLoading
              ? feeds?.feeds?.Records?.map((feed, index) => {
                  return (
                    <Col
                      xl={5}
                      lg={5}
                      md={5}
                      sm={12}
                      xs={12}
                      className='mt-4'
                      key={feed?.Id}
                    >
                      <Card
                        className='p-3 feedCards'
                        onClick={() => {
                          Navigate("/detail-feed", {
                            state: {
                              data: feed,
                            },
                          });
                        }}
                        role='button'
                      >
                        <Row>
                          <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                            <div className='d-flex align-items-center h-100'>
                              <div>
                                <img
                                  src={`https://nexudus.spaces.nexudus.com/en/blog/GetImage/?id=${feed?.Id}`}
                                  alt='hubImg'
                                  className='w-100 feedImgs'
                                />
                              </div>
                            </div>
                          </Col>
                          <Col
                            xl={8}
                            lg={8}
                            md={8}
                            sm={8}
                            xs={8}
                            className='feedFlex'
                          >
                            <Row>
                              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <h2>{feed?.Title}</h2>
                                <p>{feed?.SummaryText}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className='d-flex '>
                                  <div>
                                    <img src={Clock} alt='clock' />
                                    <span className='px-2'>
                                      {moment(feed?.PublishDate)
                                        .format("DD MMMM, YYYY")
                                        .toString()}
                                    </span>
                                  </div>
                                  <div>
                                    <img src={Dot} alt='dot' />
                                    <span className='px-2'>
                                      {extimateTimeFunc(feed?.FullText)}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })
              : ""}
          </Row>
        </Col>
      </Row>

      <div className='bottomImageContainer w-100 '>
        <img
          src={BottomImage}
          alt='backgroundbottomImg'
          className=' bottomImage'
        />
      </div>
    </>
  );
};

export default WhatHappening;
