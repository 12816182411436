import React from "react";
import { Card, Col, Row } from "reactstrap";
import UseMeetingRoom from "../useMeetingRoom";
import useDetailFeedInfo from "./useDetailFeed";
import Clock from "../../../../images/redClock.svg";
import BottomImage from "../../../../images/fintechBgImg1.svg";
import backBtn from "../../../../images/backBtnIconFeed.svg";
import moment from "moment";
import "./css/style.css";

const DetailFeed = () => {
  const {} = UseMeetingRoom();
  const { data, description, navigate } = useDetailFeedInfo();

  return (
    <>
      <Row className='feedDetailPage mt-5 '>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className='d-flex feedHeading '>
                <div
                  className='detailFeedBackBtn '
                  role='button'
                  onClick={() => navigate(-1)}
                >
                  <img src={backBtn} alt='img' className='w-50' />
                </div>
                <div>
                  <h1 className='mx-3'>{data?.Title} </h1>
                  <p className='mx-3'>{`By: ${data?.PostedByFullName}`} </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card className='feedDetailCard p-3'>
                <Row>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                    <div className=' '>
                      <div>
                        <img
                          src={`https://nexudus.spaces.nexudus.com/en/blog/GetImage/?id=${data?.Id}`}
                          alt='hubImg'
                          className='w-100 feedImgs'
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={7} lg={7} md={7} sm={7} xs={7}>
                    <h2>{data?.SummaryText}</h2>
                  </Col>
                  <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                    <div className='feedTimeFlex '>
                      <div className='d-flex'>
                        <div
                          className='redClock '
                          role='button'
                          onClick={() => navigate(-1)}
                        >
                          <img src={Clock} alt='img' className='w-50' />
                        </div>
                        <span className='px-2 mt-1'>
                          {moment(data?.PublishDate)
                            .format("DD MMMM, YYYY")
                            .toString()}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className='feedDescriptionRow mt-4'>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <p>{description(data?.FullText)}</p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className='bottomImageContainer w-100 '>
        <img
          src={BottomImage}
          alt='backgroundbottomImg'
          className=' bottomImage'
        />
      </div>
    </>
  );
};

export default DetailFeed;
