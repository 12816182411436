import { Button, Col, Row, Card, Label } from "reactstrap";
import { Image, Shimmer, Breathing } from "react-shimmer";
import PrivateOfficeImg from "../../../../../images/private_office.svg";
import DurationSideImage from "../../../../../images/durationsideimage.svg";
import BtmPic from "../../../../../images/privateroom_btm_pic.svg";
import UseSelectDuration from "./useSelectDuration";
import UsersImg from "../../../../../images/users.svg";
import "react-datepicker/dist/react-datepicker.css";
import "./css/styles.css";

const SelectDuration = () => {
  const newDate = new Date();
  const minMonth = `${newDate.getFullYear()}-${(newDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}`;
  const {
    selectedArrAdministrator,
    selectedArr,
    selectedName,
    selectName,
    selectedResourceName,
    selectedResourceCapacity,
    confirmFunction,
  } = UseSelectDuration();

  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <p className='sd_h1 mt-4'>Selected Office</p>
        <Row>
          <Col className='mb-4' xl={5} lg={5} md={5} sm={12} xs={12}>
            <Card className='p-3  db_card  w-100 h-100 '>
              <Image
                src={PrivateOfficeImg}
                fallback={<Breathing width='100' />}
                className='w-100 h-100'
              />
              <span className='d-flex pos_cardusersicon mt-3 '>
                <Label className='pos_h2  '>{selectedResourceName}</Label>
                <span className=''>
                  <img className='mx-2 ' alt='img' src={UsersImg} />
                  <Label className='pos_total_users '>
                    {" "}
                    {selectedResourceCapacity}
                  </Label>
                </span>
              </span>
            </Card>
          </Col>
          <Col xl={3} lg={3} md={3} sm={3} xs={3}></Col>

          <Col xl={4} lg={4} md={4} sm={4} xs={4}>
            <img
              className='sd_sideimg kforhide'
              alt='img'
              src={DurationSideImage}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={8} lg={8} md={8} sm={8} xs={8}>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <p className='sd_h2 mt-4'>Select members for Office</p>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}></Col>
            </Row>

            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <p className='hybrid_team_m_instruction'>
                  Remaining members will automatically be assigned dedicated
                  desks
                </p>
              </Col>
            </Row>

            <Row>
              <Row>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Label name='name 2' className={`sd_input1 w-100 mt-2 mb-2`}>
                    <span className='sd_btn_font'>
                      {selectedArrAdministrator?.fullName}
                    </span>
                    <span className='sd_administrator_paying'>
                      <span className='sd_administrator'>Administrator</span>
                      {selectedArrAdministrator?.isPayingMember === true ? (
                        <span className='sd_payingMember'>Paying member</span>
                      ) : (
                        ""
                      )}
                    </span>
                  </Label>
                </Col>

                {selectedArr &&
                  selectedArr?.map((e, index) => {
                    return (
                      <>
                        <Col
                          xl={6}
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          key={e?.Id + 1}
                        >
                          <div>
                            <div className='inputRadioGroup  mt-2 mb-4'>
                              <input
                                type='checkbox'
                                id={index}
                                name={e?.fullName}
                                value={e?.fullName}
                                onClick={(ele) => {
                                  selectName(e);
                                  selectedName(e);
                                }}
                              />
                              <label for={index} className='checkLabel'>
                                {e?.fullName}
                                {e?.isPayingMember === true ? (
                                  <span className='sd_payingMember'>
                                    Paying member
                                  </span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </Col>
                      </>
                    );
                  })}
              </Row>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xl={8} lg={8} md={8} sm={8} xs={8}></Col>
          <Col xl={4} lg={4} md={4} sm={12} xs={12}>
            <Button
              color='dark'
              className='mt-5 w-100 mb-5 loginBtn'
              onClick={confirmFunction}
            >
              confirm
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className='ImageContainer'>
              <img src={BtmPic} alt='img' className='pv_pic ' />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SelectDuration;
