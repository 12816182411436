import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import BookMeetingRoom from "../.././services/bookMeetingRoomApi";
import { useNavigate } from "react-router-dom";
import { useFcmTokenSend } from "../../rc-query/loginHook";
import { toast } from "react-toastify";
import { messaging } from "../../firebase";
import { getToken } from "firebase/messaging";

const UseRedirectmainPage = () => {
  const [fcmTokenState, setFcmTokenState] = useState("");

  const UseFcmTokenSend = useFcmTokenSend();
  const fcmTokenForPushNoti = localStorage.getItem("fcmTokenForPushNoti");
  const Navigate = useNavigate();

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // const token = getToken(messaging, {
      //   vapidKey:
      //     "BHG_TCe7aP1MhdQ177Y1Ts6JONU9ufuQbgaTON0YlwFKu32sb_qyq-QC8rf8N4NDopKitj9uqm7a-actL2XLmwA",
      // }).then((t) => setFcmTokenState(t));
      //  localStorage.setItem("fcmTokenForPushNoti", token);
      // generate token
    } else if (permission === "denied") {
      // alert(" you denied for the notification");
    }
  }

  useEffect(() => {
    // req user for notification permission
    requestPermission();
  }, []);

  // ****** Api of coworker Id *********

  const { data: coworkerListData, isLoading: coworkerLoading } = useQuery(
    ["coworkerListData"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );

  // **********Api of plans status **********
  const {
    data: plansStatusData,
    isLoading: plansStatusLoading,
    error: plansStatusError,
  } = useQuery(
    ["palnsstatusData", coworkerListData],
    () => BookMeetingRoom.plansStatus(coworkerListData),
    {
      retry: 0,
      onError: (err) => {
        if (err?.message === "Plan Request not Exists") {
          return null;
        }
      },
    }
  );

  useEffect(() => {
    localStorage.setItem("progressBarLength", "");
    localStorage.setItem("progressBarStart", false);
    localStorage.setItem("privateBar", false);
    localStorage.setItem("hybridBar", false);
  }, []);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const userId = coworkerListData?.Id;

  useEffect(() => {
    localStorage.setItem("userId", userId);
    // setTimeout(() => {
    UseFcmTokenSend.mutateAsync({ fcmTokenState, userId })
      .then((data) => {})
      .catch((err) => {
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    // }, 1000);
  }, [userId, fcmTokenState]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        (coworkerListData?.TeamIds === "" ||
          coworkerListData?.TeamIds === null ||
          coworkerListData?.TeamIds === undefined) &&
        coworkerListData?.IsTeamAdministrator === false
      ) {
        Navigate("/day-pass-app/day-pass-dashboard");
        localStorage.setItem("userTypeRedir", "daypassUser");
      } else if (
        (coworkerListData?.TeamIds !== "" ||
          coworkerListData?.TeamIds !== null ||
          coworkerListData?.TeamIds !== undefined) &&
        coworkerListData?.IsTeamAdministrator === true &&
        plansStatusError?.response?.data?.error?.message ===
          "Plan Request not Exists"
      ) {
        Navigate("/welcome");
        localStorage.setItem("userTypeRedir", "meetingroomAdminstrator");
      } else if (
        (coworkerListData?.TeamIds !== "" ||
          coworkerListData?.TeamIds !== null ||
          coworkerListData?.TeamIds !== undefined) &&
        coworkerListData?.IsTeamAdministrator === true &&
        plansStatusData?.data?.requestStatus === "pending"
      ) {
        Navigate("/pending-page");
        localStorage.setItem("userTypeRedir", "meetingroomAdminstrator");
      } else if (
        (coworkerListData?.TeamIds !== "" ||
          coworkerListData?.TeamIds !== null ||
          coworkerListData?.TeamIds !== undefined) &&
        plansStatusData?.data?.requestStatus === "approved" &&
        coworkerListData?.IsTeamAdministrator === true
      ) {
        Navigate("/meeting-room-app/meeting-room");
        localStorage.setItem("userTypeRedir", "meetingroomAdminstrator");
      } else if (
        (coworkerListData?.TeamIds !== "" ||
          coworkerListData?.TeamIds !== null ||
          coworkerListData?.TeamIds !== undefined) &&
        coworkerListData?.IsTeamAdministrator === true &&
        plansStatusData?.data?.requestStatus === "deny"
      ) {
        Navigate("/welcome");
        localStorage.setItem("userTypeRedir", "meetingroomAdminstrator");
      } else if (
        (coworkerListData?.TeamIds !== "" ||
          coworkerListData?.TeamIds !== null ||
          coworkerListData?.TeamIds !== undefined) &&
        coworkerListData?.IsTeamAdministrator === false &&
        plansStatusData?.data?.requestStatus === "pending"
      ) {
        Navigate("/pending-page");
        localStorage.setItem("userTypeRedir", "meetingroomTeamMember");
      } else if (
        (coworkerListData?.TeamIds !== "" ||
          coworkerListData?.TeamIds !== null ||
          coworkerListData?.TeamIds !== undefined) &&
        plansStatusData?.data?.requestStatus === "approved" &&
        coworkerListData?.IsTeamAdministrator === false
      ) {
        Navigate("/meeting-room-app/meeting-room");
        localStorage.setItem("userTypeRedir", "meetingroomTeamMember");
      } else if (
        (coworkerListData?.TeamIds !== "" ||
          coworkerListData?.TeamIds !== null ||
          coworkerListData?.TeamIds !== undefined) &&
        coworkerListData?.IsTeamAdministrator === false &&
        plansStatusError?.response?.data?.error?.message ===
          "Plan Request not Exists"
      ) {
        Navigate("/inactive-page");
        localStorage.setItem("userTypeRedir", "meetingroomTeamMember");
      } else if (
        (coworkerListData?.TeamIds !== "" ||
          coworkerListData?.TeamIds !== null ||
          coworkerListData?.TeamIds !== undefined) &&
        coworkerListData?.IsTeamAdministrator === false &&
        plansStatusData?.data?.requestStatus === "deny"
      ) {
        Navigate("/inactive-page");
        localStorage.setItem("userTypeRedir", "meetingroomTeamMember");
      }
      else{

    
      
      
        const timeoutId = setTimeout(() => {
          toast.error("User not found, Please login with valid credentials", {
            position: toast.POSITION.TOP_RIGHT,
          });
          localStorage.clear();
          Navigate("/")
          
        }, 1000);
  
        return () => clearTimeout(timeoutId);
      }
    }, 4000);
    return () => clearTimeout(timer);
  }, [coworkerListData, plansStatusError, plansStatusData]);
  // **********end of API coworker Id *********





  //new
  // useEffect(() => {
  //   const clearLocalStorageAfterDelay = () => {
  //     const delay = 180000; // 3 minutes in milliseconds

  //     const timeoutId = setTimeout(() => {
  //       localStorage.clear();
        
  //     }, delay);

  //     return () => clearTimeout(timeoutId);
  //   };

  //   clearLocalStorageAfterDelay();
  // }, []); 

  return {};
};

export default UseRedirectmainPage;
