import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useChangePasswordMutation,
  useUpdatePasswordMutation,
} from "../../rc-query/changePasswordHook";
import "./css/styles.css";

export const useChangePasswordInfo = (props) => {
  const { state } = useLocation();
  const { data, tab } = state;
  const changePasswordMutation = useChangePasswordMutation(props);
  const updatePasswordMutation = useUpdatePasswordMutation(props);
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const [newpasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPass, setConfirmPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [validEye, setValidEye] = useState("eye1");

  const [incorrect, setIncorrect] = useState(true);
  const [displayError, setDisplayError] = useState("hideError");
  const [lenghtValid, setLenghtValid] = useState("");
  const [numberValid, setNumberValid] = useState("");
  const [upcaseValid, setUpcaseValid] = useState("");
  const [specialCharValid, setSpecialCharValid] = useState("");

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  const toggleNewPassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setNewPasswordShown(!passwordShown);
  };

  const newPassFunc = (ele) => {
    setNewPass(ele?.target?.value);
  };
  const confirmPassFunc = (ele) => {
    setConfirmPass(ele?.target?.value);
  };

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (newPass === "" || confirmPass === "") {
      setIncorrect(true);
    } else if (newPass === confirmPass) {
      setDisplayError("hideError");
      setIncorrect(false);
    } else {
      setDisplayError("displayError");
      setIncorrect(true);
    }

    if (newPass.length < 8 && confirmPass !== "") {
      setLenghtValid("invalidSpan");
      setIncorrect(true);
    } else {
      setLenghtValid("");
    }
    if (!/[A-Z]/.test(newPass) && newPass !== "") {
      setUpcaseValid("invalidSpan");
      setIncorrect(true);
    } else {
      setUpcaseValid("");
    }
    if (!/\d/.test(newPass) && newPass !== "") {
      setNumberValid("invalidSpan");
      setIncorrect(true);
    } else {
      setNumberValid("");
    }

    if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPass) && newPass !== "") {
      setSpecialCharValid("invalidSpan");
      setIncorrect(true);
    } else {
      setSpecialCharValid("");
    }
  }, [confirmPass, newPass]);

  const changePasswordFunc = () => {
    if (data?.userId) {
      const passwordData = {
        userId: data?.userId,
        confirmPass: confirmPass,
      };

      if (newPass === confirmPass) {
        updatePasswordMutation
          .mutateAsync({ ...passwordData })
          .then((data) => {
            if (data?.data?.statusCode === 200) {
              localStorage.removeItem("token");
              toast.success(
                "Password Update Successfully, Kindly Login again with new password",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );

              navigate("/");
            }
          })
          .catch((err) => {
            toast.error(err?.response?.data?.error?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        toast.error("Password doesn't match", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      if (newPass === confirmPass) {
        changePasswordMutation
          .mutateAsync({ confirmPass: confirmPass, newPass: newPass })
          .then((data) => {
            // verify condition when reset password to new user

            if (data?.data?.Message === "Your password has been changed.") {
              // localStorage.setItem("access Token", data?.data?.access_token);

              navigate("/login");
              localStorage.setItem("access Token", "");
              toast.success("Your password has been changed.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else {
              toast.error(data?.data?.Message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((err) => {
            toast.error(err?.response?.data?.error?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        toast.error("Password doesn't match", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  return {
    passwordShown,
    newpasswordShown,
    validEye,
    data,
    incorrect,
    displayError,
    lenghtValid,
    numberValid,
    upcaseValid,
    specialCharValid,
    updatePasswordMutation,
    changePasswordMutation,
    newPassFunc,
    togglePassword,
    toggleNewPassword,
    changePasswordFunc,
    confirmPassFunc,
  };
};
