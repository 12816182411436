import { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
// import BookMeetingRoom from "../../../services/bookMeetingRoomApi";
import BookMeetingRoom from "../../../../services/bookMeetingRoomApi";
import moment from "moment";

const UseSchduleMeetingRoom = () => {
  const [dayPassActivityAvail, setDayPassActivityAvail] = useState(true);
  const userType = localStorage.getItem("userTypeRedir");
  const state = useLocation();
  const bookingroomsData = state?.state?.bookingroomsData
    ? state?.state?.bookingroomsData
    : [];
  const dataOfSelectedObject = state?.state?.dataOfSelectedObject
    ? state?.state?.dataOfSelectedObject
    : {};

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const { bookingroomsData, dataOfSelectedObject } = state?.state;

  const [scheduleDate, setScheduleDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [scheduleTime, setScheduleTime] = useState(null);
  const [scheduleNewTime, setScheduleNewTime] = useState();
  const [durationColorState, setDurationColorState] = useState();
  const [repeatbookingShow, setReapetBookingShow] = useState(false);
  const [selectedBookingRoomData, setSelectedBookingRoomData] = useState();
  const [timelineRawData, setTimelineRawData] = useState();
  const [repeatDaysState, setRepeatDaysState] = useState();
  const reschduleMode = localStorage.getItem("reschduleMode");
  const Navigate = useNavigate();

  useEffect(() => {
    const difFromTime = moment(
      dataOfSelectedObject?.FromTime,
      "YYYY-MM-DDTHH:mm:ss.SSSZ"
    );
    const difToTime = moment(
      dataOfSelectedObject?.ToTime,
      "YYYY-MM-DDTHH:mm:ss.SSSZ"
    );

    const totalFromMinutes = difFromTime.hours() * 60 + difFromTime.minutes();
    const totalToMinutes = difToTime.hours() * 60 + difToTime.minutes();
    const diffInMinutes = Math.abs(totalFromMinutes - totalToMinutes);

    /// for newschedule from Api

    const newTt = moment(dataOfSelectedObject?.ToTime).format("HH:mm");

    const dataofexpDate = `${moment().format(
      "YYYY-MM-DD"
    )} ${newTt}:00 GMT+0500 (Pakistan Standard Time)`;

    // *****if condtion starts

    if (reschduleMode === "true" && scheduleTime === null) {
      setScheduleDate(
        moment.utc(dataOfSelectedObject?.FromTime).format("YYYY-MM-DD")
      );
      setScheduleTime(moment(dataOfSelectedObject?.FromTime).format("HH:mm"));
      setDurationColorState(diffInMinutes);
      setScheduleNewTime(dataofexpDate);
      setReapetBookingShow(dataOfSelectedObject?.isRepeatBooking);

      if (dataOfSelectedObject?.isRepeatBooking === true) {
        if (dataOfSelectedObject?.Repeats === "1") {
          setRepeatDaysState({ value: 1, label: "Every Day" });
        }

        if (
          dataOfSelectedObject?.Repeats === "2" &&
          dataOfSelectedObject?.RepeatEvery === "1"
        ) {
          setRepeatDaysState({ value: 2, label: "Every Week" });
        }
        if (
          dataOfSelectedObject?.Repeats === "2" &&
          dataOfSelectedObject?.RepeatEvery === "2"
        ) {
          setRepeatDaysState({ value: 3, label: "Every Two Weeks" });
        }
        if (dataOfSelectedObject?.Repeats === "3") {
          setRepeatDaysState({ value: 4, label: "Every Month" });
        }
      }
    }
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: " rgba(0, 0, 0, 0.05)",
      borderColor: "#cacaca",
      minHeight: "30px",
      height: "62px",

      boxShadow: state.isFocused ? null : null,
    }),
  };

  const repeatDaysObj = [
    { value: 1, label: "Every Day" },
    { value: 2, label: "Every Week" },
    { value: 3, label: "Every Two Weeks" },
    { value: 4, label: "Every Month" },
  ];

  // *********cowerker API start*********
  const { data: coworkerListData, isLoading: coworkerLoading } = useQuery(
    ["coworkerListData"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );

  // *********cowerker API end*********

  // **********Active daypass booking **************
  // *********cowerker API start*********
  const {
    data: daypassActivBookingData,
    isLoading: daypassActivBookingLoading,
  } = useQuery(
    [coworkerListData?.Id, scheduleDate],
    () =>
      BookMeetingRoom.daypassActivBooking(
        coworkerListData,
        scheduleDate,
        userType
      ),
    {
      retry: 0,
    }
  );

  useEffect(() => {
    setDayPassActivityAvail(daypassActivBookingData?.data?.available);
  }, [daypassActivBookingData, scheduleDate, coworkerListData?.Id]);

  const {
    data: bookingroomsTimeLineDataa,
    isLoading: bookingroomsTimeLineDataaLoading,
  } = useQuery(
    [
      "bookingroomsTimeLineDataa",
      scheduleDate,
      selectedBookingRoomData,
      bookingroomsData[0]?.id,
    ],
    () =>
      BookMeetingRoom.getMeetingBookingsTimeLine(
        scheduleDate,
        selectedBookingRoomData,
        bookingroomsData
      ),
    {
      retry: 0,
    }
  );

  // practice start All timeline data

  const startTime = new Date("2023-03-25T08:00:00"); // replace with your start time
  const endTime = new Date("2023-03-25T22:59:00"); // replace with your end time
  const interval = 15 * 60 * 1000; // 15 minutes in milliseconds
  const timeLineBar = [];

  for (
    let time = startTime;
    time <= endTime;
    time.setTime(time.getTime() + interval)
  ) {
    let hourFormat = time.getHours() % 12 || 12; // Convert to 12 hour format
    let hourDesignation = time.getHours() >= 12 ? "PM" : "AM"; // Check if it's AM or PM
    let minuteDesignation = time.getMinutes() === 0 ? "hours" : "minutes"; // Determine if it's a main or sub hour

    // Create the time object with the AM/PM designation only for the hour

    let timeObj = {
      // time: `${
      //   time.getMinutes() === 0 ? `${hourFormat}` : `${time.getMinutes()}`
      // }`,
      hourDesignation: hourDesignation,
      minuteDesignation: minuteDesignation,
      hours: hourFormat,
      minutes: time.getMinutes(),
    };

    if (time.getMinutes() === 0) {
      timeObj.hourDesignation = hourDesignation;
    }

    timeLineBar.push(timeObj);
  }

  // practice end All timeline data
  // practice start unAvaialable  timeline array

  const timeFormat = "HH:mm"; // format for time parsing
  const displayFormat = "h:mm A"; // format for time display

  const timeLineBar2 = []; // initialize array for interval objects

  // loop through time objects and create interval objects for each 15-minute interval
  for (let i = 0; i < bookingroomsTimeLineDataa?.length; i++) {
    const start = moment(bookingroomsTimeLineDataa[i].FromTime, timeFormat); // parse start time
    const end = moment(bookingroomsTimeLineDataa[i].ToTime, timeFormat); // parse end time

    // loop through time intervals of 15 minutes until the end time is reached
    for (
      let time = start.clone();
      time.isSameOrBefore(end);
      time.add(15, "minutes")
    ) {
      // create object for current time interval
      // const slotTime = moment(time.format(displayFormat), "h:mm A");
      const intervalObject = {
        tt: time.format(displayFormat).includes(":00")
          ? Number(moment(time.format(displayFormat), ["h:mm A"]).format("h"))
          : "",
        startTime: time.format(displayFormat),
        endTime: time.add(14, "minutes").format(displayFormat),

        hourDesignation2: time.format("A"),
        minutesBackend:
          moment(time.format(displayFormat), ["h:mm A"]).format("mm") - 14,
        hoursBackend: moment(time.format(displayFormat), ["h:mm A"]).format(
          "h"
        ),
        totaltime: `${moment(time.format(displayFormat), ["h:mm A"]).format(
          "h"
        )}:${
          moment(time.format(displayFormat), ["h:mm A"]).format("mm") - 14
        } ${time.format("A")}`,
      };

      // between hours

      timeLineBar2.push(intervalObject); // add object to array
      time.subtract(14, "minutes"); // reset time back to start of interval
    }
  }

  // output array of interval objects
  // practice end unAvaialable  timeline array

  // border of selected elements start

  const startDD = moment(scheduleTime, "HH:mm");
  const startDdd = moment(
    `${startDD.format("YYYY-MM-DD")}T${startDD.format("HH:mm:ss")}`
  );

  const startDd = startDdd.format("YYYY-MM-DD[T]HH:mm:ss");
  const startTimescheduleTime = new Date(startDd); // replace with your start time
  const endTimescheduleNewTime = new Date(scheduleNewTime);
  const selectedBorderArr = [];

  for (
    let timeSchedule = startTimescheduleTime;
    timeSchedule <= endTimescheduleNewTime;
    timeSchedule.setTime(timeSchedule.getTime() + interval)
  ) {
    let hourFormatSchedule = timeSchedule.getHours() % 12 || 12; // Convert to 12 hour format
    let hourDesignationSchedule = timeSchedule.getHours() >= 12 ? "PM" : "AM"; // Check if it's AM or PM
    let minuteDesignationSchedule =
      timeSchedule.getMinutes() === 0 ? "hours" : "minutes";

    let timeObj = {
      // time: `${
      //   time.getMinutes() === 0 ? `${hourFormat}` : `${time.getMinutes()}`
      // }`,
      hourDesignation: hourDesignationSchedule,
      minuteDesignation: minuteDesignationSchedule,
      ttHours:
        Number(timeSchedule.getMinutes()) === 0 ? hourFormatSchedule : "",
      hours: Number(hourFormatSchedule),
      minutes: timeSchedule.getMinutes(),
      totaltime: `${hourFormatSchedule}:${timeSchedule.getMinutes()} ${hourDesignationSchedule}`,
    };

    if (timeSchedule.getMinutes() === 0) {
      timeObj.hourDesignationSchedule = hourDesignationSchedule;
    }

    selectedBorderArr.push(timeObj);
  }

  // border of selected elements end

  // scheduleDate
  return {
    dayPassActivityAvail,
    reschduleMode,
    scheduleDate,
    setScheduleDate,
    scheduleTime,
    setScheduleTime,
    scheduleNewTime,
    setScheduleNewTime,
    durationColorState,
    setDurationColorState,
    repeatbookingShow,
    setReapetBookingShow,
    customStyles,
    repeatDaysObj,
    Navigate,
    bookingroomsData,
    repeatDaysState,
    setRepeatDaysState,
    timeLineBar,
    timeLineBar2,
    selectedBorderArr,
    selectedBookingRoomData,
    setSelectedBookingRoomData,
    bookingroomsTimeLineDataaLoading,
    coworkerListData,
    dataOfSelectedObject,
    scheduleDate,
    repeatbookingShow,
  };
};

export default UseSchduleMeetingRoom;
