import { useEffect } from "react";

const UseDaypassBeforeDashboard = () => {
  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return {};
};

export default UseDaypassBeforeDashboard;
