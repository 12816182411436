import axios from "axios";
import { Suadi_fintech_URL, Saudi_App_Base_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import { stageToken } from "../constants/local";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_App_Base_URL,
});

const fintechRequest = axios.create({
  baseURL: Suadi_fintech_URL,
});

const BookMeetingRoom = {
  getMeetingRoom: async () => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`meeting-rooms`, {
      headers: headers,
    });

    return data;
  },

  getMeetingBookingsTime: async () => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/bookings/1414985590`, {
      headers: headers,
    });

    return data;
  },

  getMeetingBookingspayment: async (id) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };
    if (id) {
      const data = await request.get(`/resource-pice/${id}`, {
        headers: headers,
      });

      return data;
    }
  },

  meetingRoomPostApi: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const data = await request.post(
      `/meetings`,
      { ...body },
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );

    return data;
  },

  meetingRoomRescheduleApi: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const data = await request.post(
      // `/meetings-reschedule`,
      // `/meetings-reschedule-members`,
      `/meetings-reschedule`,
      { ...body },
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );

    return data;
  },

  meetingRoomRescheduleMemberApi: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const data = await request.post(
      `/meetings-reschedule-members`,

      { ...body },
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );

    return data;
  },

  coworkerIdGetApi: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
      Accept: "application/json",
      "Content-Type": "application/json",
      
    };
    const data = await fintechRequest.get(`/en/coworker/profiles`, {
      headers: headers,
    });

    let coworker = await data?.data?.find((user) => user?.CoworkerType === 1);

    // return data;
    return coworker;
  },

  getbookingSettings: async () => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
      Accept: "application/json, text/plain",
    };
    const data = await request.get(`/booking-settings`, {
      headers: headers,
    });

    return data;
  },

  getMeetingBookingsTimeLine: async (
    body,
    selectedBookingRoomData,
    bookingroomsData
  ) => {
    body === undefined
      ? (body = moment.utc(new Date()).format("YYYY-MM-DD"))
      : (body = body);

    if (bookingroomsData) {
      const ID =
        selectedBookingRoomData === undefined
          ? bookingroomsData[0]?.Id
          : selectedBookingRoomData?.Id;

      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
        // Accept: "application/json, text/plain",
      };
      // const res = await request.get(`/bookings/1414985590`, {
      const res = await request.get(`/bookings/${ID}`, {
        headers: headers,
      });

      const rawData = res?.data?.meetingRooms?.map((ele) => {
        return {
          Id: ele?.Id,
          ResourceId: ele?.ResourceId,
          ResourceName: ele?.ResourceName,
          FromCompleteDate: ele?.FromTime,
          ToCompleteDate: ele?.ToTime,
          FromDate: moment.utc(ele?.FromTime).format("YYYY-MM-DD"),
          ToDate: moment.utc(ele?.ToTime).format("YYYY-MM-DD"),
          FromTime: moment(ele?.FromTime).format("HH:mm"),
          ToTime: moment(ele?.ToTime).format("HH:mm"),
          desiredFromTimestamp: moment
            .utc(ele?.FromTime)
            .format("YYYY-MM-DDTHH:mm:ss"),
          desiredToTimestamp: moment
            .utc(ele?.ToTime)
            .format("YYYY-MM-DDTHH:mm:ss"),
        };
      });

      const data = rawData?.filter((item) => {
        if (item.FromDate === body) {
          return item;
        }
      });

      return data;
    }
  },

  confirmdaypass: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const data = await request.patch(
      `/meeting-payments/${body?.confirmData?.data?.id}`,
      {
        InvoiceDoc: body?.photoDataByApi?.data?.files[0]?.originalname,
        PaymentComment: body?.comentData ? body?.comentData : "",
        paymentStatus: "pending",
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );

    return data;
  },

  plansStatus: async (body) => {
    if (body?.TeamIds) {
      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
        Accept: "application/json, text/plain",
      };
      const data = await request.get(
        `/plan-pending-requests-status/${body?.TeamIds}`,
        {
          headers: headers,
        }
      );

      return data;
    }
  },

  reqSumStatus: async (id) => {
    if (id) {
      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
        Accept: "application/json, text/plain",
      };
      const data = await request.get(`/plan-pending-requests/${id}`, {
        headers: headers,
      });

      return data;
    }
  },

  getCustomerMeetingRoom: async (id) => {
    if (id) {
      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
        Accept: "application/json, text/plain",
      };
      const data = await request.get(`/coworker-bookings-upcoming/${id}`, {
        headers: headers,
      });

      return data;
    }
  },

  getCustomerMeetingRoomwithFilter: async (
    id,
    stateOfDropDownMeeting,
    filterState,
    TeamIds,
    rangeState
  ) => {
    if (id) {
      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
        Accept: "application/json, text/plain",
      };

      // formating the end date of filter
      const originalToDateFilter = moment(
        rangeState[0]?.endDate,
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
      );
      const formatedToDateFilter = originalToDateFilter.format(
        "YYYY-MM-DDT23:59:59.999[Z]"
      );

      // formating the start date of filter
      const originalFromDateFilter = moment(
        rangeState[0]?.startDate,
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
      );
      const formatedFromDateFilter = originalFromDateFilter.format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );

      const data = await request.get(
        // if all or team and custom also else simple

        filterState?.value === "custom"
          ? stateOfDropDownMeeting?.value === "all" ||
            stateOfDropDownMeeting?.value === "team"
            ? `/coworker-team-bookings-upcoming/${id}?bookingType=${stateOfDropDownMeeting?.value}&dateFilter=${filterState?.value}&teamId=${TeamIds}&startDate=${formatedFromDateFilter}&endDate=${formatedToDateFilter}`
            : `/coworker-team-bookings-upcoming/${id}?bookingType=${stateOfDropDownMeeting?.value}&dateFilter=${filterState?.value}&startDate=${formatedFromDateFilter}&endDate=${formatedToDateFilter}`
          : // if all or team and not custom

          stateOfDropDownMeeting?.value === "all" ||
            stateOfDropDownMeeting?.value === "team"
          ? `/coworker-team-bookings-upcoming/${id}?bookingType=${stateOfDropDownMeeting?.value}&dateFilter=${filterState?.value}&teamId=${TeamIds}`
          : `/coworker-team-bookings-upcoming/${id}?bookingType=${stateOfDropDownMeeting?.value}&dateFilter=${filterState?.value}`,
        // stateOfDropDownMeeting?.value === "all" ||
        //   stateOfDropDownMeeting?.value === "team"
        //   ? `/coworker-team-bookings-upcoming/${id}?bookingType=${stateOfDropDownMeeting?.value}&dateFilter=${filterState?.value}&teamId=${TeamIds}`
        //   : `/coworker-team-bookings-upcoming/${id}?bookingType=${stateOfDropDownMeeting?.value}&dateFilter=${filterState?.value}`,
        {
          headers: headers,
        }
      );

      return data;
    }
  },

  contractGetApi: async (id) => {
    if (id) {
      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
        Accept: "application/json, text/plain",
      };
      const data = await request.get(`/teams-contracts/${id}`, {
        headers: headers,
      });

      return data;
    }
  },

  //invoiceDownload
  invoiceDownload: async (id) => {
    if (id) {
      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
        Accept: "application/json, text/plain",
      };
      // const data = await fintechRequest.get(`/en/invoices/print?guid=${id}`, {
      const data = await fintechRequest.get(
        `/en/invoices/print?guid=1c9e9a13-05ef-475b-a6b9-c3a41820c922`,
        {
          headers: headers,
        }
      );

      return data;
    }
  },

  // invoice,
  invoice: async (inv) => {
    let id = inv?.CoworkerInvoiceNumber;
    

    if (id) {
      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
        Accept: "application/json, text/plain",
      };
      const data = await request.get(`/nexudus-invoice/${id}`, {
        headers: headers,
      });

      return data;
    }
  },

  // downloadInvApi

  downloadInvApi: async (id) => {
    const jwt = localStorage.getItem("access Token");
    const data = await request.patch(
      `/invoices-download/${id}`,
      // `/invoices-download/1420123121`,

      {},
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );

    return data;
  },
  // },

  // ******* DELETE BOOKING *********

  deleteBooking: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const data = await request.patch(
      `/cancel-meetings/${body}`,
      {},
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );

    return data;
  },

  // delete daypass

  deleteDaypasses: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const data = await request.patch(
      `/cancel-daypass/${body}`,
      {},
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );

    return data;
  },

  // DELETE BOOKING FROM UPLOADING PAGE
  deleteUploadingBooking: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const data = await request.patch(
      `/delete-meetings/${body?.data?.id}`,
      {},
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );

    return data;
  },

  deleteUploadingDaypass: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const data = await request.patch(
      `delete-daypass/${body?.data?.id}`,
      {},
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );

    return data;
  },

  daypassActivBooking: async (id,date,userType) => {
    if(userType === "daypassUser" && id?.Id && date){
   
    const jwt = localStorage.getItem("access Token");
    const data = await request.get(
      `/daypasses-request/${id?.Id}/${date}`,
      {},
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );

    return data;
  }
  else {
    return {
      available : true
    } 
  }
}
};

export default apiWrapper(BookMeetingRoom, [
  "daypassActivBooking",
  "deleteUploadingDaypass",
  "deleteUploadingBooking",
  "getMeetingRoom",
  "getMeetingBookingsTime",
  "getMeetingBookingspayment",
  "meetingRoomPostApi",
  "coworkerIdGetApi",
  "getMeetingBookingsTimeLine",
  "getbookingSettings",
  "confirmdaypass",
  "plansStatus",
  "reqSumStatus",
  "getCustomerMeetingRoom",
  "meetingRoomRescheduleApi",
  "meetingRoomRescheduleMemberApi",
  "invoice",
  "downloadInvApi",
  "invoiceDownload",
  "deleteBooking",
  "deleteDaypasses",
  "getCustomerMeetingRoomwithFilter",
]);
