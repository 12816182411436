import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import Navbar from "react-bootstrap/Navbar";
// import 'react-pro-sidebar/dist/css/styles.css';
import React, { useState, useContext } from "react";
import { TabContext } from "../../context/TabContext";
// import { CustomScrollbars } from "react-custom-scrollbars";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";

import Members from "../../images/members.svg";
import MemberShip from "../../images/membership.svg";
import Logout from "../../images/logout.svg";
import Credits from "../../images/creditsImg.svg";
import Home from "../../images/homeDashboard.svg";
import FAQ from "../../images/FAQImg.svg";
// import Home from "../../images/sidenavicons/home.svg";
import HelpDesk from "../../images/helpDesk.svg";
import Seetings from "../../images/seetings.svg";
import "./css/sidebar.scss";
import "./css/sidebar.css";
import { Col, Container, Row } from "react-bootstrap";
import { GrFormClose } from "react-icons/gr";
import Cross from "../../images/cross.svg";
import BookMeetingRoom from "../../services/bookMeetingRoomApi";

// import BookMeetingRoom from "./services/bookMeetingRoomApi";/
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";

const Sidebar = ({ navshow, setNavShow }) => {
  const userType = localStorage.getItem("userTypeRedir");
  const [memberToggle, setMemberToggle] = useState(false);
  const [requestToggle, setRequestToggle] = useState(false);
  const [logsToggle, setLogsToggle] = useState(false);
  const { activeTabJustify, setActiveTabJustify } = useContext(TabContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const toggleSubMenu = (value) => {
    if (value === 1) {
      setMemberToggle(true);
      setRequestToggle(false);
      setLogsToggle(false);
    } else if (value === 2) {
      setMemberToggle(false);
      setRequestToggle(true);
      setLogsToggle(false);
    } else if (value === 3) {
      setMemberToggle(false);
      setRequestToggle(false);
      setLogsToggle(true);
    } else {
      setMemberToggle(false);
      setRequestToggle(false);
      setLogsToggle(false);
    }
  };

  // ********* coworker start ***********
  const { data: coworkerListData } = useQuery(
    ["coworkerDatas"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );

  // ********* coworker end ***********
  const user = coworkerListData;

  return (
    <>
      <Row>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <Row>
            <ProSidebar className='sideNavShow '>
              {/* <Navbar> */}
              <Row className='pt-4 px-4'>
                {/* <Col md={3} xl={3} lg={3} sm={3} xs={3}></Col> */}
                <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                  <h5>{user?.FullName}</h5>
                  <p>{user?.Email}</p>
                  <span className='side-nav-icon'>
                    <img
                      src={Cross}
                      alt='crossIcon'
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setNavShow(!navshow);
                      }}
                    />
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                  <Navbar>
                    <SidebarContent>
                      <Menu className='sidebar-scroller'>
                        <div className='sidebarNavMenu'>
                          <div>
                            <MenuItem
                              className={
                                splitLocation[1] === "meeting-room-app" ||
                                splitLocation[1] === "day-pass-app"
                                  ? "activesidebar"
                                  : ""
                              }
                              onClick={() => {
                                toggleSubMenu(null);
                                setNavShow(!navshow);
                              }}
                            >
                              <NavLink
                                to={`${
                                  userType === "daypassUser"
                                    ? "/day-pass-app/day-pass-dashboard"
                                    : "/meeting-room-app/meeting-room"
                                }`}
                                className=' waves-effect navlinkText'
                              >
                                <Row>
                                  <Col md={1} xl={3} lg={1} sm={3} xs={3}>
                                    <img src={Home} alt='img' />
                                  </Col>
                                  <Col md={11} xl={9} lg={11} sm={9} xs={9}>
                                    <h5>Dashboard</h5>
                                    <p>Account overview</p>
                                  </Col>
                                </Row>
                              </NavLink>
                            </MenuItem>
                            <MenuItem
                              className={` ${
                                splitLocation[1] === "membership-invoice"
                                  ? "activesidebar"
                                  : ""
                              }
                              ${
                                userType === "daypassUser"
                                  ? "daypass-side-n"
                                  : ""
                              } `}
                              onClick={() => {
                                toggleSubMenu(null);
                                setNavShow(!navshow);
                              }}
                            >
                              <NavLink
                                to={`${
                                  userType === "daypassUser"
                                    ? pathname
                                    : "/membership-invoice"
                                }`}
                                className=' waves-effect navlinkText'
                              >
                                <Row>
                                  <Col md={1} xl={3} lg={1} sm={3} xs={3}>
                                    <img src={MemberShip} alt='img' />
                                  </Col>
                                  <Col md={11} xl={9} lg={11} sm={9} xs={9}>
                                    <h5>Membership</h5>
                                    <p>Review Team & Pay charges</p>
                                  </Col>
                                </Row>
                              </NavLink>
                            </MenuItem>
                            <MenuItem
                              className={
                                (`${
                                  splitLocation[1] === "my-team"
                                    ? "activesidebar"
                                    : ""
                                }`,
                                `${
                                  userType === "daypassUser"
                                    ? "daypass-side-n"
                                    : ""
                                }`)
                              }
                              onClick={() => {
                                toggleSubMenu(null);
                                setNavShow(!navshow);
                              }}
                            >
                              <NavLink
                                to={`${
                                  userType === "daypassUser"
                                    ? pathname
                                    : "/my-team"
                                }`}
                                className=' waves-effect navlinkText'
                              >
                                <Row>
                                  <Col md={1} xl={3} lg={1} sm={3} xs={3}>
                                    <img src={Members} alt='img' />
                                  </Col>
                                  <Col md={11} xl={9} lg={11} sm={9} xs={9}>
                                    <h5>My Team</h5>
                                    <p>Manage your team</p>
                                  </Col>
                                </Row>
                              </NavLink>
                            </MenuItem>
                            <MenuItem
                              className={
                                (`${
                                  splitLocation[1] === "team-credits"
                                    ? "activesidebar"
                                    : ""
                                }`,
                                `${
                                  userType === "daypassUser"
                                    ? "daypass-side-n"
                                    : ""
                                }`)
                              }
                              onClick={() => {
                                toggleSubMenu(null);
                                setNavShow(!navshow);
                              }}
                            >
                              <NavLink
                                to={`${
                                  userType === "daypassUser"
                                    ? pathname
                                    : "/team-credits"
                                }`}
                                className=' waves-effect navlinkText'
                              >
                                <Row>
                                  <Col md={1} xl={3} lg={1} sm={3} xs={3}>
                                    <img src={Credits} alt='img' />
                                  </Col>
                                  <Col md={11} xl={9} lg={11} sm={9} xs={9}>
                                    <h5>Team Credits</h5>
                                    <p>Credit purchases and history</p>
                                  </Col>
                                </Row>
                              </NavLink>
                            </MenuItem>
                          </div>

                          <div className='divDIvider '>
                            <hr className='mx-3' />
                            <MenuItem
                              className={
                                splitLocation[1] === "faq"
                                  ? "activesidebar"
                                  : ""
                              }
                              onClick={() => {
                                toggleSubMenu(null);
                                setNavShow(!navshow);
                              }}
                            >
                              <NavLink
                                to='/faq'
                                className=' waves-effect navlinkText'
                              >
                                <Row>
                                  <Col md={1} xl={3} lg={1} sm={3} xs={3}>
                                    <img src={FAQ} alt='img' />
                                  </Col>
                                  <Col md={11} xl={9} lg={1} sm={9} xs={9}>
                                    <h5 className='mt-2'>FAQs</h5>
                                    {/* <p>
                                      Submit and check status of <br />
                                      your issues
                                    </p> */}
                                  </Col>
                                </Row>
                              </NavLink>
                            </MenuItem>
                            <MenuItem
                              className={
                                splitLocation[1] === "help-desk"
                                  ? "activesidebar"
                                  : ""
                              }
                              onClick={() => {
                                toggleSubMenu(null);
                                setNavShow(!navshow);
                              }}
                            >
                              <NavLink
                                to='/help-desk'
                                className=' waves-effect navlinkText'
                              >
                                <Row>
                                  <Col md={1} xl={3} lg={1} sm={3} xs={3}>
                                    <img src={HelpDesk} alt='img' />
                                  </Col>
                                  <Col md={11} xl={9} lg={1} sm={9} xs={9}>
                                    <h5 className='mt-2'>Help Desk</h5>
                                    {/* <p>
                                      Submit and check status of <br />
                                      your issues
                                    </p> */}
                                  </Col>
                                </Row>
                              </NavLink>
                            </MenuItem>
                            <MenuItem
                              className={
                                splitLocation[1] === "manage-account" ||
                                splitLocation[1] === "settings" ||
                                splitLocation[1] === "update-profile"
                                  ? "activesidebar"
                                  : ""
                              }
                              onClick={() => toggleSubMenu(null)}
                            >
                              <NavLink
                                to='/settings'
                                className=' waves-effect navlinkText'
                              >
                                <Row>
                                  <Col md={1} xl={3} lg={1} sm={3} xs={3}>
                                    <img src={Seetings} alt='img' />
                                  </Col>
                                  <Col md={11} xl={9} lg={11} sm={9} xs={9}>
                                    <h5 className='mt-2'>Settings</h5>
                                    {/* <p>Change how app works for you</p> */}
                                  </Col>
                                </Row>
                              </NavLink>
                            </MenuItem>
                            <MenuItem
                              className={
                                splitLocation[1] === "dashboard"
                                  ? "activesidebar"
                                  : ""
                              }
                              onClick={() => {
                                toggleSubMenu(null);
                                setNavShow(!navshow);
                              }}
                            >
                              <NavLink
                                to='/login'
                                className=' waves-effect navlinkText'
                                onClick={() => {
                                  localStorage.clear();
                                }}
                              >
                                <Row>
                                  <Col md={1} xl={3} lg={1} sm={3} xs={3}>
                                    <img src={Logout} alt='img' />
                                  </Col>
                                  <Col md={1} xl={9} lg={11} sm={9} xs={9}>
                                    <h5
                                      className='mt-2'
                                      style={{ color: "#EF4050" }}
                                    >
                                      Logout
                                    </h5>
                                    {/* <p>Logout easily with one tap</p> */}
                                  </Col>
                                </Row>
                              </NavLink>
                            </MenuItem>
                          </div>
                        </div>
                      </Menu>
                    </SidebarContent>
                  </Navbar>
                </Col>
              </Row>
              {/* </Navbar> */}
            </ProSidebar>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Sidebar;
