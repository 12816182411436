import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BookMeetingRoom from "../../services/bookMeetingRoomApi";
import { useQuery } from "react-query";
import helpDeskApi from "../../services/helpDeskApi";

const UseHelpDesk = () => {
  const [modelData, setModelData] = useState();
  const [modelShow1, setModelShow1] = useState(false);
  const [activeTabJustify, setActiveTabJustify] = useState("1");
  const navigate = useNavigate();

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ********* coworker start ***********

  const { data: coworkerListData } = useQuery(
    ["coworkersData"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );

  // ********* coworker end ***********
  // const userId = coworkerListData?.Id;
  const userId = coworkerListData?.Id;

  // *******Get Customer Open Requests******

  const { data: customerOpenListData, isLoading: customerOpenListLoading } =
    useQuery(
      ["customerOpenList", userId, activeTabJustify],
      () => helpDeskApi.getCustomerOpenRequests(userId),
      {
        retry: 0,
      }
    );

  const customerOpenData = customerOpenListData?.data?.data;

  // *******Get Customer Close Requests******
  const { data: customerCloseListData, isLoading: customerCloseListLoading } =
    useQuery(
      ["customerCloseList", userId, activeTabJustify],
      () => helpDeskApi.getCustomerCloseRequests(userId),
      {
        retry: 0,
      }
    );

  const customerCloseData = customerCloseListData?.data?.data;

  const toggleCustomJustified = (tab) => {
    if (tab === "1") {
      setActiveTabJustify(tab);
    } else if (tab === "2") {
      setActiveTabJustify(tab);
    } else {
      setActiveTabJustify(tab);
    }
  };

  const closeFunc = () => {
    setModelShow1(false);
  };

  return {
    modelData,
    setModelData,
    customerCloseListLoading,
    customerOpenData,
    customerCloseData,
    closeFunc,
    modelShow1,
    setModelShow1,
    activeTabJustify,
    // membershipDetail,
    // getAllInvoices,
    // teamListID,
    toggleCustomJustified,
    navigate,
    customerOpenListLoading,
  };
};

export default UseHelpDesk;
