import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const UseDedicatedOffice = () => {
  const Navigate = useNavigate();
  const { state } = useLocation();
  const { dedicatedDeskData } = state;

  useEffect(() => {
    localStorage.setItem("progressBarLength", "");
    localStorage.setItem("progressBarStart", false);
  });

  const monthlyFunc = (e) => {
    Navigate("/monthly-dedicated-select-duration", {
      state: { dedicatedDeskData: dedicatedDeskData, plansPayment: "monthly" },
    });
    localStorage.setItem("progressBarLength", 1);
    localStorage.setItem("progressBarStart", true);
  };

  const yearlyFunc = (e) => {
    Navigate("/yearly-dedicated-select-duration", {
      state: { dedicatedDeskData: dedicatedDeskData, plansPayment: "yearly" },
    });
    localStorage.setItem("progressBarLength", 1);
    localStorage.setItem("progressBarStart", true);
  };

  return {
    dedicatedDeskData,
    yearlyFunc,
    monthlyFunc,
  };
};

export default UseDedicatedOffice;
