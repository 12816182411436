import { Link } from "react-router-dom";
import { Col, Row, Label, Button } from "reactstrap";
import BookingConfirmedIcon from "./../../../.././images/bookingConfirmed.svg";
import UseMeetingConfirmed from "./useMeetingConfirmed";
import { useQueryClient } from "react-query";
import "./css/style.css";

const MeetingConfirmed = () => {
  const {} = UseMeetingConfirmed();
  const queryClient = useQueryClient();

  return (
    <div className='p-4'>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <img className='mrc_l4 mt-5' alt='img' src={BookingConfirmedIcon} />
        </Col>
      </Row>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Label className='mrc_l1 mt-4'>Booking Confirmed</Label>
        </Col>
      </Row>
      <Row>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}></Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
          <Label className='mrc_l2 mt-4'>
            Your booking charges will be posted in your team memo.
          </Label>
        </Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}></Col>
      </Row>
      <Row>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}></Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
          <Link to='/redirect-main-page' className='btnLink'>
            <Button
              color='dark'
              className='mrc_l3 w-100  mt-4'
              onClick={() => {
                localStorage.setItem("reschduleMode", "false");
                // for listing of reschdule
                queryClient.invalidateQueries("customerbookingroomsData");
                queryClient.invalidateQueries("customerbookingroomsData2");
                queryClient.invalidateQueries("bookingDayPassLData");
              }}
            >
              Home
            </Button>
          </Link>
        </Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}></Col>
      </Row>
    </div>
  );
};

export default MeetingConfirmed;
