import { useTeamManagementInfo } from "./useTeamManagement";
import { Col, Row, Card, Button } from "reactstrap";
import TeamManagemantPic from "../../../images/teamManagement.svg";
import User from "../../../images/userBlue.svg";
import User2 from "../../../images/UserRed.svg";
import RequestModel from "../../../components/molicules/requestModel";
import TeamManagemantModel from "../../../components/molicules/teamManagementModel";
import OtpModel from "../../../components/molicules/otpModel";
import "../css/styles.css";

const TeamManagemant = (props) => {
  const {
    modelShow,
    teamManageModel,
    teamPayingModel,
    otpModel,
    confirmRequest,
    data,
    navigate,
    HandleOtpFunc,
    ChangeRequestFunc,
    setConfirmRequest,
    setOtpModel,
    setTeamPayingModel,
    setTeamManageModel,
    setModelShow,
  } = useTeamManagementInfo(props);
  return (
    <>
      <div>
        <Row>
          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
            <Row className='myTeamMainPage'>
              <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                <h1>Team { (data?.teamInfo?.companyName && data?.teamInfo?.companyName.length > 50)
  ? data?.teamInfo?.companyName.slice(0, 50) + '...'
  : data?.teamInfo?.companyName}</h1>
                <Card className='myTeamCard mt-4'>
                  <Row>
                    <Col md={1} lg={1} xl={1} sm={1} xs={1}>
                      <div className=' d-flex justify-content-end'>
                        <img
                          src={TeamManagemantPic}
                          alt='teamManagement '
                          className='myTeamImg'
                        />
                      </div>
                    </Col>
                    <Col
                      md={11}
                      lg={11}
                      xl={11}
                      sm={11}
                      xs={11}
                      className='mt-3'
                    >
                      <div className='d-flex justify-content-between align-items-end'>
                        <h2>Team management </h2>

                        <Button
                          className='myTeamEditBtn'
                          onClick={
                            () => navigate(-1)
                            // setModelShow(true)
                          }
                        >
                          Cancel
                        </Button>
                      </div>

                      <Row>
                        <Col md={6} lg={6} xl={6} sm={12} xs={12}>
                          {/* for hybrid plan showing dedicated members */}
                          {data?.teamInfo?.dedicatedMembers?.map(
                            (member, index) => {
                              if (
                                member?.isAdministrator === true ||
                                member?.isPayingMember === true
                              ) {
                                return (
                                  <div>
                                    <div className='d-flex justify-content-between mt-2'>
                                      <span>
                                        <img src={User2} alt='userPic' />
                                        {/* <h3 className='mx-3'> </h3> */}
                                        <span className='mx-3 memberList'>
                                          {member?.fullName}

                                          <span className='DDmyTeamSpan px-2 mx-3'>
                                            <span>Dedicated Desk</span>
                                          </span>
                                        </span>
                                      </span>
                                    </div>

                                    <span className='sd_administrator_paying'>
                                      {member?.isAdministrator === true ? (
                                        <span className='team_administrator   px-2'>
                                          Administrator
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {member?.isPayingMember === true ? (
                                        <span className='sd_payingMember px-2'>
                                          Paying member
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                );
                              }
                            }
                          )}
                          {/* for hybrid plan showing Private members members */}
                          {data?.teamInfo?.privateMembers?.map(
                            (member, index) => {
                              if (
                                member?.isAdministrator === true ||
                                member?.isPayingMember === true
                              ) {
                                return (
                                  <div>
                                    <div className='d-flex justify-content-between mt-2'>
                                      <span>
                                        <img src={User} alt='userPic' />
                                        {/* <h3 className='mx-3'> </h3> */}
                                        <span className='mx-3 memberList'>
                                          {member?.fullName}

                                          <span className='POmyTeamSpan px-2 mx-3'>
                                            <span>Private Office</span>
                                          </span>
                                        </span>
                                      </span>
                                    </div>

                                    <span className='sd_administrator_paying'>
                                      {member?.isAdministrator === true ? (
                                        <span className='team_administrator   px-2'>
                                          Administrator
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {member?.isPayingMember === true ? (
                                        <span className='sd_payingMember px-2'>
                                          Paying member
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                );
                              }
                            }
                          )}

                          {data?.teamInfo?.allActiveMembers?.map(
                            (member, index) => {
                              if (
                                (member.isPayingMember === true ||
                                  member?.isAdministrator === true) &&
                                !data?.teamInfo?.isMultiple
                              ) {
                                return (
                                  <div>
                                    <div className='d-flex justify-content-between mt-2'>
                                      <span>
                                        {data?.teamInfo?.ResourceTypeId ===
                                        Number(
                                          process.env.REACT_APP_DEDICATED_OFFICE
                                        ) ? (
                                          <img src={User2} alt='userPic' />
                                        ) : data?.teamInfo?.ResourceTypeId ===
                                          Number(
                                            process.env.REACT_APP_PRIVATE_OFFICE
                                          ) ? (
                                          <img src={User} alt='userPic' />
                                        ) : (
                                          ""
                                        )}
                                        {/* <h3 className='mx-3'> </h3> */}
                                        <span className='mx-3 memberList'>
                                          {member?.fullName}

                                          {data?.teamInfo?.ResourceTypeId ===
                                          Number(
                                            process.env
                                              .REACT_APP_DEDICATED_OFFICE
                                          ) ? (
                                            <span className='DDmyTeamSpan px-2 mx-3'>
                                              <span>Dedicated Desk</span>
                                            </span>
                                          ) : data?.teamInfo?.ResourceTypeId ===
                                            Number(
                                              process.env
                                                .REACT_APP_PRIVATE_OFFICE
                                            ) ? (
                                            <span className='POmyTeamSpan px-2 mx-3'>
                                              <span>Private Office</span>
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </span>
                                    </div>

                                    <span className='sd_administrator_paying'>
                                      {member?.isAdministrator === true ? (
                                        <span className='team_administrator   px-2'>
                                          Administrator
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {member?.isPayingMember === true ? (
                                        <span className='sd_payingMember px-2'>
                                          Paying member
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                );
                              }
                            }
                          )}
                        </Col>
                        <Col md={6} lg={6} xl={6} sm={12} xs={12}>
                          <div className='d-flex justify-content-end mt-3 '>
                            <button
                              className='changeAdminBtn'
                              onClick={() => setTeamManageModel(true)}
                            >
                              Change Administrator
                            </button>
                            <button
                              className='changePayingBtn'
                              onClick={() => setTeamPayingModel(true)}
                            >
                              Change Paying Member
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                <Card className='myTeamCard mt-4'>
                  <Row>
                    <Col md={1} lg={1} xl={1} sm={1} xs={1}>
                      <div className=' d-flex justify-content-end'>
                        <img
                          src={TeamManagemantPic}
                          alt='teamManagement '
                          className='myTeamImg'
                        />
                      </div>
                    </Col>
                    <Col
                      md={11}
                      lg={11}
                      xl={11}
                      sm={11}
                      xs={11}
                      className='mt-3'
                    >
                      <div className='d-flex justify-content-between align-items-end'>
                        <div className='teamManagementHeader'>
                          <h2>Team Members </h2>
                        </div>
                      </div>
                      <Row className='mt-3'>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          {data?.teamInfo?.isMultiple === false ? (
                            !data?.teamInfo?.allActiveMembers?.some(
                              (member) =>
                                !member.isAdministrator &&
                                !member.isPayingMember
                            ) ? (
                              ""
                            ) : (
                              <h4>Active</h4>
                            )
                          ) : !data?.teamInfo?.dedicatedMembers?.some(
                              (member) =>
                                !member.isAdministrator &&
                                !member.isPayingMember
                            ) ||
                            !data?.teamInfo?.privateMembers?.some(
                              (member) =>
                                !member.isAdministrator &&
                                !member.isPayingMember
                            ) ? (
                            ""
                          ) : (
                            <h4>Active</h4>
                          )}

                          {/* for hybrid plan showing dedicated members */}
                          {data?.teamInfo?.dedicatedMembers?.map(
                            (member, index) => {
                              if (
                                member?.isAdministrator === true ||
                                member?.isPayingMember === true
                              ) {
                              } else {
                                return (
                                  <div
                                    className='AvtiveMembers  d-flex justify-content-between mt-3'
                                    key={member?.Id}
                                  >
                                    <span>
                                      <img src={User2} alt='userPic' />
                                      <span className='mx-3 memberList'>
                                        {member?.fullName}
                                      </span>
                                    </span>
                                    <span className='DDmyTeamSpan px-2'>
                                      <span>Dedicated Desk</span>
                                    </span>
                                  </div>
                                );
                              }
                            }
                          )}

                          {/* for hybrid plan showing Private members members */}
                          {data?.teamInfo?.privateMembers?.map(
                            (member, index) => {
                              if (
                                member?.isAdministrator === true ||
                                member?.isPayingMember === true
                              ) {
                              } else {
                                return (
                                  <div
                                    className='AvtiveMembers  d-flex justify-content-between mt-3'
                                    key={member?.Id}
                                  >
                                    <span>
                                      <img src={User} alt='userPic' />
                                      <span className='mx-3 memberList'>
                                        {member?.fullName}
                                      </span>
                                    </span>
                                    <span className='POmyTeamSpan px-2'>
                                      <span>Private Office</span>
                                    </span>
                                  </div>
                                );
                              }
                            }
                          )}

                          {data?.teamInfo?.allActiveMembers?.map(
                            (member, index) => {
                              if (
                                data?.teamInfo?.isMultiple === false ||
                                !data?.teamInfo?.isMultiple
                              ) {
                                if (
                                  member?.isAdministrator === true ||
                                  member?.isPayingMember == true
                                ) {
                                } else {
                                  if (
                                    data?.teamInfo?.ResourceTypeId ===
                                    Number(process.env.REACT_APP_PRIVATE_OFFICE)
                                  ) {
                                    return (
                                      <div
                                        className='AvtiveMembers  d-flex justify-content-between mt-3'
                                        key={member?.Id}
                                      >
                                        <span>
                                          <img src={User} alt='userPic' />
                                          <span className='mx-3 memberList'>
                                            {member?.fullName}
                                          </span>
                                        </span>
                                        <span className='POmyTeamSpan px-2'>
                                          <span>Private Office</span>
                                        </span>
                                      </div>
                                    );
                                  } else if (
                                    data?.teamInfo?.ResourceTypeId ===
                                    Number(
                                      process.env.REACT_APP_DEDICATED_OFFICE
                                    )
                                  ) {
                                    return (
                                      <div
                                        className='AvtiveMembers  d-flex justify-content-between mt-3'
                                        key={member?.Id}
                                      >
                                        <span>
                                          <img src={User2} alt='userPic' />
                                          <span className='mx-3 memberList'>
                                            {member?.fullName}
                                          </span>
                                        </span>
                                        <span className='DDmyTeamSpan px-2'>
                                          <span>Dedicated Desk</span>
                                        </span>
                                      </div>
                                    );
                                  }
                                }
                              }
                            }
                          )}
                        </Col>
                      </Row>
                      <Row className='mt-3'>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          {data?.teamInfo?.inactive?.length === 0 ? (
                            ""
                          ) : (
                            <h4>Inactive</h4>
                          )}
                          {data?.teamInfo?.inactive?.map((member, index) => {
                            return (
                              <div
                                className='AvtiveMembers mt-3'
                                key={member?.Id}
                              >
                                <span>
                                  <img src={User2} alt='userPic' />
                                  <span className='mx-3 memberList'>
                                    {member.fullName}
                                  </span>
                                </span>
                              </div>
                            );
                          })}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <RequestModel
        show={modelShow}
        cancelModel={setModelShow}
        header={"Changes Saved"}
        body={"Your changes have been saved successfully."}
        request={"saveChanges"}
      />

      <TeamManagemantModel
        show={teamManageModel}
        cancelModel={setTeamManageModel}
        header={"Administrator"}
        members={data}
        otp={HandleOtpFunc}
        body={
          "Please select the new administrator, your app may restart in order to reflect the changes."
        }
        request={"isAdministrator"}
      />

      <TeamManagemantModel
        show={teamPayingModel}
        cancelModel={setTeamPayingModel}
        header={"Paying Member"}
        members={data}
        otp={HandleOtpFunc}
        body={
          "Please select the new paying member, your app may restart in order to reflect the changes."
        }
        request={"isPayingMember"}
      />

      <OtpModel
        show={otpModel}
        cancelModel={setOtpModel}
        header={"Enter Code"}
        changeRequest={ChangeRequestFunc}
        body={
          "We have sent you a code on your email address, please that code below to verify change"
        }
      />

      <RequestModel
        show={confirmRequest}
        cancelModel={setConfirmRequest}
        header={"Request Sent"}
        body={"You need to logout and login again to see the changing"}
        request={"saveChanges"}
      />
    </>
  );
};

export default TeamManagemant;
