import React from "react";
import UseRedirectmainPage from "./useRedirectmainPage";
import { Spinner } from "reactstrap";
import "../Login/css/styles.css";

const RedirectMainPage = () => {
  const {} = UseRedirectmainPage();
  return (
    <div className=' d-flex justify-content-center marginTopRedir '>
      <Spinner className='d-flex justify-content-center align-items-center   ' />
    </div>
  );
};

export default RedirectMainPage;
