import React from "react";
import UseSelectPackage from "./useSelectPackage";
import "../.././css/styles.css";
import { Card, Col, Label, Row, Spinner } from "reactstrap";
import selectPackageImg from "./../../../.././images/selectPackageImg.svg";

const SelectPackage = () => {
  const { Navigate, teamCreditsPackage, teamCreditsPackageLoading } =
    UseSelectPackage();
  const userType = localStorage.getItem("userTypeRedir");

  return (
    <>
      {teamCreditsPackageLoading ? (
        <div className='d-flex justify-content-center align-items-center vh-100'>
          <Spinner />
        </div>
      ) : (
        <Row className='printingCreditPage'>
          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
            <Row>
              <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                <h1>Select package </h1>
              </Col>
            </Row>

            <Row>
              {teamCreditsPackage?.map((ele) => {
                return (
                  <Col md={4} lg={4} xl={4} sm={12} xs={12}>
                    <Card
                      className='select-pkg-c1 p-3 my-3 '
                      onClick={() => {
                        if (userType === "meetingroomTeamMember") {
                          Navigate("/help-desk/credits-summary", {
                            state: ele,
                          });
                        } else if (userType === "meetingroomAdminstrator") {
                          Navigate("/help-desk/credit-summary-for-admin", {
                            state: ele,
                          });
                        }
                      }}
                    >
                      <Row>
                        <Col md={2} lg={2} xl={2} sm={2} xs={2}>
                          <img
                            src={selectPackageImg}
                            alt='img'
                            className='d-flex justify-content-center'
                          />
                        </Col>
                        <Col md={6} lg={6} xl={6} sm={6} xs={6}>
                          <p className='select-pkag-l1'>
                            <b> {ele?.Name} </b>
                          </p>
                          <p className='select-pkag-l2'> {ele?.Description}</p>
                        </Col>
                        <Col md={4} lg={4} xl={4} sm={4} xs={4}>
                          <div className='d-flex mt-3'>
                            <Label className='select-pkag-l3'>
                              {ele?.CurrencyCode}
                            </Label>
                            <Label className='select-pkag-l4 mx-2'>
                              {" "}
                              {ele?.Price}
                            </Label>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default SelectPackage;
