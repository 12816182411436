import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useUploadPhotoDayPassRoom } from "../../../rc-query/dayPassHook";
import { useUpdateInvoiceMutaion } from "../../../rc-query/membershipInvoiceHook";

export const useUploadInvoiceInfo = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data, tab } = state;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [photoDataByApi, setPhotoDataByApi] = useState();
  const [invoiceComment, setInvoiceComment] = useState("");
  const useUploadPhoto = useUploadPhotoDayPassRoom();
  const updateMutation = useUpdateInvoiceMutaion();

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        htmlFormattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles(files);

    // upload Api
    useUploadPhoto
      .mutateAsync(files)
      .then((data) => {
        if (data?.status === 200) {
          setPhotoDataByApi(data);
        } else
          toast.error("something went wrong to upload photo", {
            position: toast.POSITION.TOP_RIGHT,
          });
      })
      .catch((err) => {
        // err?.response?.data?.error?.message;
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateInvoiceFunc = () => {
    const uploadData = {
      DueDate: data?.DueDate,
      InvoiceDoc: photoDataByApi?.data?.files?.[0]?.originalname,
      PlanPrice: data?.PlanPrice,
      OtherPrice: 0,
      TaxPrice: data?.TaxAmount,
      TotalPrice: data?.TotalPrice,
      InvoiceNo: data.InvoiceNumber,
      InvoiceId: data?.InvoiceId,
      TeamName: data?.TeamName,
      PaymentComment: invoiceComment,
      TeamId: data?.TeamId,
    };

    updateMutation
      .mutateAsync(uploadData)
      .then((data) => {
        if (data?.status === 200) {
          toast.success("Invoice upload successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });

          navigate("/membership-invoice");
        }
      })
      .catch((err) => {
        // err?.response?.data?.error?.message;
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return {
    data,
    selectedFiles,
    photoDataByApi,
    updateMutation,
    setInvoiceComment,
    setSelectedFiles,
    handleAcceptedFiles,
    updateInvoiceFunc,
  };
};
