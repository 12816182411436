import { useDetailPageInfo } from "./useDetailPage";
import classnames from "classnames";
import MembershipSetting from "../../../images/settingLogo.svg";
import BottomImage from "../../../images/fintechBgImg1.svg";
import moment from "moment";
import { Card, Col, Row, NavLink, NavItem, Nav } from "reactstrap";
import { Link } from "react-router-dom";
import "../css/styles.css";

const MembershipInvoice = (props) => {
  const {
    activeTabJustify,
    membershipDetail,
    getAllInvoices,
    teamListID,
    toggleCustomJustified,
    navigate,
  } = useDetailPageInfo(props);

  return (
    <>
      <Row className=''>
        <div className='px-5 mb-5'>
          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
            <Row>
              <Col md={4} xl={4} lg={4} sm={12} xs={12}>
                <div className='px-5 navContainer'>
                  <Nav className=' nav-justified nav-width nav-btn'>
                    <NavItem className='nav-item'>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={
                          ("nav-request-bar",
                          classnames({
                            active: activeTabJustify === "1",
                          }))
                        }
                        onClick={() => {
                          toggleCustomJustified("1");
                        }}
                      >
                        <span className=' d-sm-block '>Details </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={
                          ("nav-request-bar",
                          classnames({
                            active: activeTabJustify === "2",
                          }))
                        }
                        onClick={() => {
                          toggleCustomJustified("2");
                        }}
                      >
                        <span className=' d-sm-block '>Memo(s)</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <hr className='w-100' />
              <Col md={8} xl={8} lg={8} sm={0} xs={0}></Col>
            </Row>
            {activeTabJustify === "1" ? (
              <Row className='mt-4 '>
                <Col
                  md={4}
                  xl={4}
                  lg={4}
                  sm={12}
                  xs={12}
                  className='numberMemberCard'
                >
                  <Card className='membershipInvoiceCard p-3 w-100 h-100'>
                    <Row>
                      <Col md={2} xl={2} lg={2} sm={2} xs={2}>
                        <img src={MembershipSetting} alt='settingLogo' />
                      </Col>
                      <Col md={10} xl={10} lg={10} sm={9} xs={9}>
                        <Row className='px-2 pt-2'>
                          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                            <h1>Company details</h1>
                            <h2>Company name</h2>
                            <h1>{membershipDetail?.teamName ? membershipDetail?.teamName.slice(0, 40) + '...':""}</h1>
                            <h2>Status</h2>
                            {membershipDetail?.status === "Active" ? (
                              <div className='activeStatusContainer'>
                                <div className='activeStatus'></div>
                                <span>Active</span>
                              </div>
                            ) : (
                              <div className='activeStatusContainer'>
                                <div className='activeStatus'></div>
                                <span>Inactive</span>
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col
                  md={4}
                  xl={4}
                  lg={4}
                  sm={12}
                  xs={12}
                  className='numberMemberCard'
                >
                  <Card className='membershipInvoiceCard p-3 w-100 h-100 '>
                    <Row>
                      <Col md={2} xl={2} lg={2} sm={2} xs={2}>
                        <img src={MembershipSetting} alt='settingLogo' />
                      </Col>
                      <Col md={10} xl={10} lg={10} sm={9} xs={9}>
                        <Row className='px-2 pt-2'>
                          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                            <div className='d-flex justify-content-between '>
                              <h1>Plan details</h1>
                              <Link
                                to='/edit-plan-reqeust'
                                state={{ Id: teamListID?.Id }}
                              >
                                <a>Edit</a>
                              </Link>
                            </div>
                            <h2>Resource</h2>
                            <h1>{membershipDetail?.PlanType}</h1>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col
                  md={4}
                  xl={4}
                  lg={4}
                  sm={12}
                  xs={12}
                  className='numberMemberCard'
                >
                  <Card className='membershipInvoiceCard p-3 w-100 h-100 '>
                    <Row>
                      <Col md={2} xl={2} lg={2} sm={2} xs={2}>
                        <img src={MembershipSetting} alt='settingLogo' />
                      </Col>
                      <Col md={10} xl={10} lg={10} sm={9} xs={9}>
                        <Row className='px-2 pt-2'>
                          <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                            <h1>Number of members</h1>
                            <h2>{membershipDetail?.teamMembers} members</h2>
                            <h1>Next memo date</h1>
                            <h2>
                              {moment(membershipDetail?.nextInovice)
                                .format("DD MMMM, YYYY")
                                .toString()}
                            </h2>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            ) : (
              <Row className='mt-4 invoicesContainer '>
                {getAllInvoices?.map((invoice, index) => {
                  if (invoice.Paid === false) {
                    return (
                      <Col
                        md={4}
                        xl={4}
                        lg={4}
                        sm={12}
                        xs={12}
                        className='numberMemberCard mt-3'
                        key={invoice.Id}
                      >
                        <Card
                          className='membershipInvoiceCard p-3 w-100 h-100'
                          role='button'
                          onClick={() =>
                            navigate("/invoice-detail", {
                              state: {
                                data: invoice,
                              },
                            })
                          }
                        >
                          <Row>
                            <Col
                              md={12}
                              xl={12}
                              lg={12}
                              sm={12}
                              xs={12}
                              className='d-flex justify-content-between '
                            >
                              <h1>{invoice.InvoiceNumber}</h1>
                              <h1>
                                {invoice.CurrencyCode}{" "}
                                {invoice.TotalAmountFormated}
                              </h1>
                            </Col>
                            <Col
                              md={12}
                              xl={12}
                              lg={12}
                              sm={12}
                              xs={12}
                              className='d-flex justify-content-between pt-3 membershipInvoiceCol'
                            >
                              <div>
                                <h1>Resource</h1>
                                <h2>{membershipDetail?.PlanType}</h2>
                              </div>
                              <div>
                                <h1>Due</h1>
                                <h2>
                                  {" "}
                                  {moment(invoice?.DueDateFormated)
                                    .format("DD MMMM, YYYY")
                                    .toString()}
                                </h2>
                              </div>
                              <div className='statusDiv'>
                                <h1>Status</h1>
                                <h3> Unpaid</h3>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    );
                  } else if (invoice.Paid === true) {
                    return (
                      <Col
                        md={4}
                        xl={4}
                        lg={4}
                        sm={12}
                        xs={12}
                        className='numberMemberCard mt-3'
                        key={invoice.Id}
                      >
                        <Card
                          className='membershipInvoiceCard p-3 w-100 h-100'
                          role='button'
                          onClick={() =>
                            navigate("/invoice-detail", {
                              state: {
                                data: invoice,
                              },
                            })
                          }
                        >
                          <Row>
                            <Col
                              md={12}
                              xl={12}
                              lg={12}
                              sm={12}
                              xs={12}
                              className='d-flex justify-content-between '
                            >
                              <h1>{invoice.InvoiceNumber}</h1>
                              <h1>
                                {invoice.CurrencyCode}{" "}
                                {invoice.TotalAmountFormated}
                              </h1>
                            </Col>
                            <Col
                              md={12}
                              xl={12}
                              lg={12}
                              sm={12}
                              xs={12}
                              className='d-flex justify-content-between pt-3 membershipInvoiceCol '
                            >
                              <div>
                                <h1>Resource</h1>
                                <h2>{membershipDetail?.PlanType}</h2>
                              </div>
                              <div>
                                <h1>Due</h1>
                                <h2>
                                  {
                                    moment(invoice?.DueDateFormated).format(
                                      "DD MMMM, YYYY"
                                    ).toString
                                  }
                                </h2>
                              </div>
                              <div className='statusDiv'>
                                <h1>Status</h1>
                                <h4> Paid</h4>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    );
                  }
                })}
              </Row>
            )}
          </Col>
        </div>
        <div className='bottomImageContainer w-100 '>
          <img
            src={BottomImage}
            alt='backgroundbottomImg'
            className=' bottomImage'
          />
        </div>
      </Row>
    </>
  );
};

export default MembershipInvoice;
