import { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import bookDayPassApi from "../../../../services/bookDayPassApi";
import BookMeetingRoom from "../../../../services/bookMeetingRoomApi";
import moment from "moment";

const UseDayPassAvailability = () => {
  const userType = localStorage.getItem("userTypeRedir");
  const reschduleMode = localStorage.getItem("reschduleMode");
  const [scheduleDate, setScheduleDate] = useState();
  const [dayPassActivityAvail,setDayPassActivityAvail] = useState(true);
  const state = useLocation();
  const { bookingDayPassData, textDesp } = state?.state;

  const {
    data: bookingDayAvailbityDataa,
    isLoading: dayPassAvailabilityLoading,
  } = useQuery(
    ["bookingDayPassData", scheduleDate],
    () => bookDayPassApi.checkAvailabilityDate(scheduleDate),
    {
      retry: 1,
    }
  );

  const { data: dayPassSlots, isLoading: dayPassSlotsLoading } = useQuery(
    ["dayPassSlots"],
    () => bookDayPassApi.getDayPassSlots(),
    {
      retry: 1,
    }
  );

  const bookingDayAvailbityData = bookingDayAvailbityDataa?.data;

  // checkAvailabilityDate;


// check if one time a specific day pass is booked then out proceed button will be hide 

// ********* coworker start ***********

const { data: coworkerListData } = useQuery(
  ["coworkerDatas"],
  () => BookMeetingRoom.coworkerIdGetApi(),
  {
    retry: 0,
  }
);


const {
  data: daypassActivBookingData,
  isLoading: daypassActivBookingLoading,
} = useQuery(
  [coworkerListData?.Id, scheduleDate],
  () =>
    BookMeetingRoom.daypassActivBooking(
      coworkerListData,
      scheduleDate,
      userType
    ),
  {
    retry: 0,
  }
);




useEffect(() => {
  setDayPassActivityAvail(daypassActivBookingData?.data?.available);
}, [daypassActivBookingData, scheduleDate, coworkerListData?.Id]);

  useEffect(() => {
    if (reschduleMode === "true" && bookingDayPassData) {
      setScheduleDate(
        moment(bookingDayPassData?.FromTime).format("YYYY-MM-DD")
      );
    }
  }, []);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isDateBooked = (date, bookedDates) => {
    return bookedDates?.some((bookedDate, index) => {
      if (moment(date).isSame(moment(bookedDate.date), "day")) {
        return true;
      } else {
        return false;
      }
    });
  };

  const isPastDate = (date) => {
    const today = new Date().setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return date.getTime() < tomorrow.getTime();
  };
  return {
    bookingDayPassData,
    bookingDayAvailbityData,
    dayPassAvailabilityLoading,
    scheduleDate,
    textDesp,
    dayPassSlots,
    reschduleMode,
    dayPassSlotsLoading,
    isPastDate,
    isDateBooked,
    setScheduleDate,
    dayPassActivityAvail
  };
};

export default UseDayPassAvailability;
