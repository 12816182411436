import { useInvoiceDetailInfo } from "./useInvoiceDetail";
import { useDetailPageInfo } from "../detailPage/useDetailPage";
import moment from "moment/moment";
import LoginImg from "../../../images/LoginImage.svg";
import User from "../../../images/usersCount.svg";
import { Card, Col, Row, Button } from "reactstrap";
import "../css/styles.css";

const InvoiceDetail = (props) => {
  const { products, data, uploadInvoiceFunc } = useInvoiceDetailInfo(props);
  const { membershipDetail } = useDetailPageInfo(props);

  var totaltax = 0;
  var totalAmount = 0;

  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row>
            <Col
              md={5}
              lg={5}
              xl={5}
              sm={12}
              xs={12}
              className='loginImageCol '
            >
              <img src={LoginImg} alt='img' className='invoiceDetailImg ' />
            </Col>
            <Col md={7} lg={7} xl={7} sm={12} xs={12}>
              <div className='invoiceDetailPage'>
                <Row className='sideCardView'>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    <Row>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        <Card className='invoiceDetailCard p-4'>
                          <Row>
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              <div className='d-flex justify-content-between'>
                                <h2>Team</h2>
                                <div>
                                  <img src={User} alt='memebrlogo' />
                                  <span> {membershipDetail?.teamMembers}</span>
                                </div>
                              </div>
                              <h1>{membershipDetail?.teamName}</h1>
                              <p>{membershipDetail?.teamId}</p>
                              <div className='d-flex justify-content-between teamDetail'>
                                <div>
                                  <p>Resource Plan</p>
                                  <h3>{membershipDetail?.PlanType}</h3>
                                </div>
                                <div className='cardRightText'>
                                  <p>Payment Plan</p>
                                  <h3>{membershipDetail?.planDuration}</h3>
                                </div>
                              </div>
                              <div className='teamDetail mt-3'>
                                <p>Address</p>
                                <h3>{membershipDetail?.address}</h3>
                              </div>
                              <hr />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              <h2>Billing</h2>
                              <div className='d-flex justify-content-between teamDetail'>
                                <div>
                                  <p> Invoice Date</p>
                                  <h3>
                                    {" "}
                                    {moment(data?.SentOn)
                                      .format("DD MMMM, YYYY")
                                      .toString()}
                                  </h3>
                                </div>

                                <div className='cardRightText'>
                                  <p>Due Date</p>
                                  <h3>
                                    {" "}
                                    {moment(data?.DueDate)
                                      .format("DD MMMM, YYYY")
                                      .toString()}
                                  </h3>
                                </div>
                              </div>

                              <div className='d-flex justify-content-between mt-3'>
                                <h3>
                                  {" "}
                                  {moment(data?.InvoiceFromDate)
                                    .format("DD MMMM, YYYY")
                                    .toString()}
                                </h3>
                                <div className='d-flex justify-content-between  align-self-center'>
                                  <span className='billingLeft'></span>
                                  <h3 className='billingPeriod'>
                                    Billing period
                                  </h3>
                                  <span className='billingRight'></span>
                                </div>
                                <h3>
                                  {" "}
                                  {moment(data?.InvoiceToDate)
                                    .format("DD MMMM, YYYY")
                                    .toString()}
                                </h3>
                              </div>

                              <hr />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              <h2>Products</h2>

                              {products?.map((product, index) => {
                                totaltax = totaltax + product.TaxAmount;
                                totalAmount = totalAmount + product.UnitPrice;

                                return (
                                  <div className='d-flex justify-content-between productsDetail mt-3'>
                                    <div>
                                      <h5>{product.DisplayAs}</h5>
                                      {/* <h6>Sheikh Ahmad</h6> */}
                                    </div>
                                    <div className='cardRightText'>
                                      <h5>
                                        {`${
                                          data.CurrencyCode
                                        } ${product.SubTotal.toFixed(2)}`}
                                      </h5>
                                      <h6>
                                        {product.Quantity} x{" "}
                                        {product.UnitPrice.toFixed(2)}
                                      </h6>
                                    </div>
                                  </div>
                                );
                              })}
                              <hr />
                              <div className='d-flex justify-content-between productsDetail mt-3'>
                                <div>
                                  <h5>Subtotal</h5>
                                  <h6>VAT ({products?.[0].TaxRate}%)</h6>
                                </div>
                                <div className='cardRightText'>
                                  <h5>
                                    {`${
                                      data.CurrencyCode
                                    } ${totalAmount.toFixed(2)}`}
                                  </h5>
                                  <h6>{totaltax}</h6>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                        <Card className='totalPayable  px-4 py-3'>
                          <div className='d-flex justify-content-between '>
                            <h1>Total Payable</h1>
                            <h2>{`${
                              data.CurrencyCode
                            } ${data.TotalAmount.toFixed(2)}`}</h2>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        {data?.Paid === false ? (
                          <Button
                            className='loginBtn w-100 mt-4'
                            onClick={() =>
                              uploadInvoiceFunc(
                                membershipDetail,
                                totaltax,
                                totalAmount
                              )
                            }
                          >
                            Proceed
                          </Button>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default InvoiceDetail;
