import { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import durationApi from "./../../../../.././services/selectDurationTeam";
import {
  usePostApiReschduleMeetingRoom,
  usePostApiReschduleMemberMeetingRoom,
} from "../../../../../rc-query/meetinRoomBookingHook";
import { toast } from "react-toastify";

const UseEditParticipents = () => {
  const reschduleMeetingRoom = usePostApiReschduleMemberMeetingRoom();
  const [selectedArrAdministrator, setSelectedArrAdministrator] = useState({});
  const [otherInvitesState, setOtherInvitesState] = useState(false);
  const [otherInvitesArr, setOtherInvitesArr] = useState([]);
  const [wholePageLoading, setWholePageLoading] = useState("false");
  const [selectedArr, setSelectedArr] = useState([]);
  const [otherInvitesName, setOtherInvitesName] = useState();
  const [otherInvitesEmail, setOtherInvitesEmail] = useState();
  const [selectedCceck, setSelectedCceck] = useState([]);
  const reschduleMode = localStorage.getItem("reschduleMode");
  const Navigate = useNavigate();
  const state = useLocation();
  const { dataOfSelectedObject } = state?.state;

  // ************modified team for backend **********
  const { data: teamListData, isLoading: teamLoading } = useQuery(
    ["teamListDatass"],
    () => durationApi.durationForMeetingRoomApi(),
    {
      retry: 0,
    }
  );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const allteamListData = teamListData;

  // ********* end *********

  // ************whole team start ********
  const { data: wholeTeamListData, isLoading: wholeTeamLoading } = useQuery(
    ["teamListDatas"],
    () => durationApi.TeamMemberList(),
    {
      retry: 0,
    }
  );

  const teamName = wholeTeamListData?.data[0]?.Team?.Name;

  //  ************end**********

  const selectedName = (e) => {
    let indexToDelete = -1;
    for (let i = 0; i < selectedArr.length; i++) {
      if (selectedArr[i].VisitorEmail === e?.VisitorEmail) {
        indexToDelete = i;
        break; // Stop looping since we found the matching object
      }
    }

    // If we found the matching object, delete it from the array
    if (indexToDelete !== -1) {
      const abc = selectedArr;
      abc.splice(indexToDelete, 1);
      setSelectedArr(abc);
    } else {
      // else add it into array
      setSelectedArr([
        ...selectedArr,
        {
          VisitorFullName: e?.VisitorFullName,
          // isAdministrator: e?.isAdministrator,
          VisitorEmail: e?.VisitorEmail,
          TypeName: "bookingVisitor",
        },
      ]);
    }
  };

  useEffect(() => {
    const admininstratorData = allteamListData?.find((e) => {
      if (e?.isAdministrator === true) {
        return e;
      }
    });
    setSelectedArrAdministrator({
      VisitorFullName: admininstratorData?.VisitorFullName,
      isAdministrator: admininstratorData?.isAdministrator,
      TypeName: "bookingVisitor",
    });
  }, [allteamListData]);

  useEffect(() => {
    if (reschduleMode === "true" && dataOfSelectedObject) {
      setOtherInvitesState(false);
      // setSelectedArr(dataOfSelectedObject?.BookingVisitors);

      let check = false;
      allteamListData?.map((e) => {
        check = false;
        dataOfSelectedObject?.BookingVisitors?.map((f) => {
          // If we found the matching object then add to selected array

          if (f?.VisitorEmail === e?.VisitorEmail) {
            selectedCceck.push(true);
            check = true;
            setSelectedArr([
              ...selectedArr,
              {
                VisitorFullName: e?.VisitorFullName,
                // isAdministrator: e?.isAdministrator,
                VisitorEmail: e?.VisitorEmail,
                TypeName: "bookingVisitor",
              },
            ]);
          }
        });

        if (!check) {
          selectedCceck.push(false);
        }
      });
      setOtherInvitesState(true);
    }
  }, [allteamListData]);

 

  useEffect(() => {
    const filteredArray = dataOfSelectedObject?.BookingVisitors?.filter(
      (member) => {
        // Return true if the member's id is not present in the selectedMembers array
        return !selectedArr.some(
          (selected) => selected.VisitorEmail === member.VisitorEmail
        );
      }
    );

    setOtherInvitesArr(filteredArray);
  }, [selectedArr, allteamListData]);

  const checkFunc = (ele, index) => {
    const newArr = [...selectedCceck];
    newArr[index] = ele.target.checked;
    setSelectedCceck(newArr);
  };

  


  const Proceedfunc = () => {
    setWholePageLoading("true");
    const combinedSelectedTeamArray = selectedArr.concat(otherInvitesArr);

    const obj1 = {
      totalPayable: dataOfSelectedObject?.totalPayable?dataOfSelectedObject?.totalPayable:0,
      subTotal: dataOfSelectedObject?.subTotal?dataOfSelectedObject?.subTotal:0,
      unitPrice: dataOfSelectedObject?.unitPrice?dataOfSelectedObject?.unitPrice:0,
    vat: dataOfSelectedObject?.vat?dataOfSelectedObject?.vat:0,
    vatPercentage: dataOfSelectedObject?.vatPercentage,
      //new
      ResourceId: dataOfSelectedObject?.ResourceId,
      isRepeatBooking: dataOfSelectedObject?.isRepeatBooking,
      isTeamBooking: dataOfSelectedObject?.isTeamBooking,
      FromTime: dataOfSelectedObject?.FromTime,
      ToTime: dataOfSelectedObject?.ToTime,
      BookingVisitors: combinedSelectedTeamArray,
      Team: dataOfSelectedObject?.Team,
      CoworkerName: dataOfSelectedObject?.CoworkerName,

      BookedOn: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString(),
      ResourceName: dataOfSelectedObject?.ResourceName,
      Price: Number(dataOfSelectedObject?.Price),
      CoworkerId: dataOfSelectedObject?.CoworkerId,
      isRescheduleRequest: true,
      Tentative: dataOfSelectedObject?.Tentative,
      isRepeatBooking: dataOfSelectedObject?.isRepeatBooking,
      // rescheduleId: dataOfSelectedObject?.rescheduleId,
      rescheduleId: dataOfSelectedObject?.id,
      // BookingId: dataOfSelectedObject?.BookingId,
      // BusinessId: dataOfSelectedObject?.BusinessId,
      // CurrencyId: dataOfSelectedObject?.CurrencyId,
      // InvoiceNo: dataOfSelectedObject?.InvoiceNo,
       // status: dataOfSelectedObject?.status,
       // TypeName: dataOfSelectedObject?.TypeName,
     
    };

    reschduleMeetingRoom
      .mutateAsync(obj1)
      .then((data) => {
      
        setWholePageLoading("false");
        if (data?.data?.statusCode === 200 || data?.data?.statusCode === "200") {
          Navigate("/redirect-main-page");
          setWholePageLoading("false");
        } else if (data?.data?.statusCode === "400") {
          setWholePageLoading("false");
          toast.error(data?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        setWholePageLoading("false");
       
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return {
    wholePageLoading,
    selectedArrAdministrator,
    setSelectedArrAdministrator,
    otherInvitesState,
    setOtherInvitesState,
    otherInvitesArr,
    setOtherInvitesArr,
    selectedArr,
    setSelectedArr,
    otherInvitesName,
    setOtherInvitesName,
    otherInvitesEmail,
    teamName,
    teamLoading,
    setOtherInvitesEmail,
    Navigate,
    checkFunc,
    teamListData,
    allteamListData,
    selectedName,
    checkFunc,
    dataOfSelectedObject,
    selectedCceck,
    setSelectedCceck,
    Proceedfunc,
  };
};

export default UseEditParticipents;
