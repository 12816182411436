import { useQuery } from "react-query";
import bookDayPassApi from "../../../services/bookDayPassApi";
import authApi from "../../../services/loginApi";
import BookMeetingRoom from "../../../services/bookMeetingRoomApi";

import { useEffect, useState } from "react";

const UseDaypassDashboard = () => {
  const [textDesp, setTextDesp] = useState();

  // ********* coworker start ***********
  const { data: coworkerListData } = useQuery(
    ["coworkerData"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ********* coworker end ***********
  const userId = coworkerListData?.Id;

  // listing Api of day

  const { data: dayPassListingDataa, isLoading: dayPassListingLoading } =
    useQuery(
      ["bookingDayPassLData", userId],
      () => bookDayPassApi.getDayPasslisting(userId),
      {
        retry: 0,
      }
    );
  const daypassListingData = dayPassListingDataa?.data?.data;

  const { data: bookingDayPassDataa, isLoading: bookingDayPassLoading } =
    useQuery(["bookingDayPassData"], () => bookDayPassApi.getDayPass(), {
      retry: 0,
    });

  const bookingDayPassData = bookingDayPassDataa?.data?.dayPass;

  const { data: findUserNexudus, isLoading: findUserNexudusLoading } = useQuery(
    ["findUserNexudus", userId],
    () => authApi.findUserLocally(userId),
    {
      retry: 0,
    }
  );

  useEffect(() => {
    if (bookingDayPassData?.Description) {
      const match = bookingDayPassData?.Description.match(
        /<span[^>]*>([^<]*)<\/span>/
      );
      const text = match && match[1];

      setTextDesp(text);
      // return text;
    }
  }, [bookingDayPassData]);

  return {
    bookingDayPassData,
    bookingDayPassLoading,
    textDesp,
    findUserNexudus,
    findUserNexudusLoading,
    daypassListingData,
    dayPassListingLoading,
  };
};

export default UseDaypassDashboard;
