import { useQuery } from "react-query";
import BookMeetingRoom from "../../../services/bookMeetingRoomApi";
import teamCredits from "../../../services/teamCreditsApi";
import feedApi from "../../../services/feedsApi";
import { useEffect } from "react";

const UseMeetingRoom = (props) => {
  const { data: bookingroomsDataa, isLoading: bookingroomsLoading } = useQuery(
    ["bookingsroomsData"],
    () => BookMeetingRoom.getMeetingRoom(),
    {
      retry: 0,
    }
  );
  const bookingroomsData = bookingroomsDataa?.data?.meetingRooms;

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ********* coworker start ***********
  const { data: coworkerListData } = useQuery(
    ["coworkerData"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );

  const userId = coworkerListData?.Id;

  // ********* coworker end ***********

  // ********* get user credits ***********
  const { data: memberCredits, isLoading: memberCreditsLoading } = useQuery(
    ["memberCredits", coworkerListData],
    () => teamCredits.getSingleMemberCredits(coworkerListData?.Id),
    {
      retry: 0,
    }
  );

  // ********* get feed from fintech ***********
  const { data: feeds, isLoading: feedsLoading } = useQuery(
    ["feeds"],
    () => feedApi.getAllFeeds(),
    {
      retry: 0,
    }
  );

  // ******* booked customer booking room data ***********

  const {
    data: customerBookingroomsDataa,
    isLoading: customerBookingroomsLoading,
  } = useQuery(
    ["customerbookingroomsData", userId],
    () => BookMeetingRoom.getCustomerMeetingRoom(userId),
    {
      retry: 0,
    }
  );
  const customerBookedData = customerBookingroomsDataa?.data?.data;

  // *Calculate the estimated reading time of an article
  const extimateTimeFunc = (description) => {
    let value;
    if (description) {
      const match = description.match(/<span[^>]*>([^<]*)<\/span>/);
      value = match && match[1];
    }

    if (value) {
      const text = value;
      const wpm = 183;
      const words = text.trim().split(/\s+/).length;
      const time = Math.ceil(words / wpm);
      return `${time} min read`;
    } else {
      return `0 min read`;
    }
  };

  return {
    bookingroomsData,
    bookingroomsLoading,
    customerBookedData,
    customerBookingroomsLoading,
    memberCredits,
    memberCreditsLoading,
    feeds,
    feedsLoading,
    extimateTimeFunc,
  };
};

export default UseMeetingRoom;
