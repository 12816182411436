import React, { useEffect, useState } from "react";
import { Button, Col, Row, Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import Switch from "react-switch";
import ProfileSetting from "../../../images/profileSetting.svg";
import notificationsApi from "../../../services/notificationsApi";
import Cancel from "../../../images/cancel.svg";
import { useNavigate } from "react-router-dom";
import "./css/styles.css";

const NotiSetting = (props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [closingTime, setClosingTime] = useState(false);
  const [newBookings, setNewBookings] = useState(false);
  const [bookingRequest, setBookingRequest] = useState(false);
  const [dueDate, setDueDate] = useState(false);

  const handleRequestModelFunc = () => {
    const requestData = {
      notification30MintBefore: closingTime,
      notificationForNewBookings: newBookings,
      notificationBookingApproved: bookingRequest,
      notificationInvoiceDue: dueDate,
    };
    props.updateNotification(requestData);
    props.cancelModel(false);
  };

  const { data: notificationSettings, isLoading: notificationSettingsLoading } =
    useQuery(
      ["GetNotificationSettings", props?.coworkerId, props.show],
      () => notificationsApi.getNotificationSettings(props?.coworkerId),
      {
        retry: 0,
      }
    );

  useEffect(() => {
    setClosingTime(notificationSettings?.notification30MintBefore);
    setNewBookings(notificationSettings?.notificationForNewBookings);
    setDueDate(notificationSettings?.notificationInvoiceDue);
    setBookingRequest(notificationSettings?.notificationBookingApproved);
  }, [notificationSettings]);

  return (
    <Modal isOpen={props.show} centered={true} className='fintechModel'>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                <img src={ProfileSetting} alt='profileImg' className='w-100' />
              </Col>

              <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                <div className='d-flex align-items-center h-100 cardHeader'>
                  <div>
                    <h4>Notifications settings</h4>
                    <p>Manage notifications</p>
                  </div>
                </div>
              </Col>
            </Row>
            <img
              src={Cancel}
              alt='bancelButton'
              role='button'
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                props.cancelModel(false);
                queryClient.invalidateQueries("GetNotificationSettings");
              }}
            />

            <div className='notiSettingInputs py-3'>
              <Row className='py-2'>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className='d-flex justify-content-between'>
                    <h5>Notify 30 minutes before closing time</h5>
                    <Switch
                      id={"" + 123}
                      checked={closingTime}
                      onChange={(e) => {
                        setClosingTime(e);
                      }}
                      // disabled={samePlanResources}
                      width={40}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      // checkedHandleIcon={false}
                      height={20}
                      onColor='#0129FA'
                      className='mr-1 mx-2 d-flex  mt-1'
                    />
                  </div>
                </Col>
              </Row>

              <Row className='py-2'>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className='d-flex justify-content-between'>
                    <h5>Notify for any new bookings</h5>
                    <Switch
                      id={"" + 123}
                      checked={newBookings}
                      onChange={(e) => {
                        setNewBookings(e);
                      }}
                      // disabled={samePlanResources}
                      width={40}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      // checkedHandleIcon={false}
                      height={20}
                      onColor='#0129FA'
                      className='mr-1 mx-2 d-flex  mt-1'
                    />
                  </div>
                </Col>
              </Row>

              <Row className='py-2'>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className='d-flex justify-content-between'>
                    <h5>Notify if any booking request is approved</h5>
                    <Switch
                      id={"" + 123}
                      checked={bookingRequest}
                      onChange={(e) => {
                        setBookingRequest(e);
                      }}
                      // disabled={samePlanResources}
                      width={40}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      // checkedHandleIcon={false}
                      height={20}
                      onColor='#0129FA'
                      className='mr-1 mx-2 d-flex  mt-1'
                    />
                  </div>
                </Col>
              </Row>
              <Row className='py-2'>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className='d-flex justify-content-between'>
                    <h5>Notify a week before memo due date</h5>
                    <Switch
                      id={"" + 123}
                      checked={dueDate}
                      onChange={(e) => {
                        setDueDate(e);
                      }}
                      // disabled={samePlanResources}
                      width={40}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      // checkedHandleIcon={false}
                      height={20}
                      onColor='#0129FA'
                      className='mr-1 mx-2 d-flex  mt-1'
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <Button
                  className='w-100 requestModelBtn'
                  onClick={() => handleRequestModelFunc()}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export default NotiSetting;
