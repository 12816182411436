import { useExploreInfo } from "./useExplore";
import { Col, Row, Card } from "reactstrap";
import Header from "../../components/header";
import ExploreImg from "../../images/exploreBottomImage.svg";
import PassLogo from "../../images/passLogo.svg";
import TourLogo from "../../images/tourLogo.svg";
import MembershipLogo from "../../images/membershipLogo.svg";
import "./css/styles.css";
import "font-awesome/css/font-awesome.min.css";

// import Card from "react-bootstrap/Card";
// import LoginModel from "../../components/molecules/loginModel";
// import "font-awesome/css/font-awesome.min.css";
// import Spinner from "react-bootstrap/Spinner";

const Explore = (props) => {
  const {} = useExploreInfo(props);

  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row>
            <Col md={12} lg={12} sm={12} xs={12} xl={12}>
              <Header />
            </Col>
          </Row>

          <Row className='loginRow'>
            <Col md={12} lg={12} sm={12} xs={12} xl={12}>
              <Row className='settings-page'>
                <Col md={4} lg={4} xl={4} sm={12} xs={12} className='pt-3'>
                  <Card className='setting-card px-3  pb-2 w-100' role='button'>
                    <Row>
                      <Col
                        md={2}
                        lg={2}
                        xl={2}
                        sm={2}
                        xs={2}
                        className='d-flex justify-content-center'
                      >
                        <img src={MembershipLogo} alt='img' className='w-100' />
                      </Col>
                      <Col md={10} lg={10} xl={10} sm={10} xs={10}>
                        <div className='pt-4 cardText '>
                          <h6>Membership</h6>
                          <p>
                            Build your next idea at the hub, enjoy dedicated
                            space for your team.{" "}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col md={4} lg={4} xl={4} sm={12} xs={12} className='pt-3'>
                  <Card className='setting-card px-3  pb-2 w-100' role='button'>
                    <Row>
                      <Col
                        md={2}
                        lg={2}
                        xl={2}
                        sm={2}
                        xs={2}
                        className='d-flex justify-content-center'
                      >
                        <img src={TourLogo} alt='img' className='w-100' />
                      </Col>
                      <Col md={10} lg={10} xl={10} sm={10} xs={10}>
                        <div className='pt-4 cardText '>
                          <h6>Tour</h6>
                          <p>
                            Apply for a tour and get a chance to explore the
                            fintech hub space.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col md={4} lg={4} xl={4} sm={12} xs={12} className='pt-3'>
                  <Card className='setting-card px-3  pb-2 w-100' role='button'>
                    <Row>
                      <Col
                        md={2}
                        lg={2}
                        xl={2}
                        sm={2}
                        xs={2}
                        className='d-flex justify-content-center'
                      >
                        <img src={PassLogo} alt='img' className='w-100' />
                      </Col>
                      <Col md={10} lg={10} xl={10} sm={10} xs={10}>
                        <div className='pt-4 cardText '>
                          <h6>Day Pass</h6>
                          <p>
                            Work on any day as required, without any long term
                            commitment.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
            <div className='exploreBottomImgContainer'>
              <img src={ExploreImg} alt='img' className='w-100' />
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Explore;
