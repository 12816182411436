import React from "react";
import {
  Button,
  Col,
  Row,
  Card,
  Label,
  Input,
  Form,
  Spinner,
} from "reactstrap";
import Dropzone from "react-dropzone";
import PrivateOfficeImg from "./../../../.././images/private_office.svg";
import CloseIcon from "./../../../.././images/icons8-close-48.svg";
import SummaryDuration from "./../../../.././images/summary_duration.svg";
import UploadLogo from "./../../../.././images/upload-logo.svg";
import UsePaymentUploadPhoto from "./usePaymentUploadPhoto";
import moment from "moment";
import "./css/style.css";
import UseValueValue from "../../../vatValue/useValueValue";
import CloseButtonComp from "../../../../components/molicules/closeButton";

// import { useNavigate } from "react-router-dom";
import { PlanRequestModel } from "../../../../components/molicules/userModel";

const PaymentUploadPhoto = () => {
  // const userType = localStorage.getItem("userTypeRedir");
  // const Navigate = useNavigate();
  // const { vatValue } = UseValueValue();
  const {
    modelShow1,
    setModelShow1,
    delPlanFunc,
    wholePageLoading,
    textDesp,
    confirmData,
    confirmFunc,
    handleAcceptedFiles,
    selectedFiles,
    setSelectedFiles,
    setComentData,
  } = UsePaymentUploadPhoto();

  return (
    <>
      {wholePageLoading === "true" ? (
        <div className='d-flex justify-content-center whole_page_loader h-100'>
          <Spinner className='justify-item-center mx-1 ' />
        </div>
      ) : (
        <div className='p-4'>
          <Row>
            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
              <p className='smr_h1 '>
                <b className='kforhide'>{`Book a ${
                  localStorage.getItem("daypassMode") === "modeIsMeetingroom"
                    ? "meeting room"
                    : "daypass"
                } `}</b>
              </p>
            </Col>
            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
              <p className='smr_h1 '>
                <b>Payment</b>
              </p>
            </Col>
            <Col xl={1} lg={1} md={1} sm={1} xs={1}></Col>
            <Col xl={1} lg={1} md={1} sm={1} xs={1}>
              <div className='d-flex'>
                {
                  <div className='icon-box'>
                    <span
                      onClick={() => {
                        setModelShow1(true);
                      }}
                    >
                      <img src={CloseIcon} alt='logo' width={24} height={24} />
                    </span>
                  </div>

                  // <CloseButtonComp />
                }
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className='mb-5'>
                <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                  <Card className='p-2 s_o_cc  w-100  mb-4'>
                    {/* <img src={PrivateOfficeImg} alt='img' className='w-100 h-100' /> */}
                    <img
                      src={PrivateOfficeImg}
                      alt='img'
                      className='w-100 h-100'
                    />
                    <span className='d-flex pos_cardusersicon mt-3 '>
                      <Label className='c-avail_h1  '>
                        {/* {selectedBookingRoomData?.Title} */}
                        {`Book a ${
                          localStorage.getItem("daypassMode") ===
                          "modeIsMeetingroom"
                            ? "Meeting Room"
                            : "Day Pass"
                        } `}
                      </Label>
                    </span>
                    <Label className='m_rB_l3 mt-2'>{textDesp}</Label>
                  </Card>
                </Col>
                <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                  <Card className='p_day_card '>
                    <Row className=' py-3 '>
                      <Col
                        className='px-3 mx-2'
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                      >
                        <Label className='p_day_l1'>Invoice ID</Label>

                        <p className='p_day_l2'>
                          {confirmData?.data?.InvoiceNo}
                        </p>
                        <Label className='p_day_l1'>Total</Label>
                        <Label className='p_day_l3'>
                          SAR{" "}
                          {
                            confirmData?.data?.Price
                            // +
                            // (vatValue?.setting?.isTaxEnable === "true"
                            //   ? Number(
                            //       (Number(vatValue?.setting?.taxRate) / 100) *
                            //         confirmData?.data?.Price
                            //     )
                            //   : 0)
                          }
                        </Label>
                      </Col>
                      <Col className='px-2' xl={5} lg={5} md={5} sm={5} xs={5}>
                        <Label className='p_day_l1'>Due Date</Label>
                        <p className='p_day_l2'>
                          {moment(confirmData?.data?.BookedOn).format(
                            "DD-MM-YYYY"
                          )}
                        </p>
                        <Label className='p_day_l1'>Status</Label>
                        <Label className='p_day_l4 py-2'>
                          Transfer {confirmData?.data?.status}
                        </Label>
                      </Col>
                    </Row>
                  </Card>
                  <Card className='p_day_card mt-3 '>
                    <Row className='py-3 px-2'>
                      <Col className='mx-3' xl={1} lg={1} md={1} sm={1} xs={1}>
                        <img
                          src={SummaryDuration}
                          alt='logo'
                          className='sum_img'
                        />
                      </Col>
                      <Col
                        xl={10}
                        lg={10}
                        md={10}
                        sm={12}
                        xs={12}
                        className='px-3'
                      >
                        <Label className='p_day_l5 '>Bank details</Label>
                        <p className='p_day_l6'>
                          Please transfer payable amount into this account and
                          attach the screenshot below
                        </p>
                        <Label className='p_day_l7'>Account name</Label>
                        <p className='p_day_l9'>Fintech Saudi</p>
                        <Label className='p_day_l7'>IBAN</Label>
                        <p className='p_day_l8'>
                          SA40 0110 0001 2303 0500 0009
                        </p>
                        <Label>Sarie details</Label>
                        <p className='p_day_l7'>Sarie Code</p>
                        <span className='d-flex justify-content-between'>
                          <Label className='p_day_l8'>SAMASARI</Label>{" "}
                          <Label className='p_day_l10'>
                            Saudi Arabian Monetary Authority
                          </Label>
                        </span>
                      </Col>
                    </Row>
                  </Card>
                  <div className=' my-3'>
                    <Row>
                      <Col className='' xl={1} lg={1} md={1} sm={1} xs={1}>
                        <img src={UploadLogo} alt='logo' className='sum_img' />
                      </Col>
                      <Col
                        className='py-2 mx-3'
                        xl={10}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={10}
                      >
                        <Label className='p_day_l5'>Upload payment proof</Label>
                        <Label className='p_day_l8'>
                          Please make sure the file size is less than 5MB
                        </Label>
                      </Col>
                    </Row>
                  </div>
                  <div className='w-100'>
                    <Form
                      className='p_day_img_border mt-3  p-4 '
                      hidden={selectedFiles.length > 0}
                    >
                      <Dropzone
                        accept={".png ,.jpeg,.jpg,.pdf"}
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div>
                            <div
                              className='dz-message needsclick'
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />

                              <p className='p_day_l8 mt-3 d-flex justify-content-center align-items-center'>
                                Click here to upload receipt
                              </p>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </Form>
                    <div className='dropzone-previews mt-3' id='file-previews'>
                      {selectedFiles.length > 0 && (
                        <div className='mt-1  p_day_img_border'>
                          <div className='p-2'>
                            <Row className='align-items-center'>
                              <Col md={4}></Col>
                              <Col
                                md={7}
                                className=' d-flex  justify-content-between align-items-center'
                              >
                                <img
                                  data-dz-thumbnail=''
                                  height='80'
                                  className='text-center avatar-sm rounded bg-light d-flex justify-content-center'
                                  alt={selectedFiles[0].name}
                                  src={selectedFiles[0].preview}
                                />
                                {/* </Col>
                          <Col md={1}> */}
                                <span onClick={() => setSelectedFiles([])}>
                                  <img
                                    src={CloseIcon}
                                    alt='logo'
                                    width={24}
                                    height={24}
                                  />
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <Label className='p_day_l5 mt-3'>Any comments?</Label>
                  <Input
                    type='textarea'
                    maxLength={700}
                    onChange={(e) => {
                      setComentData(e?.target?.value);
                    }}
                  />

                  <Button
                    color='dark'
                    style={{ minHeight: "62px" }}
                    className='w-100 my-4'
                    onClick={() => {
                      confirmFunc();
                    }}
                  >
                    Confirm
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}

      <PlanRequestModel
        show={modelShow1}
        cancelApiFunc={delPlanFunc}
        setModelShow1={setModelShow1}
        header={`Discard Bookings`}
        body={`Are you sure you want to discard this booking?`}
        // link={`/meeting-room-app/meeting-room`}
      />
    </>
  );
};

export default PaymentUploadPhoto;
