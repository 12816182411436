import React from "react";
import useSettingInfo from "./useSetting";
import { Row, Col, Card } from "reactstrap";
import ProfileSetting from "../../images/profileSetting.svg";
import NotificationLogo from "../../images/notificationSetting.svg";
import { useNavigate } from "react-router-dom";
import NotiSetting from "../../components/molicules/notificationSettingModel";
import RequestModel from "../../components/molicules/requestModel";
import "./css/styles.css";

const Settings = (props) => {
  const navigate = useNavigate();
  const {
    confirmModel,
    settingModel,
    coworkerId,
    handleNotificationSetting,
    setSettingModel,
    setConfirmModel,
  } = useSettingInfo(props);
  return (
    <div className='mx-4 '>
      <Row className='settingPage'>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Row>
            <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mt-5'>
              <h2>Profile</h2>
              <Card
                className='settingPageCard py-2 px-3 mt-3'
                role='button'
                onClick={() => navigate("/manage-account")}
              >
                <Row>
                  <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                    <img
                      src={ProfileSetting}
                      alt='profileImg'
                      className='w-100'
                    />
                  </Col>

                  <Col xl={9} lg={9} md={9} sm={9} xs={9}>
                    <div className='d-flex align-items-center h-100'>
                      <div>
                        <h4>Profile settings</h4>
                        <p>Manage Profile</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mt-5'>
              <h2>Notifications</h2>
              <Card
                className='settingPageCard py-2 px-3 mt-3'
                role='button'
                onClick={() => setSettingModel(true)}
              >
                <Row>
                  <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                    <img
                      src={NotificationLogo}
                      alt='profileImg'
                      className='w-100'
                    />
                  </Col>

                  <Col xl={9} lg={9} md={9} sm={9} xs={9}>
                    <div className='d-flex align-items-center h-100'>
                      <div>
                        <h4>Notifications settings</h4>
                        <p>Manage notifications</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <NotiSetting
        show={settingModel}
        cancelModel={setSettingModel}
        coworkerId={coworkerId}
        updateNotification={handleNotificationSetting}
        confirmModel={setConfirmModel}
      />
      <RequestModel
        show={confirmModel}
        cancelModel={setConfirmModel}
        header={"Settings Saved"}
        body={"You settings have been saved successfully"}
        request={"notiSetting"}
      />
    </div>
  );
};

export default Settings;
