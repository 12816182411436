// *** Login Mutations*************
export const LOGIN_MUTATION = "loginMutation";
export const FORGOT_PASSWORD = "forgotMutation";
// *** Change Passowrd ********
export const CHANGE_PASSWORD_MUTATION = "changePasswordMutation";
export const UPDATE_PASSWORD_MUTATION = "updatePasswordMutation";

// **** plans Summary Hook ************
export const PLANS_SUMMARY_MUTATION = "sumitSummaryMutation";

// **** plans Summary Hook ************
export const UPDATE_INVOICE_MUTATION = "membershipInvoiceMutation";
export const EDIT_HELPDESK_MUTATION = "membershipInvoiceMutation";

// **** myTeam Seetings ************
export const POOL_TEAM_CREDITS_MUTATION = "poolTeamCreditMutation";
export const CREDIT_PERMISSIONS_MUTATION = "creditPermissionMutation";
export const TEAM_OTP_REQUEST = "teamOtpRequestMutation";
export const PATCH_TEAM_MANAGEMENT = "teamManagenmentMutation";
export const REMOVE_TEAM_MEMBER_MUTATION = "removeTeamMutation";
export const ADD_TEAM_MEMBER_MUTATION = "addTeamMutation";

// **** myTeam Seetings ************
export const UPDATE_PROFILE_MUTATION = "updateProfileMutation";

// **** Notifications Seetings ************
export const UPDATE_NOTIFICATION_MUTATION = "updateNotificationMutation";
