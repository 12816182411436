import axios from "axios";
import { Suadi_fintech_URL, Saudi_App_Base_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import { stageToken } from "../constants/local";
import moment from "moment";

const request = axios.create({
  baseURL: Saudi_App_Base_URL,
});

const fintechRequest = axios.create({
  baseURL: Suadi_fintech_URL,
});

const request3 = axios.create({
  baseURL: "https://storage.nexudus.com/api",
});

const BookDayPass = {
  getDayPass: async () => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`daypasses-rooms`, {
      headers: headers,
    });

    return data;
  },
  checkAvailabilityDate: async (scheduleDate) => {
    if (scheduleDate) {
      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
      };

      const data = await request.get(`/daypasses-available/${scheduleDate}`, {
        headers: headers,
      });

      return data;
    }
  },

  // listing Api
  getDayPasslisting: async (userId) => {
    if (userId) {
      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
      };

      const data = await request.get(
        `/coworker-all-upcoming-bookings/${userId}`,
        {
          headers: headers,
        }
      );

      return data;
    }
  },

  // daypass listing Data with filter
  getDayPassFilteredlisting: async (
    id,
    stateOfDropDownMeeting,
    filterState,
    rangeState
  ) => {
    if (id) {
      // formating the end date of filter
      const originalToDateFilter = moment(
        rangeState[0]?.endDate,
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
      );
      const formatedToDateFilter = originalToDateFilter.format(
        "YYYY-MM-DDT23:59:59.999[Z]"
      );

      // formating the start date of filter
      const originalFromDateFilter = moment(
        rangeState[0]?.startDate,
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
      );
      const formatedFromDateFilter = originalFromDateFilter.format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );

      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
      };

      const data = await request.get(
        filterState?.value === "custom"
          ? `/coworker-all-upcoming-bookings-filtered/${id}?bookingType=${stateOfDropDownMeeting?.value}&dateFilter=${filterState?.value}&startDate=${formatedFromDateFilter}&endDate=${formatedToDateFilter}`
          : `/coworker-all-upcoming-bookings-filtered/${id}?bookingType=${stateOfDropDownMeeting?.value}&dateFilter=${filterState?.value}`,

        {
          headers: headers,
        }
      );

      return data;
    }
  },

  getPrice: async (body) => {
  
    const jwt = localStorage.getItem("access Token");
    const data = await request.post(
      `daypasses-price`,
      { ...body },
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );

    return data;
  },

  schduledaypass: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const data = await request.post(
      `daypasses`,
      { ...body },
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );

    return data;
  },
  reschduledaypass: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const data = await request.post(
      `daypasses-reschedule`,
      { ...body },
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );

    return data;
  },

  uploadPhotoDaypass: async (body) => {
    var formdata = new FormData();
    formdata.append("file", body[0]);

    const data = await request.post(
      `files`,
      // { ...body },
      formdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json, text/plain",
        },
      }
    );

    return data;
  },

  nexudusUploadPhoto: async (body) => {
    var formdata = new FormData();
    formdata.append("file", body[0]);

    const data = await request3.post(
      `/content/uploads/tempfile`,
      // { ...body },
      formdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json, text/plain",
        },
      }
    );

    return data;
  },

  confirmdaypass: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const data = await request.patch(
      `/daypass-payments/${body?.scheduledData?.data?.id}`,
      {
        InvoiceDoc: body?.photoDataByApi?.data?.files[0]?.originalname,
        PaymentComment: body?.comentData ? body?.comentData : "",
        paymentStatus: "pending",
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );

    return data;
  },

  getDayPassSlots: async () => {
    const data = await request.get(`/booked-daypass-slots`, {
      headers: {
        Accept: "application/json, text/plain",
      },
    });

    return data?.data?.data;
  },
};

export default apiWrapper(BookDayPass, [
  "getDayPass",
  "checkAvailabilityDate",
  "getPrice",
  "schduledaypass",
  "uploadPhotoDaypass",
  "confirmdaypass",
  "getDayPasslisting",
  "getDayPassFilteredlisting",
  "reschduledaypass",
  "nexudusUploadPhoto",
  "getDayPassSlots",
]);
