import membershipInvoices from "../../../services/membershipAndInvoicesApi";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { useEffect } from "react";

export const useInvoiceDetailInfo = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data, tab } = state;

  const { data: products, isLoading: productsLoading } = useQuery(
    ["products", data?.Id],
    () => membershipInvoices.getInvoiceProducts(data?.Id),
    {
      retry: 1,
    }
  );

  const uploadInvoiceFunc = (teamInfo, tax, amount) => {
    const invoiceData = {
      InvoiceNumber: data?.InvoiceNumber,
      DueDate: data?.DueDate,
      InvoiceId: data?.Id,
      TeamId: teamInfo?.teamId,
      TeamName: teamInfo?.teamName,
      CurrencyCode: data?.CurrencyCode,
      PlanPrice: amount,
      TaxAmount: tax,
      TotalPrice: data?.TotalAmount,
    };

    navigate("/invoice-detail/upload", {
      state: {
        data: invoiceData,
      },
    });
  };

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return { products, data, uploadInvoiceFunc, navigate };
};
