import React, { useEffect, useRef } from "react";
import { MessageBox, SystemMessage, Input } from "react-chat-elements";
import chatBtn from "./../../../images/chatBtn.svg";
import adminImg from "./../../../images/adminImage.svg";
import { Card, Col, Container, Row, Spinner } from "reactstrap";
import UseChatSupport from "./useChatSupport";
import "./css/styles.css";
import "react-chat-elements/dist/main.css";

const ChatSupport = () => {
  const {
    postLoading,
    inputRef,
    state,
    renderMessageBox,
    coworkerId,
    clientAndAdminDataa,
    PostChatFunc,
    inputValue,
    setInputValue,
  } = UseChatSupport();

  const inboxMessagesRef = useRef(null);

  // Scroll to the bottom of the container when the component updates
  useEffect(() => {
    // Scroll to the bottom when the component mounts or when new messages are added
    scrollToBottom();
  }, [clientAndAdminDataa]);
  const scrollToBottom = () => {
    inboxMessagesRef.current.scrollTo(0, inboxMessagesRef.current.scrollHeight);
  };

  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card className='w-100 messagesCard py-4'>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className='firstCenterDiv'>
                <p className='chatlabel2'> Meeting Room</p>
                <p className='chatlabel3'> Help Requested</p>
                <p className='chatlabel3'> 26/02/2022</p>{" "}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div
                className='parentDivOfChat'
                id='scrollableArea'
                ref={inboxMessagesRef}
              >
                {/* // first chat that is detail of issue  */}
                <div className='messagesContainer'>
                  <div className='rightbarchat my-2'>
                    <MessageBox
                      // date={new Date()}
                      date={state?.CreatedOn}
                      position={"right"}
                      type={"text"}
                      text={state?.MessageText}
                    />
                  </div>
                  {/* // first chat that is photo of issue  */}
                  <div className='rightbarchat  my-2'>{renderMessageBox()}</div>
                  {clientAndAdminDataa?.map((element) => {
                    if (element?.CoworkerId === coworkerId) {
                      return (
                        // user messages
                        <div className='rightbarchat my-2 '>
                          <MessageBox
                            date={element?.CreatedOn}
                            position={"right"}
                            type={"text"}
                            text={element?.MessageText.replace(/\n/g, "")}
                          />
                        </div>
                      );
                    } else {
                      return (
                        //  admin reply

                        <div className='leftbarchat d-flex my-2'>
                          <img src={adminImg} alt='admin' />

                          <MessageBox
                            position={"left"}
                            type={"text"}
                            date={element?.CreatedOn}
                            text={element?.MessageText.replace(/\n/g, "")}
                            subtitle={"What are you doing?"}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Card>
        <Row className='inputMessgeContainer mt-4'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {state?.Closed === true ? (
              <SystemMessage text={"End of conversation"} className='mt-4' />
            ) : (
              <div className='msgTextArea'>
                <div className='inputchatCss  '>
                  <form onSubmit={(e) => PostChatFunc(e)} className='d-flex'>
                    <input
                      className='w-100  messageAreaField pt-5'
                      type='text'
                      value={inputValue}
                      placeholder='Send message to support'
                      multiline={true}
                      onChange={(e) => {
                        setInputValue(e?.target?.value);
                      }}
                    />
                    {postLoading ? (
                      <Spinner
                        // className='mt-5'
                        style={{
                          height: 17,
                          width: 17,
                          marginTop: 70,
                        }}
                      />
                    ) : (
                      <img
                        className='d-flex pt-5'
                        type='button'
                        onClick={(e) => {
                          PostChatFunc(e);
                        }}
                        src={chatBtn}
                        text='Send'
                        alt='Send message to support'
                      />
                    )}
                  </form>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ChatSupport;
