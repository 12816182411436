import axios from "axios";
import { Suadi_fintech_URL } from "../constants/config";
import ChangePassword from "../pages/changePassword";
import { apiWrapper } from "./interceptors";

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
const request1 = axios.create({
  baseURL: process.env.REACT_APP_NEXDUS_BASE_URL,
});

const HelpDeskApi = {
  getCustomerOpenRequests: async (body) => {
    if (body) {
      const data = await request.get(
        `/customer-supports-open/${body}`,
        {
          newPassword: body.confirmPass,
          token: localStorage.getItem("loginToken"),
        },
        {
          headers: {
            Accept: "application/json, text/plain",
          },
        }
      );

      return data;
    }
  },

  clientAndAdminDataApi: async (id) => {
    if (id) {
      const data = await request.get(
        // `/helpdesks-chats/${id}`,
        `/helpdesks-chats/${id}`,

        {
          token: localStorage.getItem("loginToken"),
        },
        {
          headers: {
            Accept: "application/json, text/plain",
          },
        }
      );

      return data;
    }
  },

  getCustomerCloseRequests: async (body) => {
    if (body) {
      const data = await request.get(
        `/customer-supports-closed/${body}`,
        {
          newPassword: body.confirmPass,
          token: localStorage.getItem("loginToken"),
        },
        {
          headers: {
            Accept: "application/json, text/plain",
          },
        }
      );

      return data;
    }
  },

  createHelpDesk: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.post(`/supports`, body, {
      headers: headers,
    });

    return data;
  },

  postChatHelpDesk: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request1.post(`/en/support/newcomment`, body, {
      headers: headers,
    });

    return data;
  },
};

export default apiWrapper(HelpDeskApi, [
  "getCustomerOpenRequests",
  "getCustomerCloseRequests",
  "createHelpDesk",
  "clientAndAdminDataApi",
]);
