import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useSumaryMutation } from "../../../../../rc-query/plansSummaryHook";
import planSummary from "../../../../../services/PlansSummaryApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useMemo } from "react";
import moment from "moment";

const UseSummary = () => {
  const [modelShow1, setModelShow1] = useState(false);
  const summaryMutation = useSumaryMutation();
  const Navigate = useNavigate();
  const [terrifiedIds, setTerrifiedIds] = useState();
  const { state } = useLocation();
  const {
    dedicatedDeskData,
    selectedArr,
    selectedArrAdministrator,
    startDate,
    selectedResourceCapacity,
    selectedResourceName,
    selectedResourceId,
    plansPayment,
    startDateState,
    monthNumbers,
    onlyTeam,
  } = state;
  useEffect(() => {
    const resourceIds = dedicatedDeskData[0]?.ResourcePlan;

    setTerrifiedIds({
      TeamLeadMonthlyPlan: resourceIds?.TeamLeadMonthlyPlan?.id,
      TeamLeadYearlyPlan: resourceIds?.TeamLeadYearlyPlan?.id,
      TeamMemberMonthlyPlan: resourceIds?.TeamMemberMonthlyPlan?.id,
      TeamMemberYearlyPlan: resourceIds?.TeamMemberYearlyPlan?.id,
    });
  }, [dedicatedDeskData]);

  //*********leadSummaryData********* */

  const { data: leadSummaryData, isLoading: leadSumaryLoading } = useQuery(
    ["leadSummaryData", terrifiedIds],
    () => planSummary.leadSumary(terrifiedIds, plansPayment),
    {
      retry: 0,
    }
  );

  const leadSummaryDataa = leadSummaryData?.data?.data;

  //*********membershipSumaryData********* */

  const { data: membershipSumaryData, isLoading: membershipSumaryLoading } =
    useQuery(
      ["teamMemberSummaryData", terrifiedIds],
      () => planSummary.membershipSumary(terrifiedIds, plansPayment),
      {
        retry: 0,
      }
    );

  const membershipSumaryDataa = membershipSumaryData?.data?.data;

  useMemo(() => {
    selectedArr.push(selectedArrAdministrator);
  }, []);

  const plansSummaryFunc = () => {
    setModelShow1(false);
    // selectedArr.push(selectedArrAdministrator);

    summaryMutation
      .mutateAsync({
        privateOfficePrice:0,
        dedicatedDeskPrice:leadSummaryDataa?.Price + membershipSumaryDataa?.Price,
        name: selectedArrAdministrator?.fullName
          ? selectedArrAdministrator?.fullName
          : "",
        planDuration: plansPayment ? plansPayment : "",
        date: startDate
          ? moment(startDate, "MMMM, YYYY")
              .set({
                hour: 12,
                minute: 27,
                second: 54,
                millisecond: 530,
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : new Date(),
        deskCapacity: selectedResourceCapacity,
        RenewalMonths: monthNumbers,
        startDate: startDate
          ? moment(startDate, "MMMM, YYYY")
              .set({
                hour: 12,
                minute: 27,
                second: 54,
                millisecond: 530,
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : new Date(),
        price:
          leadSummaryDataa?.Price +
          membershipSumaryDataa?.Price +
          leadSummaryDataa?.SignUpFee +
          membershipSumaryDataa?.SignUpFee,
        teamMembers: selectedArr ? selectedArr : "",
        MemberTarrifId:
          plansPayment === "monthly"
            ? terrifiedIds?.TeamMemberMonthlyPlan
            : terrifiedIds?.TeamMemberYearlyPlan,
        LeadTarrifId:
          plansPayment === "monthly"
            ? terrifiedIds?.TeamLeadMonthlyPlan
            : terrifiedIds?.TeamLeadYearlyPlan,
        teamId: onlyTeam?.Id,
        companyName: onlyTeam?.Name,
        ResourceTypeId: dedicatedDeskData[0]?.ResourceId
          ? dedicatedDeskData[0]?.ResourceId
          : 0,
        planType: dedicatedDeskData[0]?.ResourceName
          ? dedicatedDeskData[0]?.ResourceName
          : "",
        Desk: selectedResourceId ? selectedResourceId : 0,
        MemberCancellationLimitDays:
          membershipSumaryDataa?.CancellationLimitDays,
        LeadCancellationLimitDays: leadSummaryDataa?.CancellationLimitDays,
        isMultiple: dedicatedDeskData[0]?.isMultiple,
      })
      .then((data) => {
        setModelShow1(false);
        if (data?.status === 200 && data?.data?.teamId) {
          Navigate("/yearly-dedicated-private-request-sent");
          localStorage.setItem("progressBarLength", 0);
          localStorage.setItem("progressBarStart", false);
        }
      })
      .catch((err) => {
        setModelShow1(false);
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return {
    modelShow1,
    setModelShow1,
    membershipSumaryDataa,
    leadSummaryDataa,
    startDate,
    selectedArrAdministrator,
    selectedArr,
    plansSummaryFunc,
    startDateState,
    leadSumaryLoading,
  };
};

export default UseSummary;
