import { useMemo, useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useSumaryMutation } from "../../../../../rc-query/plansSummaryHook";
import planSummary from "../../../../../services/PlansSummaryApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

const UseSummary = () => {
  const [modelShow1, setModelShow1] = useState(false);
  const [wholePageLoading, setWholePageLoading] = useState();
  const summaryMutation = useSumaryMutation();
  const Navigate = useNavigate();
  const [terrifiedIds, setTerrifiedIds] = useState();
  const { state } = useLocation();
  const {
    privateOfficeData,
    selectedArr,
    selectedArrAdministrator,
    startDate,
    selectedResourceCapacity,
    selectedResourceName,
    resourceId,
    selectedResourceId,
    plansPayment,
    startDateState,
    monthNumbers,
    onlyTeam,
  } = state;

  useEffect(() => {
    const resourceIds = privateOfficeData[0]?.ResourcePlan?.resources?.find(
      (ele) => {
   
        if (ele?.resourceId === selectedResourceId) {
          return ele;
        }
      }
    );


   




    setTerrifiedIds({
      TeamLeadMonthlyPlan: resourceIds?.TeamLeadMonthlyPlan?.id,
      TeamLeadYearlyPlan: resourceIds?.TeamLeadYearlyPlan?.id,
      TeamMemberMonthlyPlan: resourceIds?.TeamMemberMonthlyPlan?.id,
      TeamMemberYearlyPlan: resourceIds?.TeamMemberYearlyPlan?.id,
    });
  }, [privateOfficeData]);

  //*********leadSummaryData********* */

  const { data: leadSummaryData, isLoading: leadSumaryLoading } = useQuery(
    ["leadSummaryData", terrifiedIds],
    () => planSummary.leadSumary(terrifiedIds, plansPayment),
    {
      retry: 0,
    }
  );

  const leadSummaryDataa = leadSummaryData?.data?.data;

  //*********membershipSumaryData********* */

  const { data: membershipSumaryData, isLoading: membershipSumaryLoading } =
    useQuery(
      ["teamMemberSummaryData", terrifiedIds],
      () => planSummary.membershipSumary(terrifiedIds, plansPayment),
      {
        retry: 0,
      }
    );

  const membershipSumaryDataa = membershipSumaryData?.data?.data;

  useMemo(() => {
    selectedArr.push(selectedArrAdministrator);
  }, []);

  const plansSummaryFunc = () => {
    setModelShow1(false);
    setWholePageLoading("true");

    // selectedArr.push(selectedArrAdministrator);

    summaryMutation
      .mutateAsync({

        privateOfficePrice:leadSummaryDataa?.Price +
        membershipSumaryDataa?.Price,
        dedicatedDeskPrice:0,
        name: selectedArrAdministrator?.fullName
          ? selectedArrAdministrator?.fullName
          : "",
        planDuration: plansPayment ? plansPayment : "",
        date: startDate
          ? moment(startDate, "MMMM, YYYY")
              .set({
                hour: 12,
                minute: 27,
                second: 54,
                millisecond: 530,
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : new Date(),
        deskCapacity: selectedResourceCapacity,
        RenewalMonths: monthNumbers,
        startDate: startDate
          ? moment(startDate, "MMMM, YYYY")
              .set({
                hour: 12,
                minute: 27,
                second: 54,
                millisecond: 530,
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : new Date(),
        price:
          leadSummaryDataa?.Price 
          +
          membershipSumaryDataa?.Price 
          +
          leadSummaryDataa?.SignUpFee +
          membershipSumaryDataa?.SignUpFee
          ,
        teamMembers: selectedArr ? selectedArr : "",
        MemberTarrifId:
          plansPayment === "monthly"
            ? terrifiedIds?.TeamMemberMonthlyPlan
            : terrifiedIds?.TeamMemberYearlyPlan,
        LeadTarrifId:
          plansPayment === "monthly"
            ? terrifiedIds?.TeamLeadMonthlyPlan
            : terrifiedIds?.TeamLeadYearlyPlan,
        teamId: onlyTeam?.Id,
        companyName: onlyTeam?.Name,
        deskName:selectedResourceName,
        ResourceTypeId: privateOfficeData[0]?.ResourceId
          ? privateOfficeData[0]?.ResourceId
          : 0,
        planType: privateOfficeData[0]?.ResourceName
          ? privateOfficeData[0]?.ResourceName
          : "",
        Desk: selectedResourceId ? selectedResourceId : 0,
        resourceId: resourceId ? resourceId : 0,
        MemberCancellationLimitDays:
          membershipSumaryDataa?.CancellationLimitDays,
        LeadCancellationLimitDays: leadSummaryDataa?.CancellationLimitDays,
        isMultiple: privateOfficeData[0]?.isMultiple,
      })
      .then((data) => {
        setWholePageLoading("false");
        if (data?.status === 200 && data?.data?.Desk) {
          Navigate("/private_request-sent");
          localStorage.setItem("progressBarStart", false);
          localStorage.setItem("progressBarLength", 0);
          localStorage.setItem("privateBar", false);
        }
      })
      .catch((err) => {
        setWholePageLoading("false");
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return {
    modelShow1,
    setModelShow1,
    wholePageLoading,
    setWholePageLoading,
    selectedResourceName,
    selectedResourceCapacity,
    membershipSumaryDataa,
    leadSummaryDataa,
    plansSummaryFunc,
    startDate,
    selectedArrAdministrator,
    selectedArr,
    startDateState,
    leadSumaryLoading,
  };
};

export default UseSummary;
