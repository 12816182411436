import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../components/verticalLayout";
import Header from "../components/header";
import { Col, Container, Row, Toast } from "react-bootstrap";
import "./css/auth.css";
import React, { useState } from "react";
import { TabContext } from "../context/TabContext";
import { stageToken } from "../constants/local";

// const token = stageToken;

const useAuth = () => {
  // const token = localStorage.getItem("token");
  // if (token) return true;
  // else return false;
  return true;
  // const user = { loggedIn: true };
  // return user && user.loggedIn;
};

const Logout = () => {
  // localStorage.removeItem("token");
  // localStorage.removeItem("code");
};

const PrivateRoute = ({ allowedRoles }) => {
  const [navShow, setNavShow] = useState(false);
  const [activeTabJustify, setActiveTabJustify] = useState("1");

  const isAuth = useAuth();
  return isAuth ? (
    <TabContext.Provider value={{ activeTabJustify, setActiveTabJustify }}>
      <Row style={{ backgroundColor: "blue" }}>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <Header
            navshow={navShow}
            setNavShow={setNavShow}
            className='header-row w-100'
            // fixed='top'
          />
        </Col>
      </Row>
      {/* <div className='scroller'> */}
      <Row>
        <Col
          md={12}
          xl={12}
          lg={12}
          sm={12}
          xs={12}
          style={{ width: "100%" }}
          className='col-header'
        >
          <Row>
            <Col md={12} xl={12} lg={12} sm={12} xs={12}>
              <Container fluid className=' maincontainercss '>
                <Outlet />
              </Container>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* </div> */}
    </TabContext.Provider>
  ) : (
    <div>
      <Navigate replace to='/login' />
    </div>
  );
};

export default PrivateRoute;
