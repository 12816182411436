import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const UseDaypassApproval = () => {
  const state = useLocation();

  const textDesp = state?.state;

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/day-pass-app/daypass-approval") {
      // Prevent user from going back to previous page using the browser back button
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
      };
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return { textDesp };
};

export default UseDaypassApproval;
