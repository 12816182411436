import React from "react";
import { Row, Col, Label, Table } from "reactstrap";
import DelAccount from "../../../images/ProfileInfo.svg";
import delData from "../../../images/accountManage.svg";
import editInfo from "../../../images/editInfo.svg";
import Password from "../../../images/updatePassword.svg";
import { useNavigate } from "react-router-dom";
import UseManageAccount from "./useManageAccount";
import "../css/styles.css";

const ManageAccount = () => {
  const Navigate = useNavigate();
  const { UserData } = UseManageAccount();

  return (
    <div className=' mt-5 h-100'>
      <Row className='f_div'>
        <Col
          lg={1}
          md={1}
          sm={1}
          xs={1}
          className='d-flex justify-content-center align-items-center'
        >
          <div>
            <img src={DelAccount} alt='img' className='del_a_img_size' />
          </div>
        </Col>

        <Col lg={11} md={11} sm={11} xs={11}>
          <div className='d-flex justify-content-between'>
            <p className='del_a_p1'>
              <b>Personal information</b>
            </p>
            <img
              src={editInfo}
              alt='logo'
              className='mx-3'
              role='button'
              onClick={() =>
                Navigate("/update-profile", {
                  state: {
                    data: UserData,
                  },
                })
              }
            />
          </div>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className='personalDetailTable'>
                <Table responsive='sm xl lg xs md '>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone number</th>
                      <th>{UserData?.TeamNames ? "Company name" : ""}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{UserData?.FullName}</td>
                      <td>{UserData?.Email}</td>
                      <td>
                        {UserData?.MobilePhone
                          ? UserData?.MobilePhone
                          : UserData?.LandLine}
                      </td>
                      <td>{UserData?.TeamNames}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className='h-100'>
        <Col lg={12} md={12} sm={12} xs={12}>
          <div className='manageSttingPage h-100'>
            <div>
              <Row className='mt-4'>
                <Col
                  lg={5}
                  md={12}
                  sm={12}
                  xs={12}
                  onClick={() => {
                    Navigate("/view-password");
                  }}
                  role='button'
                >
                  <Row className='del_a_c22 my-4 px-4' role='button'>
                    <Col
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      className='d-flex justify-content-center align-items-center'
                    >
                      <div>
                        <img
                          src={Password}
                          alt='img'
                          className='del_a_img_size'
                          width={50}
                        />
                      </div>
                    </Col>

                    <Col lg={11} md={11} sm={11} xs={11}>
                      <div
                        className='px-4 h-100 d-flex align-items-center'
                        role='button'
                      >
                        <div>
                          <p className='del_a_p33'>
                            <b>Change Password</b>
                          </p>
                          <span>Update your password </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>{" "}
            </div>
            <div className='h-100 manageAccountContainer'>
              <Row className=''>
                <Col
                  lg={5}
                  md={12}
                  sm={12}
                  xs={12}
                  onClick={() => {
                    Navigate("/delete-account");
                  }}
                  role='button'
                >
                  <Row className='del_a_c22  px-4 ' role='button'>
                    <Col
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      className='d-flex justify-content-center align-items-center'
                    >
                      <div>
                        <img
                          src={delData}
                          alt='img'
                          className='del_a_img_size'
                        />
                      </div>
                    </Col>

                    <Col lg={11} md={11} sm={11} xs={11}>
                      <div
                        className='px-4 h-100 d-flex align-items-center'
                        role='button'
                      >
                        <p className='del_a_p33'>
                          <b>Manage Account & Data</b>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>{" "}
            </div>{" "}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ManageAccount;
