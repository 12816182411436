import { useAddTeammMemberInfo } from "./useAddTeaMember";
import { Col, Row, Card, Button, Input } from "reactstrap";
import TeamManagemant from "../../../images/teamManagement.svg";
import PhoneInput from "react-phone-input-2";
import RequestModel from "../../../components/molicules/requestModel";
import "react-phone-input-2/lib/style.css";
import "../css/styles.css";

const AddTeamMember = (props) => {
  const {
    modelShow,
    name,
    email,
    number,
    title,
    resource,
    resourceErrContainer,
    data,
    isDisable,
    handleResourceChange,
    handleAddMemberFunc,
    setResource,
    setEmail,
    setNumber,
    setName,
    setTitle,
    setModelShow,
  } = useAddTeammMemberInfo(props);

  return (
    <>
      <div>
        <Row>
          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
            <Row className='myTeamMainPage'>
              <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                <h1>Add a new team member</h1>
                <Card className='myTeamCard mt-4'>
                  <Row>
                    <Col md={1} lg={1} xl={1} sm={1} xs={1}>
                      <div className=' d-flex justify-content-end'>
                        <img
                          src={TeamManagemant}
                          alt='teamManagement '
                          className='myTeamImg'
                        />
                      </div>
                    </Col>
                    <Col md={11} lg={11} xl={11} sm={11} xs={11}>
                      <Row className='newTeamForm'>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          <Row>
                            <Col md={5} lg={5} xl={5} sm={12} xs={12}>
                              <div>
                                <h3 className='required'>Name</h3>
                                <Input
                                  type='text'
                                  className='newMemberFields'
                                  value={name}
                                  maxLength={30}
                                  onChange={(e) => {
                                    const input = e.target.value;
                                    const filteredInput = input.replace(
                                      /[^A-Za-z\s]/g,
                                      ""
                                    ); // Filter out non-alphabetic characters except spaces
                                    setName(filteredInput);
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md={5} lg={5} xl={5} sm={12} xs={12}>
                              <div>
                                <h3 className='required'>Phone number</h3>

                                <PhoneInput
                                  placeholder='e.g. +966 500 - 500 - 550'
                                  inputProps={{
                                    maxLength: 16,
                                    readOnly: false,
                                  }}
                                  disableDropdown={true}
                                  countryCodeEditable={false}
                                  country={"sa"}
                                  name='pName'
                                  inputStyle={{
                                    borderRadius: "4px",
                                    border: "none",
                                    backgroundColor: "#eeeeee",

                                    width: "100%",
                                    height: "48px",
                                  }}
                                  style={{ width: "100%", height: "48px" }}
                                  value={number}
                                  onChange={(e) => setNumber(e)}
                                  required={true}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={5} lg={5} xl={5} sm={12} xs={12}>
                              <div className='mt-4'>
                                <h3 className='required'>Work email address</h3>
                                <Input
                                  type='email'
                                  className='newMemberFields'
                                  value={email}
                                  maxLength={50}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={5} lg={5} xl={5} sm={12} xs={12}>
                              <div className='mt-4'>
                                <h3 className='required'>Work title</h3>
                                <Input
                                  type='text'
                                  className='newMemberFields'
                                  value={title}
                                  maxLength={30}
                                  onChange={(e) => setTitle(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={5} lg={5} xl={5} sm={12} xs={12}>
                              <div className='mt-4'>
                                <h3 className='required'>Select Resource</h3>
                                <Input
                                  id='select'
                                  type='select'
                                  value={resource}
                                  disabled={
                                    data?.teamInfo?.isMultiple === true
                                      ? false
                                      : true
                                  }
                                  onChange={(e) => handleResourceChange(e)}
                                  className='newMemberFields'
                                >
                                  <option value={0} selected disabled>
                                    Select Resource
                                  </option>
                                  <option
                                    value={Number(
                                      process.env.REACT_APP_PRIVATE_OFFICE
                                    )}
                                  >
                                    Private Office
                                  </option>
                                  <option
                                    value={Number(
                                      process.env.REACT_APP_DEDICATED_OFFICE
                                    )}
                                  >
                                    Dedicated Desk
                                  </option>
                                </Input>
                              </div>
                            </Col>
                            <Col md={5} lg={5} xl={5} sm={12} xs={12}>
                              <div
                                className={`${resourceErrContainer}  mt-4 d-flex align-items-center h-100`}
                              >
                                <p>
                                  Your private office is at capacity, you cannot
                                  add a new member to it.
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={5} lg={5} xl={5} sm={12} xs={12}>
                              <div className='mt-4 '>
                                <Button
                                  className='w-100 newTeamConfirmBtn'
                                  onClick={() => handleAddMemberFunc()}
                                  disabled={
                                    !name ||
                                    !email ||
                                    !title ||
                                    !resource ||
                                    isDisable
                                  }
                                >
                                  Confirm
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <RequestModel
        show={modelShow}
        cancelModel={setModelShow}
        header={"Request Sent"}
        body={
          "Your request to add a new team member has been sent. You will be notified about the status."
        }
        request={"addMember"}
      />
    </>
  );
};

export default AddTeamMember;
