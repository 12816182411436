const host = window.location.host;

//Set Actions

export const setVerifyToken = (tokenData) => {
  localStorage.setItem(`verify_token`, JSON.stringify(tokenData));
};

//Get Actions

export const getVerifyToken = () => {
  return JSON.parse(localStorage.getItem(`verify_token`));
};

// Remove Actions

export const removeVerifyToken = () => {
  localStorage.removeItem(`verify_token`);
};
