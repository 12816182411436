import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import BookMeetingRoom from "../../services/bookMeetingRoomApi";
import { useUpdateNotificationMutation } from "../../rc-query/notificationsHook";

const useSettingInfo = (props) => {
  const [settingModel, setSettingModel] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);
  const updateNotification = useUpdateNotificationMutation(props.history);

  const { data: coworkerID, isLoading: coworkerIDLoading } = useQuery(
    ["getCoworkerIdForNoti"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );

  const coworkerId = coworkerID?.Id;

  const handleNotificationSetting = (settings) => {
    const data = {
      userId: coworkerId,
      requestData: settings,
    };
    updateNotification.mutateAsync(data).then((res) => {
      if (res?.status === 200) {
        setConfirmModel(true);
      }
    });
  };

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return {
    confirmModel,
    settingModel,
    coworkerId,
    handleNotificationSetting,
    setSettingModel,
    setConfirmModel,
  };
};

export default useSettingInfo;
