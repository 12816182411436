import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import BookMeetingRoom from "../../.././services/bookMeetingRoomApi";
import { useNavigate } from "react-router-dom";
import {
  usePoolTeamCreditMutaion,
  useCreditPermissionMutaion,
} from "../../../rc-query/myTeamHooks";
import MyTeam from "../../.././services/myTeamApi";

export const useMainPageInfo = (props) => {
  const [isAdminstrator, setIsAdministrator] = useState();
  const [poolTeam, setPoolTeam] = useState(false);
  const [creditPermission, setCreditPermission] = useState(false);
  const poolCredit = usePoolTeamCreditMutaion(props.history);
  const permissionCredit = useCreditPermissionMutaion(props.history);
  const [teamId, setTeamId] = useState();
  const navigate = useNavigate();

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ****** Api of coworker Id *********

  const { data: coworkerListData, isLoading: coworkerLoading } = useQuery(
    ["coworkerListData"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );

  const { data: teamInfo, isLoading: teamInfoLoading } = useQuery(
    ["myTeamInformation", isAdminstrator, teamId],
    () => MyTeam.getTeamInfo({ isAdminstrator, teamId }),
    {
      retry: 0,
    }
  );

  const { data: teamSetting, isLoading: teamSettingLoading } = useQuery(
    ["myTeamInfo", teamId],
    () => MyTeam.getTeamSetting(teamId),
    {
      retry: 0,
    }
  );

  useEffect(() => {
    setIsAdministrator(coworkerListData?.IsTeamAdministrator);
    setTeamId(coworkerListData?.TeamIds);
  }, [coworkerLoading, coworkerListData]);

  useEffect(() => {
    setPoolTeam(teamSetting?.poolCredits);
    setCreditPermission(teamSetting?.creditPermission);
  }, [teamSetting, teamSettingLoading]);

  const handleCreditPermissions = (e) => {
    permissionCredit.mutateAsync({ teamId, e }).then((res) => {});
  };
  const handlePoolCredits = (e) => {
    poolCredit.mutateAsync({ teamId, e }).then((res) => {});
  };

  return {
    poolTeam,
    creditPermission,
    coworkerListData,
    teamInfoLoading,
    teamSettingLoading,
    coworkerLoading,
    teamInfo,
    navigate,
    handleCreditPermissions,
    handlePoolCredits,
    setPoolTeam,
    setCreditPermission,
  };
};
