import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import BookMeetingRoom from "../../../../.././services/bookMeetingRoomApi";
import {
  UseInvDownloadMutation,
  useDelDaypassessMutation,
} from "../../../../../rc-query/meetinRoomBookingHook";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import { usePostApiDayPassRoom } from "../../../../../rc-query/dayPassHook";
import calculatePercentage from "../../../../../utills/calculatePercentage";
import UseValueValue from "../../../../vatValue/useValueValue";
import { Suadi_fintech_URL } from "../../../../../constants/config";

const UseDaypassScheduleDaypass = () => {
  const bookingDayPassRoom = usePostApiDayPassRoom();
  const Navigate = useNavigate();
  const delDaypassessMutation = useDelDaypassessMutation();
  const { vatValue } = UseValueValue();
  // const invDownloadMutation = UseInvDownloadMutation();
  const [modelShow, setModelShow] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);
  const [modelShow2, setModelShow2] = useState(false);
  const [invoiceModel, setInvoiceModel] = useState(false);
  const [invLoading, setInvLoading] = useState(false);
  const [pricingData, setPricingData] = useState({})

  const state = useLocation();
  const { dataOfSelectedObject } = state?.state;

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ********** all booking cards data start ***********

  const { data: bookingroomsDataa, isLoading: bookingroomsLoading } = useQuery(
    ["bookingsroomsData"],
    () => BookMeetingRoom.getMeetingRoom(),
    {
      retry: 0,
    }
  );
  const bookingroomsData = bookingroomsDataa?.data?.meetingRooms;

  // ********** all booking cards data end ***********




  useEffect(() => {
   
    bookingDayPassRoom
      .mutateAsync({
        TypeName: "booking",
        ResourceId: dataOfSelectedObject?.ResourceId,
        CoworkerId: dataOfSelectedObject?.CoworkerId,
        FromTime: dataOfSelectedObject?.FromTime,
       ToTime: dataOfSelectedObject?.ToTime,
     })
     .then((data) => {
       if (data?.status === 200 && data?.data?.statusCode === 200) {
         setPricingData(data?.data?.Price);

         // setPaymentprice(data?.data);
       } else if (data?.data?.statusCode === 400) {
         toast.error(data?.data?.message, {
           position: toast.POSITION.TOP_RIGHT,
         });
       }
     })
     .catch((err) => {});
 }, [dataOfSelectedObject]);
 

  // // ********* invoice start ***********
  const { data: invoiceListData } = useQuery(
    ["invoiceListData"],
    () => BookMeetingRoom.invoice(dataOfSelectedObject),
    {
      retry: 0,
    }
  );

  // // ********* invoice end ***********
  const invData1 = pricingData;
  const invData = invoiceListData?.data?.data[0];
  const downliadIncId = invoiceListData?.data?.uniqueId;

  // ********* coworker start ***********
  const { data: coworkerListData } = useQuery(
    ["coworkerDatas"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );

  const userEmail = coworkerListData?.Email;
  // ********* coworker end ***********

  // ****** downloadInvoices**********

  const downloadFunc = () => {
    setInvLoading(true);
    fetch(
      `${Suadi_fintech_URL}/en/invoices/print?guid=${downliadIncId}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access Token"),
        }
      }
    )
      .then((response) => {
      
        setInvLoading(false);
        
        return response.blob()
      })
      .then((blob) => {
       
        saveAs(blob?.url, "filename.pdf");

        
      
      })
      .catch((error) => {
        setInvLoading(false);
        // toast.error("Error downloading invoice")
      });
  };

  const cancelApiFunc = () => {
    setModelShow2(false);
    setModelShow1(true);

    delDaypassessMutation
      .mutateAsync(dataOfSelectedObject?.id)
      .then((data) => {
        if (data?.data?.message === "Meeting Booking has been Cancelled") {
          // ******this will work after Api call under lines ******

          setModelShow2(false);
          setModelShow1(true);

          Navigate("/day-pass-app/day-pass-dashboard");
        } else {
          toast.error(data?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setModelShow2(false);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });

        setModelShow2(false);
      });
  };

  const TextExtractor = (description) => {
    if (description) {
      const match = description.match(/<span[^>]*>([^<]*)<\/span>/);
      const text = match && match[1];

      return text;
    }
  };

  const objectedFunc = async () => {
    const details = await TextExtractor(dataOfSelectedObject?.Description);
    const objData = { data: dataOfSelectedObject };
    Navigate("/day-pass-app/payment-upload-photo", {
      state: {
        //     bookingDayPassData: dataOfSelectedObject,
        //     bookingDayAvailbityData: "",
        //     scheduleDate: dataOfSelectedObject,
        scheduledData: objData,
        textDesp: details,
      },
    });
  };


  const daypasssubtotal = (
    (invData1?.EstimatedCost
      +(invData1?.DiscountAmount 
      ? 
      calculatePercentage(invData1?.DiscountAmount,vatValue?.setting?.taxRate  )
      : 0)).toFixed(2)
  )
  return {
    daypasssubtotal,
    invData1,
    TextExtractor,
    objectedFunc,
    coworkerListData,
    invLoading,
    downliadIncId,
    downloadFunc,
    dataOfSelectedObject,
    modelShow2,
    setModelShow2,
    modelShow1,
    setModelShow1,
    modelShow,
    setModelShow,
    bookingroomsData,
    bookingroomsLoading,
    invoiceModel,
    cancelApiFunc,
    setInvoiceModel,
    invData,
    userEmail,
  };
};

export default UseDaypassScheduleDaypass;
