import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import BookMeetingRoom from "../../../../services/bookMeetingRoomApi";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  usePostApiDayPassRoom,
  usePostApischeduleDayPassRoom,
  usePostApiRescheduleDayPassRoom,
} from "../../../../rc-query/dayPassHook";
import moment from "moment";
import UseValueValue from "../../../vatValue/useValueValue";
import calculatePercentage from "../../../../utills/calculatePercentage";

const UseScheduleAvailability = () => {
  const queryClient = useQueryClient();
  const reschduleMode = localStorage.getItem("reschduleMode");
  const reschduleId = localStorage.getItem("reschduledaypassId");
  const [wholePageLoading, setWholePageLoading] = useState();
  const [pricingData, setPricingData] = useState();
  const bookingDayPassRoom = usePostApiDayPassRoom();
  const scheduleDayPassRoom = usePostApischeduleDayPassRoom();
  const reScheduleDayPassRoom = usePostApiRescheduleDayPassRoom();
  const { vatValue } = UseValueValue();
  const Navigate = useNavigate();
  const state = useLocation();
  const {
    bookingDayPassData,
    bookingDayAvailbityData,
    scheduleDate,
    textDesp,
  } = state?.state;


  
 

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ********* booking-settings start ***********
  const { data: bookingSettingsData } = useQuery(
    ["bookingSettings"],
    () => BookMeetingRoom.getbookingSettings(),
    {
      retry: 0,
    }
  );
  const daypassConfirm = bookingSettingsData?.data?.setting?.daypassConfirm;

  // ********* booking-settings end ***********

  // ********* coworker start ***********
  const { data: coworkerListData } = useQuery(
    ["coworkerData"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );



 const daypassVat = (vatValue?.setting?.isTaxEnable === "true"
 ? Number(
     (Number(vatValue?.setting?.taxRate) /
       100) *
       (reschduleMode === "true"
         ? bookingDayPassData?.Price
         : pricingData?.Price?.EstimatedCost)
   ).toFixed(2)
 : 0)


 const discountedAmountDP = (
  reschduleMode === "true"
         ? bookingDayPassData?.discountedAmount:
  pricingData?.Price?.DiscountAmount > 0 || !pricingData?.Price?.DiscountAmount === null ?(pricingData?.Price?.DiscountAmount).toFixed(2)
  : "-"
 )
 

 


//  const daypasssubTotal = (
//   reschduleMode === "true"
//                                   ? bookingDayPassData?.Price + (pricingData?.Price?.DiscountAmount?pricingData?.Price?.DiscountAmount:0)
//                                   :( pricingData?.Price?.EstimatedCost + calculatePercentage(pricingData?.Price?.DiscountAmount,vatValue?.setting?.taxRate  )
//  ).toFixed(2)
//  )


const daypasssubTotal = (
  reschduleMode === "true"
                                  ? bookingDayPassData?.subTotal 
                                  :
                                  (Number( pricingData?.Price?.EstimatedCost) + Number(pricingData?.Price?.DiscountAmount ? pricingData?.Price?.DiscountAmount : 0))
 ).toFixed(2)
 









  // ********* coworker end ***********

  useEffect(() => {
    const nowFromTime = moment.utc(scheduleDate);
    const newFromTime1 =
      moment
        .utc([
          nowFromTime.year(),
          nowFromTime.month(),
          nowFromTime.date(),
          3,
          0,
          0,
        ])
        .toISOString()
        .slice(0, -5) + "Z";
    const newFromTime = moment.utc(newFromTime1).add(1, "day");

    const nowToTime = moment.utc(scheduleDate);
    const newToTime1 =
      moment
        .utc([nowToTime.year(), nowToTime.month(), nowToTime.date(), 17, 0, 0])
        .toISOString()
        .slice(0, -5) + "Z";

    const newToTime = moment.utc(newToTime1).add(1, "day");

    bookingDayPassRoom
      .mutateAsync({
        TypeName: "booking",
        ResourceId: bookingDayPassData?.Id,
        CoworkerId: coworkerListData?.Id,
        FromTime: newFromTime,
        ToTime: newToTime,
      })
      .then((data) => {
        if (data?.status === 200 && data?.data?.statusCode === 200) {
          setPricingData(data?.data);
        } else if (data?.data?.statusCode === 400) {
          toast.error(data?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {});
  }, [coworkerListData?.Id]);

  const proceedFunc = () => {
    queryClient.invalidateQueries("customerbookingroomsData");
    queryClient.invalidateQueries("customerbookingroomsData2");
    queryClient.invalidateQueries("bookingDayPassLData");
    setWholePageLoading("true");
    const nowBookTime = new Date();
    const newBookedTime = moment(nowBookTime).format("YYYY-MM-DDTHH:mm:ss[Z]");

    const nowFromTime = moment.utc(scheduleDate);
    const newFromTime1 =
      moment
        .utc([
          nowFromTime.year(),
          nowFromTime.month(),
          nowFromTime.date(),
          8,
          0,
          0,
        ])
        .toISOString()
        .slice(0, -5) + "Z";
    // const newFromTime = moment.utc(newFromTime1);
    // const newFromTime = moment(newFromTime1).subtract(
    //   moment().utcOffset(),
    //   "minutes"
    // );
    const newFromTime = moment(scheduleDate)
      .startOf("days")
      .add(480, "minutes");

    // I changeed time Dawood

    const nowToTime = moment.utc(scheduleDate);
    const newToTime1 =
      moment
        .utc([nowToTime.year(), nowToTime.month(), nowToTime.date(), 22, 0, 0])
        .toISOString()
        .slice(0, -5) + "Z";
    const newToTime = moment(scheduleDate).startOf("days").add(1320, "minutes");

    const obj = {
      // ResourceId: bookingDayPassData?.Id,
      subTotal:Number(daypasssubTotal),
      vat:Number(daypassVat),
      vatPercentage : Number(vatValue?.setting?.taxRate),
      totalPayable:Number(reschduleMode === "true"
      ? 

      (
        Number( bookingDayPassData?.Price) +Number(daypassVat)
      ).toFixed(2)
      :     
        (
          Number(pricingData?.Price?.EstimatedCost) +Number(daypassVat)
        ).toFixed(2))
        ,
      CoworkerId: coworkerListData?.Id,
      FromTime: newFromTime,
      ToTime: newToTime,

      Tentative: daypassConfirm, // due to requirment change daypass is always need to be approved by admin

      // Tentative: true,

      TypeName: "booking",
      BookedOn: newBookedTime,
      CoworkerName: coworkerListData?.UserFullName,
      Price: pricingData?.Price?.EstimatedCost,
      CoworkerName: coworkerListData?.UserFullName,
    };

    if (reschduleMode === "true") {
      obj.Price = bookingDayPassData?.Price + 0;
      obj.isRescheduleRequest = true;
      obj.rescheduleId = reschduleId ? reschduleId : "";

      reScheduleDayPassRoom.mutateAsync(obj).then((data1) => {
        scheduleDayPassRoom
          .mutateAsync(obj)
          .then((data) => {
            setWholePageLoading("false");

            if (
              reschduleMode === "true"
                ? bookingDayPassData?.Price
                : pricingData?.Price?.EstimatedCost > 0
            ) {
              // if (daypassConfirm === true) {
              Navigate("/day-pass-app/payment-upload-photo", {
                state: {
                  bookingDayPassData: bookingDayPassData,
                  bookingDayAvailbityData: bookingDayAvailbityData,
                  scheduleDate: scheduleDate,
                  scheduledData: data?.data,
                  textDesp: textDesp,
                },
              });
            } else {
              Navigate("/day-pass-app/daypass-approval", {
                state: { textDesp: textDesp },
              });
              // }
            }
            if (
              data?.data?.statusCode === "400" ||
              data?.data?.statusCode === 400
            ) {
              toast.error(data?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((err) => {
            setWholePageLoading("false");
            // err?.response?.data?.error?.message;
            toast.error("No resource available", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      });
    } else {
      scheduleDayPassRoom
        .mutateAsync(obj)
        .then((data) => {
          setWholePageLoading("false");

          if (
            reschduleMode === "true"
              ? bookingDayPassData?.Price
              : pricingData?.Price?.EstimatedCost > 0
          ) {
            // if (daypassConfirm === true) {
            Navigate("/day-pass-app/payment-upload-photo", {
              state: {
                bookingDayPassData: bookingDayPassData,
                bookingDayAvailbityData: bookingDayAvailbityData,
                scheduleDate: scheduleDate,
                scheduledData: data?.data,
                textDesp: textDesp,
              },
            });
          } else {
            Navigate("/day-pass-app/daypass-approval", {
              state: { textDesp: textDesp },
            });
            // }
          }
          if (
            data?.data?.statusCode === "400" ||
            data?.data?.statusCode === 400
          ) {
            toast.error(data?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          setWholePageLoading("false");
          // err?.response?.data?.error?.message;
          toast.error("No resource available", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return {
    discountedAmountDP,
    daypasssubTotal,
    daypassVat,
    reschduleMode,
    wholePageLoading,
    scheduleDate,
    coworkerListData,
    pricingData,
    proceedFunc,
    bookingDayPassData,
    textDesp,
  };
};

export default UseScheduleAvailability;
