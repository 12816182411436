import React from "react";
import { Row, Col, Label, Input, Button, Spinner } from "reactstrap";
import DelAccount from "../../../images/delAccount.svg";
import Infoic from "../../../images//infoic.svg";
import blackInfo from "../../../images//blackInfo.svg";
import { useNavigate } from "react-router-dom";
import { ConfirmIdentityModel } from "../../../components/molicules/userModel";
import UseDeletionTerms from "./useDeletionTerms";

const DeletionTerms = () => {
  const userType = localStorage.getItem("userTypeRedir");

  const {
    contractsLoading,
    requestEnable,
    pageEnable,
    passwordText,
    setPasswordText,
    modelShow1,
    setModelShow1,
    isChecked,
    setIsChecked,
    isChecked1,
    setIsChecked1,
    confirmFunc,
    isChecked2,
    setIsChecked2,
  } = UseDeletionTerms();
  const Navigation = useNavigate();

  return (
    <div>
      <p className='del_a_pp7 mt-5 mx-3'>
        Please agree to the following terms, first.
      </p>
      <div className='mx-4 '>
        <Row>
          <Col lg={10} md={10} sm={10} xs={10}>
            <Row className='f_div'>
              <Col
                lg={1}
                md={1}
                sm={1}
                xs={1}
                className='d-flex justify-content-center align-items-center'
              >
                <div>
                  <img src={DelAccount} alt='img' className='del_a_img_size' />
                </div>
              </Col>

              <Col lg={8} md={8} sm={8} xs={8}>
                <div>
                  <p className='del_a_p1'>
                    <b>Delete Account(s) Request</b>
                  </p>
                  <Label className='del_a_p2'>
                    You are about to request permanent deletion of all of your
                    company accounts. If you want to end this contract, and
                    delete all of your team accounts, then you may submit a
                    request.
                  </Label>
                </div>
              </Col>
            </Row>

            <Row className='del-a-check mt-4'>
              <Col lg={12} md={12} sm={12} xs={12}>
                {/* <input type='checkbox' /> */}

                <Input
                  disabled={!pageEnable}
                  className='mx-3 del_a_pp2 '
                  type='checkbox'
                  checked={isChecked}
                  onChange={() => {
                    setIsChecked(!isChecked);
                  }}
                />
                <Label className='del_a_pp1'>
                {userType === "daypassUser"  ?
                "You will no longer be able to use any the remaining credits, if any." :
                 ` You and your team will no longer be able to use any the
                  remaining credits.`}
                </Label>
              </Col>
            </Row>

            <Row className='del-a-check mt-4'>
              <Col lg={12} md={12} sm={12} xs={12}>
                {/* <input type='checkbox' /> */}

                <Input
                  disabled={!pageEnable}
                  className='mx-3 del_a_pp2 '
                  type='checkbox'
                  checked={isChecked1}
                  onChange={() => {
                    setIsChecked1(!isChecked1);
                  }}
                />
                
                <Label className='del_a_pp1'>
                {userType === "daypassUser"  ?
                 `Your booking details may remain part of Fintech Hub Archive.`:
                 ` Your contract details will not be deleted, it will remain part
                  of the records at Fintech Hub archive.`}
                </Label>
              </Col>
            </Row>

            <Row className='del-a-check mt-4'>
              <Col lg={12} md={12} sm={12} xs={12}>
                {/* <input type='checkbox' /> */}

                <Input
                  disabled={!pageEnable}
                  className='mx-3 del_a_pp2 '
                  type='checkbox'
                  checked={isChecked2}
                  onChange={() => {
                    setIsChecked2(!isChecked2);
                  }}
                />
                <Label className='del_a_pp1'>
                  {userType === "daypassUser" ? `
                  Your invoices for bookings and other purchases cannot be deleted because it is prohibited by state laws.`:
                 ` Your memo(s) for past bookings and purchases cannot be deleted
                  because it is prohibited by state laws.`}
                </Label>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <Row className='mt-4 mx-1'>
        <Col lg={7} md={7} sm={7} xs={7}>
          {pageEnable ? (
            <>
              <p className='del_a_pp5'>
                <img src={Infoic} alt='logo' />{" "}
                <label className='mx-2'>Warning!</label>
              </p>
              <p className='del_a_pp6'>
                You are about to permanently delete your team accounts and data
              </p>
            </>
          ) : (
            <>
              <p className='del_a_pp55'>
                <img src={blackInfo} alt='logo' />{" "}
                <label className='mx-2'>Error!</label>
              </p>
              <p className='del_a_pp66'>
                You must cancel your contract before you are able to delete your
                and your team accounts.
              </p>
            </>
          )}
        </Col>
        <Col lg={3} md={3} sm={3} xs={3}>
          {contractsLoading || contractsLoading === "undefined" ? (
            <Spinner className='mx-5' />
          ) : (
            <Button
              disabled={!pageEnable || !requestEnable}
              color='dark'
              className='w-100'
              onClick={() => {
                // Navigation("/del-and-logout");
                setModelShow1(true);
              }}
            >
              Request deletion
            </Button>
          )}
        </Col>
      </Row>

      <ConfirmIdentityModel
        show={modelShow1}
        cancelApiFunc={confirmFunc}
        setModelShow1={setModelShow1}
        header={`Confirm your identity`}
        setPasswordText={setPasswordText}
        passwordText={passwordText}
      />
    </div>
  );
};

export default DeletionTerms;
