import { useEffect, useState } from "react";

export const useFintechHubInfo = (props) => {
  const [gifLogo, setGifLogo] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setGifLogo(false);
    }, 4000);
  });

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return { gifLogo };
};
