import { useMutation } from "react-query";
import { LOGIN_MUTATION, FORGOT_PASSWORD } from "./keys";

import authApi from "../services/loginApi";

export const useLoginMutation = (history) => {
  const response = useMutation(authApi.login, {
    mutationKey: LOGIN_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useDelAccountMutation = (history) => {
  const response = useMutation(authApi.delAccount, {
    mutationKey: "del_account",
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useForgotMutation = (history) => {
  const response = useMutation(authApi.forgotPassword, {
    mutationKey: FORGOT_PASSWORD,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useFcmTokenSend = (history) => {
  const response = useMutation(authApi.fcmTokenPost, {
    mutationKey: "fcmTokenPost",
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
