import { useWelcomeInfo } from "././useWelcome";
import { Col, Row, Card, Label, Spinner } from "reactstrap";
import PrivateOffice from "../.././images/private_office.svg";
import "./css/styles.css";

const Welcome = (props) => {
  const { welcomeFintechDataa, resourceFunc, membershipLoading } =
    useWelcomeInfo(props);

  return (
    <Row className='p-4'>
      <Col md={12} lg={12} sm={12} xs={12} xl={12}>
        <Label className='db_h1'> Welcome to Fintech Saudi</Label>
        <Label className='db_h '>
          Select a membership type to begin your journey with Fintech Saudi
        </Label>

        {membershipLoading ? (
          <div className='d-flex justify-content-center my-auto'>
            <Spinner />
          </div>
        ) : (
          <Row>
            {welcomeFintechDataa &&
              welcomeFintechDataa?.map((ele, index) => {
                return (
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    className='pt-3'
                    key={index + 932}
                  >
                    <Card
                      className='p-2 db_card w-100 h-100'
                      role='button'
                      id={ele?.ResourceName}
                      onClick={(e) => resourceFunc(ele?.ResourceId)}
                    >
                      <img src={PrivateOffice} alt='img' />

                      <h6 className='db_card_h1 mt-3'>{ele?.ResourceName}</h6>
                      <p className='db_card_h2'>
                      {ele?.ResourceName === "Private Office" ?  "A Private room for your team.":ele?.ResourceName === "Dedicated Desk" ? "Set of dedicated desks for your team in an open space":"A combination of an office and dedicated desks."}
                      </p>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default Welcome;
