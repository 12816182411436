import { useState, useEffect } from "react";
import {
  useTeamOtpRequestMutaion,
  useTeamManagenmentMutaion,
} from "../../../rc-query/myTeamHooks";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useTeamManagementInfo = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { data, tab } = state;

  const [modelShow, setModelShow] = useState(false);
  const [teamManageModel, setTeamManageModel] = useState(false);
  const [memberRequest, setMemberRequest] = useState("");
  const [memberId, setMemberId] = useState("");
  const [teamId, setTeamId] = useState();
  const [email, setEmail] = useState();
  const [otpModel, setOtpModel] = useState(false);
  const [confirmRequest, setConfirmRequest] = useState(false);
  const [teamPayingModel, setTeamPayingModel] = useState(false);
  const otpMutation = useTeamOtpRequestMutaion(props.history);
  const teamManagementMutation = useTeamManagenmentMutaion(props.history);

  const HandleOtpFunc = (request, ID) => {
    setMemberRequest(request);
    setMemberId(String(ID));
    setTeamManageModel(false);

    otpMutation.mutateAsync({ teamId, email }).then((res) => {
      if (res?.statusCode === 200) {
        setOtpModel(true);
      }
    });
  };

  const ChangeRequestFunc = (otp) => {
    teamManagementMutation
      .mutateAsync({ teamId, memberId, otp, memberRequest })
      .then((res) => {
        if (res?.statusCode === 200) {
          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        navigate(-1);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTeamId(data?.teamInfo?.NexudusTeamId);
    setEmail(data?.email);
  }, [data]);

  return {
    modelShow,
    teamManageModel,
    teamPayingModel,
    otpModel,
    confirmRequest,
    data,
    navigate,
    ChangeRequestFunc,
    HandleOtpFunc,
    setConfirmRequest,
    setOtpModel,
    setTeamPayingModel,
    setTeamManageModel,
    setModelShow,
  };
};
