import React from "react";
import { useQuery } from "react-query";
import vatValueGetApi from "../../services/vatValueApi";

const UseValueValue = () => {
  // ********* get vat ***********
  const { data: vatValue, isLoading: vatValueLoading } = useQuery(
    ["VatValue"],
    () => vatValueGetApi.getVatValue(),
    {
      retry: 0,
    }
  );

 

  return { vatValue };
};

export default UseValueValue;
