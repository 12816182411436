import React, { useState } from "react";
import { Button, Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./css/styles.css";

const TeamManagemantModel = (props) => {
  const navigate = useNavigate();
  const [memberId, setMemberId] = useState();

  const handleRequestModelFunc = () => {
    props.otp(props.request, memberId);
    props.cancelModel(false);
  };
  return (
    <Modal isOpen={props.show} centered={true} className='fintechModel'>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelHeader'>
                  <h1>{props.header}</h1>
                  <p className='mt-3'>{props.body}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                {props.members.teamInfo?.allActiveMembers?.map(
                  (member, index) => {
                    if (
                      props.request === "isAdministrator" &&
                      !member.isAdministrator === true
                    ) {
                      return (
                        <div className={"inputRadioGroup mt-2 mb-4"}>
                          <input
                            type='radio'
                            id={index}
                            name={"size"}
                            value={member?.Id}
                            onClick={(e) => {
                              setMemberId(e.target.value);
                            }}
                          />
                          <label
                            for={index}
                            className='checkLabel sip_teams_bg'
                          >
                            {member.fullName}
                          </label>
                        </div>
                      );
                    } else if (
                      props.request === "isPayingMember" &&
                      !member.isPayingMember === true
                    ) {
                      return (
                        <div className={"inputRadioGroup mt-2 mb-4"}>
                          <input
                            type='radio'
                            id={index}
                            name={"size"}
                            value={member?.Id}
                            onClick={(e) => {
                              setMemberId(e.target.value);
                            }}
                          />
                          <label
                            for={index}
                            className='checkLabel sip_teams_bg'
                          >
                            {member.fullName}
                          </label>
                        </div>
                      );
                    }
                  }
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='d-flex justify-content-between'>
                  <Button
                    className='w-100 requestCancelModelBtn '
                    onClick={() => props.cancelModel(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='w-100 requestUpdateModelBtn'
                    onClick={() => handleRequestModelFunc()}
                  >
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export default TeamManagemantModel;
