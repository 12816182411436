import React from "react";
import UseMeetingRoom from "./useMeetingRoom";
import { useMainPageInfo } from "../../myTeam/mainPage/useMainPage";
import { Col, Row, Card, Label, Spinner } from "reactstrap";
import UsersImg from "./../../.././images/users.svg";
import UsersImgBrown from "./../../.././images/brownusers.svg";
import CardPolygonwhite from "./../../.././images/cardPolygon.svg";
import CardPolygonblack from "./../../.././images/cardPolygonBlack.svg";
import TodayClock from "./../../.././images/today.svg";
import DurationClock from "./../../.././images/durationclock.svg";
import Clock from "./../../.././images/yellowClock.svg";
import RedClock from "./../../.././images/welcomePageRedClock.svg";
import YellowClock from "./../../.././images/welcomePageYellowClock.svg";
import Dot from "./../../.././images/yellowDot.svg";
import moment from "moment";
import Zero_meeting_room_book from "./../../.././images/zero_meeting_room_book.svg";
import Artboard from "./../../.././images/Artboard.svg";
import { Image, Breathing } from "react-shimmer";
import { useNavigate } from "react-router-dom";
import "./css/style.css";

const MeetingRoom = (props) => {
  const Navigate = useNavigate();
  const {
    bookingroomsData,
    bookingroomsLoading,
    customerBookedData,
    customerBookingroomsLoading,
    memberCredits,
    memberCreditsLoading,
    feeds,
    feedsLoading,
    extimateTimeFunc,
  } = UseMeetingRoom(props);

  const userType = localStorage.getItem("userTypeRedir");

  const { teamInfo, teamInfoLoading } = useMainPageInfo(props);

  const TextExtractor = (description) => {
    if (description) {
      const match = description.match(/<span[^>]*>([^<]*)<\/span>/);
      const text = match && match[1];

      return text;
    }
  };

  const ttt = customerBookedData
    ? moment.duration(
        moment(customerBookedData[0]?.ToTime).diff(
          moment(customerBookedData[0]?.FromTime),
          "minutes"
        ),
        "minutes"
      )
    : 0;

  const yyy = customerBookedData
    ? moment.duration(
        moment(customerBookedData[1]?.ToTime).diff(
          moment(customerBookedData[1]?.FromTime),
          "minutes"
        ),
        "minutes"
      )
    : 0;

  return (
    <div className=''>
      <Row>
        <Col>
          <Row>
           
            {!teamInfoLoading ? (
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                {userType === "daypassUser" ? (
                  ""
                ) : moment(new Date(), "h:mm:ss A").isBefore(
                    moment(teamInfo?.startDate).format("DD MMMM,YYYY")
                  ) ? (
                  <div className='notifyMembership notifyMembershipStart px-4  mb-3 '>
                    <Row>
                      <Col md={2} xl={1} lg={1} sm={2} xs={3}>
                        <div className='py-2'>
                          <img src={YellowClock} alt='' className='w-75 ' />
                        </div>
                      </Col>
                      <Col md={10} xl={11} lg={11} sm={10} xs={9}>
                        <div className='notifyMembershipContainer py-2 '>
                          <h1>
                            <h1>
                              {/* {moment(teamInfo?.startDate)
                                .subtract(moment().utcOffset(), "minutes")
                                .format("DD MMMM,YYYY")} */}
                              {moment(teamInfo?.startDate).format(
                                "DD MMMM,YYYY"
                              )}
                            </h1>
                          </h1>
                          <span>Your membership will start soon.</span>
                        </div>
                      </Col>
                      <Col></Col>
                    </Row>
                  </div>
                ) : moment(new Date(), "h:mm:ss A").isAfter(
                    moment(teamInfo?.RenewalMonth).format("DD MMMM,YYYY")
                  ) ? (
                  <div className='notifyMembership notifyMembershipExpired px-4 mb-3  '>
                    <Row>
                      <Col md={2} xl={1} lg={1} sm={2} xs={3}>
                        <div className='py-2'>
                          <img src={RedClock} alt='' className='w-75 ' />
                        </div>
                      </Col>
                      <Col md={10} xl={11} lg={11} sm={10} xs={9}>
                        <div className='notifyMembershipContainer py-2 '>
                          <h1>
                            <h1>
                              {moment(teamInfo?.RenewalMonth)
                                .subtract(moment().utcOffset(), "minutes")
                                .format("DD MMMM,YYYY")}
                            </h1>
                          </h1>
                          <span>Your membership is expired.</span>
                        </div>
                      </Col>
                      <Col></Col>
                    </Row>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            ) : (
              ""
            )}
            <Col className='my-3 ' xl={5} lg={5} md={5} sm={12} xs={12}>
              <Label className='m_rB_l1'>
                <b>Book a Meeting Room</b>
              </Label>
            </Col>
            <Col
              className='bx-3 my-3 d-flex m_rB_space'
              xl={5}
              lg={5}
              md={5}
              sm={12}
              xs={12}
            >
              <Label className='m_rB_l1'>
                <b>Your meetings</b>
              </Label>
              <Label
                className='m_rB_l2'
                onClick={() => {
                  localStorage.setItem("reschduleMode", "false");
                  Navigate("/meeting-room-app/view-all-meetings");
                }}
                role='button'
              >
                See All
              </Label>
            </Col>
          </Row>

          <Row className='mb-5'>
            <Col xl={5} lg={5} md={5} sm={12} xs={12} className='pb-3'>
              {bookingroomsLoading ? (
                <div className='d-flex justify-content-center'>
                  <Spinner
                    type='grow'
                    color='warning'
                    className='justify-item-center mx-1'
                  />
                  <Spinner
                    type='grow'
                    color='warning'
                    className='justify-item-center mx-1'
                  />
                  <Spinner
                    type='grow'
                    color='warning'
                    className='justify-item-center mx-1'
                  />
                </div>
              ) : (
                <Card
                  className={
                    userType === "daypassUser"
                      ? `p-2 s_o_cc  w-100 `
                      : moment(new Date(), "h:mm:ss A").isBefore(
                          moment(teamInfo?.startDate).format("DD MMMM,YYYY")
                        ) ||
                        moment(new Date(), "h:mm:ss A").isAfter(
                          moment(teamInfo?.RenewalMonth).format("DD MMMM,YYYY")
                        )
                      ? `p-2 s_o_cc  w-100 disabled-card `
                      : `p-2 s_o_cc  w-100 `
                  }
                  onClick={() => {
                    if (userType === "daypassUser") {
                      localStorage.setItem("reschduleMode", "false");
                      Navigate("/schdule-meeting-room", {
                        state: {
                          bookingroomsData: bookingroomsData[0]
                            ? bookingroomsData
                            : "",
                        },
                      });
                    } else if (
                      moment(teamInfo?.startDate, "h:mm:ss A").isBefore(
                        moment(new Date(), "h:mm:ss A")
                      ) ||
                      moment(new Date(), "h:mm:ss A").isAfter(
                        moment(teamInfo?.RenewalMonth).format("DD MMMM,YYYY")
                      )
                    ) {
                    } else {
                      localStorage.setItem("reschduleMode", "false");
                      Navigate("/schdule-meeting-room", {
                        state: {
                          bookingroomsData: bookingroomsData[0]
                            ? bookingroomsData
                            : "",
                        },
                      });
                    }
                  }}
                  role='button'
                >
                  <div style={{ width: "100%", overflow: "hidden" }}>
                    <Image
                      src={`https://nexudus.spaces.nexudus.com//en/publicresources/getimage/${bookingroomsData[0]?.Id}?w=565&h=210&anchor=middlecenter&cache=2023-03-16T07:23:02Z`}
                      fallback={<Breathing width='100' />}
                      alt='Booking Room Image'
                      className='w-100 h-100'
                    />
                  </div>
                  <span className='d-flex pos_cardusersicon mt-3 '>
                    <Label className='so_h2  '>
                      {bookingroomsData[0]?.Title}
                    </Label>

                    <span className=''>
                      <img className='mx-2 ' alt='img' src={UsersImg} />
                      <Label className='so_total_users '>
                        {bookingroomsData[0]?.Allocation}
                      </Label>
                    </span>
                  </span>
                  <Label className='m_rB_l3'>
                    {TextExtractor(bookingroomsData[0]?.Description)}
                  </Label>
                </Card>
              )}

              <Card
                role='button'
                className='p-2 s_o_cc  w-100 mt-3 '
                onClick={() => {
                  Navigate("/team-credits");
                }}
              >
                <Row>
                  <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                    <img src={Artboard} alt='img' />
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Label className='so_h2 mt-3' role='button'>
                      {" "}
                      Printing Credit
                    </Label>
                  </Col>

                  <Col className='d-flex' xl={4} lg={4} md={4} sm={12} xs={12}>
                    <span className=' m_rB_l4 mt-2 mx-2'>SAR </span>
                    <span className='m_rB_l5 mt-2 '>
                      {!memberCreditsLoading ? (
                        memberCredits?.credits ? (
                          memberCredits?.credits
                        ) : (
                          0
                        )
                      ) : (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                          variant='light'
                        />
                      )}
                    </span>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xl={5} lg={5} md={5} sm={12} xs={12} className='pb-3'>
              {customerBookingroomsLoading ? (
                // <></>

                <div className='d-flex flex-wrap justify-content-center my-5'>
                  <Spinner className='' />
                </div>
              ) : (
                <>
                  {customerBookedData?.length === 0 ? (
                    <>
                      <Row className='mb-3'>
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className=''
                        >
                          <img
                            src={Zero_meeting_room_book}
                            alt='zerobooking'
                            className='w-100 h-100'
                          />
                        </Col>
                      </Row>
                    </>
                  ) : customerBookedData !== undefined ? (
                    <>
                      <div className='marginless'>
                        <Card
                          className=' m_rb_c1  w-100 h-100'
                          onClick={() => {
                            localStorage.setItem("reschduleMode", "true");
                            Navigate("/reschedule-meeting-room", {
                              state: {
                                dataOfSelectedObject: customerBookedData[0],
                              },
                            });
                          }}
                        >
                          <Row>
                            <Col
                              className='p-3 mt-1'
                              xl={9}
                              lg={9}
                              md={9}
                              sm={12}
                              xs={12}
                            >
                              <Label className='mx-2 m_rb_c1_l2'>
                                {customerBookedData[0]?.ResourceName}
                              </Label>

                              <p className='m_rb_c1_l1'>
                                <img
                                  className='mx-2 '
                                  alt='img'
                                  src={UsersImgBrown}
                                />
                                <span className='mx-1 '>
                                  {customerBookedData[0]?.BookingVisitors
                                    ?.length }
                                </span>
                                <span> member invited</span>
                              </p>
                            </Col>

                            <Col
                              xl={3}
                              lg={3}
                              md={3}
                              sm={3}
                              xs={3}
                              className='mr_duration_bor'
                            >
                              <span className='mr_coming_font'>Upcoming</span>
                            </Col>
                          </Row>

                          <Row className=''>
                            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                              <img
                                src={TodayClock}
                                alt='today'
                                className='mr_cal'
                              />
                              <Label className='mx-2 mr_today_dur'>
                                {moment(customerBookedData[0]?.FromTime)
                                  .subtract(moment().utcOffset(), "minutes")
                                  .format("DD-MM-YYYY")}
                                <p className='mr_Time '>
                                  {moment(
                                    customerBookedData[0]?.FromTime
                                  ).format("hh:mm a")}{" "}
                                  -{" "}
                                  {moment(customerBookedData[0]?.ToTime).format(
                                    "hh:mm a"
                                  )}
                                </p>
                              </Label>
                            </Col>
                            <Col
                              className=''
                              xl={4}
                              lg={4}
                              md={4}
                              sm={12}
                              xs={12}
                            >
                              <img
                                src={DurationClock}
                                alt='duration'
                                className='mr_cal1 mr_cal_sm_space'
                              />

                              <Label className='mx-2 mr_today_dur'>
                                {" "}
                                Duration
                                <p className='mr_Time '>
                                  {/* {moment(customerBookedData[0]?.ToTime).diff(
                                    moment(customerBookedData[0]?.FromTime),
                                    "minutes"
                                  )} */}
                                  {`${ttt.hours()}h ${ttt.minutes()}`}m
                                </p>
                              </Label>
                            </Col>

                            <Col
                              className=''
                              xl={3}
                              lg={3}
                              md={3}
                              sm={12}
                              xs={12}
                            >
                              <img
                                src={CardPolygonblack}
                                alt='ploygon'
                                className='mr_mettingRoomImg'
                              />
                            </Col>
                          </Row>
                        </Card>
                      </div>
                      {customerBookedData[1] !== undefined ? (
                        <Row className='my-3'>
                          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Card
                              className=' s_o_cc1   w-100 h-100'
                              onClick={() => {
                                localStorage.setItem("reschduleMode", "true");
                                Navigate("/reschedule-meeting-room", {
                                  state: {
                                    dataOfSelectedObject: customerBookedData[1],
                                  },
                                });
                              }}
                            >
                              <Row>
                                <Col
                                  className='p-3 mt-1'
                                  xl={9}
                                  lg={9}
                                  md={9}
                                  sm={12}
                                  xs={12}
                                >
                                  <Label className='mx-2  m_rb_c1_l22 m_rb_c1_l2'>
                                    {customerBookedData[1]?.ResourceName}
                                  </Label>

                                  <p className='m_rb_c1_l11'>
                                    <img
                                      className='mx-2 '
                                      alt='img'
                                      src={UsersImgBrown}
                                    />
                                    <span className='mx-1 '>
                                      {customerBookedData[1]?.BookingVisitors
                                        ?.length + 1}
                                    </span>
                                    <span> member invited</span>
                                  </p>
                                </Col>

                                <Col
                                  className='mr_duration_bor1'
                                  xl={3}
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  xs={3}
                                >
                                  <span className='mr_coming_font'>
                                    Upcoming
                                  </span>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className=''
                                  xl={5}
                                  lg={5}
                                  md={5}
                                  sm={12}
                                  xs={12}
                                >
                                  <img
                                    src={TodayClock}
                                    alt='today'
                                    className='mr_cal '
                                  />

                                  <Label className='mx-1 mr_today_dur1'>
                                    {moment(customerBookedData[1]?.FromTime)
                                      .subtract(moment().utcOffset(), "minutes")
                                      .format("DD-MM-YYYY")}
                                    <p className='mr_Time1 '>
                                      {moment(
                                        customerBookedData[1]?.FromTime
                                      ).format("hh:mm a")}{" "}
                                      -{" "}
                                      {moment(
                                        customerBookedData[1]?.ToTime
                                      ).format("hh:mm a")}
                                    </p>
                                  </Label>
                                </Col>
                                <Col
                                  className=''
                                  xl={4}
                                  lg={4}
                                  md={4}
                                  sm={12}
                                  xs={12}
                                >
                                  <img
                                    src={DurationClock}
                                    alt='duration'
                                    className='mr_cal1 mr_cal_sm_space'
                                  />

                                  <Label className='mx-2 mr_today_dur1'>
                                    {" "}
                                    Duration
                                    <p className='mr_Time1 '>
                                      {/* {moment(
                                        customerBookedData[1]?.ToTime
                                      ).diff(
                                        moment(customerBookedData[1]?.FromTime),
                                        "minutes"
                                      )} */}{" "}
                                      {`${yyy.hours()}h ${yyy.minutes()}`}m
                                    </p>
                                  </Label>
                                </Col>

                                <Col
                                  className=''
                                  xl={3}
                                  lg={3}
                                  md={3}
                                  sm={12}
                                  xs={12}
                                >
                                  <img
                                    src={CardPolygonwhite}
                                    alt='ploygon'
                                    className='mr_mettingRoomImg'
                                  />
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}

              <Row className='mt-3'>
                {feeds?.feeds?.Records?.length === 0 ? (
                  ""
                ) : (
                  <Col
                    className='bx-3 mt-3  d-flex m_rB_space'
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Label className='m_rB_l1'>
                      <b>What’s happening</b>
                    </Label>
                    <Label
                      className='m_rB_l2'
                      onClick={() =>
                        Navigate("/meeting-room-app/what-happening")
                      }
                      role='button'
                    >
                      See All
                    </Label>
                  </Col>
                )}
              </Row>

              {!feedsLoading
                ? feeds?.feeds?.Records?.map((feed, index) => {
                    if (index <= 3) {
                      return (
                        <Row className='mt-3 ' key={feed?.Id}>
                          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Card
                              className='p-3 feedCards '
                              onClick={() => {
                                Navigate("/detail-feed", {
                                  state: {
                                    data: feed,
                                  },
                                });
                              }}
                              role='button'
                            >
                              <Row>
                                <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                  <div className='d-flex align-items-center '>
                                    <div>
                                      <img
                                        src={`https://nexudus.spaces.nexudus.com/en/blog/GetImage/?id=${feed?.Id}`}
                                        alt='hubImg'
                                        className='w-100 feedImgs'
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col
                                  xl={8}
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  xs={8}
                                  className='feedFlex'
                                >
                                  <Row>
                                    <Col
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                    >
                                      <h2>{feed?.Title}</h2>
                                      <p>{feed?.SummaryText}</p>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                    >
                                      <div className='d-flex '>
                                        <div>
                                          <img src={Clock} alt='clock' />
                                          <span className='px-2'>
                                            {moment(feed?.PublishDate)
                                              .format("DD MMMM, YYYY")
                                              .toString()}
                                          </span>
                                        </div>
                                        <div>
                                          <img src={Dot} alt='dot' />
                                          <span className='px-2'>
                                            {extimateTimeFunc(feed?.FullText)}
                                          </span>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                      );
                    }
                  })
                : ""}
            </Col>

            <Col xl={2} lg={2} md={2} sm={12} xs={12}></Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default MeetingRoom;
