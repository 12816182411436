import React from "react";
import { Button, Col, Row, Card, Label } from "reactstrap";

import CloseIcon from "./../../../../images/icons8-close-48.svg";
import Calendar from "react-calendar";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UseDayPassAvailability from "./useDayPassAvailability";
import { useMemo } from "react";
import CloseButtonComp from "../../../../components/molicules/closeButton";
import "react-calendar/dist/Calendar.css";
import "./css/style.css";
import moment from "moment";
import { toast } from "react-toastify";

const DayPassAvailability = () => {
  const Navigate = useNavigate();

  const calendarStyle = {
    minWidth: "100%", // Set the minimum width of the calendar
    width: "auto", // Allow the calendar to expand to fit its contents
  };

  const {
    dayPassActivityAvail,
    reschduleMode,
    bookingDayPassData,
    bookingDayAvailbityData,
    dayPassAvailabilityLoading,
    textDesp,
    dayPassSlots,
    scheduleDate,
    dayPassSlotsLoading,
    isPastDate,
    isDateBooked,
    setScheduleDate,
  } = UseDayPassAvailability();

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const minDate = tomorrow.toISOString().slice(0, 10);
  useMemo(() => {
    setScheduleDate(minDate);
  }, []);

  const TextExtractor = (description) => {
    if (bookingDayPassData?.Description) {
      const match = description.match(/<span[^>]*>([^<]*)<\/span>/);
      const text = match && match[1];

      return text;
    }
  };

  return (
    <div className='p-4'>
      <Row>
        <Col xl={5} lg={5} md={5} sm={12} xs={12}>
          <p className='smr_h1 '>
            <b>Book a Day Pass</b>
          </p>
        </Col>
        <Col xl={5} lg={5} md={5} sm={12} xs={12}>
          <p className='smr_h1 '>
            <b className='sm_scr_shw'>Check availability</b>
          </p>
        </Col>

        <Col xl={1} lg={1} md={1} sm={10} xs={10}></Col>
        <Col xl={1} lg={1} md={1} sm={1} xs={1}>
          <div className='d-flex'>{<CloseButtonComp />}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className='mb-5'>
            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
              <Card className='p-2 s_o_cc  w-100  mb-4'>
                {/* <img src={PrivateOfficeImg} alt='img' className='w-100 h-100' /> */}
                <img
                  src={`https://nexudus.spaces.nexudus.com//en/publicresources/getimage/${
                    reschduleMode === "true"
                      ? bookingDayPassData?.ResourceId
                      : bookingDayPassData?.Id
                  }?w=565&h=210&anchor=middlecenter&cache=2023-03-16T07:23:02Z`}
                  alt='img'
                  className='w-100 h-100'
                />
                <span className='d-flex pos_cardusersicon mt-3 '>
                  <Label className='c-avail_h1  '>
                    {reschduleMode === "true"
                      ? bookingDayPassData?.ResourceName
                      : bookingDayPassData?.Title}
                  </Label>
                </span>
                <Label className='m_rB_l3 mt-2'>
                  {reschduleMode === "true"
                    ? TextExtractor(bookingDayPassData?.Description)
                    : textDesp}
                </Label>
                  
              </Card>
            </Col>
            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <p className='c-avail_h2 '>
                    <b>Select Date</b>
                  </p>

                  <Label className='smr_h2'>
                    {!dayPassSlotsLoading ? (
                      <Calendar
                        tileDisabled={({ date }) =>
                          isDateBooked(date, dayPassSlots) || isPastDate(date)
                        }
                        className={"w-100 dayPassCalenderStyle"}
                        type='date'
                        value={scheduleDate}
                        name='fName'
                        onChange={(e) => {
                          setScheduleDate(moment(e).format("YYYY-MM-DD"));
                        }}
                        errorMessage='Required'
                        required={true}
                        minDate={new Date()}
                      />
                    ) : (
                      <div className='d-flex justify-content-center align-items-center w-100 py-5 '>
                        <div>
                          <Spinner />
                        </div>
                      </div>
                    )}
                  </Label>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  <Label className='c-avail_h1  '>Day passes</Label>
                </Col>
                <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                  <span className='c-avail_h3 px-3 py-1'>
                    <Label className='c-avail_h4'>
                      {bookingDayAvailbityData?.available && !dayPassActivityAvail
                        ? "Available"
                        : "Unavailable"}
                    </Label>
                  </span>
                </Col>
              </Row>

              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Card className='p-2 s_o_cc  w-100  mt-3'>
                    {/* <img src={PrivateOfficeImg} alt='img' className='w-100 h-100' /> */}
                    <img
                      src={`https://nexudus.spaces.nexudus.com//en/publicresources/getimage/${
                        reschduleMode === "true"
                          ? bookingDayPassData?.ResourceId
                          : bookingDayPassData?.Id
                      }?w=565&h=210&anchor=middlecenter&cache=2023-03-16T07:23:02Z`}
                      alt='img'
                      className='w-100 h-100'
                    />
                    <span className='d-flex pos_cardusersicon mt-3 '>
                      <Label className='c-avail_h1  '>
                        {/* {bookingDayPassData?.Title} */}
                        {reschduleMode === "true"
                          ? bookingDayPassData?.ResourceName
                          : bookingDayPassData?.Title}
                      </Label>
                    </span>
                  </Card>
                </Col>
              </Row>
              {dayPassAvailabilityLoading ? (
                <div className='d-flex justify-content-center my-auto mt-5'>
                  <Spinner />
                </div>
              ) : (
                <div>
                  
                  <Button
                    disabled={!bookingDayAvailbityData?.available || dayPassActivityAvail}
                    color='dark'
                    style={{ minHeight: "62px" }}
                    className='w-100 my-4'
                    onClick={() =>{
                      if(reschduleMode === "true" && moment(bookingDayPassData?.FromTime).format("YYYY-MM-DD") ===  scheduleDate
                        ){
                          toast.error("please choose different Date", {
                            position: toast.POSITION.TOP_RIGHT,
                          });
                        }
                      else{
                      Navigate("/day-pass-app/day-pass-schedule-availability", {
                        state: {
                          bookingDayPassData: bookingDayPassData,
                          bookingDayAvailbityData: bookingDayAvailbityData,
                          scheduleDate: scheduleDate,
                          textDesp:
                            reschduleMode === "true"
                              ? TextExtractor(bookingDayPassData?.Description)
                              : textDesp,
                        },
                      })
                    }
                  }}
                  >
                    Proceed
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DayPassAvailability;
