import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useAddTeamMemberMutaion } from "../../../rc-query/myTeamHooks";

export const useAddTeammMemberInfo = (props) => {
  const { state } = useLocation();
  const { data, tab } = state;
  const [modelShow, setModelShow] = useState(false);
  const [name, setName] = useState();
  const [resourceErrContainer, setResourceErrContainer] =
    useState("resourceErr");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [title, setTitle] = useState("");
  const [resource, setResource] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const addMemberMutation = useAddTeamMemberMutaion(props.history);

  useEffect(() => {
    setName(data?.member?.fullName);
    setEmail(data?.member?.email);
    setNumber(data?.member?.phoneNumber);
    setTitle(data?.member?.workTitle);
    if (data?.teamInfo?.isMultiple === false) {
      setResource(data?.teamInfo?.ResourceTypeId);
      if (
        data?.teamInfo?.ResourceTypeId ===
          Number(process.env.REACT_APP_PRIVATE_OFFICE) &&
        data?.teamInfo?.allActiveMembers.length === data?.teamInfo?.deskCapacity
      ) {
        setResourceErrContainer("resourceErrDis");
        setIsDisable(true);
      }
    } else {
      if (
        data?.teamInfo?.privateMembers.length === data?.teamInfo?.deskCapacity
      ) {
        setResourceErrContainer("resourceErrDis");
        setIsDisable(true);
      }
    }
  }, [data]);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function toLowerCase(email) {
    return email.toLowerCase();
  }

  const handleAddMemberFunc = () => {
    const re = /\S+@\S+\.\S+/;

    if (!re.test(email)) {
      toast.warn("Email is not valid", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const requestBody = {
      name: toCamelCase(name),
      email: toLowerCase(email),
      companyName: data?.teamInfo?.companyName,
      phoneNumber: number,
      workTitle: title,
      capacity: data?.teamInfo?.deskCapacity,
      nexudusTeamId: data?.teamInfo?.NexudusTeamId,
      resourceTypeId: Number(resource),
    };
    addMemberMutation.mutateAsync(requestBody).then((res) => {
      if (res?.status === 200) {
        setModelShow(true);
      }
    });
  };

  function toCamelCase(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    });
    return capitalizedWords.join(" ");
  }

  const handleResourceChange = (e) => {
    setResource(e.target.value);

    if (
      Number(e.target.value) === Number(process.env.REACT_APP_PRIVATE_OFFICE) &&
      data?.teamInfo?.privateMembers.length === data?.teamInfo?.deskCapacity
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  };

  return {
    modelShow,
    name,
    email,
    number,
    title,
    resource,
    resourceErrContainer,
    isDisable,
    data,
    handleResourceChange,
    handleAddMemberFunc,
    setResource,
    setEmail,
    setNumber,
    setName,
    setTitle,
    setModelShow,
  };
};
