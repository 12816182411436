import { MeetingRoomApp } from "../pages/meetingRoomApp";
import MeetingRoom from "../pages/meetingRoomApp/meetingRoom";
import MeetingConfirmed from "../pages/meetingRoomApp/meetingRoom/meetingConfirmed";
import WhatHappening from "../pages/meetingRoomApp/meetingRoom/whatHappening";
import ViewAllDaypass from "../pages/dayPass/dayPassDashboard/viewAllDaypass";
import PaymentUploadPhoto from "../pages/meetingRoomApp/meetingRoom/paymentUploadPhoto";
import ViewAllMeetings from "../pages/meetingRoomApp/meetingRoom/viewAllMeetings";
import Notifications from "../pages/notifications";

// meeting Room

const Pages = [
  // meeting Room
  { id: "126", path: "/meeting-room-app", element: <MeetingRoomApp /> },
  {
    id: "127",
    path: "/meeting-room-app/meeting-room",
    element: <MeetingRoom />,
  },
  {
    id: "128",
    path: "/meeting-room-app/what-happening",
    element: <WhatHappening />,
  },

  {
    id: "129",
    path: "/meeting-room-app/meeting-room-confirmed",
    element: <MeetingConfirmed />,
  },
  {
    id: "130",
    path: "/meeting-room-app/payment-upload-photo",
    element: <PaymentUploadPhoto />,
  },
  {
    id: "132",
    path: "/meeting-room-app/view-all-meetings",
    element: <ViewAllMeetings />,
  },
  {
    id: "133",
    path: "/view-all-daypass-meetingsplus",
    element: <ViewAllDaypass />,
  },
  {
    id: "134",
    path: "/notifications-page",
    element: <Notifications />,
  },
];

export default Pages;
