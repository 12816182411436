import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../../rc-query/loginHook";
import { toast } from "react-toastify";
import bookMeetingRoomApi from "../../../services/bookMeetingRoomApi";
import { useQuery } from "react-query";

const UseDeletionTerms = (props) => {
  const Navigate = useNavigate();
  const [passwordText, setPasswordText] = useState("");
  const [modelShow1, setModelShow1] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const [pageEnable, setPageEnable] = useState(true);
  const [requestEnable, setRequestEnable] = useState(false);
  const loginMutation = useLoginMutation(props);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // *****prfile data ***********

  const { data: coworkerListData, isLoading: coworkerLoading } = useQuery(
    ["coworkerDateda"],
    () => bookMeetingRoomApi.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );
  // ********* coworker end ***********
  const userEmail = coworkerListData?.Email;
  const teamId = coworkerListData?.TeamIds;

  // ************** team contracts **********

  const { data: contractsListData, isLoading: contractsLoading } = useQuery(
    ["contractslist", teamId],
    () => bookMeetingRoomApi.contractGetApi(teamId),
    {
      retry: 0,
    }
  );
  // ********* coworker end ***********
  const contracts = contractsListData?.data?.isContractAvailable;

  useEffect(() => {
    if (contracts === true) {
      setPageEnable(false);
    } else {
      setPageEnable(true);
    }
  }, [contracts]);

  useEffect(() => {
    if (isChecked2 && isChecked1 && isChecked) {
      setRequestEnable(true);
    } else {
      setRequestEnable(false);
    }
  }, [isChecked2, isChecked1, isChecked]);

  const confirmFunc = () => {
    // Navigate("/del-and-logout");

    loginMutation
      .mutateAsync({ username: userEmail, password: passwordText })
      .then((data) => {
        if (data?.data?.access_token && data?.status === 200) {
          localStorage.setItem("access Token", data?.data?.access_token);
          localStorage.setItem("loginToken", data?.data?.access_token);

          // Navigate("/welcome");
          Navigate("/del-and-logout");
        } else {
          if (
            data?.data?.error?.error_description ===
            "The email or password is incorrect."
          ) {
            toast.error("password is incorrect", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(data?.data?.error?.error, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  
  return {
    contractsLoading,
    passwordText,
    setPasswordText,
    modelShow1,
    setModelShow1,
    isChecked,
    setIsChecked,
    isChecked1,
    setIsChecked1,
    confirmFunc,
    isChecked2,
    setIsChecked2,
    pageEnable,
    requestEnable,
  };
};

export default UseDeletionTerms;
