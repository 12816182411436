import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row, Card, Label } from "reactstrap";
import PrivateOfficeImg from "./../../../.././images/private_office.svg";
import ApprovalLogo from "./../../../.././images/daypass_approval_logo.svg";
import UseDaypassApproval from "./useDaypassApproval";
import { useQueryClient } from "react-query";
import "./css/style.css";

const DaypassApproval = () => {
  const {} = UseDaypassApproval();
  const queryClient = useQueryClient();
  return (
    <div className='p-4 pt-2'>
      <Row>
        <Col xl={5} lg={5} md={5} sm={12} xs={12}>
          <p className='smr_h1 '>
            <b>Book a Day Pass</b>
          </p>
        </Col>
        <Col xl={5} lg={5} md={5} sm={12} xs={12}></Col>
      </Row>
      <Row>
        <Col>
          <Row className='mb-5'>
            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
              <Card className='p-2 s_o_cc  w-100  mb-4'>
                {/* <img src={PrivateOfficeImg} alt='img' className='w-100 h-100' /> */}
                <img src={PrivateOfficeImg} alt='img' className='w-100 h-100' />
                <span className='d-flex pos_cardusersicon mt-3 '>
                  <Label className='c-avail_h1  '>
                    {/* {selectedBookingRoomData?.Title} */}
                    Day Pass
                  </Label>
                </span>
                {/* <Label className='m_rB_l3 mt-2'>
                  Day pass to visit the hub, and having a dedicated desk
                </Label> */}
              </Card>
            </Col>
            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
              <div className='a_s_centerized'>
                <img src={ApprovalLogo} alt='logo' />
              </div>
              <div className='a_s_centerized mt-3'>
                <p className='a_s_l1'>Your approval is pending!</p>
              </div>
              <div className='a_s_centerized mt-3'>
                <p className='a_s_l2'>
                  Your receipt has been uploaded, is being reviewed by our team,
                  you will be notified via notification.
                </p>
              </div>
              <Link to='/day-pass-app/day-pass-dashboard'>
                <Button
                  color='dark'
                  style={{ minHeight: "62px" }}
                  className='w-100 my-4'
                  onClick={() => {
                    localStorage.setItem("reschduleMode", "false");
                    // for listing of reschdule
                    queryClient.invalidateQueries("customerbookingroomsData");
                    queryClient.invalidateQueries("customerbookingroomsData2");
                    queryClient.invalidateQueries("bookingDayPassLData");
                  }}
                >
                  Home
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DaypassApproval;
