import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreditsHook } from "../../../rc-query/creditsHook";
import { toast } from "react-toastify";
import { useMainPageInfo } from "../../myTeam/mainPage/useMainPage";
import { useState } from "react";

const UseCreditsSummary = (props) => {
  const postCredit = useCreditsHook();
  const Navigate = useNavigate();
  const { state } = useLocation();
  const { coworkerListData } = useMainPageInfo(props);
  const singlePersonData = state;
  const [creditAddedModel, setCreditAddedModel] = useState(false);
  const [purchaseCreditState, setPurchaseCreditState] = useState(false);

  const postCreditFunc = () => {
    postCredit
      .mutateAsync({
        CoworkerId: coworkerListData?.Id,
        ProductId: singlePersonData?.Id,
      })
      .then((data) => {
        if (data?.data?.statusCode === 200) {
          setPurchaseCreditState(false);
          setCreditAddedModel(true);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return {
    Navigate,
    singlePersonData,
    postCreditFunc,
    creditAddedModel,
    setCreditAddedModel,
    setPurchaseCreditState,
    purchaseCreditState,
  };
};

export default UseCreditsSummary;
