import React, { useState } from "react";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Spinner,
} from "reactstrap";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./css/formInput.css";
import { useQueryClient } from "react-query";
import CancelLogo from "./../.././images/cancel.svg";
import moment from "moment";
// import ModelImage from "../../images/icons/ModelImg.svg";

export const RescheduleModel = (props) => {
  const queryClient = useQueryClient();
  const Navigate = useNavigate();
  return (
    <Modal isOpen={props.show} centered={true}>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelImg'>
                  {" "}
                  <h3 className='modelHeading'>{props.header}</h3>
                </div>{" "}
              </Col>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelBody mt-2'>
                  {" "}
                  <p className='modelDesp'>{props.body}</p>
                </div>{" "}
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                <Button
                  className='w-100  modelBtn1'
                  onClick={() => props.setModelShow(false)}
                >
                  No
                </Button>
              </Col>
              <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                {" "}
                {/* <Link to={props.link}> */}
                {/* <Button onClick={props.onClick} className='w-100  modelBtn2'> */}
                <Button
                  onClick={() => {
                    queryClient.invalidateQueries("customerbookingroomsData2");
                    queryClient.invalidateQueries("bookingDayPassLData");
                    localStorage.setItem("reschduleMode", "true");
                    Navigate(`${props.link}`, {
                      state: {
                        dataOfSelectedObject: props?.dataOfSelectedObject,
                        bookingroomsData: props?.bookingroomsData,
                        bookingDayPassData: props?.bookingDayPassData,
                        state: props.state,
                      },
                    });

                    localStorage.setItem(
                      "reschduledaypassId",
                      props?.dataOfSelectedObject?.id
                    );
                  }}
                  className='w-100  modelBtn2'
                >
                  Yes
                </Button>
                {/* </Link> */}
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export const CanceledModel = (props) => {
  return (
    <Modal isOpen={props.show} centered={true}>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelImg'>
                  {" "}
                  <h3 className='modelHeading'>{props.header}</h3>
                </div>{" "}
              </Col>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelBody mt-2'>
                  {" "}
                  <p className='modelDesp'>{props.body}</p>
                </div>{" "}
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                {" "}
                {/* <Link to='/wellcome'> */}
                <Link to={props.link}>
                  <Button onClick={props.onClick} className='w-100  modelBtn2'>
                    Back to home
                  </Button>
                </Link>
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export const CancelModel = (props) => {
  return (
    <Modal isOpen={props.show} centered={true}>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelImg'>
                  {" "}
                  <h3 className='modelHeading'>{props.header}</h3>
                </div>{" "}
              </Col>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelBody mt-2'>
                  {" "}
                  <p className='modelDesp'>{props.body}</p>
                </div>{" "}
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                <Button
                  className='w-100  modelBtn1'
                  onClick={() => props.setModelShow2(false)}
                >
                  No
                </Button>
              </Col>
              <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                {" "}
                {/* <Link to='/wellcome'> */}
                {/* <Link to={props.link}> */}
                {props?.cancelLoadingBtn ? (
                  <Button className='w-100  modelBtn2'>
                    <Spinner></Spinner>
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      props.cancelApiFunc();
                      // props.setModelShow2(false);
                      // props.setModelShow1(true);
                    }}
                    className='w-100  modelBtn2'
                  >
                    Yes
                  </Button>
                )}
                {/* </Link> */}
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export const PlanRequestModel = (props) => {
  return (
    <Modal isOpen={props.show} centered={true}>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelImg'>
                  {" "}
                  <h3 className='modelHeading'>{props.header}</h3>
                </div>{" "}
              </Col>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelBody mt-2'>
                  {" "}
                  <p className='modelDesp'>{props.body}</p>
                </div>{" "}
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                <Button
                  className='w-100  modelBtn1'
                  onClick={() => props.setModelShow1(false)}
                >
                  No
                </Button>
              </Col>
              <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                {" "}
                {/* <Link to='/wellcome'> */}
                {/* <Link to={props.link}> */}
                <Button
                  onClick={() => {
                    props.cancelApiFunc();
                    // props.setModelShow2(false);
                    // props.setModelShow1(true);
                  }}
                  className='w-100  modelBtn2'
                >
                  Yes
                </Button>
                {/* </Link> */}
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export const DelAccountModel = (props) => {
  return (
    <Modal isOpen={props.show} centered={true}>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelImg'>
                  {" "}
                  <h3 className='modelHeading'>
                    <b>{props.header}</b>
                  </h3>
                </div>{" "}
              </Col>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelBody mt-2'>
                  {" "}
                  <p className=''>
                    {
                      <>
                        <p className='medelDelAccount mt-4'>
                          Are you sure you want to submit request for
                          permanently deleting your account and data?
                        </p>
                        <br />
                        <p className='medelDelAccount'>
                          You will not be able to recover the account or data
                          once it has been deleted.
                        </p>
                      </>
                    }
                  </p>
                </div>{" "}
              </Col>
            </Row>

            <Row className='mt-3'>
              <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                <Button
                  className='w-100  modelBtn1'
                  onClick={() => props.setModelShow1(false)}
                >
                  No
                </Button>
              </Col>
              <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                {" "}
                {/* <Link to='/wellcome'> */}
                {/* <Link to={props.link}> */}
                {props.loader ? (
                  <Button className='w-100  modelBtn2'>
                    <Spinner />
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      props.cancelApiFunc();
                      // props.setModelShow2(false);
                      // props.setModelShow1(true);
                    }}
                    className='w-100  modelBtn2'
                  >
                    Yes, Log Out
                  </Button>
                )}
                {/* </Link> */}
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export const ConfirmIdentityModel = (props) => {
  return (
    <Modal isOpen={props.show} centered={true}>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelImg'>
                  {" "}
                  <h3 className='modelHeading'>
                    <b>{props.header}</b>
                  </h3>
                </div>{" "}
              </Col>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelBody mt-2'>
                  {" "}
                  <p className='medelDelAccount mt-4'>
                    Enter your password to confirm your identity & proceeding
                    with the permanent account & data deletion.
                  </p>
                </div>{" "}
              </Col>
            </Row>

            <Row className=' my-3'>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <Input
                  type='password'
                  value={props.passwordText}
                  onChange={(e) => {
                    props.setPasswordText(e?.target?.value);
                  }}
                />
              </Col>
            </Row>

            <Row className='mt-3'>
              <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                <Button
                  className='w-100  modelBtn1'
                  onClick={() => props.setModelShow1(false)}
                >
                  Cancel
                </Button>
              </Col>
              <Col md={6} xl={6} lg={6} sm={6} xs={6}>
                {" "}
                {/* <Link to='/wellcome'> */}
                {/* <Link to={props.link}> */}
                <Button
                  onClick={() => {
                    props.cancelApiFunc();
                    // props.setModelShow2(false);
                    // props.setModelShow1(true);
                  }}
                  className='w-100  modelBtn2'
                >
                  Confirm
                </Button>
                {/* </Link> */}
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export const HelpDeskModel = (props) => {
  return (
    <Modal isOpen={props.show} centered={true}>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className=' d-flex justify-content-between'>
                  {" "}
                  <div className='d-flex'>
                    <h3 className='helpDeskModel'>{props.header}</h3>
                    {props.resolved === true ? (
                      <span className='d-flex justify-content-start helpDeskModelResolved px-2 mx-2'>
                        Resolved
                      </span>
                    ) : (
                      <span className='helpDeskModelInreview px-2 mx-2'>
                        In-review
                      </span>
                    )}
                  </div>
                  <span className='' onClick={() => props.setModelShow1(false)}>
                    <img src={CancelLogo} alt='cross' />
                  </span>
                </div>{" "}
              </Col>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelBody mt-2'>
                  {" "}
                  <p className='helpDeskModelDate'>
                    {moment(props.date).format("D-MM-yyyy")}
                  </p>
                  <p className='helpDeskModelbody'>{props.body}</p>
                  <p className='helpDeskModelattachments'>Attachment</p>
                  <div className='helpDeskModelphoto'>
                    <img
                      className='helpDeskModelphoto'
                      src={`${process.env.REACT_APP_IMG_SOURCE}${props.img}`}
                      alt='logo'
                    />
                  </div>
                </div>{" "}
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export const CreatedDeskModel = (props) => {
  return (
    <Modal isOpen={props.show} centered={true}>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelImg'>
                  {" "}
                  <h3 className='modelHeading'>{props.header}</h3>
                </div>{" "}
              </Col>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelBody mt-2'>
                  {" "}
                  <p className='modelDesp'>{props.body}</p>
                </div>{" "}
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                {" "}
                {/* <Link to='/wellcome'> */}
                <Link to={props.link}>
                  <Button
                    // onClick={() => {
                    //   props.setModelShow1(false);
                    // }}
                    className='w-100  modelBtn2'
                  >
                    Back to home
                  </Button>
                </Link>
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};
