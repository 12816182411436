import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useSumaryMutation } from "../../../../../rc-query/plansSummaryHook";
import planSummary from "../../../../../services/PlansSummaryApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

const UseSummary = () => {
  const [modelShow1, setModelShow1] = useState(false);
  const [wholePageLoading, setWholePageLoading] = useState();
  const summaryMutation = useSumaryMutation();
  const Navigate = useNavigate();
  const [privateTerrifiedIds, setPrivateTerrifiedIds] = useState();
  const [dedicTerrifiedIds, setdedicTerrifiedIds] = useState();
  const { state } = useLocation();
  const {
    selectedArr,
    selectedArrAdministrator,
    startDate,
    plansPayment,
    hybridPlanData,
    selectedResourceId,
    selectedResourceName,
    selectedResourceCapacity,
    resourceId,
    startDateState,
    monthNumbers,
    selectedOfficeAllData,
    onlyTeam,
    selectedArrForDivision,
    unselectedArrForDivision,
  } = state;

  //Hybrid dedicated UseEffect
  useEffect(() => {
    const hybridPrivateIds =
      hybridPlanData[0]?.ResourcePlan[
        String(process.env.REACT_APP_PRIVATE_OFFICE)
      ]?.resources[0];
    setPrivateTerrifiedIds({
      TeamLeadMonthlyPlan: hybridPrivateIds?.TeamLeadMonthlyPlan?.id,
      TeamLeadYearlyPlan: hybridPrivateIds?.TeamLeadYearlyPlan?.id,
      TeamMemberMonthlyPlan: hybridPrivateIds?.TeamMemberMonthlyPlan?.id,
      TeamMemberYearlyPlan: hybridPrivateIds?.TeamMemberYearlyPlan?.id,
    });
  }, [hybridPlanData?.ResourcePlan]);

  //Hybrid private Office UseEffect
  useEffect(() => {
    const hybridDedicatedIds =
      hybridPlanData[0]?.ResourcePlan[
        String(process.env.REACT_APP_DEDICATED_OFFICE)
      ]?.resources;
    setdedicTerrifiedIds({
      TeamLeadMonthlyPlan: hybridDedicatedIds?.TeamLeadMonthlyPlan?.id,
      TeamLeadYearlyPlan: hybridDedicatedIds?.TeamLeadYearlyPlan?.id,
      TeamMemberMonthlyPlan: hybridDedicatedIds?.TeamMemberMonthlyPlan?.id,
      TeamMemberYearlyPlan: hybridDedicatedIds?.TeamMemberYearlyPlan?.id,
    });
  }, [hybridPlanData?.ResourcePlan]);

  //*********HybridPrivateleadSummaryData********* */

  const { data: leadPrivateSummaryData, isLoading: leadSumaryLoading } =
    useQuery(
      ["leadSummaryData", privateTerrifiedIds],
      () => planSummary.leadSumary(privateTerrifiedIds, plansPayment),
      {
        retry: 0,
      }
    );

  const leadPrivateSummaryDataa = leadPrivateSummaryData?.data?.data;

  //*********HybridPrivatemembershipSumaryData********* */

  const {
    data: membershipPrivateSumaryData,
    isLoading: membershipSumaryLoading,
  } = useQuery(
    ["teamMemberSummaryData", privateTerrifiedIds],
    () => planSummary.membershipSumary(privateTerrifiedIds, plansPayment),
    {
      retry: 0,
    }
  );

  const membershipPrivateSumaryDataa = membershipPrivateSumaryData?.data?.data;

  //*********HybridDedicatedleadSummaryData********* */

  const {
    data: leadDedicatedSummaryData,
    isLoading: leadDedicatedSumaryLoading,
  } = useQuery(
    ["leadSummaryData", dedicTerrifiedIds],
    () => planSummary.leadSumary(dedicTerrifiedIds, plansPayment),
    {
      retry: 0,
    }
  );

  const leaddedicatedSummaryDataa = leadDedicatedSummaryData?.data?.data;

  //*********HybridDedicatedmembershipSumaryData********* */

  const {
    data: membershipdedicatedSumaryData,
    isLoading: membershipdedicatedSumaryLoading,
  } = useQuery(
    ["teamMemberdedicatedSummaryData", dedicTerrifiedIds],
    () => planSummary.membershipSumary(dedicTerrifiedIds, plansPayment),
    {
      retry: 0,
    }
  );

  const membershipdedicatedSumaryDataa =
    membershipdedicatedSumaryData?.data?.data;
  const plansSummaryFunc = () => {
    setModelShow1(false);
    setWholePageLoading("true");
    selectedArr.push(selectedArrAdministrator);

    summaryMutation
      .mutateAsync({
        privateOfficePrice:membershipPrivateSumaryDataa?.Price +
        leadPrivateSummaryDataa?.Price,
        dedicatedDeskPrice:membershipdedicatedSumaryDataa?.Price +
        leaddedicatedSummaryDataa?.Price,
        name: selectedArrAdministrator?.fullName
          ? selectedArrAdministrator?.fullName
          : "",
        teamId: onlyTeam?.Id,

        planDuration: plansPayment ? plansPayment : "",
        planType: hybridPlanData[0]?.ResourceName
          ? hybridPlanData[0]?.ResourceName
          : "",
        date: startDate
          ? moment(startDate, "MMMM, YYYY")
              .set({
                hour: 12,
                minute: 27,
                second: 54,
                millisecond: 530,
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : "",
        companyName: onlyTeam?.Name,
        deskCapacity: selectedResourceCapacity,
        RenewalMonths: monthNumbers,
        startDate: startDate
          ? moment(startDate, "MMMM, YYYY")
              .set({
                hour: 12,
                minute: 27,
                second: 54,
                millisecond: 530,
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : "",
        Desk: selectedResourceId ? selectedResourceId : 0,
        resourceId: resourceId ? resourceId : 0,
        price:
          membershipdedicatedSumaryDataa?.SignUpFee +
          leaddedicatedSummaryDataa?.SignUpFee +
          membershipPrivateSumaryDataa?.SignUpFee +
          leadPrivateSummaryDataa?.SignUpFee +
          membershipdedicatedSumaryDataa?.Price +
          leaddedicatedSummaryDataa?.Price +
          membershipPrivateSumaryDataa?.Price +
          leadPrivateSummaryDataa?.Price,

        DedicatedMemberTarrifId:
          plansPayment === "monthly"
            ? dedicTerrifiedIds?.TeamMemberMonthlyPlan
            : dedicTerrifiedIds?.TeamMemberYearlyPlan,

        DedicatedLeadTarrifId:
          plansPayment === "monthly"
            ? dedicTerrifiedIds?.TeamLeadMonthlyPlan
            : dedicTerrifiedIds?.TeamLeadYearlyPlan,

        MemberTarrifId:
          plansPayment === "monthly"
            ? privateTerrifiedIds?.TeamMemberMonthlyPlan
            : privateTerrifiedIds?.TeamMemberYearlyPlan,
        LeadTarrifId:
          plansPayment === "monthly"
            ? privateTerrifiedIds?.TeamLeadMonthlyPlan
            : privateTerrifiedIds?.TeamLeadYearlyPlan,

        MemberCancellationLimitDays:
          membershipPrivateSumaryDataa?.CancellationLimitDays,
        LeadCancellationLimitDays:
          leadPrivateSummaryDataa?.CancellationLimitDays,

        teamMembers: selectedArr ? selectedArr : "",
        isMultiple: hybridPlanData[0]?.isMultiple,

        dedicatedMembers: unselectedArrForDivision,
        privateMembers: [...selectedArrForDivision, selectedArrAdministrator],

        privateResourceTypeId: hybridPlanData[0]?.PrivateOfficeResourceId,
        dedicatedResourceTypeId: hybridPlanData[0]?.DDResourceId,
      })
      .then((data) => {
        setWholePageLoading("false");
        if (data?.status === 200 && data?.data?.teamId) {
          Navigate("/yearly-hybrid-request-sent");
          localStorage.setItem("progressBarLength", 0);
          localStorage.setItem("progressBarStart", false);
          localStorage.setItem("hybridBar", false);
        }
      })
      .catch((err) => {
        setWholePageLoading("false");

        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return {
    modelShow1,
    setModelShow1,
    wholePageLoading,
    setWholePageLoading,
    plansSummaryFunc,
    selectedResourceName,
    selectedResourceCapacity,
    // membershipSumaryDataa,
    // leadSummaryDataa,
    startDate,
    selectedArrAdministrator,
    selectedArr,
    // plansSummaryFunc,
    membershipdedicatedSumaryDataa,
    leaddedicatedSummaryDataa,
    membershipPrivateSumaryDataa,
    leadPrivateSummaryDataa,
    startDateState,
    selectedArrForDivision,
    unselectedArrForDivision,
    leadSumaryLoading,
  };
};

export default UseSummary;
