import { useQuery } from "react-query";
import BookMeetingRoom from "../../../../services/bookMeetingRoomApi";
import { useEffect, useState } from "react";
import filterIcon from "./../../../.././images/filterIcon.svg";
import { components } from "react-select";
import { addDays } from "date-fns";

const UseViewAllMeetings = () => {
  const [stateOfDropDownMeeting, setStateOfDropDownMeeting] = useState({
    label: "All",
    value: "all",
  });
  const [rangeState, setRangeState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [filterState, setFilterState] = useState({
    label: "All",
    value: "all",
  });
  const [showModel, setShowModel] = useState(false);

  // ********* coworker start ***********
  const { data: coworkerListData } = useQuery(
    ["coworkerData"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ********* coworker end ***********
  const TeamIds = coworkerListData?.TeamIds;
  const userId = coworkerListData?.Id;

  // ******* booked customer booking room data ***********

  const {
    data: customerBookingroomsDataa,
    isLoading: customerBookingroomsLoading,
  } = useQuery(
    [
      "customerbookingroomsData2",
      userId,
      stateOfDropDownMeeting?.value,
      filterState?.value,
      showModel,
    ],
    () =>
      BookMeetingRoom.getCustomerMeetingRoomwithFilter(
        userId,
        stateOfDropDownMeeting,
        filterState,
        TeamIds,
        rangeState
      ),
    {
      retry: 0,
    }
  );

  const customerBookedData = customerBookingroomsDataa?.data?.data;

  const TextExtractor = (description) => {
    if (description) {
      const match = description.match(/<span[^>]*>([^<]*)<\/span>/);
      const text = match && match[1];

      return text;
    }
  };

  // dropdown filter functions are bellow

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={filterIcon} alt='Filter Icon' />
      </components.DropdownIndicator>
    );
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,

      border: "1px solid #bdc7ff",
      borderRadius: "122.449px",
      background: "#bdc7ff",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#333333"
        : state.isFocused
        ? "#f0f0f0"
        : "transparent",
      color: state.isSelected ? "#ffffff" : "#000000",
    }),

    // under text style
    singleValue: (provided) => ({
      ...provided,
      color: " #0129FA", // Change the color as per your requirement
      // textAlign: "center",
      fontFamily: "Montserrat",
      fontWeight: "500",
      fontSize: "16px",
      marginLeft: "10px",
    }),
    // Add more custom styles for other components if needed
  };

  // dropdown filter functions end

  // ***** range calender*****
  const RangeCalenderFunc = (item) => {
    setRangeState([item.selection]);
  };

  //  ***** for my meeting dropdown ****
  const customStyles1 = {
    control: (provided) => ({
      ...provided,
      border: "none",
      background: "none",
      boxShadow: "none",
      cursor: "pointer",
      padding: "0",
      margin: "0",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000000",
      // textAlign: "center",
      fontFamily: "Montserrat",
      fontWeight: 400,
      fontSize: "22px",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#333333"
        : state.isFocused
        ? "#f0f0f0"
        : "transparent",
      color: state.isSelected ? "#ffffff" : "#000000",
    }),

    // ****** this indecator is to hide the saprator of value and logo
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Hide the indicator separator
    }),
  };

  useEffect(() => {
    {
      localStorage.setItem("filterForReControl", stateOfDropDownMeeting?.value);
    }
  }, [stateOfDropDownMeeting]);

  return {
    stateOfDropDownMeeting,
    setStateOfDropDownMeeting,
    customStyles1,
    customerBookedData,
    customerBookingroomsLoading,
    filterState,
    setFilterState,
    DropdownIndicator,
    customStyles,
    TextExtractor,
    showModel,
    setShowModel,
    RangeCalenderFunc,
    rangeState,
  };
};

export default UseViewAllMeetings;
