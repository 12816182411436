import React from "react";
import { Button, Col, Spinner, Row, Card, Label } from "reactstrap";
import { Image, Breathing } from "react-shimmer";
import PrivateOfficeImg from "../../../../../images/private_office.svg";
import moment from "moment";
import SummaryDuration from "../../../../../images/summary_duration.svg";
import SummaryPayment from "../../../../../images/summary_payment.svg";
import SummaryTeam from "../../../../../images/summary_team.svg";
import UsersImg from "../../../../../images/users.svg";
import UserImg from "../../../../../images/user.svg";
import UseSummary from "./useSummary";
import { PlanRequestModel } from "../../../../../components/molicules/userModel";
import "./css/styles.css";
import UseValueValue from "../../../../vatValue/useValueValue";

const PrivateSummary = () => {
  const { vatValue } = UseValueValue();
  const {
    modelShow1,
    setModelShow1,
    membershipdedicatedSumaryDataa,
    leaddedicatedSummaryDataa,
    membershipPrivateSumaryDataa,
    leadPrivateSummaryDataa,
    selectedResourceName,
    selectedResourceCapacity,
    startDate,
    selectedArrAdministrator,
    plansSummaryFunc,
    startDateState,
    selectedArrForDivision,
    unselectedArrForDivision,
    leadSumaryLoading,
    wholePageLoading,
  } = UseSummary();

  return (
    <>
      {wholePageLoading === "true" ? (
        <div className='d-flex justify-content-center whole_page_loader h-100'>
          <Spinner className='justify-item-center mx-1 ' />
        </div>
      ) : (
        <Row className='p-4'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <p className='pos_h1'> Summary</p>

            <Row>
              <Col className='mb-4' xl={5} lg={5} md={5} sm={12} xs={12}>
                <Card className='p-3  db_card  w-100 h-100 '>
                  <Image
                    src={PrivateOfficeImg}
                    fallback={<Breathing width='100' />}
                    className='w-100 h-100'
                  />
                  <span className='d-flex pos_cardusersicon mt-3 '>
                    <Label className='pos_h2  '>{selectedResourceName}</Label>
                    <span className=''>
                      <img className='mx-2 ' alt='img' src={UsersImg} />
                      <Label className='pos_total_users '>
                        {" "}
                        {selectedResourceCapacity}
                      </Label>
                    </span>
                  </span>
                </Card>
              </Col>
              <Col className='mb-4' xl={5} lg={5} md={5} sm={12} xs={12}>
                <Card className='p-2 pt-4  db_card  w-100 h-100 '>
                  <Row>
                    <Col className='mx-3' xl={1} lg={1} md={1} sm={1} xs={1}>
                      <img
                        src={SummaryDuration}
                        alt='logo'
                        className='sum_img'
                      />
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={12} xs={12}>
                      <p className='pos_h1'>Duration</p>
                      <p className='pos_h3'>
                        <b>Expected Dates</b>
                      </p>
                      <span className='pos_display_inline1'>
                        <Label className='pos_h2  '>
                          {" "}
                          {moment(startDate)?.format("MMMM, YYYY")}
                        </Label>

                        <Label className='pos_h2 mx-4 '>{startDateState}</Label>
                      </span>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xl={2} lg={2} md={2} sm={12} xs={12}></Col>
            </Row>

            <Row>
              <Col className='mb-4' xl={5} lg={5} md={5} sm={12} xs={12}>
                <Card className='p-2 pt-4 db_card  w-100 h-100 '>
                  {leadSumaryLoading ? (
                    <div className='d-flex justify-content-center align-items-center my-auto'>
                      <Spinner />
                    </div>
                  ) : (
                    <Row>
                      <Col className='mx-3' xl={1} lg={1} md={1} sm={1} xs={1}>
                        <img
                          src={SummaryPayment}
                          alt='logo'
                          // className='mt-2'
                          className='sum_img'
                        />
                      </Col>
                      <Col className='' xl={10} lg={10} md={10} sm={10} xs={10}>
                        <p className='pos_h1'>Payment</p>
                        <span className='pos_display_inline'>
                          <Label className='pos_h2 mt-2'>Plan</Label>
                          <Label className='pos_h4 mt-2'>Yearly</Label>
                        </span>

                        <span className='pos_display_inline'>
                          <Label className=' pos_h2'>Private Office</Label>
                          <Label className='pos_h4'>
                            SAR{" "}
                            {
                            // membershipPrivateSumaryDataa?.Price +
                              leadPrivateSummaryDataa?.Price}
                          </Label>
                        </span>
                        <span className='pos_display_inline'>
                          <Label className=' pos_h2'>Dedicated desk(1)</Label>
                          <Label className='pos_h4'>
                            SAR{" "}
                            {membershipdedicatedSumaryDataa?.Price
                             +
                              leaddedicatedSummaryDataa?.Price
                              }
                          </Label>
                        </span>
                        <hr />
                        <span className='pos_display_inline'>
                          <Label className='pos_h2 '>Plan fee</Label>
                          <Label className='pos_h4'>
                            {membershipdedicatedSumaryDataa?.SignUpFee +
                              leaddedicatedSummaryDataa?.SignUpFee 
                              +
                              membershipPrivateSumaryDataa?.SignUpFee +
                              leadPrivateSummaryDataa?.SignUpFee
                              }
                          </Label>
                        </span>

                        <hr />
                        <span className='pos_display_inline'>
                          <Label className='pos_h2 '>Vat</Label>
                          <Label className='pos_h4'>
                            {vatValue?.setting?.isTaxEnable === "true"
                              ? Number(
                                  (Number(vatValue?.setting?.taxRate) / 100) *
                                    (membershipdedicatedSumaryDataa?.Price +
                                      leaddedicatedSummaryDataa?.Price +
                                      // membershipPrivateSumaryDataa?.Price +
                                      leadPrivateSummaryDataa?.Price)
                                ).toFixed(2)
                              : 0}
                          </Label>
                        </span>

                        <span className='pos_display_inline'>
                          <Label className='pos_h4'>Total payable</Label>
                          <Label className='pos_h5'>
                            SAR{" "}
                            {membershipdedicatedSumaryDataa?.SignUpFee +
                              leaddedicatedSummaryDataa?.SignUpFee +
                              membershipPrivateSumaryDataa?.SignUpFee +
                              leadPrivateSummaryDataa?.SignUpFee +
                              membershipdedicatedSumaryDataa?.Price +
                              leaddedicatedSummaryDataa?.Price +
                              // membershipPrivateSumaryDataa?.Price +
                              leadPrivateSummaryDataa?.Price +
                              Number(
                                vatValue?.setting?.isTaxEnable === "true"
                                  ? Number(
                                      (Number(vatValue?.setting?.taxRate) /
                                        100) *
                                        (membershipdedicatedSumaryDataa?.Price 
                                          +
                                          leaddedicatedSummaryDataa?.Price +
                                          // membershipPrivateSumaryDataa?.Price +
                                          leadPrivateSummaryDataa?.Price)
                                    ).toFixed(2)
                                  : 0
                              )}
                          </Label>
                        </span>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>
              <Col className='mb-4' xl={5} lg={5} md={5} sm={12} xs={12}>
                <Card className='p-2  pt-4 db_card  w-100 h-100 '>
                  <Row>
                    <Col className='mx-3' xl={1} lg={1} md={1} sm={1} xs={1}>
                      <img src={SummaryTeam} alt='logo' className='sum_img' />
                    </Col>
                    <Col
                      className='mb-4'
                      xl={10}
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                    >
                      <p className='pos_h1'>Team</p>

                      <h5 className=' pos_h4 '>
                        {" "}
                        {selectedArrAdministrator?.fullName}
                      </h5>

                      {selectedArrAdministrator?.isAdministrator ? (
                        <Label className='pos_administrator'>
                          Administrator
                        </Label>
                      ) : (
                        ""
                      )}
                      {selectedArrAdministrator?.isPayingMember ? (
                        <Label className='pos_paying_member'>
                          Paying member
                        </Label>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      {selectedArrForDivision?.map((e, i) => {
                        return (
                          <div>
                            <p className='d-flex px-2' key={i + 3892}>
                              <img className='mx-2' alt='img' src={UserImg} />
                              <span className=' pos_h4  flex-grow-1'>
                                {e?.fullName}
                              </span>{" "}
                              <div className='d-flex justify-content-end'>
                                <label className='pos_pri_l1 px-2 py-1 '>
                                  Private Office
                                </label>
                              </div>
                            </p>
                            <span className='d-flex justify-content-end'>
                              {e?.isPayingMember ? (
                                <Label className='pos_paying_member'>
                                  Paying member
                                </Label>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        );
                      })}

                      {unselectedArrForDivision?.map((e, i) => {
                        return (
                          <div className=''>
                            <p className='d-flex px-2' key={i + 3892}>
                              <img className='mx-2' alt='img' src={UserImg} />
                              <span className=' pos_h4  flex-grow-1'>
                                {e?.fullName}
                              </span>
                              <div className='d-flex justify-content-end'>
                                <label className='pos_ded_l1 px-2 py-1 '>
                                  Dedicated Desk
                                </label>
                              </div>
                            </p>
                            <span className='d-flex justify-content-end'>
                              {e?.isPayingMember ? (
                                <Label className='pos_paying_member'>
                                  Paying member
                                </Label>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        );
                      })}
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xl={2} lg={2} md={2} sm={12} xs={12}></Col>
            </Row>
            <Row>
              <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <Button
                  color='dark'
                  className='mt-5 loginBtn w-100'
                  onClick={() => setModelShow1(true)}
                >
                  Confirm
                </Button>
              </Col>
            </Row>
          </Col>

          <PlanRequestModel
            show={modelShow1}
            cancelApiFunc={plansSummaryFunc}
            setModelShow1={setModelShow1}
            header={`Request plan`}
            body={`Are you sure you want to request for this plan?`}
            // link={`/meeting-room-app/meeting-room`}
          />
        </Row>
      )}
    </>
  );
};

export default PrivateSummary;
