import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDelAccountMutation } from "../../../rc-query/loginHook";
import bookMeetingRoomApi from "../../../services/bookMeetingRoomApi";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

const UseDelAndLogout = (props) => {
  const [logoutLoader, setLogoutLoader] = useState(false);
  const Navigate = useNavigate();
  const [modelShow1, setModelShow1] = useState(false);
  const delAccountMutation = useDelAccountMutation(props);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: coworkerListData } = useQuery(
    ["coworkerDateda"],
    () => bookMeetingRoomApi.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );
  // ********* coworker end ***********
  const TeamId = coworkerListData?.TeamIds;
  const userId = coworkerListData?.Id;

  const delAndLogoutFunc = () => {
    setLogoutLoader(true);
    delAccountMutation
      .mutateAsync({ TeamId: TeamId, userId: userId })
      .then((data) => {
        setLogoutLoader(false);
        if (data?.data?.statusCode === 200) {
          Navigate("/login");
        } else {
          toast.error(data?.data?.error?.error, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        setLogoutLoader(false);

        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    // setModelShow1(false);
  };
  return {
    setLogoutLoader,
    logoutLoader,
    modelShow1,
    setModelShow1,
    delAndLogoutFunc,
  };
};

export default UseDelAndLogout;
