import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const useWhatHappeningInfo = () => {
  const Navigate = useNavigate();

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // *Calculate the estimated reading time of an article
  const extimateTimeFunc = (description) => {
    let value;
    if (description) {
      const match = description.match(/<span[^>]*>([^<]*)<\/span>/);
      value = match && match[1];
    }

    if (value) {
      const text = value;
      const wpm = 183;
      const words = text.trim().split(/\s+/).length;
      const time = Math.ceil(words / wpm);
      return `${time} min read`;
    } else {
      return `0 min read`;
    }
  };
  return { extimateTimeFunc, Navigate };
};

export default useWhatHappeningInfo;
