import React, { useState } from "react";
import { Button, Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./css/styles.css";

const RangeCalenderModel = (props) => {
  const navigate = useNavigate();

  const handleRequestModelFunc = () => {
    props.removeModelFunc(props.ID);
  };
  return (
    <Modal
      isOpen={props.show}
      centered={true}
      className='fintechModel'
      size='xl'
    >
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={11} xl={11} lg={11} sm={11} xs={11}></Col>
              <Col md={1} xl={1} lg={1} sm={1} xs={1}>
                <Button
                  color='warning'
                  onClick={() => props.setShowModel(false)}
                >
                  Done
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div>
                  <DateRangePicker
                    // onChange={(item) => setState([item.selection])}
                    onChange={(item) => props?.RangeCalenderFunc(item)}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={props?.rangeState}
                    direction='horizontal'
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export default RangeCalenderModel;
