import React from "react";
import { Button, Col, Row, Card, Label, Input, Spinner } from "reactstrap";
import PrivateOfficeImg from "./../../../.././images/private_office.svg";
import moment from "moment";
import SummaryDuration from "./../../../.././images/summary_duration.svg";
import SummaryPayment from "./../../../.././images/summary_payment.svg";
import UseScheduleAvailability from "./useScheduleAvailability";
import "./css/style.css";
import CloseButtonComp from "../../../../components/molicules/closeButton";
import UseValueValue from "./../../../vatValue/useValueValue";
import calculatePercentage from "../../../../utills/calculatePercentage";

const ScheduleAvailability = () => {
  const { vatValue } = UseValueValue();
  const {
    discountedAmountDP,
    daypassVat,
    bookingDayPassData,
    reschduleMode,
    textDesp,
    scheduleDate,
    coworkerListData,
    pricingData,
    proceedFunc,
    wholePageLoading,
    daypasssubTotal
  } = UseScheduleAvailability();

  const TextExtractor = (description) => {
    if (bookingDayPassData?.Description) {
      const match = description.match(/<span[^>]*>([^<]*)<\/span>/);
      const text = match && match[1];

      return text;
    }
  };

  // const [scheduleDate, setScheduleDate] = useState();
  const calendarStyle = {
    minWidth: "100%", // Set the minimum width of the calendar
    width: "auto", // Allow the calendar to expand to fit its contents
  };
  return (
    <>
      {wholePageLoading === "true" ? (
        <div className='d-flex justify-content-center whole_page_loader h-100'>
          <Spinner className='justify-item-center mx-1 ' />
        </div>
      ) : (
        <div className='p-4'>
          <Row>
            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
              <p className='smr_h1 '>
                <b>Book a Day Pass</b>
              </p>
            </Col>
            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
              <p className='smr_h1 '>
                <b className='sm_scr_shw'>Check availability</b>
              </p>
            </Col>

            <Col xl={1} lg={1} md={1} sm={10} xs={10}>
              {" "}
            </Col>
            <Col xl={1} lg={1} md={1} sm={1} xs={1}>
              <div className='d-flex'>{<CloseButtonComp />}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className='mb-5'>
                <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                  <Card className='p-2 s_o_cc  w-100  mb-4'>
                    {/* <img src={PrivateOfficeImg} alt='img' className='w-100 h-100' /> */}
                    <img
                      src={PrivateOfficeImg}
                      alt='img'
                      className='w-100 h-100'
                    />
                    <span className='d-flex pos_cardusersicon mt-3 '>
                      <Label className='c-avail_h1  '>
                        {/* {selectedBookingRoomData?.Title} */}
                        Day Pass
                      </Label>
                    </span>
                    <Label className='m_rB_l3 mt-2'>{textDesp}</Label>
                  </Card>
                </Col>
                <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      <p className='c-avail_h2 '>
                        <b>Selected Date</b>
                      </p>

                      <Label className='smr_h2'>
                        <Input
                          type='date'
                          className='smr_dateTime'
                          style={{ minHeight: "62px" }}
                          calendarStyle={calendarStyle}
                          innerWidth='500px'
                          disabled={true}
                          value={scheduleDate}
                          // onChange={(e) => {
                          //   setScheduleDate(e?.target?.value);
                          // }}
                        />
                      </Label>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                      <Label className='c-avail_h1  mt-2'>Day passes</Label>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                      <span className='c-avail_h3 px-3 py-1'>
                        <Label className='c-avail_h4'>Available</Label>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Card className='p-2 s_o_cc  w-100  mt-3'>
                        {/* <img src={PrivateOfficeImg} alt='img' className='w-100 h-100' /> */}
                        <img
                          src={PrivateOfficeImg}
                          alt='img'
                          className='w-100 h-100'
                        />
                        <span className='d-flex pos_cardusersicon mt-3 '>
                          <Label className='c-avail_h1  '>
                            {/* {selectedBookingRoomData?.Title} */}
                            Day Pass
                          </Label>
                        </span>
                      </Card>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Card className='p-2 pt-4  db_card  w-100 h-100 '>
                        <Row>
                          <Col
                            className='mx-3'
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                          >
                            <img
                              src={SummaryDuration}
                              alt='logo'
                              className='sum_img'
                            />
                          </Col>
                          <Col
                            className='c-avail_Dates px-4 pt-2'
                            xl={10}
                            lg={10}
                            md={10}
                            sm={12}
                            xs={12}
                          >
                            <p className='c-avail_h9'>Your schedule</p>
                            <p className='c-avail_h10'>
                              {/* Muhammad Khalid nayaan */}
                              {coworkerListData?.UserFullName}
                            </p>
                          </Col>
                        </Row>
                        <Row className='mt-3 px-3'>
                          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <span className=' c-avail_date_space'>
                              <Label className='c-avail_h7  '>Booked on</Label>
                              <Label className='c-avail_h7  '>Booked for</Label>
                            </span>
                            <span className=' c-avail_date_space'>
                              <Label className='c-avail_Dates  '>
                                {/* 16th June, 2022 */}
                                {moment().format("DD/MM/YYYY")}
                              </Label>
                              <Label className='c-avail_Dates  '>
                                {/* 16th June, 2022  */}
                                {moment(scheduleDate).format("DD/MM/YYYY")}
                              </Label>
                            </span>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Card className='p-2 pt-4 db_card  w-100 h-100 '>
                        <Row>
                          <Col
                            className='mx-3'
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                          >
                            <img
                              src={SummaryPayment}
                              alt='logo'
                              // className='mt-2'
                              className='sum_img'
                            />
                          </Col>
                          <Col
                            className=' px-4 pt-2'
                            xl={10}
                            lg={10}
                            md={10}
                            sm={12}
                            xs={12}
                          >
                            <Label className='c-avail_h9'>Payment</Label>
                            <Label className='c-avail_h5'>
                              Please submit payment receipt for confirmation
                            </Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className='px-4'
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <span className='pos_display_inline mt-3'>
                              <Label className='c-avail_h7 mt-2'>
                                Day pass
                              </Label>
                              <Label className='c-avail_h6 mt-2'>
                               
                                  {daypasssubTotal}
                              </Label>
                            </span>
                            <hr />
                            <span className='pos_display_inline'>
                              <Label className='c-avail_h7 mt-2'>
                                Subtotal
                              </Label>
                              <Label className='c-avail_h6 mt-2'>
                                {" "}
                           
                                  {daypasssubTotal}
                              </Label>
                            </span>

                          
                            <span className='pos_display_inline'>
                              <Label className=' c-avail_h7'>Discount({pricingData?.Price?.DiscountCode?pricingData?.Price?.DiscountCode:"-"})</Label>
                              <Label className='c-avail_h6'>
                                SAR{" "}
                                {
                                discountedAmountDP}
                              </Label>
                            </span>

                            <span className='pos_display_inline'>
                              <Label className=' c-avail_h7'>VAT({vatValue?.setting?.taxRate}%)</Label>
                              <Label className='c-avail_h6'>
                                SAR{" "}
                                
                                  {daypassVat}
                              </Label>
                            </span>
                            <hr />

                            <span className='pos_display_inline'>
                              <Label className='c-avail_h6'>
                                Total payable
                              </Label>
                              <Label className='c-avail_h8'>
                                SAR{" "}
                                {
                                reschduleMode === "true"
                                  ? 

                                  (
                                    Number( bookingDayPassData?.Price) +Number(daypassVat)
                                  ).toFixed(2)
                                  :     
                                    (
                                      Number(pricingData?.Price?.EstimatedCost) +Number(daypassVat)
                                    ).toFixed(2)
                                    }
                              </Label>
                            </span>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>{" "}
                  <Button
                    color='dark'
                    style={{ minHeight: "62px" }}
                    className='w-100 my-4'
                    onClick={() => {
                      proceedFunc();
                    }}
                  >
                    Proceed
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ScheduleAvailability;
