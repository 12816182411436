import React from "react";
import { Row, Col, Label, Button } from "reactstrap";
import LoginImg from "./../../.././images/LoginImage.svg";
import DelAccountWarn from "./../../.././images/delAccountWarn.svg";
import { DelAccountModel } from "../../../components/molicules/userModel";
import UseDelAndLogout from "./useDelAndLogout";
import ".././css/styles.css";

const DelAndLogout = () => {
  const userType = localStorage.getItem("userTypeRedir");
  const { modelShow1, setModelShow1, delAndLogoutFunc, logoutLoader } =
    UseDelAndLogout();
  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row>
            <Col
              xl={5}
              lg={5}
              md={5}
              sm={12}
              xs={12}
              className='loginImageCol '
            >
              <img src={LoginImg} alt='img' className='invoiceDetailImg ' />
            </Col>

            <Col xl={7} lg={7} md={7} sm={12} xs={12}>
              <div className='del_warn_p1  del_warn_p3'>
                <img src={DelAccountWarn} alt='logo' class='' />

                <p className='del_warn_p4 mt-4'> Sorry to see you go! </p>
                <p className='del_warn_p5 '> What happens next?</p>
              </div>

              <div className='del_warn_p2'>
                <div className='d-flex mt-4'>
                  <li className='del_warn_p7 mx-3' />
                  <Label className='del_warn_p6 '>
                    { (userType === "daypassUser") ?
                     " You will no longer be able to use any the remaining credits, if any.":"Your team account(s) are queued for deletion."
                    }
                    
                  </Label>
                </div>
                <div className='d-flex mt-4'>
                  <li className='del_warn_p7 mx-3' />
                  <Label className='del_warn_p6 '>
                  { (userType === "daypassUser") ?
                `Your booking details may remain part of Fintech Hub Archive.`
                :  ` You will receive an email once your accounts and associated
                  data is deleted.`}
                   
                  </Label>
                </div>
                <div className='d-flex mt-4'>
                  <li className='del_warn_p7 mx-3' />
                  <Label className='del_warn_p6 '>
                 { (userType === "daypassUser") ?
                 `Your invoices for bookings and other purchases cannot be deleted because it is prohibited by state laws`
                   : `Please note, as intimated earlier, your contract and memo(s)
                    will be stored in Fintech Hub’s archive.`}
                  </Label>
                </div>

                <Button
                  color='danger w-100  mx-3 mt-4'
                  onClick={() => {
                    setModelShow1(true);
                  }}
                >
                  {" "}
                  Delete & logout
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <DelAccountModel
        show={modelShow1}
        cancelApiFunc={delAndLogoutFunc}
        setModelShow1={setModelShow1}
        header={`Delete Account`}
        loader={logoutLoader}
        // body={

        // }
        // link={`/meeting-room-app/meeting-room`}
      />
    </>
  );
};

export default DelAndLogout;
