import { Route, Routes, Navigate, HashRouter } from "react-router-dom";
import PrivateRoute from "../services/auth";
import Pages from "../routes/index";
import MeetingRoomPages from "../routes/meetingRoomPages";
import DayPassPages from "../routes/dayPassPages";
import Fintech from "./fintechHub/index";
import Login from "./Login/index";
import ChangePassword from "./changePassword";
import Explore from "./explore";
import { MeetingRoomApp } from "./meetingRoomApp";
import { DayPassApp } from "./dayPass/dayPassApp";
import ViewPassword from "./changePassword/viewPassword";
import RedirectMainPage from "./redirectMainPage";
import InactivePage from "./redirectMainPage/inactivePage";
import PendingPage from "./redirectMainPage/pendingPage";
import RequestStatusPage from "./redirectMainPage/requestStatusPage";
// import Chatpractice from "./chatPracticeFolder/chatpractice";
import RescheduleMeetingRoom from "./meetingRoomApp/meetingRoom/rescheduleMeetingRoom";
import { useEffect } from "react";
import { messaging } from "../firebase";
import { getToken } from "firebase/messaging";

function App() {
  // async function requestPermission() {
  //   const permission = await Notification.requestPermission();
  //   if (permission === "granted") {
  //     const token = getToken(messaging, {
  // vapidKey:
  //   "BMqy4RyMvTe5mM3PS6cvvABjvXuL5BhMXsdf63sBNEwpZxzheM_lROFD_Jd7wTDlqoF1th_CLVN3IPtK2IyyrSo",
  // });
  //     localStorage.setItem("fcmTokenForPushNoti", token);
  //     // generate token
  //   } else if (permission === "denied") {
  //     // alert(" you denied for the notification");
  //   }
  // }

  // useEffect(() => {
  //   // req user for notification permission
  //   requestPermission();
  // }, []);
  return (
    <>
      <HashRouter>
        <Routes>
          {/* <Route path='chat-practice' element={<Chatpractice />} /> */}
          <Route
            path='/'
            element={
              <Navigate
                replace
                to={`${
                  // localStorage.getItem("access Token") !== ""
                  //   ? "/redirect-main-page"
                  //   :

                  // "/fintech"

                  localStorage.getItem("access Token") === "" ||
                  localStorage.getItem("access Token") === undefined ||
                  localStorage.getItem("access Token") === null ||
                  !localStorage.getItem("access Token")
                    ? "/fintech"
                    : "/redirect-main-page"
                }`}
              />
            }
          />
          <Route path='/fintech' element={<Fintech />} />
          <Route path='/login' element={<Login />} />
          <Route path='/change-password' element={<ChangePassword />} />
          <Route path='/view-password' element={<ViewPassword />} />
          <Route path='/explore' element={<Explore />} />
          <Route path='/redirect-main-page' element={<RedirectMainPage />} />
          {/* <Route path='/inactive-page' element={<InactivePage />} /> */}
          {/* <Route path='/pending-page' element={<PendingPage />} /> */}
          <Route path='/request-status-page' element={<RequestStatusPage />} />
          {/* <Route
            path='/reschedule-meeting-room'
            element={<RescheduleMeetingRoom />}
          /> */}

          <Route path='' element={<PrivateRoute />} key={"1xbt"}>
            {Pages.map((page) => (
              <Route path={page?.path} element={page?.element} key={page?.id} />
            ))}
          </Route>
          <Route path='' element={<MeetingRoomApp />} key={"1xbt"}>
            {MeetingRoomPages?.map((page) => (
              <Route path={page?.path} element={page?.element} key={page?.id} />
            ))}
          </Route>
          <Route path='' element={<DayPassApp />} key={"1xbt"}>
            {DayPassPages?.map((page) => (
              <Route path={page?.path} element={page?.element} key={page?.id} />
            ))}
          </Route>
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
