import { useMutation } from "react-query";
import { PLANS_SUMMARY_MUTATION } from "./keys";
import teamCredits from "../services/teamCreditsApi";

export const useCreditsHook = (history) => {
  const response = useMutation(teamCredits.postCreditsApi, {
    mutationKey: PLANS_SUMMARY_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
