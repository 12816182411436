import React from "react";
import {useNavigate} from "react-router-dom";
import {
 Button,
 Col,
 Row,
 Card,
 Label
} from "reactstrap";
import RepeatBookingIcon from "./../../../.././images/repeatBookingIcon.svg";
import moment from "moment";
import UseRescheduleMeetingRoom from "./useRescheduleMeetingRoom";
import TodayClock from "./../../../.././images/blue_calender.svg";
import SummaryTeam from "./../../../../images/summary_team.svg";
import DurationClock from "./../../../.././images/blue_clock.svg";
import SummaryPayment from "./../../../../images/summary_payment.svg";
import UserImg from "./../../../../images/user.svg";
import UsersImg from "./../../../../images/users.svg";
import {CancelModel, CanceledModel, RescheduleModel} from "../../../../components/molicules/userModel";
import "./css/styles.css";
import CloseButtonComp from "../../../../components/molicules/closeButton";

const RescheduleMeetingRoom = () => {
 const enableRoomByFilter = localStorage.getItem("filterForReControl");
 const userRole = localStorage.getItem("userTypeRedir");

 const Navigate = useNavigate();
 const {
  dataOfSelectedObject,
  cancelApiFunc,
  modelShow,
  setModelShow,
  cancelLoadingBtn,
  modelShow1,
  setModelShow1,
  bookingroomsData,
  modelShow2,
  bookingroomsLoading,
  setModelShow2
 } = UseRescheduleMeetingRoom();

 const TextExtractor = (description) => {
  if (description) {
   const match = description.match(/<span[^>]*>([^<]*)<\/span>/);
   const text = match && match[1];

   return text;
  }
 };

 

 const hoursDur = moment.duration(moment(dataOfSelectedObject ?. ToTime).diff(moment(dataOfSelectedObject ?. FromTime), "minutes"), "minutes");

 const currentDate = new Date();

 const bookedToTime = new Date((dataOfSelectedObject ?. ToTime))


 const bookedIsPastDate = (bookedToTime < currentDate);


 
 return (
  <div>
   <div className='p-4'>
    <Row>
     <Col>
      <Row>
       <Col xl={11}
        lg={11}
        md={11}
        sm={10}
        xs={10}>
        <p className='smr_h1 '>Scheduled meetings</p>
       </Col>
       <Col xl={1}
        lg={1}
        md={1}
        sm={2}
        xs={2}>
        <div>{
         < CloseButtonComp />
        }</div>
       </Col>
      </Row>

     

      <Row className='mb-5'>
       <Col className='mb-2'
        xl={5}
        lg={5}
        md={5}
        sm={12}
        xs={12}>
        <Card className='p-2 s_o_cc  w-100  h-100'
         // onClick={() => {
         //   Navigate("/reschedule-meeting-room", {
         //     state: { dataOfSelectedObject: ele },
         //   });
         // }}
        >
         <img src={
           `https://nexudus.spaces.nexudus.com//en/publicresources/getimage/${
            dataOfSelectedObject ?. ResourceId
           }?w=565&h=210&anchor=middlecenter&cache=2023-03-16T07:23:02Z`
          }
          alt='img'
          className='w-100 h-100 imgCorners'/>
          <Col
                              xl={3}
                              lg={3}
                              md={3}
                              sm={3}
                              xs={3}
                              className={`mx-2 ${
                                dataOfSelectedObject?.status === "cancelled" ||
                                dataOfSelectedObject?.status === "deny"
                                  ? "mr_duration_Cancel"
                                  : ""
                              }

                            ${
                              dataOfSelectedObject?.status === "approved"
                                ? "mr_duration_confirmed"
                                : ""
                            }

                            ${
                              dataOfSelectedObject?.status === "pending" ||
                              dataOfSelectedObject?.status === "rescheduled"
                                ? "mr_duration_pending"
                                : ""
                            }
                            
                            
                            
                            `}
                            >
                              <span className='mr_coming_font'>
                                {dataOfSelectedObject?.status === "approved"
                                  ? "Confirmed"
                                  : dataOfSelectedObject?.paymentStatus === "objected"
                                  ? dataOfSelectedObject?.paymentStatus
                                  : dataOfSelectedObject?.status}
                              </span>
                            </Col>
         <span className='d-flex pos_cardusersicon mt-3 '>
          <Label className='so_h2  '>
           {
           dataOfSelectedObject ?. ResourceName
          } </Label>

          <span className=''>
           <img className='mx-2 ' alt='img'
            src={UsersImg}/>
           <Label className='so_total_users '>
            {
            dataOfSelectedObject ?. BookingVisitors ?. length + 1
           } </Label>
          </span>
         </span>
         <Label className='m_rB_l3 mt-4'>
          {
          TextExtractor(dataOfSelectedObject ?. Description)
         } </Label>

         <Row>
          <Col className='flex-column '
           xl={4}
           lg={4}
           md={4}
           sm={12}
           xs={12}>
           <img src={TodayClock}
            alt='today'
            className=''/>
           <span className='mr_today_dur1'>
            <b className='mx-2'>
             {
             moment(dataOfSelectedObject ?. FromTime).format("DD-MM-YYYY")
            } </b>
            <span className='mr_Time1 mx-4 '>
             <b> {
              moment(dataOfSelectedObject ?. FromTime).format("hh:mm a")
             }
              {" "}
              -{" "}
              {
              moment(dataOfSelectedObject ?. ToTime).format("hh:mm a")
             } </b>
            </span>
           </span>
          </Col>
          <Col className=''
           xl={4}
           lg={4}
           md={4}
           sm={12}
           xs={12}>
           <img src={DurationClock}
            alt='duration'/>
           <span className=' mr_today_dur1'>
            <b className='mx-2'>Duration</b>
            <p className='mr_Time1 mx-4'>
             {/* {moment(dataOfSelectedObject?.ToTime).diff(
                            moment(dataOfSelectedObject?.FromTime),
                            "minutes"
                          )}{" "}
                          min */}
             <b> {" "}
              {
              `${
               hoursDur.hours()
              }h 
                           ${
               hoursDur.minutes()
              }`
             }
              m
             </b>
            </p>
           </span>
          </Col>
          <Col className=''
           xl={4}
           lg={4}
           md={4}
           sm={12}
           xs={12}>
           <img src={RepeatBookingIcon}
            alt='duration'
            // className='mr_cal1'
           />

           <span className=' mr_today_dur1'>
            <b className='mx-2'>Repeat</b>
            <p className='mr_Time1 mx-4'>
             <b> {" "}
              {
              dataOfSelectedObject ?. isRepeatBooking ? "true" : "false"
             } </b>
            </p>
           </span>
          </Col>
         </Row>
        </Card>
       </Col>
       <Col className='mb-2'
        xl={3}
        lg={3}
        md={3}
        sm={12}
        xs={12}>
        <Card className='p-2    pt-4 db_card  w-100 h-100 '>
         <Row>
          <Col className=' '
           xl={9}
           lg={9}
           md={9}
           sm={12}
           xs={12}>
           <span className='d-flex mx-3'>
            <img src={SummaryTeam}
             alt='logo'
             className='sum_img'/>

            <p className='pos_h1  mt-4 mx-3'>Participants</p>
           </span>
          </Col>
          {
          dataOfSelectedObject ?. status === "cancelled" || dataOfSelectedObject ?. status === "deny" ? ("") : (
           <Col className=' '
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}>
            <div className='w-100 edit_btn_justify '>
             <Button disabled={
               (userRole === "meetingroomAdminstrator" && enableRoomByFilter === "visitor") || (userRole === "meetingroomTeamMember" && enableRoomByFilter === "visitor") || (userRole === "meetingroomTeamMember" && enableRoomByFilter === "team" || dataOfSelectedObject ?. isCancellable === false || dataOfSelectedObject ?.isOwner === false
               )
              }
              className='w-100'
              color='light'
              onClick={
               () => {
                Navigate("/edit-participents-rescheduled", {
                 state: {
                  dataOfSelectedObject: dataOfSelectedObject
                 }
                });
               }
             }>
              <p className='edit_btn_participent'>Edit</p>
             </Button>
            </div>
           </Col>
          )
         } </Row>
         <Row>
          <Col className='mb-4 mt-4 '
           xl={10}
           lg={10}
           md={10}
           sm={10}
           xs={10}>
           {
           dataOfSelectedObject ?. BookingVisitors ?. map((ele, ll) => {
            return (
             <p className='d-flex mx-3'
              key={
               ll + 3892
             }>
              <img className='' alt='img'
               src={UserImg}/>

              <span className='mx-3 pos_h4'>
               {
               ele ?. VisitorFullName
              } </span>
             </p>
            );
           })
          } </Col>
         </Row>
        </Card>
       </Col>

       <Col xl={3}
        lg={3}
        md={3}
        sm={12}
        xs={12}>
        <Card className='p-2 pt-4 db_card  w-100 h-100 '>
         <Row>
          <Col className='mx-3'
           xl={12}
           lg={12}
           md={12}
           sm={12}
           xs={12}>
           <span className='d-flex'>
            <img src={SummaryPayment}
             alt='logo'
             // className='mt-2'
             className='sum_img'
            />
            <p className='pos_h1 mt-4 mx-3'>Booking details</p>
           </span>
          </Col>
         </Row>

         <Row className='mt-4'>
          <Col className=''
           xl={2}
           lg={2}
           md={2}
           sm={1}
           xs={1}>
           <img src={TodayClock}
            alt='today'
            className='mx-3'/>
          </Col>
          <Col className=' '
           xl={9}
           lg={9}
           md={9}
           sm={10}
           xs={10}>
           <span className='pos_display_inline '>
            <Label className='pos_h2 mt-1 mr_cal_sm_space'>
             Booked on:
            </Label>
            <Label className='dmr_inv_float_right dmr_inv_vat  mt-2'>
             {/* {moment(dataOfSelectedObject?.FromTime).format(
                            "DD-MM-YYYY"
                          )} */}

             {
             moment(dataOfSelectedObject ?. BookedOn).subtract(moment().utcOffset(), "minutes").format("DD-MM-YYYY")
            } </Label>
           </span>
          </Col>
         </Row>
         <Row className=''>
          <Col className=''
           xl={2}
           lg={2}
           md={2}
           sm={1}
           xs={1}>
           <img src={TodayClock}
            alt='today'
            className='mx-3'/>
          </Col>
          <Col className=' '
           xl={9}
           lg={9}
           md={9}
           sm={10}
           xs={10}>
           <span className='pos_display_inline'>
            <Label className='pos_h2 mt-1 mr_cal_sm_space'>
             Booked by:
            </Label>
            <Label className='dmr_inv_float_right dmr_inv_vat  mt-2'>
             {
             dataOfSelectedObject ?. CoworkerName
            } </Label>
           </span>
          </Col>
         </Row>
        </Card>
       </Col>
      </Row>
     </Col>
    </Row>

    {
    dataOfSelectedObject ?. isCancellable === false ? ("") : dataOfSelectedObject ?. status === "cancelled" || dataOfSelectedObject ?. status === "deny" || dataOfSelectedObject ?.isOwner === false ? ("") : (
     <Row>
      <Col xl={6}
       lg={6}
       md={6}
       sm={12}
       xs={12}></Col>
      <Col xl={3}
       lg={3}
       md={3}
       sm={12}
       xs={12}
       className='px-2 mb-2'>
       <Button disabled={
         (userRole === "meetingroomAdminstrator" && enableRoomByFilter === "visitor") || (userRole === "meetingroomTeamMember" && enableRoomByFilter === "visitor") || (userRole === "meetingroomTeamMember" && enableRoomByFilter === "team")
        }
        color='light'
        style={
         {minHeight: "62px"}
        }
        // className='w-100 my-4'
        className='w-100 smr_yes_btn'
        onClick={
         () => setModelShow2(true)
       }>
        Cancel
       </Button>
      </Col>
      <Col xl={3}
       lg={3}
       md={3}
       sm={12}
       xs={12}
       className='px-2'>
       <Button disabled={
         bookingroomsLoading || (userRole === "meetingroomAdminstrator" && enableRoomByFilter === "visitor") || (userRole === "meetingroomTeamMember" && enableRoomByFilter === "visitor") || (userRole === "meetingroomTeamMember" && enableRoomByFilter === "team")
        }
        color='dark'
        style={
         {minHeight: "62px"}
        }
        className='w-100 '
        onClick={
         () => setModelShow(true)
       }>
        Reschedule
       </Button>
      </Col>
     </Row>
    )
   } </div>
   <RescheduleModel show={modelShow}
    setModelShow={setModelShow}
    header={`Reschedule Meeting`}
    body={`Do you want to reschdule this meeting?`}
    link={`/schdule-meeting-room`}
    dataOfSelectedObject={dataOfSelectedObject}
    bookingroomsData={bookingroomsData}/>

   <CancelModel cancelLoadingBtn={cancelLoadingBtn}
    show={modelShow2}
    setModelShow1={setModelShow1}
    setModelShow2={setModelShow2}
    cancelApiFunc={cancelApiFunc}
    header={`Cancel Meeting`}
    body={`Do you want to cancel this meeting?`}
    // onClick={() => {
    //   setModelShow1(true);
    // }}
    // link={`/wellcome`}
   />

   <CanceledModel show={modelShow1}
    // setModelShow1={setModelShow1}
    setModelShow2={setModelShow2}
    header={`Meeting Cancelled`}
    body={`Do you want to reschedule this meeting?`}
    link={`/meeting-room-app/meeting-room`}/>
  </div>
 );
};

export default RescheduleMeetingRoom;
