import React from "react";
import { Button, Col, Row } from "reactstrap";
import SummaryDuration from "../../../../../images/request_sent.svg";
import BtmPic from "../../../../../images/privateroom_btm_pic.svg";
import { Link } from "react-router-dom";
import UseRequestSent from "./useRequestSent";
import "./css/styles.css";

const RequestSent = () => {
  const {} = UseRequestSent();
  return (
    <Row className='p-5'>
      <Col xl={12} lg={12} md={12} sm={12} xs={12} className=''>
        <Row>
          <Col xl={5} lg={5} md={5} sm={3} xs={3}></Col>
          <Col xl={2} lg={2} md={2} sm={6} xs={6}>
            <div className='rs_img_size mb-5'>
              <img className=' w-100 ' src={SummaryDuration} alt='logo' />
            </div>
          </Col>
          <Col xl={5} lg={5} md={5} sm={3} xs={3}></Col>
        </Row>
        <Row className=' rs_centerized mt-5 mb-5'>
          <Col xl={4} lg={4} md={4} sm={12} xs={12}></Col>
          <Col xl={4} lg={4} md={4} sm={12} xs={12}>
            <p className='rs_h1 mt-4'>That's all!</p>
            <p className='rs_h2'>
              Your request for the hybrid plan has been sent!
            </p>
            <p className='rs_h2'>You’ll hear from us soon..</p>
            <Link to='/redirect-main-page' className='btnLink'>
              {" "}
              <Button color='dark' className='w-100 loginBtn '>
                {" "}
                Back to menu
              </Button>
            </Link>
          </Col>
          <Col xl={4} lg={4} md={4} sm={4} xs={4}></Col>
        </Row>

        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className=' ImageContainer '>
              <img src={BtmPic} alt='img' className='pv_pic ' />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RequestSent;
