import { useEffect, useState } from "react";
import { useQuery, queryClient } from "react-query";
import welcomeApi from "../../services/welcomeApi";

export const useFAQInfo = (props) => {
  const [expandedCardId, setExpandedCardId] = useState(null);

  const { data: fqaData, isLoading: fqaDataLoading } = useQuery(
    ["faqData"],
    () => welcomeApi.fqaData(),
    {
      retry: 0,
    }
  );

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleCard = (id) => {
    if (expandedCardId === id) {
      setExpandedCardId(null);
    } else {
      setExpandedCardId(id);
    }
  };

  const isCardExpanded = (id) => {
    return expandedCardId === id;
  };

  const renderCardDescription = (description, id) => {
    if (isCardExpanded(id)) {
      if (!description) {
        return <p></p>;
      }
      const parser = new DOMParser();
      const parsedDescription = parser.parseFromString(
        description,
        "text/html"
      );
      const paragraphs = Array.from(
        parsedDescription.body.getElementsByTagName("p")
      );
      const textContent = paragraphs
        .map((p) => p.textContent.trim())
        .join("\n");
      return <pre>{textContent}</pre>;
    } else {
      if (description) {
        const parser = new DOMParser();
        const parsedDescription = parser.parseFromString(
          description,
          "text/html"
        );
        const paragraphs = Array.from(
          parsedDescription.body.getElementsByTagName("p")
        );
        const textContent = paragraphs
          .map((p) => p.textContent.trim())
          .join("\n");
        const words = textContent.split(/\s+/); // Split textContent into words
        const truncatedDescription = words.slice(0, 50).join(" ");
        const isTruncated = words.length > 50;

        return (
          <>
            <p>
              {truncatedDescription + "..."}
              {isTruncated && "..."}
            </p>
          </>
        );
      } else {
        return <p></p>;
      }
    }
  };

  return {
    fqaDataLoading,
    fqaData,
    toggleCard,
    renderCardDescription,
    isCardExpanded,
  };
};
