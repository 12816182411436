import helpDeskApi from "../services/helpDeskApi";
import { useMutation } from "react-query";

export const useCreateHelpDesk = (history) => {
  const response = useMutation(helpDeskApi.createHelpDesk, {
    mutationKey: "useCreateHelpDesk",
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const usePostChatHelpDesk = (history) => {
  const response = useMutation(helpDeskApi.postChatHelpDesk, {
    mutationKey: "useCreateHelpDesk",
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
