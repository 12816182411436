import React, { useEffect } from "react";
import { Col, Row, Spinner, Card, Label } from "reactstrap";
import PrivateOfficeImg from "../../../../../images/private_office.svg";
import BtmPic from "../../../../../images/privateroom_btm_pic.svg";
import UsersImg from "../../../../../images/users.svg";
import UseSelectOffice from "./useSelectOffice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./css/styles.css";

const SelectOffice = () => {
  const { selectedOfficeAllData, pOfficeFunc, officeLoading } =
    UseSelectOffice();

  useEffect(() => {}, [selectedOfficeAllData]);
  return (
    <Row className='p-4'>
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <p className='so_h1'>Select Private Office</p>

        {officeLoading || selectedOfficeAllData === undefined ? (
          <div className='d-flex justify-content-center my-auto'>
            <Spinner />
          </div>
        ) : (
          <Row className='mb-5'>
            
            {selectedOfficeAllData?.map((ele, i) => {
              
              return (
                <Col
                  xl={5}
                  lg={5}
                  md={5}
                  sm={12}
                  xs={12}
                  className='pb-3'
                  key={i + 23}
                >
                  <Card
                    className='p-2 s_o_cc  w-100 h-100 '
                    onClick={() => {
                      pOfficeFunc(ele);
                    }}
                  >
                    {/* <Image
                      src={PrivateOfficeImg}
                      fallback={<Breathing width='100' />}
                      className='w-100 h-100'
                    /> */}
                    <LazyLoadImage
                      className='w-100 h-100'
                      alt={"images"}
                      effect={"blur"}
                      src={PrivateOfficeImg}
                      placeholderSrc={"images"}
                    />
                    <span className='d-flex pos_cardusersicon mt-3 '>
                      <Label className='so_h2  '>{ele?.ResourceName}</Label>

                      <span className=''>
                        <img className='mx-2 ' alt='img' src={UsersImg} />
                        <Label className='so_total_users '>
                          {ele?.Capacity}
                        </Label>
                      </span>
                    </span>
                  </Card>
                </Col>
              );
            })}

            <Col xl={2} lg={2} md={2} sm={12} xs={12}></Col>
          </Row>
        )}

        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className=' ImageContainer'>
              <img src={BtmPic} alt='img' className='pv_pic ' />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SelectOffice;
