import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate, useLocation } from "react-router-dom";
import durationApi from "../../../../../services/selectDurationTeam";
import { useQuery } from "react-query";
import moment from "moment";

const UseSelectDuration = () => {
  const Navigate = useNavigate();
  const [prceedEnables, setPrceedEnables] = useState(true);
  const [selectedArr, setSelectedArr] = useState([]);
  const [nameColorActive, setNameColorActive] = useState(true);
  const [startDate, setStartDate] = useState();
  const [selectedArrAdministrator, setSelectedArrAdministrator] = useState({});
  const { state } = useLocation();
  const [startDateState, setStartDateState] = useState();
  const [monthNumbers, setMonthNumbers] = useState();
  const [numberOfMonths, setNumberOfMonths] = useState();
  const { dedicatedDeskData, plansPayment } = state;
  const ref = useRef();

  const { data: teamListData, isLoading: teamLoading } = useQuery(
    ["teamListData"],
    () => durationApi.TeamMemberList(),
    {
      retry: 0,
    }
  );
  const allteamListData = teamListData?.data[0]?.AllTeamMembers;
  const onlyTeam = teamListData?.data[0]?.Team;

  const dateInputFunc = (e) => {
    const date = moment(e?.target?.value);
    setStartDate(date.format("MMMM, YYYY"));
    date.add(1, "year");

    setStartDateState(date.format("MMMM, YYYY"));
  };

  // useEffect(() => {
  //   const date = moment(startDateState);
  //   date?.add(numberOfMonths, "months");
  //   setStartDateState(date?.format("MMMM, YYYY"));
  // }, [numberOfMonths]);

  const numOfMonthFunc = (e) => {
    setMonthNumbers(e?.value);
    setNumberOfMonths(e?.value);
  };

  const durationFunc = () => {
    Navigate("/yearly-dedicated-private-summary", {
      state: {
        selectedArr: selectedArr,
        selectedArrAdministrator: selectedArrAdministrator,
        startDate: startDate,
        dedicatedDeskData: dedicatedDeskData,
        plansPayment: plansPayment,
        startDateState: startDateState,
        monthNumbers: monthNumbers,
        onlyTeam: onlyTeam,
      },
    });
    localStorage.setItem("progressBarLength", 2);
    localStorage.setItem("progressBarStart", true);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: "0 !important",
    },
    removeElement: {
      display: "none !important",
    },
    header: {
      paddingBottom: "8px",
      background: "white !important",
    },
    month: {
      padding: "8px 0",
    },
    selectedMonth: {
      background: "gray !important",
      padding: "8px 0",
    },
    inputBox: {
      // background: 'white !important',
      border: "none",
    },
  }));
  const classes = useStyles();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#f5f5f5;",
      borderColor: "#cacaca",
      minHeight: "30px",
      height: "62px",

      boxShadow: state.isFocused ? null : null,
    }),
  };

  const NuberOfMonths = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
  ];

  const selectName = (e) => {
    // setNameColorActive(!nameColorActive);
  };

  const selectedName = (e) => {
    let indexToDelete = -1;
    for (let i = 0; i < selectedArr.length; i++) {
      // if (selectedArr[i].FullName === e?.FullName) {
      if (selectedArr[i].fullName === e?.FullName) {
        indexToDelete = i;
        break; // Stop looping since we found the matching object
      }
    }

    // If we found the matching object, delete it from the array
    if (indexToDelete !== -1) {
      selectedArr.splice(indexToDelete, 1);
    } else {
      // else add it into array
      setSelectedArr([
        ...selectedArr,
        {
          fullName: e?.FullName,
          Id: e?.Id,
          // isPayingMember: e?.IsPayingMember,
          isPayingMember: e?.Id === onlyTeam?.PayingMemberId ? true : false,
          isAdministrator: e?.IsTeamAdministrator,
        },
      ]);
    }
  };

  useEffect(() => {
    const admininstratorData = allteamListData?.find((e) => {
      if (e?.IsTeamAdministrator === true) {
        return e;
      }
    });
    setSelectedArrAdministrator({
      fullName: admininstratorData?.FullName,
      Id: admininstratorData?.Id,
      // isPayingMember: admininstratorData?.IsPayingMember,
      isPayingMember:
        admininstratorData?.Id === onlyTeam?.PayingMemberId ? true : false,
      isAdministrator: admininstratorData?.IsTeamAdministrator,
    });
  }, [allteamListData]);

  useEffect(() => {
    selectedArr && startDateState
      ? setPrceedEnables(false)
      : setPrceedEnables(true);
  }, [selectedArr, startDateState]);

  const isPayingMemberFunc = (id, e) => {
    if (id === onlyTeam?.PayingMemberId) {
      return true;
    } else {
      return false;
    }
  };

  return {
    durationFunc,
    allteamListData,
    selectName,
    selectedName,
    NuberOfMonths,
    customStyles,
    dateInputFunc,
    numOfMonthFunc,
    numberOfMonths,
    dedicatedDeskData,
    startDateState,
    teamLoading,
    prceedEnables,
    isPayingMemberFunc,
    ref,
  };
};

export default UseSelectDuration;
