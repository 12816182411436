import { Button, Col, Label, Row, Input, Spinner } from "reactstrap";
import DurationSideImage from "../../../../../images/durationsideimage.svg";
import BtmPic from "../../../../../images/privateroom_btm_pic.svg";
import UseSelectDuration from "./useSelectDuration";
import "react-datepicker/dist/react-datepicker.css";
import "./css/styles.css";
import moment from "moment";
import { useState } from "react";

const SelectDuration = () => {
  const {
    prceedEnables,
    teamLoading,
    selectName,
    selectedName,
    isPayingMemberFunc,
    privateOfficeData,
    durationFunc,
    startDateState,
    allteamListData,
    dateInputFunc,
    ref,
  } = UseSelectDuration();

  const getCurrentMonth = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits
    return `${year}-${month}`;
  };
  const [minMonth, setMinMonth] = useState(getCurrentMonth());

  // const newDate = new Date();
  // const minMonth = `${newDate.getFullYear()}-${(
  //   newDate.getMonth() +
  //   privateOfficeData[0]?.startMonth +
  //   1
  // )
  //   .toString()
  //   .padStart(2, "0")}`;

  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <p className='sd_h1 mt-4'> Select Duration</p>

        <Row>
          <Col xl={8} lg={8} md={8} sm={8} xs={8}>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <p className='sd_h2'>Start Month </p>
                <div>
                  {" "}
                  <Input
                    // cssModule={ useStyles }
                    className='sd_input'
                    innerRef={ref}
                    name='fName'
                    onChange={dateInputFunc}
                    type='month'
                    min={minMonth}
                    errorMessage='Required'
                    required={true}
                  />
                </div>

                <p className='sd_h2 mt-4'>Select Team</p>
              </Col>

              {startDateState ? (
                <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                  <p className='sd_h2'>Expected end Month </p>
                  <p>{ (moment(startDateState).subtract(1, 'days')).format('DD MMMM,YYYY')}</p>
                </Col>
              ) : (
                ""
              )}
            </Row>

            {teamLoading ? (
              <div className='d-flex justify-content-center my-auto'>
                <Spinner />
              </div>
            ) : (
              <Row>
                {allteamListData &&
                  allteamListData?.map((e, index) => {
                    return (
                      <>
                        {e?.IsTeamAdministrator === true ? (
                          <Col xl={6} lg={6} md={6} sm={12} xs={12} key={e?.Id}>
                            <Label
                              name='name 2'
                              className={`sd_input1 w-100 mt-2 mb-2`}
                              // onClick={(e) => {
                              //   // selectedName(e);
                              // }}
                            >
                              <span className='sd_btn_font'>{e?.FullName}</span>
                              <span className='sd_administrator_paying'>
                                <span className='sd_administrator'>
                                  Administrator
                                </span>
                                {/* {e?.IsPayingMember === true ? ( */}
                                {isPayingMemberFunc(e?.Id, e) === true ? (
                                  <span className='sd_payingMember'>
                                    Paying member
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </Label>
                          </Col>
                        ) : e?.CoworkerType !== "Company" ? (
                          <Col
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            key={e?.Id + 1}
                          >
                            <div>
                              <div className='inputRadioGroup  mt-2 mb-4'>
                                <input
                                  type='checkbox'
                                  id={index}
                                  name={e?.FullName}
                                  value={e?.FullName}
                                  // className={` w-100 mt-2 mb-2 sd_btn_font ${
                                  //   nameColorActive ? "sd_input1" : "sd_input"
                                  // } `}
                                  onClick={(ele) => {
                                    selectName(e);
                                    selectedName(e);
                                  }}
                                />
                                <label for={index} className='checkLabel'>
                                  {e?.FullName}
                                  {/* {e?.IsPayingMember === true ? ( */}
                                  {isPayingMemberFunc(e?.Id, e) === true ? (
                                    <span className='sd_payingMember'>
                                      Paying member
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </label>
                              </div>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
              </Row>
            )}
          </Col>
          <Col xl={4} lg={4} md={4} sm={4} xs={4}>
            <img className='sd_sideimg' alt='img' src={DurationSideImage} />
          </Col>
        </Row>
        <Row>
          <Col xl={8} lg={8} md={8} sm={8} xs={8}></Col>
          <Col xl={4} lg={4} md={4} sm={12} xs={12}>
            <Button
              color='dark'
              className='mt-5 w-100 mb-5 loginBtn'
              onClick={durationFunc}
              disabled={prceedEnables}
            >
              confirm
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className='ImageContainer'>
              <img src={BtmPic} alt='img' className='pv_pic ' />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SelectDuration;
