import axios from "axios";
import { Suadi_fintech_URL, Saudi_App_Base_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import { stageToken } from "../constants/local";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_App_Base_URL,
});

const nexudusRequest = axios.create({
  baseURL: Suadi_fintech_URL,
});

const teamCredits = {
  getLeadCredits: async (teamLead) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    if (teamLead) {
      const data = await nexudusRequest.get(`/en/allowances/plans?_depth=4`, {
        headers: headers,
      });

      return data?.data;
    }
  },
  getTeamCredits: async (memberId, teamLead, selectedMember, isAllow) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    if (teamLead === false) {
      const data = await request.get(`/credits/${memberId}`, {
        headers: headers,
      });

      return data?.data;
    } else if (teamLead && isAllow) {
      const data = await request.get(`/credits/${selectedMember}`, {
        headers: headers,
      });

      return data?.data;
    }
  },

  getSingleMemberCredits: async (memberId) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    if (memberId) {
      const data = await request.get(`/credits/${memberId}`, {
        headers: headers,
      });
      return data?.data;
    }
  },

  getTeamMembers: async (teamId, teamLead) => {
    if (teamId) {
      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
      };

      if (teamLead === true) {
        const data = await request.get(`/teams-members/${teamId}`, {
          headers: headers,
        });

        return data?.data;
      }
    }
  },

  getTeamCreditsPermission: async (teamId) => {
    if (teamId) {
      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
      };

      const data = await request.get(`/teams-settings/${teamId}`, {
        headers: headers,
      });

      return data?.data;
    }
  },

  getTeamCreditsPackage: async () => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/credits-products`, {
      headers: headers,
    });

    return data?.data;
  },

  postCreditsApi: async (body) => {
    const jwt = localStorage.getItem("access Token");

    const data = await request.post(
      `/credits`,
      { ...body },
      {
        headers: {
          Accept: "application/json, text/plain",
          Authorization: "Bearer " + jwt,
        },
      }
    );
    return data;
  },

  gethistoryCreditsCredits: async (teamId) => {
    if (teamId) {
      const jwt = localStorage.getItem("access Token");
      const headers = {
        Authorization: "Bearer " + jwt,
      };

      // const data = await request.get(`/credit-histories/${teamId}`, {
      const data = await request.get(`/credit-histories/1416819898`, {
        headers: headers,
      });

      return data?.data;
    }
  },
};

export default apiWrapper(teamCredits, [
  "selectMonthlyPrivateOffice",
  "getTeamCreditsPermission",
  "getTeamCreditsPackage",
  "postCreditsApi",
  "gethistoryCreditsCredits",
]);
