import React, { useEffect } from "react";
import { Row, Col, Label } from "reactstrap";
import DelAccount from "../.././images/delAccount.svg";
import delData from "../.././images/delData.svg";
import { useNavigate } from "react-router-dom";
import "./css/styles.css";

const DeleteAccount = () => {
  const userType = localStorage.getItem("userTypeRedir");

  const Navigate = useNavigate();

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='mx-4 mt-5'>
      <Row className='f_div'>
        <Col
          lg={1}
          md={1}
          sm={1}
          xs={1}
          className='d-flex justify-content-center align-items-center'
        >
          <div>
            <img src={DelAccount} alt='img' className='del_a_img_size' />
          </div>
        </Col>

        <Col lg={11} md={11} sm={11} xs={11}>
          <div>
            <p className='del_a_p1'>
              <b>Account information</b>
            </p>
            <Label className='del_a_p2'>
              Your company has an active contract with Fintech Hub, all of your
              data is managed according to the Hub policy. This policy is
              available on the Hub Website.
            </Label>
          </div>
        </Col>
      </Row>
      {userType === "daypassUser" ? (
        <Row
          onClick={() => {
            Navigate("/deletion-terms");
          }}
          className={" mt-4"}
          role='button'
        >
          <Col lg={5} md={5} sm={5} xs={5}>
            <Row className='del_a_c2 my-4 px-4' role='button'>
              <Col
                lg={1}
                md={1}
                sm={1}
                xs={1}
                className='d-flex justify-content-center align-items-center'
              >
                <div role='button'>
                  <img src={delData} alt='img' className='del_a_img_size' />
                </div>
              </Col>

              <Col lg={11} md={11} sm={11} xs={11}>
                <div className='px-4' role='button'>
                  <p className='del_a_p3'>
                    <b>Delete Account & Data</b>
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row
          onClick={() => {
            Navigate("/deletion-terms");
          }}
          role='button'
          className={
            userType === "meetingroomAdminstrator"
              ? " mt-4"
              : "mt-4 del_card_disabled"
          }
        >
          <Col lg={5} md={5} sm={5} xs={5}>
            <Row className='del_a_c2 my-4 px-4'>
              <Col
                lg={1}
                md={1}
                sm={1}
                xs={1}
                className='d-flex justify-content-center align-items-center'
              >
                <div>
                  <img src={delData} alt='img' className='del_a_img_size' />
                </div>
              </Col>

              <Col lg={11} md={11} sm={11} xs={11}>
                <div className='px-4'>
                  <p className='del_a_p3'>
                    <b>Delete Account & Data</b>
                  </p>
                  <Label className='del_a_p2'>
                    It will be done by sending a request
                  </Label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DeleteAccount;
