import React from "react";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
} from "reactstrap";
import cancelCredit from "./../../.././images/cancelCredit.svg";
import moment from "moment";

import "./css/styles.css";
const CreditTransactionModel = (props) => {
  return (
    <Modal
      isOpen={props.show}
      centered={true}
      size='lg'
      className='fintechModel'
    >
      <Row className='model-body'>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <ModalBody>
            <div className='d-flex justify-content-between'>
              <p className='t_d_p6 '>Transaction Details</p>
              <img
                src={cancelCredit}
                alt='cancel'
                type='button'
                onClick={() => {
                  props.cancelModel(false);
                }}
              />
            </div>
            <Card className='t_d_card p-3'>
              <div className='d-flex justify-content-between'>
                <p className='t_d_p1'>
                  {moment(
                    props?.data[0]?.element?.timestamp,
                    "MM/DD/YYYY HH:mm"
                  ).format("Do MMMM, YYYY")}
                  <span className='t_d_p2'>
                    {" "}
                    {moment(
                      props?.data[0]?.element?.timestamp,
                      "MM/DD/YYYY HH:mm"
                    ).format(" hh:mm A")}
                  </span>
                </p>
                <p className='t_d_p3 px-3 py-2'>Debit</p>
              </div>
              {/* <p className='t_d_p4'>SAR 120.00</p>
              <hr /> */}
              <p className='t_d_p4'>{props?.data[0]?.element?.name}</p>
              {/* <p className='t_d_p5'>Purchase</p> */}
            </Card>

            <Card className='t_d_card p-3 mt-2'>
              <div className='d-flex justify-content-between'>
                <div>
                  <p className='t_d_p4'>Type </p>
                  <div className='d-flex'>
                    {" "}
                    <p className='t_d_p4'>Color</p>
                    <p className='t_d_p5'>
                      (SAR {props?.data[1]?.whole?.Color})
                    </p>
                  </div>
                  <div className='d-flex'>
                    {" "}
                    <p className='t_d_p4'>B&W</p>
                    <p className='t_d_p5'>(SAR {props?.data[1]?.whole?.BnW})</p>
                  </div>
                </div>

                <div>
                  {" "}
                  <p className='t_d_p4'>Page Count</p>
                  <p>
                    {props?.data[0]?.element?.type === "COLOR"
                      ? props?.data[0]?.element?.count
                      : 0}
                  </p>
                  <p>
                    {props?.data[0]?.element?.type === "BLACK_WHITE"
                      ? props?.data[0]?.element?.count
                      : 0}
                  </p>
                </div>

                <div>
                  <p className='t_d_p4'>Sub-total</p>
                  <p className='t_d_p4'>
                    {props?.data[0]?.element?.type === "COLOR"
                      ? props?.data[0]?.element?.count *
                        props?.data[1]?.whole?.Color
                      : 0}
                  </p>
                  <p className='t_d_p4'>
                    {" "}
                    {props?.data[0]?.element?.type === "BLACK_WHITE"
                      ? props?.data[0]?.element?.count *
                        props?.data[1]?.whole?.BnW
                      : 0}
                  </p>
                </div>
              </div>
              <div className='d-flex justify-content-end'>
                <p className='t_d_p4'>
                  <span className='mx-2'> Total</span>
                  {props?.data[0]?.element?.type === "COLOR"
                    ? props?.data[0]?.element?.count *
                      props?.data[1]?.whole?.Color
                    : props?.data[0]?.element?.count *
                      props?.data[1]?.whole?.BnW}
                </p>
              </div>
            </Card>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export default CreditTransactionModel;
