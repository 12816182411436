import React from "react";
import { Card, Col, Row } from "reactstrap";
import Noti_p1 from "../../images/noti_p1.svg";
// import { firebaseDB } from "./../../firebase";
import { ref, onValue, getDatabase, update, set } from "firebase/database";
import { useEffect } from "react";
import { useState } from "react";
import { db2 } from "./../../firebase";
import "./css/styles.css";
import moment from "moment";

const Notifications = () => {
  // Notifications
  const [notifications, setNotifications] = useState([]);
  const [requestNotifications, setRequestNotifications] = useState([]);
  const [paymentNotifications, setPaymentNotifications] = useState([]);
  const [activeNotifications, setActiveNotifications] = useState(false);
  // const[newNotifications,setNewNotifications] = useState([])
  const [notificationType, setNotificationType] = useState("all");
  const database = getDatabase();
  const userIdLocal = localStorage.getItem("userId");

  
  useEffect(() => {
    onValue(ref(database, `/${userIdLocal}`), (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        // setActiveNotifications(false)
        const arrayOfObj = Object.entries(data)
          .map((e) => e[1])
          .reverse();
          arrayOfObj.sort((a, b) => parseInt(b.time) - parseInt(a.time));

        setNotifications(arrayOfObj);
        if (notifications.length !== arrayOfObj.length) {
          setActiveNotifications(true);
        }

       
      } else {
        // setActiveNotifications(false)
      }
      // set(ref(database, `/${userIdLocal}`), [])
    });


   
   
  }, [notifications.length]);

  


  const notificationItems = notifications.length
    ? Object?.values(notifications[0])
    : [];
    

  function getRelativeTime(milliseconds) {
    const currentDate = new Date();
    const targetDate = new Date(milliseconds);

    // Calculate the time difference in milliseconds
    const timeDiff = currentDate - targetDate;

    // Convert the time difference to days
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (daysDiff === 0) {
      return "today";
    } else if (daysDiff === 1) {
      return "yesterday";
    } else {
      // You can customize this to handle different cases
      // like returning the actual date for a larger time difference
      // or more specific relative terms like "2 days ago", "3 days ago", etc.
      return `${daysDiff} days ago`;
    }
  }

  function millisecondsToTime(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    const formattedHours = hours % 12 || 12;
    const amPm = hours < 12 ? "am" : "pm";

    const formattedMinutes = (minutes % 60).toString().padStart(2, "0");

    const timeFormat = `${formattedHours}:${formattedMinutes}${amPm}`;
    return timeFormat;
}

  return (
    <div className='p-2'>
   
      <Row>
        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
          {notifications
            ? notifications?.map((item, index) => {
                const lastNotificationTime = getRelativeTime(
                  notifications[index - 1]?.time
                );
                const milliseconds = item?.time;
                const relativeTime = getRelativeTime(milliseconds);

                const timeStr = moment(item?.time).format('hh:mm a');
                if (lastNotificationTime === relativeTime) {
                  return (
                    <Card
                      className='noti_1 w-100 px-2'
                      key={868756746567 + index}
                    >
                      <div className='d-flex w-100 mt-2'>
                        <img
                          src={Noti_p1}
                          className='noti_logo'
                          alt='notifications'
                        />
                        <p className='noti_p2 mt-2 w-100'>
                          <b>{item?.title}</b>
                          <div className='d-flex justify-content-between'>
                            <span className='noti_p2 mt-1'>{item?.body}</span>

                            <p className='noti_4 mt-1'>{timeStr}</p>
                          </div>
                        </p>
                      </div>
                    </Card>
                  );
                } else {
                  return (
                    <>
                      <p className='noti_5 mt-3'>{relativeTime}</p>

                      <Card className='noti_1 w-100 px-2 '>
                        <div className='d-flex w-100 mt-2'>
                          <img
                            src={Noti_p1}
                            className='noti_logo'
                            alt='notifications'
                          />
                          <p className='noti_p2 mt-2 w-100'>
                            <b>{item?.title}</b>
                            <div className='d-flex justify-content-between'>
                              <span className='noti_p2 mt-1'>{item?.body}</span>
                              <p className='noti_4 mt-1'>{timeStr}</p>
                            </div>
                          </p>
                        </div>
                      </Card>
                    </>
                  );
                }
              })
            : ""}
        </Col>
      </Row>
    </div>
  );
};

export default Notifications;
