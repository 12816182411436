import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useRemoveTeamMemberMutaion } from "../../../rc-query/myTeamHooks";

export const useTeamMembersInfo = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data, tab } = state;
  const [modelShow, setModelShow] = useState(false);
  const [removeModel, setRemoveModel] = useState(false);
  const [removeModel2, setRemoveModel2] = useState(false);
  const [memberId, setMemberId] = useState(false);
  const [resourceId, setResourceId] = useState(false);
  const [memberName, setMemberName] = useState(false);
  const removeTeamMember = useRemoveTeamMemberMutaion(props.history);

  const memberHandleFunc = (Id, name, resourceId) => {
    setRemoveModel(true);
    setMemberId(Id);
    setMemberName(name);
    setResourceId(resourceId);
  };

  const removeMemberFunc = (id) => {
    setRemoveModel(false);

    const memberData = {
      teamId: data.NexudusTeamId,
      id: id,
    };
    removeTeamMember.mutateAsync({ ...memberData }).then((res) => {
      if (res?.statusCode === 200) {
        setRemoveModel2(true);
      }
    });
  };

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return {
    modelShow,
    data,
    removeModel,
    memberName,
    memberId,
    resourceId,
    removeModel2,
    setRemoveModel2,
    removeMemberFunc,
    memberHandleFunc,
    setMemberId,
    setMemberName,
    setRemoveModel,
    setModelShow,
    navigate,
  };
};
