import React from "react";
import { useNavigate } from "react-router-dom";
import TeamCredits from "../../../../services/teamCreditsApi";
import { useQuery } from "react-query";

const UseSelectPackage = () => {
  const Navigate = useNavigate();

  const { data: teamCreditsPackageData, isLoading: teamCreditsPackageLoading } =
    useQuery(
      ["teamCreditsPackage"],
      () => TeamCredits.getTeamCreditsPackage(),
      {
        retry: 0,
      }
    );
  const teamCreditsPackage = teamCreditsPackageData?.data;

  return { Navigate, teamCreditsPackage, teamCreditsPackageLoading };
};

export default UseSelectPackage;
