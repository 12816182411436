import { useTeamMembersInfo } from "./useTeamMember";
import { Col, Row, Card, Button } from "reactstrap";
import TeamManagemant from "../../../images/teamManagement.svg";
import AddMember from "../../../images/addMemberLogo.svg";
import User from "../../../images/userBlue.svg";
import User2 from "../../../images/UserRed.svg";
import RequestModel from "../../../components/molicules/requestModel";
import RemoveTeamMember from "../../../components/molicules/removeMemberModel";
import "../css/styles.css";

const TeamMembers = (props) => {
  const {
    modelShow,
    data,
    removeModel,
    memberName,
    memberId,
    resourceId,
    removeModel2,
    setRemoveModel2,
    removeMemberFunc,
    memberHandleFunc,
    setRemoveModel,
    setModelShow,
    navigate,
  } = useTeamMembersInfo(props);
  return (
    <>
      <div>
        <Row>
          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
            <Row className='myTeamMainPage'>
              <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                <h1>Team { (data?.companyName && data?.companyName.length > 50)
  ? data?.companyName.slice(0, 50) + '...'
  : data?.companyName}</h1>
                <Card className='myTeamCard mt-4'>
                  <Row>
                    <Col md={1} lg={1} xl={1} sm={1} xs={1}>
                      <div className=' d-flex justify-content-end'>
                        <img
                          src={TeamManagemant}
                          alt='teamManagement '
                          className='myTeamImg'
                        />
                      </div>
                    </Col>
                    <Col
                      md={11}
                      lg={11}
                      xl={11}
                      sm={11}
                      xs={11}
                      className='mt-3'
                    >
                      <div className='d-flex justify-content-between align-items-end'>
                        <h2>Team management </h2>
                      </div>
                      {/* for hybrid plan showing dedicated members */}
                      {data?.dedicatedMembers?.map((member, index) => {
                        if (
                          member?.isAdministrator === true ||
                          member?.isPayingMember === true
                        ) {
                          return (
                            <div key={member?.Id}>
                              <div className='d-flex justify-content-between mt-2'>
                                <span>
                                  <img src={User2} alt='userPic' />
                                  <span className='mx-3 memberList'>
                                    {member?.fullName}
                                  </span>
                                </span>

                                <span className='DDmyTeamSpan px-2'>
                                  <span>Dedicated Desk</span>
                                </span>
                              </div>
                              <span className='sd_administrator_paying'>
                                {member?.isAdministrator === true ? (
                                  <span className='team_administrator   px-2'>
                                    Administrator
                                  </span>
                                ) : (
                                  ""
                                )}
                                {member?.isPayingMember === true ? (
                                  <span className='sd_payingMember px-2'>
                                    Paying member
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          );
                        }
                      })}
                      {/* for hybrid plan showing Private members members */}
                      {data?.privateMembers?.map((member, index) => {
                        if (
                          member?.isAdministrator === true ||
                          member?.isPayingMember === true
                        ) {
                          return (
                            <div key={member?.Id}>
                              <div className='d-flex justify-content-between mt-2'>
                                <span>
                                  <img src={User} alt='userPic' />
                                  <span className='mx-3 memberList'>
                                    {member?.fullName}
                                  </span>
                                </span>

                                <span className='POmyTeamSpan px-2 '>
                                  <span>Private Office </span>
                                </span>
                              </div>
                              <span className='sd_administrator_paying'>
                                {member?.isAdministrator === true ? (
                                  <span className='team_administrator   px-2'>
                                    Administrator
                                  </span>
                                ) : (
                                  ""
                                )}
                                {member?.isPayingMember === true ? (
                                  <span className='sd_payingMember px-2'>
                                    Paying member
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          );
                        }
                      })}

                      {data?.allActiveMembers?.map((member, index) => {
                        if (
                          (member.isPayingMember === true ||
                            member?.isAdministrator === true) &&
                          !data?.isMultiple
                        ) {
                          return (
                            <div key={member?.Id}>
                              <div className='d-flex justify-content-between mt-2'>
                                <span>
                                  {data?.ResourceTypeId ===
                                  Number(
                                    process.env.REACT_APP_DEDICATED_OFFICE
                                  ) ? (
                                    <img src={User2} alt='userPic' />
                                  ) : data?.ResourceTypeId ===
                                    Number(
                                      process.env.REACT_APP_PRIVATE_OFFICE
                                    ) ? (
                                    <img src={User} alt='userPic' />
                                  ) : (
                                    ""
                                  )}
                                  <span className='mx-3 memberList'>
                                    {member?.fullName}
                                  </span>
                                </span>

                                {data?.ResourceTypeId ===
                                Number(
                                  process.env.REACT_APP_DEDICATED_OFFICE
                                ) ? (
                                  <span className='DDmyTeamSpan px-2'>
                                    <span>Dedicated Desk</span>
                                  </span>
                                ) : data?.ResourceTypeId ===
                                  Number(
                                    process.env.REACT_APP_PRIVATE_OFFICE
                                  ) ? (
                                  <span className='POmyTeamSpan px-2 '>
                                    <span>Private Office </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <span className='sd_administrator_paying'>
                                {member?.isAdministrator === true ? (
                                  <span className='team_administrator px-2'>
                                    Administrator
                                  </span>
                                ) : (
                                  ""
                                )}
                                {member?.isPayingMember === true ? (
                                  <span className='sd_payingMember px-2'>
                                    Paying member
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          );
                        }
                      })}
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                <Card className='myTeamCard mt-4'>
                  <Row>
                    <Col md={1} lg={1} xl={1} sm={1} xs={1}>
                      <div className=' d-flex justify-content-end'>
                        <img
                          src={TeamManagemant}
                          alt='teamManagement '
                          className='myTeamImg'
                        />
                      </div>
                    </Col>
                    <Col
                      md={11}
                      lg={11}
                      xl={11}
                      sm={11}
                      xs={11}
                      className='mt-3'
                    >
                      <div className='d-flex justify-content-between align-items-end'>
                        <div className='teamManagementHeader'>
                          <h2>Team Members </h2>
                        </div>

                        <Button
                          className='myTeamEditBtn'
                          onClick={
                            () => navigate(-1)
                            // setModelShow(true)
                          }
                        >
                          Cancel
                        </Button>
                      </div>
                      <Row className='mt-3'>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          {data?.isMultiple === false ? (
                            !data?.allActiveMembers?.some(
                              (member) =>
                                !member.isAdministrator &&
                                !member.isPayingMember
                            ) ? (
                              ""
                            ) : (
                              <h4>Active</h4>
                            )
                          ) : !data?.dedicatedMembers?.some(
                              (member) =>
                                !member.isAdministrator &&
                                !member.isPayingMember
                            ) ||
                            !data?.privateMembers?.some(
                              (member) =>
                                !member.isAdministrator &&
                                !member.isPayingMember
                            ) ? (
                            ""
                          ) : (
                            <h4>Active</h4>
                          )}

                          {/* for hybrid plan showing dedicated members */}
                          {data?.dedicatedMembers?.map((member, index) => {
                            if (
                              member?.isAdministrator === true ||
                              member?.isPayingMember === true
                            ) {
                            } else {
                              return (
                                <div
                                  className='AvtiveMembers  d-flex justify-content-between mt-3'
                                  key={member?.Id}
                                >
                                  <span>
                                    <img src={User2} alt='userPic' />
                                    <span className='mx-3 memberList'>
                                      {member?.fullName}
                                    </span>
                                  </span>
                                  <div>
                                    <span className='DDmyTeamSpan px-2'>
                                      <span>Dedicated Desk</span>
                                    </span>
                                    <span
                                      className='removeLink px-3'
                                      role='button'
                                      onClick={() => {
                                        memberHandleFunc(
                                          member.Id,
                                          member.fullName,
                                          Number(
                                            process.env
                                              .REACT_APP_DEDICATED_OFFICE
                                          )
                                        );
                                      }}
                                    >
                                      Remove
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          })}

                          {/* for hybrid plan showing Private members members */}
                          {data?.privateMembers?.map((member, index) => {
                            if (
                              member?.isAdministrator === true ||
                              member?.isPayingMember === true
                            ) {
                            } else {
                              return (
                                <div
                                  className='AvtiveMembers  d-flex justify-content-between mt-3'
                                  key={member?.Id}
                                >
                                  <span>
                                    <img src={User} alt='userPic' />
                                    <span className='mx-3 memberList'>
                                      {member?.fullName}
                                    </span>
                                  </span>
                                  <div>
                                    <span className='POmyTeamSpan px-2'>
                                      <span>Private Office</span>
                                    </span>
                                    <span
                                      className='removeLink px-3'
                                      role='button'
                                      onClick={() => {
                                        memberHandleFunc(
                                          member.Id,
                                          member.fullName,
                                          Number(
                                            process.env.REACT_APP_PRIVATE_OFFICE
                                          )
                                        );
                                      }}
                                    >
                                      Remove
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          })}

                          {data?.allActiveMembers?.map((member, index) => {
                            if (
                              data?.isMultiple === false ||
                              !data?.isMultiple
                            ) {
                              if (
                                member?.isAdministrator === true ||
                                member?.isPayingMember === true
                              ) {
                              } else {
                                if (
                                  data?.ResourceTypeId ===
                                  Number(process.env.REACT_APP_PRIVATE_OFFICE)
                                ) {
                                  return (
                                    <div
                                      className='AvtiveMembers  d-flex justify-content-between mt-3'
                                      key={member?.Id}
                                    >
                                      <span>
                                        <img src={User} alt='userPic' />
                                        <span className='mx-3 memberList'>
                                          {member?.fullName}
                                        </span>
                                      </span>
                                      <div>
                                        <span className='POmyTeamSpan px-2'>
                                          <span>Private Office</span>
                                        </span>
                                        <span
                                          className='removeLink px-3'
                                          role='button'
                                          onClick={() => {
                                            memberHandleFunc(
                                              member.Id,
                                              member.fullName,
                                              Number(
                                                process.env
                                                  .REACT_APP_PRIVATE_OFFICE
                                              )
                                            );
                                          }}
                                        >
                                          Remove
                                        </span>
                                      </div>
                                    </div>
                                  );
                                } else if (
                                  data?.ResourceTypeId ===
                                  Number(process.env.REACT_APP_DEDICATED_OFFICE)
                                ) {
                                  return (
                                    <div
                                      className='AvtiveMembers  d-flex justify-content-between mt-3'
                                      key={member?.Id}
                                    >
                                      <span>
                                        <img src={User2} alt='userPic' />
                                        <span className='mx-3 memberList'>
                                          {member?.fullName}
                                        </span>
                                      </span>
                                      <div>
                                        <span className='DDmyTeamSpan px-2'>
                                          <span>Dedicated Desk</span>
                                        </span>
                                        <span
                                          className='removeLink px-3'
                                          role='button'
                                          onClick={() => {
                                            memberHandleFunc(
                                              member.Id,
                                              member.fullName,
                                              Number(
                                                process.env
                                                  .REACT_APP_DEDICATED_OFFICE
                                              )
                                            );
                                          }}
                                        >
                                          Remove
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              }
                            }
                          })}
                        </Col>
                      </Row>

                      <Row className='mt-3'>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          {data?.inactive?.length === 0 ? (
                            ""
                          ) : (
                            <h4>Inactive</h4>
                          )}
                          {data?.inactive?.map((member, index) => {
                            return (
                              <div
                                className='AvtiveMembers mt-3'
                                key={member?.Id}
                              >
                                <span
                                  role='button'
                                  onClick={() => {
                                    navigate("/add-team-member", {
                                      state: {
                                        data: {
                                          teamInfo: data,
                                          member: member,
                                        },
                                      },
                                    });
                                  }}
                                >
                                  <img src={User2} alt='userPic' />
                                  <span className='mx-3 memberList'>
                                    {member.fullName}
                                  </span>
                                </span>
                              </div>
                            );
                          })}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col>
                <div className='d-flex justify-content-end mt-4'>
                  <Button
                    className=' addMemberBtn'
                    onClick={() => {
                      navigate("/add-team-member", {
                        state: {
                          data: {
                            teamInfo: data,
                          },
                        },
                      });
                    }}
                  >
                    <span>
                      <img
                        src={AddMember}
                        alt='addMemberLogo'
                        className='px-3'
                      />
                    </span>
                    Add New Member
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* <RequestModel
        show={modelShow}
        cancelModel={setModelShow}
        header={"Changes Saved"}
        body={"Your changes have been saved successfully."}
        request={"saveChanges"}
      /> */}
      <RequestModel
        show={removeModel2}
        cancelModel={setRemoveModel2}
        header={"Member Removed"}
        body={
          "You have successfully removed this member.? You will not be charged for this member in next billing cycle."
        }
        request={"removeMember"}
      />
      <RemoveTeamMember
        show={removeModel}
        cancelModel={setRemoveModel}
        removeModelFunc={removeMemberFunc}
        ID={memberId}
        resourceId={resourceId}
        NAME={memberName}
        header={"Remove Team Member"}
        body={"Are you sure you want to remove this member?"}
        request={"removeMember"}
      />
    </>
  );
};

export default TeamMembers;
