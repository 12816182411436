import { Col, Row, NavItem, NavLink, Container } from "reactstrap";
import FT_LOGO from "../../images/explorePageLogo.svg";
import backIcon from "../../images/backIcon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import sideBtn from "../../images/sideBarToggle.svg";
import Notification from "../../images/notification.svg";
import Logout from "../../images/headerLogout.svg";
import classnames from "classnames";
import { useEffect, useState } from "react";
import { ref, onValue, getDatabase,  set } from "firebase/database";
import "./css/styles.css";

const Header = ({ navshow, setNavShow }) => {
  const [notifications, setNotifications] = useState([]);
  const[newNotifications,setNewNotifications] = useState([])
  const [activeNotifications, setActiveNotifications] = useState(false);
  const progressBarLength = localStorage.getItem("progressBarLength");
  const progressBarStart = localStorage.getItem("progressBarStart");
  const privateBar = localStorage.getItem("privateBar");
  const hybridBar = localStorage.getItem("hybridBar");
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const userIdLocal = localStorage.getItem("userId");
  const database = getDatabase();

  useEffect(() => {
    onValue(ref(database, `/users/110011`), (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        // setActiveNotifications(false)
        const arrayOfObj = Object.entries(data)
          .map((e) => e[1])
          .reverse();

        setNotifications(arrayOfObj);
        if (notifications.length !== arrayOfObj.length) {
          setActiveNotifications(true);
        }
      } else {
        // setActiveNotifications(false)
      }
    });
  }, [notifications.length]);

  // for dot of notifications
  useEffect(() => {
    onValue(ref(database, `/users/${userIdLocal}`), (snapshot) => {
      const data = snapshot.val();
        setNewNotifications(data)
    }

    );
  }, [notifications.length]);

  // Assume you have a database reference
 

  const updateDot = () => {
   
    set(ref(database, `/users/${userIdLocal}`), {
      seen: true,
    });
  }

  return (
    <div className=' w-100'>
      <Row className='headerBG '>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row>
            <Col md={4} lg={4} sm={4} xs={4} xl={4}>
              <a href='/'>
                <img
                  src={FT_LOGO}
                  alt='img'
                  className='headerLogo'
                  role='button'
                />
              </a>
            </Col>
            <Col
              md={4}
              lg={4}
              sm={4}
              xs={4}
              xl={4}
              className='progressBarDisplayCol'
            
            >
              <div className='d-flex justify-content-center align-items-end progressBarDisplay' >
                {progressBarStart === "true" ? (
                  <div className='progressBar'>
                    <div className='mt-3 d-flex p-b-center'>
                      <div>
                        <span
                          className={` ${
                            progressBarLength > 0
                              ? "step-number-active"
                              : "step-number"
                          } mx-2 my-2`}
                        >
                          1
                        </span>
                      </div>
                      <div className='prog-line mx-1 mt-1' />
                      <div>
                        <span
                          className={` ${
                            progressBarLength > 1
                              ? "step-number-active"
                              : "step-number"
                          } mx-2 my-2`}
                        >
                          2
                        </span>
                      </div>

                      {privateBar === "true" || hybridBar === "true" ? (
                        <>
                          <div className='prog-line mx-1 mt-1' />
                          <div>
                            <span
                              className={` ${
                                progressBarLength > 2
                                  ? "step-number-active"
                                  : "step-number"
                              } mx-2 my-2`}
                            >
                              3
                            </span>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {hybridBar === "true" ? (
                        <>
                          <div className='prog-line mx-1 mt-1' />
                          <div>
                            <span
                              className={` ${
                                progressBarLength > 3
                                  ? "step-number-active"
                                  : "step-number"
                              } mx-2 my-2`}
                            >
                              4
                            </span>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col md={4} lg={4} sm={4} xs={4} xl={4}>
              <div className='d-flex justify-content-end align-items-center h-100 w-100 px-3'>
                <div>
                  {splitLocation[1] === "login" ||
                  splitLocation[1] === "monthly-dedicated-select-duration" ||
                  splitLocation[1] === "monthly-dedicated-private-summary" ||
                  splitLocation[1] === "yearly-dedicated-private-summary" ||
                  splitLocation[1] === "private-office" ||
                  splitLocation[1] === "select-duration" ||
                  splitLocation[1] === "select-office" ||
                  splitLocation[1] === "yearly-select-duration" ||
                  splitLocation[1] === "yearly-select-office" ||
                  splitLocation[1] === "hybrid-office" ||
                  splitLocation[1] === "monthly-hybrid-select-duration" ||
                  splitLocation[1] === "private-summary" ||
                  splitLocation[1] === "meeting-room-booking-summary" ||
                  splitLocation[1] === "monthly-hybrid-select-team" ||
                  splitLocation[1] === "monthly-hybrid-select-summary" ||
                  splitLocation[1] === "deletion-terms" ||
                  splitLocation[1] === "yearly-hybrid-select-summary" ||
                  splitLocation[1] === "yearly-dedicated-select-duration" ||
                  splitLocation[1] === "welcome" ||
                  splitLocation[1] === "private_request-sent" ||
                  splitLocation[1] === "pending-page" ||
                  splitLocation[1] === "yearly-hybrid-select-team" ||
                  splitLocation[1] === "yearly-hybrid-select-office" ||
                  splitLocation[1] === "edit-participents-rescheduled" ||
                  splitLocation[1] === "reschedule-meeting-room" ||
                  splitLocation[1] === "change-password" ||
                  splitLocation[1] === "inactive-page" ||
                  splitLocation[1] === "view-password" ||
                  splitLocation[1] === "schedule-meeting-room-in-progress" ||
                  splitLocation[1] === "yearly-hybrid-select-duration" ||
                  splitLocation[1] === "yearly-private-summary" ||
                  splitLocation[1] === "schdule-meeting-room" ||
                  splitLocation[1] === "edit-participents-rescheduled" ||
                  splitLocation[1] === "dedicated-office" ? (
                    ""
                  ) : (
                    <>
                      {
                      // notificationSeenHasFalseValue(notifications) === true ?
                      newNotifications?.seen === false ?
                      (
                        <div className='notification-dot'></div>
                      ) : (
                        ""
                      )}
                      <img
                        src={Notification}
                        alt='img'
                        className='px-2'
                        role='button'
                        onClick={() => {
                        updateDot();
                        navigate("/notifications-page")}}
                      />
                    </>
                  )}
                </div>

                {splitLocation[1] === "login" ||
                splitLocation[1] === "monthly-dedicated-select-duration" ||
                splitLocation[1] === "monthly-dedicated-private-summary" ||
                splitLocation[1] === "yearly-dedicated-private-summary" ||
                splitLocation[1] === "private-office" ||
                splitLocation[1] === "select-duration" ||
                splitLocation[1] === "select-office" ||
                splitLocation[1] === "yearly-select-duration" ||
                splitLocation[1] === "yearly-select-office" ||
                splitLocation[1] === "hybrid-office" ||
                splitLocation[1] === "notifications-page" ||
                splitLocation[1] === "deletion-terms" ||
                splitLocation[1] === "edit-participents-rescheduled" ||
                splitLocation[1] === "edit-participents-rescheduled" ||
                splitLocation[1] === "meeting-room-booking-summary" ||
                splitLocation[1] === "private-summary" ||
                splitLocation[1] === "monthly-hybrid-select-duration" ||
                splitLocation[1] === "monthly-hybrid-select-team" ||
                splitLocation[1] === "monthly-hybrid-select-summary" ||
                splitLocation[1] === "yearly-dedicated-select-duration" ||
                splitLocation[1] === "yearly-hybrid-select-summary" ||
                splitLocation[1] === "yearly-hybrid-select-team" ||
                splitLocation[1] === "meeting-room-app" ||
                splitLocation[1] === "my-team" ||
                splitLocation[1] === "reschedule-meeting-room" ||
                splitLocation[1] === "my-team-management" ||
                splitLocation[1] === "my-team-members" ||
                splitLocation[1] === "schedule-meeting-room-in-progress" ||
                splitLocation[1] === "add-team-member" ||
                splitLocation[1] === "yearly-hybrid-select-office" ||
                splitLocation[1] === "yearly-hybrid-select-duration" ||
                splitLocation[1] === "team-credits" ||
                splitLocation[1] === "help-desk" ||
                splitLocation[1] === "faq" ||
                splitLocation[1] === "manage-account" ||
                splitLocation[1] === "day-pass-app" ||
                splitLocation[1] === "delete-account" ||
                splitLocation[1] === "yearly-private-summary" ||
                splitLocation[1] === "update-profile" ||
                splitLocation[1] === "settings" ||
                splitLocation[1] === "membership-invoice" ||
                splitLocation[1] === "schdule-meeting-room" ||
                splitLocation[1] === "dedicated-office" ? (
                  ""
                ) : (
                  <div>
                    <img
                      src={Logout}
                      alt='img'
                      className='px-2'
                      role='button'
                      onClick={() => {
                        localStorage.setItem("access Token", "");
                        navigate("/fintech");
                      }}
                    />
                  </div>
                )}

                <div className=''>
                  <img
                    src={sideBtn}
                    alt='img'
                    role='button'
                    className='sidebtn px-2'
                    onClick={() => {
                      setNavShow(!navshow);
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
