import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const UsePrivateOffice = () => {
  const Navigate = useNavigate();
  const { state } = useLocation();
  const { privateOfficeData } = state;

  useEffect(() => {
    localStorage.setItem("progressBarLength", "");
    localStorage.setItem("progressBarStart", false);
    localStorage.setItem("privateBar", false);
  });

  const monthlyFunc = (e) => {
    Navigate("/select-duration", {
      state: { privateOfficeData, plansPayment: "monthly" },
    });
    localStorage.setItem("progressBarLength", 1);
    localStorage.setItem("progressBarStart", true);
    localStorage.setItem("privateBar", true);
  };

  const yearlyFunc = (e) => {
    Navigate("/yearly-select-duration", {
      state: { privateOfficeData, plansPayment: "yearly" },
    });
    localStorage.setItem("progressBarLength", 1);
    localStorage.setItem("progressBarStart", true);
    localStorage.setItem("privateBar", true);
  };

  return {
    privateOfficeData,
    monthlyFunc,
    yearlyFunc,
  };
};

export default UsePrivateOffice;
