import { useChangePasswordInfo } from "./useChangePassword";
import { Button, Col, FormGroup, Input, Row, Form } from "reactstrap";
import Header from "../../components/header";
import LoginImg from "../../images/LoginImage.svg";
import UpdateLogo from "../../images/updatePassword.svg";
import LoginLogo from "../../images/lgLogo.svg";
import Spinner from "react-bootstrap/Spinner";
import "./css/styles.css";
import "font-awesome/css/font-awesome.min.css";

const ChangePassword = (props) => {
  const {
    confirmPassFunc,
    passwordShown,
    newpasswordShown,
    validEye,
    data,
    lenghtValid,
    numberValid,
    upcaseValid,
    incorrect,
    displayError,
    specialCharValid,
    updatePasswordMutation,
    changePasswordMutation,
    togglePassword,
    newPassFunc,
    toggleNewPassword,
    changePasswordFunc,
  } = useChangePasswordInfo(props);

  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row>
            <Col md={12} lg={12} sm={12} xs={12} xl={12}>
              <Header />
            </Col>
          </Row>

          <Row className='loginRow'>
            <Col
              md={5}
              lg={5}
              xl={5}
              sm={12}
              xs={12}
              className='loginImageCol '
            >
              <img src={LoginImg} alt='img' className='loginImage ' />
            </Col>
            <Col md={7} lg={7} xl={7} sm={12} xs={12}>
              <div className='loginForm'>
                <Row>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    <Form
                    // onSubmit={}
                    >
                      <FormGroup>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            {data === "changePassword" ? (
                              <div className='lgLogoContainer'>
                                <img
                                  src={LoginLogo}
                                  alt='img'
                                  className='lgLogo'
                                />
                              </div>
                            ) : (
                              <div className='lgLogoContainer'>
                                <img
                                  src={UpdateLogo}
                                  alt='img'
                                  className='lgLogo'
                                />
                              </div>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <h4>Setup a new password</h4>
                            <p>
                              To keep your account safe, please update your
                              password{" "}
                            </p>
                            <h6>Please follow these Guidelines</h6>
                            <p className={lenghtValid}>
                              Must be a minimum of 8 characters long{" "}
                            </p>
                            <p className={upcaseValid}>
                              Must contain an uppercase letter
                            </p>
                            <p className={numberValid}>Must contain a number</p>
                            <p className={specialCharValid}>
                              Must contain special character
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <h6>New Password</h6>
                            <div className='input-icons '>
                              <i
                                onClick={() => toggleNewPassword()}
                                className={`fa  ${validEye} ${
                                  !newpasswordShown ? "fa-eye-slash" : "fa-eye"
                                }`}
                              ></i>
                              <Input
                                id='example-input-text'
                                className='inputField'
                                placeholder='********'
                                type={newpasswordShown ? "text" : "password"}
                                onChange={(e) => {
                                  newPassFunc(e);
                                }}
                              ></Input>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <h6>Confirm New Password</h6>
                            <div className='input-icons '>
                              <i
                                onClick={() => togglePassword()}
                                className={`fa  ${validEye} ${
                                  !passwordShown ? "fa-eye-slash" : "fa-eye"
                                }`}
                              ></i>
                              <Input
                                id='example-date-input-text'
                                className='inputField'
                                placeholder='********'
                                type={passwordShown ? "text" : "password"}
                                onChange={(e) => confirmPassFunc(e)}
                              ></Input>
                            </div>
                            <p className={`${displayError}`}>
                              Password does not match
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          {data === "changePassword" ? (
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              {/* <Link to='/login'> */}
                              <Button
                                onClick={changePasswordFunc}
                                className='loginBtn w-100'
                                disabled={incorrect}
                              >
                                {changePasswordMutation?.isLoading ? (
                                  <Spinner
                                    as='span'
                                    animation='border'
                                    size='sm'
                                    role='status'
                                    aria-hidden='true'
                                    variant='light'
                                  />
                                ) : (
                                  ""
                                )}{" "}
                                Set Password
                              </Button>
                              {/* </Link> */}
                            </Col>
                          ) : (
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              {/* <Link to='/login'> */}
                              <Button
                                onClick={changePasswordFunc}
                                className='loginBtn w-100'
                                disabled={incorrect}
                              >
                                {updatePasswordMutation?.isLoading ? (
                                  <Spinner
                                    as='span'
                                    animation='border'
                                    size='sm'
                                    role='status'
                                    aria-hidden='true'
                                    variant='light'
                                  />
                                ) : (
                                  ""
                                )}{" "}
                                Update Password
                              </Button>
                              {/* </Link> */}
                            </Col>
                          )}
                        </Row>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ChangePassword;
