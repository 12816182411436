import { useViewPasswordInfo } from "./useViewPassword";
import { Button, Col, FormGroup, Input, Row, Form } from "reactstrap";
import "font-awesome/css/font-awesome.min.css";
import Header from "../../../components/header";
import LoginImg from "../../../images/LoginImage.svg";
import UpdateLogo from "../../../images/updatePassword.svg";
import Spinner from "react-bootstrap/Spinner";
import "../css/styles.css";

const ViewPassword = (props) => {
  const {
    password,
    passwordShown,
    validEye,
    loginMutation,
    setPassword,
    updatePassword,
    togglePassword,
  } = useViewPasswordInfo(props);

  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row>
            <Col md={12} lg={12} sm={12} xs={12} xl={12}>
              <Header />
            </Col>
          </Row>

          <Row className='loginRow'>
            <Col
              md={5}
              lg={5}
              xl={5}
              sm={12}
              xs={12}
              className='loginImageCol '
            >
              <img src={LoginImg} alt='img' className='loginImage ' />
            </Col>
            <Col md={7} lg={7} xl={7} sm={12} xs={12}>
              <div className='loginForm'>
                <Row>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    <Form onSubmit={updatePassword}>
                      <FormGroup>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <div className='lgLogoContainer'>
                              <img
                                src={UpdateLogo}
                                alt='img'
                                className='lgLogo'
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <h6>Old Password</h6>
                            <div className='update-input-icons '>
                              <i
                                onClick={() => togglePassword()}
                                className={`fa  ${validEye} ${
                                  !passwordShown ? "fa-eye-slash" : "fa-eye"
                                }`}
                              ></i>
                              <Input
                                id='example-date-input-text'
                                className='inputField'
                                placeholder='********'
                                type={passwordShown ? "text" : "password"}
                                onChange={(e) => setPassword(e.target.value)}
                              ></Input>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <Button
                              className='loginBtn w-100'
                              onClick={() => updatePassword()}
                              disabled={password ? false : true}
                            >
                              {loginMutation?.isLoading ? (
                                <Spinner
                                  as='span'
                                  animation='border'
                                  size='sm'
                                  role='status'
                                  aria-hidden='true'
                                  variant='light'
                                />
                              ) : (
                                ""
                              )}{" "}
                              Verify
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ViewPassword;
