import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Card, Label, Spinner } from "reactstrap";
import moment from "moment";
// import PrivateOfficeImg from "./../../../.././images/private_office.svg";
import UsersImg from "./../../../.././images/users.svg";
import TodayClock from "./../../../.././images/blue_calender.svg";
import DurationClock from "./../../../.././images/blue_clock.svg";
import Zero_meeting_room_book from "./../../../.././images/zero_meeting_room_book.svg";
import UseViewAllDaypass from "./useViewAllDaypass";
import RangeCalenderModel from "../../../../components/molicules/rangeCalenderModel";
import Select from "react-select";

const ViewAllDaypass = (props) => {
  const Navigate = useNavigate();
  const {
    dayPassListingLoading,
    stateOfDropDownMeeting,
    setStateOfDropDownMeeting,
    customStyles1,
    daypassListingData,
    filterState,
    RangeCalenderFunc,
    TextExtractor,
    DropdownIndicator,
    customStyles,
    setFilterState,
    setShowModel,
    rangeState,
    showModel,
  } = UseViewAllDaypass();

  const hoursDur1 = (ele) => {
    const aa = moment.duration(
      moment(ele ? ele?.ToTime : "").diff(
        moment(ele ? ele?.FromTime : ""),
        "minutes"
      ),
      "minutes"
    );

    return `${aa.hours()}h ${aa.minutes()}m`;
  };

  return (
    <>
      {dayPassListingLoading ? (
        <div className='d-flex justify-content-center whole_page_loader h-100'>
          <Spinner className='justify-item-center mx-1 ' />
        </div>
      ) : daypassListingData ? (
        <div>
          <div className='p-4'>
            <Row>
              <Col>
                <Row className='mb-3'>
                  <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                    {/* <p className='smr_h1 '>Schedule Meetings</p> */}

                    <Select
                      className='mt-2'
                      value={stateOfDropDownMeeting}
                      options={[
                        { label: "All", value: "all" },
                        { label: "My Meetings", value: "meeting" },
                        { label: "Day Pass", value: "daypass" },
                        { label: "Invitees", value: "visitor" },
                      ]}
                      styles={customStyles1}
                      isSearchable={false}
                      onChange={(e) => {
                        setStateOfDropDownMeeting(e);
                      }}
                    />
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={1} xs={1}></Col>
                  <Col xl={3} lg={3} md={3} sm={5} xs={5}>
                    <Select
                      value={filterState}
                      placeholder='All'
                      {...props}
                      components={{
                        ...props.components,
                        DropdownIndicator: DropdownIndicator,
                      }}
                      isSearchable={false}
                      options={[
                        { label: "All", value: "all" },
                        { label: "Today", value: "today" },
                        { label: "This Week", value: "this_week" },
                        { label: "Last Week", value: "last_week" },
                        { label: "This Month", value: "this_month" },
                        { label: "Last Month", value: "last_month" },
                        { label: "Custom", value: "custom" },
                      ]}
                      styles={customStyles}
                      onChange={(e) => {
                        setFilterState(e);
                        if (e?.value === "custom") {
                          setShowModel(true);
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row className='mb-5'>
                  {daypassListingData?.length === 0 ? (
                    <div className='marginless '>
                      <Row className=''>
                        <Col xl={5} lg={5} md={5} sm={12} xs={12} className=''>
                          <img
                            src={Zero_meeting_room_book}
                            alt='zerobooking'
                            className='w-100 h-100'
                          />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    daypassListingData?.map((ele) => {
                      return (
                        <Col
                          xl={6}
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          key={ele?.ResourceId}
                        >
                          <Card
                            className='p-2 s_o_cc  w-100  '
                            onClick={() => {
                              ele?.BookingVisitors
                                ? Navigate("/daypass-reschedule-meeting-room", {
                                    state: {
                                      dataOfSelectedObject: ele,
                                    },
                                  })
                                : Navigate("/daypass-reschedule-daypass", {
                                    state: {
                                      dataOfSelectedObject: ele,
                                    },
                                  });
                            }}
                          >
                            <img
                              src={`https://nexudus.spaces.nexudus.com//en/publicresources/getimage/${ele?.ResourceId}?w=565&h=210&anchor=middlecenter&cache=2023-03-16T07:23:02Z`}
                              alt='img'
                              className='w-100 h-100 imgCorners'
                            />

                            {/* // status on top right start */}
                            <Col
                              xl={3}
                              lg={3}
                              md={3}
                              sm={3}
                              xs={3}
                              className={`mx-2 ${
                                ele?.status === "cancelled" ||
                                ele?.status === "deny"
                                  ? "mr_duration_Cancel"
                                  : ""
                              }

                            ${
                              ele?.status === "approved"
                                ? "mr_duration_confirmed"
                                : ""
                            }

                            ${
                              ele?.status === "pending" ||
                              ele?.status === "rescheduled"
                                ? "mr_duration_pending"
                                : ""
                            }
                            
                            
                            
                            `}
                            >
                              <span className='mr_coming_font'>
                                {ele?.status === "approved"
                                  ? "Confirmed"
                                  : ele?.paymentStatus === "objected"
                                  ? ele?.paymentStatus
                                  : ele?.status}
                              </span>
                            </Col>

                            {/* // status on top right end */}
                            <span className='d-flex pos_cardusersicon mt-3 '>
                              <Label className='so_h2  '>
                                {/* {selectedBookingRoomData?.Title} */}
                                {ele?.ResourceName}
                              </Label>
                              {ele?.BookingVisitors ? (
                                <span className=''>
                                  <img
                                    className='mx-2 '
                                    alt='img'
                                    src={UsersImg}
                                  />
                                  <Label className='so_total_users '>
                                    {/* {selectedBookingRoomData?.Allocation} */}
                                    {ele?.BookingVisitors?.length + 1}
                                  </Label>
                                </span>
                              ) : (
                                <span className=''>
                                  <img
                                    className='mx-2 '
                                    alt='img'
                                    src={UsersImg}
                                  />
                                  <Label className='so_total_users '>1</Label>
                                </span>
                              )}
                            </span>
                            <Label className='m_rB_l3 mt-1'>
                              {TextExtractor(ele?.Description)}
                            </Label>

                            <Row className='mt-2'>
                              <Col
                                className='flex-column'
                                xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}
                              >
                                <img
                                  src={TodayClock}
                                  alt='today'
                                  className=''
                                />
                                <span className='mr_today_dur1'>
                                  <b className='mx-2'>
                                    {moment(ele?.FromTime).format("DD-MM-YYYY")}
                                  </b>
                                  <span className='mr_Time1 mx-4 '>
                                    {moment(ele?.FromTime).format("hh:mm a")} -{" "}
                                    {moment(ele?.ToTime).format("hh:mm a")}
                                  </span>
                                  {/* <p> {moment(scheduleDate).format("ddd,MMMM,YYYY")}</p> */}
                                </span>
                              </Col>
                              <Col
                                className=''
                                xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}
                              >
                                <img src={DurationClock} alt='duration' />

                                <span className=' mr_today_dur1'>
                                  <b className='mx-2'>Duration</b>
                                  <p className='mr_Time1 mx-4'>
                                    {hoursDur1(ele)}
                                  </p>
                                </span>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      );
                    })
                  )}
                </Row>
              </Col>
            </Row>
          </div>
          <RangeCalenderModel
            show={showModel}
            setShowModel={setShowModel}
            RangeCalenderFunc={RangeCalenderFunc}
            rangeState={rangeState}
            header={"Remove Team Member"}
            body={"Are you sure you want to remove this member?"}
            request={"removeMember"}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ViewAllDaypass;
