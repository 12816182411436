import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useDetailFeedInfo = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data, tab } = state;

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // *Calculate the estimated reading time of an article
  const description = (description) => {
    let value;
    if (description) {
      const match = description.match(/<span[^>]*>([^<]*)<\/span>/);
      value = match && match[1];
    }

    return value?.replace(/&nbsp;/g, "");

    return value;
  };
  return { data, description, navigate };
};

export default useDetailFeedInfo;
