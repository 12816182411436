import axios from "axios";
import { Suadi_fintech_URL, Saudi_App_Base_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import { stageToken } from "../constants/local";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_App_Base_URL,
});

const myTeam = {
  getTeamInfo: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    if (body.isAdminstrator) {
      const data = await request.get(`/team-lead-members/${body.teamId}`, {
        headers: headers,
      });
      return data?.data;
    } else {
      const data = await request.get(`/team-members/${body.teamId}`, {
        headers: headers,
      });
      return data?.data;
    }
  },
  getTeamSetting: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    if (body) {
      const data = await request.get(`/teams-settings/${body}`, {
        headers: headers,
      });
      return data?.data;
    }
  },

  teamPoolCredit: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    if (body) {
      const data = await request.patch(
        `/teams-pool/${body?.teamId}`,
        { poolCredits: body.e },
        {
          headers: headers,
        }
      );
      return data?.data;
    }
  },
  creditPermissions: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    if (body) {
      const data = await request.patch(
        `/teams-permission/${body?.teamId}`,
        {
          creditPermission: body.e,
        },
        {
          headers: headers,
        }
      );
      return data?.data;
    }
  },

  changeTeamManagenment: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    if (body) {
      const data = await request.patch(
        `/teams-member-request/${body?.teamId}/${body?.memberId}`,
        {
          memberRequest: body.memberRequest,
          otp: Number(body.otp),
        },
        {
          headers: headers,
        }
      );
      return data?.data;
    }
  },
  teamManagenmentOtp: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    if (body) {
      const data = await request.patch(
        `/teams-otp/${body?.teamId}/${body?.email}`,
        {},
        {
          headers: headers,
        }
      );
      return data?.data;
    }
  },

  removeMember: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    if (body) {
      const data = await request.patch(
        `/remove-teamMember/${body?.teamId}/${body?.id}`,
        {},
        {
          headers: headers,
        }
      );
      return data?.data;
    }
  },
  addMember: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    if (body) {
      const data = await request.post(
        `/member-requests`,
        { ...body },
        {
          headers: headers,
        }
      );
      return data;
    }
  },
};

export default apiWrapper(myTeam, [
  "getTeamInfo",
  "getTeamSetting",
  "teamPoolCredit",
  "creditPermissions",
  "changeTeamManagenment",
  "teamManagenmentOtp",
  "removeMember",
  "addMember",
]);
