import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BookMeetingRoom from "../../../services/bookMeetingRoomApi";
import membershipInvoices from "../../../services/membershipAndInvoicesApi";
import { useQuery } from "react-query";
import { useEffect } from "react";

export const useDetailPageInfo = (props) => {
  const [activeTabJustify, setActiveTabJustify] = useState("1");
  const navigate = useNavigate();

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: teamListID, isLoading: teamsLoading } = useQuery(
    ["teamListsID"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );

  const { data: membershipDetail, isLoading: membershipLoading } = useQuery(
    ["membershipDetail", teamListID?.TeamIds],
    () => membershipInvoices.membershipDetail(teamListID?.TeamIds),
    {
      retry: 1,
    }
  );

  const { data: getAllInvoices, isLoading: invoicesLoading } = useQuery(
    ["getAllInvoices", teamListID?.Id],
    () => membershipInvoices.getUserInvoices(teamListID?.Id),
    {
      retry: 1,
    }
  );

  const toggleCustomJustified = (tab) => {
    if (tab === "1") {
      setActiveTabJustify(tab);
    } else if (tab === "2") {
      setActiveTabJustify(tab);
    } else {
      setActiveTabJustify(tab);
    }
  };

  return {
    activeTabJustify,
    membershipDetail,
    getAllInvoices,
    teamListID,
    toggleCustomJustified,
    navigate,
  };
};
