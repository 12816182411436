import { useEffect } from "react";
import { useQuery } from "react-query";
import BookMeetingRoom from "../../../services/bookMeetingRoomApi";

import { useNavigate } from "react-router-dom";
const UsePendingPage = () => {
  const Navigate = useNavigate();
  // ****** Api of coworker Id *********

  const { data: coworkerListData, isLoading: coworkerLoading } = useQuery(
    ["coworkerListData"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 1,
    }
  );

  // **********Api of plans status **********
  const {
    data: plansStatusData,
    isLoading: plansStatusLoading,
    error: plansStatusError,
  } = useQuery(
    ["palnsstatusData", coworkerListData],
    () => BookMeetingRoom.plansStatus(coworkerListData),
    {
      retry: 1,
      onError: (err) => {
        if (err?.message === "Plan Request not Exists") {
          return null;
        }
      },
    }
  );

  useEffect(() => {
    localStorage.setItem("progressBarLength", "");
    localStorage.setItem("progressBarStart", false);
    localStorage.setItem("privateBar", false);
    localStorage.setItem("hybridBar", false);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        (coworkerListData?.TeamIds === "" ||
          coworkerListData?.TeamIds === null ||
          coworkerListData?.TeamIds === undefined) &&
        coworkerListData?.IsTeamAdministrator === false
      ) {
        Navigate("/day-pass-app/day-pass-dashboard");
        localStorage.setItem("userTypeRedir", "daypassUser");
      } else if (
        (coworkerListData?.TeamIds !== "" ||
          coworkerListData?.TeamIds !== null ||
          coworkerListData?.TeamIds !== undefined) &&
        coworkerListData?.IsTeamAdministrator === true &&
        plansStatusError?.response?.data?.error?.message ===
          "Plan Request not Exists"
      ) {
        Navigate("/welcome");
        localStorage.setItem("userTypeRedir", "meetingroomAdminstrator");
      } else if (
        (coworkerListData?.TeamIds !== "" ||
          coworkerListData?.TeamIds !== null ||
          coworkerListData?.TeamIds !== undefined) &&
        coworkerListData?.IsTeamAdministrator === true &&
        plansStatusData?.data?.requestStatus === "pending"
      ) {
        Navigate("/pending-page");
        localStorage.setItem("userTypeRedir", "meetingroomAdminstrator");
      } else if (
        (coworkerListData?.TeamIds !== "" ||
          coworkerListData?.TeamIds !== null ||
          coworkerListData?.TeamIds !== undefined) &&
        plansStatusData?.data?.requestStatus === "approved" &&
        coworkerListData?.IsTeamAdministrator === true
      ) {
        Navigate("/meeting-room-app/meeting-room");
        localStorage.setItem("userTypeRedir", "meetingroomAdminstrator");
      } else if (
        (coworkerListData?.TeamIds !== "" ||
          coworkerListData?.TeamIds !== null ||
          coworkerListData?.TeamIds !== undefined) &&
        coworkerListData?.IsTeamAdministrator === true &&
        plansStatusData?.data?.requestStatus === "deny"
      ) {
        Navigate("/welcome");
        localStorage.setItem("userTypeRedir", "meetingroomAdminstrator");
      } else if (
        (coworkerListData?.TeamIds !== "" ||
          coworkerListData?.TeamIds !== null ||
          coworkerListData?.TeamIds !== undefined) &&
        coworkerListData?.IsTeamAdministrator === false &&
        plansStatusData?.data?.requestStatus === "pending"
      ) {
        Navigate("/pending-page");
        localStorage.setItem("userTypeRedir", "meetingroomTeamMember");
      } else if (
        (coworkerListData?.TeamIds !== "" ||
          coworkerListData?.TeamIds !== null ||
          coworkerListData?.TeamIds !== undefined) &&
        plansStatusData?.data?.requestStatus === "approved" &&
        coworkerListData?.IsTeamAdministrator === false
      ) {
        Navigate("/meeting-room-app/meeting-room");
        localStorage.setItem("userTypeRedir", "meetingroomTeamMember");
      } else if (
        (coworkerListData?.TeamIds !== "" ||
          coworkerListData?.TeamIds !== null ||
          coworkerListData?.TeamIds !== undefined) &&
        coworkerListData?.IsTeamAdministrator === false &&
        plansStatusError?.response?.data?.error?.message ===
          "Plan Request not Exists"
      ) {
        Navigate("/inactive-page");
        localStorage.setItem("userTypeRedir", "meetingroomTeamMember");
      } else if (
        (coworkerListData?.TeamIds !== "" ||
          coworkerListData?.TeamIds !== null ||
          coworkerListData?.TeamIds !== undefined) &&
        coworkerListData?.IsTeamAdministrator === false &&
        plansStatusData?.data?.requestStatus === "deny"
      ) {
        Navigate("/inactive-page");
        localStorage.setItem("userTypeRedir", "meetingroomTeamMember");
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [coworkerListData, plansStatusError, plansStatusData]);
  // **********end of API coworker Id *********

  return { Navigate, coworkerListData };
};

export default UsePendingPage;
