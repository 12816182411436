import axios from "axios";
import { Suadi_fintech_URL, Saudi_App_Base_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import { stageToken } from "../constants/local";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Suadi_fintech_URL,
});

const request1 = axios.create({
  baseURL: Saudi_App_Base_URL,
});

const durationApi = {
  TeamMemberList: async () => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/en/team/profiles`, {
      headers: headers,
    });

    return data;
  },

  durationForMeetingRoomApi: async () => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    // for team member we are uding this Api modification

    const res = await request.get(`/en/coworker/profiles`, {
      headers: headers,
    });

    let coworker = res?.data?.find((user) => user?.CoworkerType === 1);

    const { TeamIds } = coworker;
    if (TeamIds) {
      const res1 = await request1.get(`teams-members/${TeamIds}`, {
        headers: headers,
      });

      const data = res1?.data?.map((e) => {
        return {
          VisitorFullName: e?.FullName,
          isAdministrator: e?.isAdministrator,
          VisitorEmail: e?.Email,
          // TeamName:
        };
      });

      return data;
    }
  },

  durationForMeetingRoomApiforCrdetis: async () => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    // for team member we are uding this Api modification

    const res = await request.get(`/en/coworker/profiles`, {
      headers: headers,
    });

    let coworker = res?.data?.find((user) => user?.CoworkerType === 1);

    const { TeamIds } = coworker;

    if (TeamIds) {
      const res1 = await request1.get(`teams-members/${TeamIds}`, {
        headers: headers,
      });

      const data = res1?.data?.map((e) => {
        return {
          VisitorFullName: e?.FullName,
          isAdministrator: e?.isAdministrator,
          VisitorEmail: e?.Email,
          // TeamName:
          Id: e?.Id,
        };
      });

      return data;
    }
  },
};

export default apiWrapper(durationApi, [
  "TeamMemberList",
  "durationForMeetingRoomApi",
  "durationForMeetingRoomApiforCrdetis",
]);
