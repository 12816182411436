import React, { useState } from "react";
import { Button, Col, Row, Modal, Input, ModalBody, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import Cancel from "../../../images/cancel.svg";
import Gmail from "../../../images/gmail.svg";
import Outlook from "../../../images/outlook.svg";
import Mail from "../../../images/mail.svg";
import "./css/styles.css";

export const ForgotModel = (props) => {
  const [email, setEmail] = useState("");

  function toLowerCase(email) {
    return email.toLowerCase();
  }

  const handleRequestModelFunc = () => {
    const re = /\S+@\S+\.\S+/;

    if (!re.test(email)) {
      toast.warn("Email is not valid", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return;
    }

    props.forgorRequest(toLowerCase(email));

    // props.cancelModel(false);
    // props.confirmModel(true);
  };

  return (
    <Modal isOpen={props.show} centered={true} className='fintechModel'>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <Modal isOpen={props.show} centered={true} className='fintechModel'>
            <Row className='model-body'>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <ModalBody>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='modelHeader'>
                        <h1>{props.header}</h1>
                        <p className='mt-3'>{props.body}</p>
                      </div>
                    </Col>
                  </Row>
                  <img
                    src={Cancel}
                    alt='bancelButton'
                    role='button'
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => props.cancelModel(false)}
                  />
                  <img
                    src={Cancel}
                    alt='bancelButton'
                    role='button'
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => props.cancelModel(false)}
                  />
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='forgotinputField'>
                        <h3>Enter your email address</h3>
                        <Input
                          type='text'
                          className='forgotEmail'
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      {props.loader ? (
                        <Button className='w-100 requestModelBtn'>
                          <Spinner />
                        </Button>
                      ) : (
                        <Button
                          className='w-100 requestModelBtn'
                          disabled={email === "" ? true : false}
                          onClick={() => handleRequestModelFunc()}
                        >
                          Send email
                        </Button>
                      )}
                    </Col>
                  </Row>
                </ModalBody>
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
    </Modal>
  );
};
export const EmailShow = (props) => {
  return (
    <Modal isOpen={props.show} centered={true} className='fintechModel'>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <Modal isOpen={props.show} centered={true} className='fintechModel'>
            <Row className='model-body'>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <ModalBody>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='modelHeader'>
                        <h1>{props.header}</h1>
                        <p className='mt-3'>{props.body}</p>
                      </div>
                    </Col>
                  </Row>
                  <img
                    src={Cancel}
                    alt='bancelButton'
                    role='button'
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => props.cancelModel(false)}
                  />
                  <img
                    src={Cancel}
                    alt='bancelButton'
                    role='button'
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => props.cancelModel(false)}
                  />
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <div className='d-flex justify-content-around mb-4'>
                        <a href='https://mail.google.com' target='blank'>
                          <img src={Gmail} alt='gmail' />
                        </a>
                        <a href='https://outlook.live.com' target='blank'>
                          <img src={Outlook} alt='outlook' />
                        </a>
                        <a href='https://www.icloud.com/mail' target='blank'>
                          <img src={Mail} alt='mail' />
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                      <Button
                        className='w-100 requestModelBtn'
                        onClick={() => props.cancelModel(false)}
                      >
                        Login
                      </Button>
                    </Col>
                  </Row>
                </ModalBody>
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
    </Modal>
  );
};
