import { useEditPlanRequestInfo } from "./useEditPlanRequest";
import { Button, Col, FormGroup, Input, Row, Form } from "reactstrap";
import LoginImg from "../../../images/LoginImage.svg";
import PlanLogo from "../../../images/editPlanLogo.svg";
import Spinner from "react-bootstrap/Spinner";
import "font-awesome/css/font-awesome.min.css";
import "../css/styles.css";

const EditPlanRequest = (props) => {
  const { editHelpDesk, setTitle, setDescription, submitFunc } =
    useEditPlanRequestInfo(props);
  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row>
            <Col
              md={5}
              lg={5}
              xl={5}
              sm={12}
              xs={12}
              className='loginImageCol '
            >
              <img src={LoginImg} alt='img' className='invoiceDetailImg ' />
            </Col>
            <Col md={7} lg={7} xl={7} sm={12} xs={12}>
              <div className='loginForm'>
                <Row className='sideCardView '>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    <Form
                    // onSubmit={}
                    >
                      <FormGroup>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <div className='lgLogoContainer'>
                              <img
                                src={PlanLogo}
                                alt='img'
                                className='lgLogo'
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <p>
                              Open a request. Get in touch with our
                              representative at Fintech Saudi Space
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <h6>Plan Change Request Title</h6>
                            <Input
                              id='example-date-input-text'
                              className='inputField'
                              onChange={(e) => setTitle(e.target.value)}
                              type='text'
                            ></Input>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                            <h6>What do you want to change in plan ?</h6>
                            <Input
                              id='example-date-input-text'
                              className='planChangeTextArea'
                              onChange={(e) => setDescription(e.target.value)}
                              type='textarea'
                              maxLength={700}
                            ></Input>
                          </Col>
                        </Row>

                        <Row className='mt-4'>
                          {editHelpDesk.isLoading ? (
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              <Button
                                className='loginBtn w-100'
                                disabled={true}
                              >
                                <Spinner
                                  as='span'
                                  animation='border'
                                  size='sm'
                                  role='status'
                                  aria-hidden='true'
                                  variant='light'
                                />{" "}
                                Submitting
                              </Button>
                            </Col>
                          ) : (
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              <Button
                                className='loginBtn w-100'
                                onClick={() => submitFunc()}
                              >
                                Submit
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EditPlanRequest;
