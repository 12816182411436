import { useMutation } from "react-query";
import { UPDATE_NOTIFICATION_MUTATION } from "./keys";

import authApi from "../services/loginApi";
import notificationsApi from "../services/notificationsApi";

export const useUpdateNotificationMutation = (history) => {
  const response = useMutation(notificationsApi.notificationSettings, {
    mutationKey: UPDATE_NOTIFICATION_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
