import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import Mem_active from "../../.././images/mem_active.svg";
import BottomImage from "../../.././images/fintechBgImg1.svg";
import UsePendingPage from "./usePendingPage";
import ".././css/styles.css";

const PendingPage = () => {
  const { Navigate } = UsePendingPage();

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Row className='mb-5'>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}></Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
          <Row
            className='u_r_row_color pt-3 mt-5'
            onClick={() => {
              Navigate("/request-status-page");
            }}
          >
            <Col className='mx-2' xl={2} lg={2} md={2} sm={12} xs={12}>
              <img src={Mem_active} alt='logo' />
            </Col>
            <Col
              className='mx-3  my-2 px-2'
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <p className='u_r_l1'> Approval Pending</p>
              <p className='u_r_l2'>
                Please wait, your team membership request is in processing.
              </p>
            </Col>
          </Row>
        </Col>
        <Col xl={4} lg={4} md={4} sm={12} xs={12}></Col>
      </Row>

      <div className='bottomImageContainer w-100 '>
        <img src={BottomImage} alt='backgroundbottomImg' className=' w-100' />
      </div>
    </div>
  );
};

export default PendingPage;
