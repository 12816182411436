import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const UseMeetingConfirmed = () => {
  const location = useLocation();



  
 

  useEffect(() => {
    if (location.pathname === "/meeting-room-app/meeting-room-confirmed") {
      // Prevent user from going back to previous page using the browser back button
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
      };
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return {};
};

export default UseMeetingConfirmed;
