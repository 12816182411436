import React, { useState } from "react";
import { Button, Col, Row, Modal, Input, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./css/styles.css";

const OtpModel = (props) => {
  const navigate = useNavigate();
  const [otpCode, setOtpCode] = useState("");

  const handleRequestModelFunc = () => {
    props.changeRequest(otpCode);
    props.cancelModel(false);
  };
  return (
    <Modal isOpen={props.show} centered={true} className='fintechModel'>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelHeader'>
                  <h1>{props.header}</h1>
                  <p className='mt-3'>{props.body}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelHeader'>
                  <Input
                    type='number'
                    className='otpField'
                    onChange={(e) => setOtpCode(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='d-flex justify-content-between'>
                  <Button
                    className='w-100 requestCancelModelBtn '
                    onClick={() => props.cancelModel(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='w-100 requestUpdateModelBtn'
                    onClick={() => handleRequestModelFunc()}
                  >
                    Submit Code
                  </Button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export default OtpModel;
