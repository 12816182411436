import React, { useEffect } from "react";
import { Button, Col, Row, Card, Label, Input } from "reactstrap";
import UsersImg from "./../../../.././images/users.svg";
import TodayClock from "./../../../.././images/blue_calender.svg";
import DurationClock from "./../../../.././images/blue_clock.svg";
import CrossIcon from "./../../../.././images/x.svg";
import RepeatBookingIcon from "./../../../.././images/repeatBookingIcon.svg";
import UserPlus from "./../../../.././images/user-plus.svg";
import UseScheduleInProgress from "./useScheduleInProgress";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import "./css/style.css";
import CloseButtonComp from "../../../../components/molicules/closeButton";
import { toast } from "react-toastify";

const ScheduleInProgress = () => {
  const {
    teamName,
    repeatDaysState,
    otherInvitesState,
    setOtherInvitesState,
    otherInvitesArr,
    setOtherInvitesArr,
    dataOfSelectedObject,
    selectedArr,
    otherInvitesName,
    setOtherInvitesName,
    otherInvitesEmail,
    repeatbookingShow,
    setOtherInvitesEmail,
    Navigate,
    allteamListData,
    selectedCceck,
    bookingroomsData,
    // onlyTeam,
    scheduleDate,
    scheduleNewTime,
    selectedName,
    scheduleTime,
    durationColorState,
    selectedBookingRoomData,
    checkFunc,
    teamLoading,
  } = UseScheduleInProgress();

  useEffect(() => {}, [selectedBookingRoomData]);

  const TextExtractor = (description) => {
    if (description) {
      const match = description.match(/<span[^>]*>([^<]*)<\/span>/);
      const text = match && match[1];

      return text;
    }
  };

  const selectedBookingRoomDataa = selectedBookingRoomData
    ? selectedBookingRoomData
    : bookingroomsData[0];

  return (
    <div className='p-4'>
      <Row>
        <Col>
          <Row>
            <Col xl={11} lg={11} md={11} sm={10} xs={10}>
              <p className='smr_h1 '>Schedule</p>
            </Col>
            <Col xl={1} lg={1} md={1} sm={2} xs={2}>
              <div>{<CloseButtonComp />}</div>
            </Col>
          </Row>

          <Row className='mb-5'>
            <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mb-5'>
              <Card className='p-2 s_o_cc  w-100 h-100 '>
                {/* <img src={PrivateOfficeImg} alt='img' className='w-100 h-100' /> */}
                <img
                  src={`https://nexudus.spaces.nexudus.com//en/publicresources/getimage/${
                    selectedBookingRoomData?.Id
                      ? selectedBookingRoomData?.Id
                      : bookingroomsData[0]?.Id
                  }?w=565&h=210&anchor=middlecenter&cache=2023-03-16T07:23:02Z`}
                  alt='img'
                  className='w-100 h-100'
                />
                <span className='d-flex pos_cardusersicon mt-3 '>
                  <Label className='so_h2  '>
                    {selectedBookingRoomData?.Title
                      ? selectedBookingRoomData?.Title
                      : selectedBookingRoomDataa?.Title}
                  </Label>

                  <span className=''>
                    <img className='mx-2 ' alt='img' src={UsersImg} />
                    <Label className='so_total_users '>
                      {selectedBookingRoomData?.Allocation
                        ? selectedBookingRoomData?.Allocation
                        : selectedBookingRoomDataa?.Allocation}
                    </Label>
                  </span>
                </span>
                <Label className='m_rB_l3 mt-4'>
                  {/* A room has a big table in the middle with Smart TV */}
                  {TextExtractor(
                    selectedBookingRoomData?.Description
                      ? selectedBookingRoomData?.Description
                      : selectedBookingRoomDataa?.Description
                  )}
                </Label>

                <Row>
                  <Col
                    className='flex-column'
                    xl={4}
                    lg={4}
                    md={4}
                    sm={6}
                    xs={6}
                  >
                    <img src={TodayClock} alt='today' className='' />
                    <span className='mr_today_dur1'>
                      <b className='mx-2'>
                        {moment(scheduleDate).format("dddd")}
                      </b>
                      <span className='mr_Time1 mx-4 '>
                        {scheduleTime} -{" "}
                        {moment(scheduleNewTime).format("hh:mm")}
                      </span>
                      {/* <p> {moment(scheduleDate).format("ddd,MMMM,YYYY")}</p> */}
                    </span>
                  </Col>
                  <Col className='' xl={4} lg={4} md={4} sm={6} xs={6}>
                    <img
                      src={DurationClock}
                      alt='duration'
                      // className='mr_cal1'
                    />

                    <span className=' mr_today_dur1'>
                      <b className='mx-2'>Duration</b>
                      <p className='mr_Time1 mx-4'>{durationColorState} min</p>
                      {/* {scheduleNewTime} */}
                    </span>
                  </Col>
                  {localStorage.getItem("daypassMode") ===
                  "modeIsMeetingroom" ? (
                    ""
                  ) : (
                    <Col className='' xl={4} lg={4} md={4} sm={12} xs={12}>
                      <img
                        src={RepeatBookingIcon}
                        alt='duration'
                        // className='mr_cal1'
                      />

                      <span className=' mr_today_dur1'>
                        <b className='mx-2'>Repeat</b>
                        <p className='mr_Time1 mx-4'>
                          {repeatDaysState?.label
                            ? repeatDaysState?.label
                            : "No"}
                        </p>
                      </span>
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>

            <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mb-5'>
              <Card className=' s_o_cc  w-100 p-3 h-100'>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <span className='mt-3 d-flex justify-content-between'>
                      <Label className='sip_team_l1'>
                        {localStorage.getItem("daypassMode") ===
                        "modeIsMeetingroom"
                          ? "Add Members "
                          : "Select from team"}{" "}
                      </Label>
                      {otherInvitesState === true ? (
                        ""
                      ) : (
                        <span
                          className='d-flex sip_invity px-3'
                          onClick={() => {
                            setOtherInvitesState(true);
                          }}
                        >
                          <img src={UserPlus} alt='add more' className=' ' />{" "}
                          <Label className='sip_invites_addmore mt-2 mx-3'>
                            Other Invites
                          </Label>
                        </span>
                      )}
                    </span>
                  </Col>
                </Row>
                {teamLoading ? (
                  <div className='d-flex justify-content-center align-items-center mt-5'>
                    <Spinner
                      type='grow'
                      color='info'
                      className='justify-item-center mx-2 mt-5'
                    />
                  </div>
                ) : (
                  <Row className='mt-3'>
                    {allteamListData &&
                      allteamListData?.map((e, index) => {
                        return (
                          <>
                            <Col
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              key={e?.Id + 1}
                            >
                              {e?.isAdministrator === true ? (
                                ""
                              ) : (
                                <div className={"inputRadioGroup1 mt-2 mb-4"}>
                                  <input
                                    type='checkbox'
                                    id={index}
                                    name={e?.VisitorFullName}
                                    value={e?.VisitorFullName}
                                    checked={selectedCceck[index]}
                                    onChange={(ele) => {
                                      // selectedCceck[index] = ele.target.checked;
                                      checkFunc(ele, index);

                                      selectedName(e);
                                    }}
                                  />
                                  <label
                                    for={index}
                                    className='checkLabel sip_teams_bg'
                                  >
                                    {e?.VisitorFullName}
                                  </label>
                                </div>
                              )}
                            </Col>
                          </>
                        );
                      })}
                  </Row>
                )}
              </Card>
            </Col>

            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              {otherInvitesState === true ? (
                <Card className=' s_o_cc  w-100 p-3 h-100'>
                  <Row>
                    <Label className='sip_team_l1'>Other Invites </Label>

                    <Row>
                      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        {otherInvitesArr?.map((n, val) => {
                          return (
                            <Label
                              className='sip_invited_mem sip_invit_mem mx-2'
                              key={val + 8932}
                            >
                              {n?.VisitorFullName}{" "}
                              <img
                                src={CrossIcon}
                                alt='icon'
                                onClick={() => {
                                  var newArr = otherInvitesArr;
                                  const newVal = newArr?.filter((e, i) => {
                                    if (i !== val) {
                                      return { e };
                                    }
                                  });

                                  setOtherInvitesArr(newVal);
                                }}
                              />
                            </Label>
                          );
                        })}
                      </Col>
                    </Row>

                    <Row className=''>
                      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Label className='sip_other_invites mt-4'>Name</Label>

                        <Input
                          className='sip_intvies_input'
                          value={otherInvitesName?.VisitorFullName}
                          onChange={(e) => {
                            const input = e.target.value;
                            const filteredInput = input.replace(
                              /[^A-Za-z\s]/g,
                              ""
                            ); // Filter out non-alphabetic characters except spaces
                            setOtherInvitesName({
                              VisitorFullName: filteredInput,
                            });
                          }}
                        />
                        <Label className='sip_other_invites  mt-4'>Email</Label>
                        <Input
                          value={otherInvitesName?.VisitorEmail}
                          className='sip_intvies_input'
                          type='email'
                          onChange={(e) => {
                            setOtherInvitesEmail({
                              VisitorEmail: e?.target?.value,
                            });
                          }}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div
                          className='d-flex mt-3 justify-content-end'
                          onClick={() => {
                            if (
                              otherInvitesName?.VisitorFullName &&
                              otherInvitesEmail?.VisitorEmail
                            ) {
                              const re = /\S+@\S+\.\S+/;
                              if (!re.test(otherInvitesEmail?.VisitorEmail)) {
                                toast.warn("Email is not valid", {
                                  position: toast.POSITION.TOP_RIGHT,
                                });
                                return;
                              }
                              setOtherInvitesArr([
                                ...otherInvitesArr,
                                {
                                  VisitorFullName:
                                    otherInvitesName?.VisitorFullName,
                                  VisitorEmail: otherInvitesEmail?.VisitorEmail,
                                  TypeName: "bookingVisitor",
                                },
                              ]);

                              setOtherInvitesName({
                                VisitorFullName: "",
                                VisitorEmail: "",
                              });
                            }
                          }}
                        >
                          <img
                            src={UserPlus}
                            alt='add more'
                            className=' mx-3'
                          />{" "}
                          <Label
                            className='sip_invites_addmore mt-3'
                            type={"button"}
                          >
                            Save & Add more
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </Card>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}></Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}></Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              {" "}
              <Button
                className='w-100'
                color='dark'
                onClick={() => {
                  Navigate("/meeting-room-booking-summary", {
                    state: {
                      bookingroomsData: bookingroomsData
                        ? bookingroomsData
                        : "",
                      scheduleDate: scheduleDate ? scheduleDate : "",
                      scheduleTime: scheduleTime ? scheduleTime : "",
                      scheduleNewTime: scheduleNewTime ? scheduleNewTime : "",
                      repeatbookingShow: repeatbookingShow
                        ? repeatbookingShow
                        : "",
                      repeatDaysState: repeatDaysState ? repeatDaysState : "",
                      durationColorState: durationColorState
                        ? durationColorState
                        : "",
                      otherInvitesArr: otherInvitesArr ? otherInvitesArr : "",
                      selectedTeamArr: selectedArr ? selectedArr : "",
                      selectedBookingRoomData: selectedBookingRoomData
                        ? selectedBookingRoomData
                        : "",
                      teamName: teamName ? teamName : "",
                      dataOfSelectedObject: dataOfSelectedObject
                        ? dataOfSelectedObject
                        : undefined,
                    },
                  });
                }}
              >
                Proceed
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ScheduleInProgress;
