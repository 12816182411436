import { useEffect } from "react";
import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const UseSelectDuration = () => {
  const Navigate = useNavigate();
  const [selectedArrForDivision, setSelectedArrForDivision] = useState([]);
  const [nameColorActive, setNameColorActive] = useState(true);
  const [unselectedArrForDivision, setUnselectedArrForDivision] =
    useState(true);
  const { state } = useLocation();

  const {
    selectedArr,
    selectedArrAdministrator,
    startDate,
    plansPayment,
    hybridPlanData,
    selectedResourceId,
    resourceId,
    selectedResourceName,
    selectedResourceCapacity,
    startDateState,
    selectedOfficeAllData,
    monthNumbers,
    onlyTeam,
    officeNum
  } = state;
  const ref = useRef();

  const selectName = (e) => {
    // setNameColorActive(!nameColorActive);
  };

  const selectedName = (e) => {
    let indexToDelete = -1;
    for (let i = 0; i < selectedArrForDivision.length; i++) {
      if (selectedArrForDivision[i].fullName === e?.fullName) {
        indexToDelete = i;
        break; // Stop looping since we found the matching object
      }
    }

    // If we found the matching object, delete it from the array
    if (indexToDelete !== -1) {
      selectedArrForDivision.splice(indexToDelete, 1);
    } else {
      // else add it into array
      setSelectedArrForDivision([
        ...selectedArrForDivision,
        {
          fullName: e?.fullName,
          Id: e?.Id,
          isPayingMember: e?.isPayingMember,
          isAdministrator: e?.isAdministrator,
        },
      ]);
    }
  };

  const confirmFunction = () => {
    Navigate("/monthly-hybrid-select-summary", {
      state: {
        onlyTeam: onlyTeam,
        selectedArr: selectedArr,
        selectedArrAdministrator: selectedArrAdministrator,
        startDate: startDate,
        plansPayment: plansPayment,
        hybridPlanData: hybridPlanData,
        resourceId: resourceId,
        selectedResourceId: selectedResourceId,
        selectedResourceName: selectedResourceName,
        selectedResourceCapacity: selectedResourceCapacity,
        startDateState: startDateState,
        monthNumbers: monthNumbers,
        selectedOfficeAllData: selectedOfficeAllData,
        selectedArrForDivision: selectedArrForDivision,
        unselectedArrForDivision: unselectedArrForDivision,
        officeNum:officeNum
      },
    });
    localStorage.setItem("progressBarLength", 4);
    localStorage.setItem("progressBarStart", true);
  };

  useEffect(() => {
    const nonMatchingObjects = selectedArr.filter(
      (obj) => !selectedArrForDivision.some((o) => o?.Id === obj?.Id)
    );
    setUnselectedArrForDivision(nonMatchingObjects);
  }, [selectedArrForDivision]);

  return {
    selectedArrAdministrator,
    selectedArr,
    selectedName,
    selectName,
    selectedResourceName,
    selectedResourceCapacity,
    confirmFunction,
  };
};

export default UseSelectDuration;
