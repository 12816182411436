import React from "react";
import CloseIcon from "./../../../././images/icons8-close-48.svg";
import { useNavigate } from "react-router-dom";

const CloseButtonComp = (prop) => {
  const userType = localStorage.getItem("userTypeRedir");
  const Navigate = useNavigate();
  return (
    <div className='icon-box'>
      <span
        onClick={() => {
          Navigate(
            `${
              userType === "daypassUser"
                ? "/day-pass-app/day-pass-dashboard"
                : "/meeting-room-app/meeting-room"
            }`
          );
        }}
      >
        <img src={CloseIcon} alt='logo' width={24} height={24} />
      </span>
    </div>
  );
};

export default CloseButtonComp;
