import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import BookMeetingRoom from "../../../../services/bookMeetingRoomApi";
import { useDelBookingMutation } from "../../../../rc-query/meetinRoomBookingHook";
import { toast } from "react-toastify";

const UseRescheduleMeetingRoom = () => {
  const queryClient = useQueryClient();
  const Navigate = useNavigate();
  const delBookingMutation = useDelBookingMutation();
  const [modelShow, setModelShow] = useState(false);
  const [modelShow1, setModelShow1] = useState(false);
  const [modelShow2, setModelShow2] = useState(false);
  const [cancelLoadingBtn, setCancelLoadingBtn] = useState(false);

  const state = useLocation();
  const { dataOfSelectedObject } = state?.state;

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ********** all booking cards data start ***********

  const { data: bookingroomsDataa, isLoading: bookingroomsLoading } = useQuery(
    ["bookingsroomsData"],
    () => BookMeetingRoom.getMeetingRoom(),
    {
      retry: 0,
    }
  );
  const bookingroomsData = bookingroomsDataa?.data?.meetingRooms;

  // ********** all booking cards data end ***********

  const cancelApiFunc = () => {
    setCancelLoadingBtn(true);
    delBookingMutation
      .mutateAsync(dataOfSelectedObject?.id)
      .then((data) => {
        setCancelLoadingBtn(false);
        queryClient.invalidateQueries("customerbookingroomsData");
        if (data?.data?.message === "Meeting Booking has been Cancelled") {
          // ******this will work after Api call under lines ******

          setModelShow2(false);
          setModelShow1(true);

          Navigate(
            "/meeting-room-app/meeting-room",
            (state = {
              bookingroomsData: {},
              dataOfSelectedObject: {},
            })
          );
        } else {
          setCancelLoadingBtn(false);
          toast.error(data?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setModelShow2(false);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });

        setModelShow2(false);
      });
  };

  return {
    dataOfSelectedObject,
    modelShow,
    setModelShow,
    modelShow1,
    setModelShow1,
    modelShow2,
    setModelShow2,
    bookingroomsData,
    bookingroomsLoading,
    cancelApiFunc,
    cancelLoadingBtn,
    setCancelLoadingBtn,
  };
};

export default UseRescheduleMeetingRoom;
