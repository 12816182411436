import { useMutation } from "react-query";
import { PLANS_SUMMARY_MUTATION } from "./keys";

import authApi from "../services/loginApi";
import bookMeetingRoomApi from "../services/bookMeetingRoomApi";

// ********create ********

export const usePostApiBookMeetingRoom = (history) => {
  const response = useMutation(bookMeetingRoomApi.meetingRoomPostApi, {
    mutationKey: PLANS_SUMMARY_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

// ******* reschdule *****
export const usePostApiReschduleMeetingRoom = (history) => {
  const response = useMutation(bookMeetingRoomApi.meetingRoomRescheduleApi, {
    mutationKey: PLANS_SUMMARY_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

// ******* reschdule member *****
export const usePostApiReschduleMemberMeetingRoom = (history) => {
  const response = useMutation(
    bookMeetingRoomApi.meetingRoomRescheduleMemberApi,
    {
      mutationKey: PLANS_SUMMARY_MUTATION,
      onSuccess: (data) => {},
      onError: (error) => {},
    }
  );
  return response;
};

export const useConfirmMeetingRoom = (history) => {
  const response = useMutation(bookMeetingRoomApi.confirmdaypass, {
    mutationKey: PLANS_SUMMARY_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const UseInvDownloadMutation = (history) => {
  const response = useMutation(bookMeetingRoomApi.downloadInvApi, {
    mutationKey: "PLANS_SUMMARY_MUTATION_DOWNLOAD",
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useDaypassAtivityConfirm = (history) => {
  const response = useMutation(bookMeetingRoomApi.daypassBookingAtivty, {
    mutationKey: " daypassBookingAtivty",
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useDelBookingMutation = (history) => {
  const response = useMutation(bookMeetingRoomApi.deleteBooking, {
    mutationKey: " PLANS_SUMMARY_MUTATION_DELETE_BOOKING",
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useDelUploadinBookingMutation = (history) => {
  const response = useMutation(bookMeetingRoomApi.deleteUploadingBooking, {
    mutationKey: " PLANS_SUMMARY_MUTATION_UPLOADING_DELETE_BOOKING",
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useDelUploadinDaypassMutation = (history) => {
  const response = useMutation(bookMeetingRoomApi.deleteUploadingDaypass, {
    mutationKey: " PLANS_SUMMARY_MUTATION_UPLOADING_DELETE_DAYPASS",
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const useDelDaypassessMutation = (history) => {
  const response = useMutation(bookMeetingRoomApi.deleteDaypasses, {
    mutationKey: " PLANS_SUMMARY_MUTATION_DELETE_BOOKING",
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
