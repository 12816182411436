import { useMutation } from "react-query";
import { CHANGE_PASSWORD_MUTATION, UPDATE_PASSWORD_MUTATION } from "./keys";
import ChangePasswordApi from "../services/changePasswordApi";

export const useChangePasswordMutation = (history) => {
  const response = useMutation(ChangePasswordApi.newPassword, {
    mutationKey: CHANGE_PASSWORD_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useUpdatePasswordMutation = (history) => {
  const response = useMutation(ChangePasswordApi.updatePassword, {
    mutationKey: UPDATE_PASSWORD_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
