import { useState } from "react";
import { useLocation } from "react-router-dom";
import { addDays } from "date-fns";
import filterIcon from "./../../../.././images/filterIcon.svg";
import { components } from "react-select";
import { logDOM } from "@testing-library/react";
import BookMeetingRoom from "../../../../services/bookMeetingRoomApi";
import { useQuery } from "react-query";
import bookDayPassApi from "../../../../services/bookDayPassApi";
import { useEffect } from "react";

const UseViewAllDaypass = () => {
  const state = useLocation();
  // const { daypassListingData } = state?.state;
  const [rangeState, setRangeState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [stateOfDropDownMeeting, setStateOfDropDownMeeting] = useState({
    label: "All",
    value: "all",
  });
  const [filterState, setFilterState] = useState({
    label: "All",
    value: "all",
  });
  const [showModel, setShowModel] = useState(false);

  const TextExtractor = (description) => {
    if (description) {
      const match = description.match(/<span[^>]*>([^<]*)<\/span>/);
      const text = match && match[1];

      return text;
    }
  };

  // dropdown filter functions are bellow

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={filterIcon} alt='Filter Icon' />
      </components.DropdownIndicator>
    );
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,

      border: "1px solid #bdc7ff",
      borderRadius: "122.449px",
      background: "#bdc7ff",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#333333"
        : state.isFocused
        ? "#f0f0f0"
        : "transparent",
      color: state.isSelected ? "#ffffff" : "#000000",
    }),

    // under text style
    singleValue: (provided) => ({
      ...provided,
      color: " #0129FA",
      // textAlign: "center",
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "16px",
      marginLeft: "10px",
    }),
  };

  // dropdown filter functions end

  // ***** range calender*****
  const RangeCalenderFunc = (item) => {
    setRangeState([item.selection]);
  };

  //  ***** for my meeting dropdown ****
  const customStyles1 = {
    control: (provided) => ({
      ...provided,
      border: "none",
      background: "none",
      boxShadow: "none",
      cursor: "pointer",
      padding: "0",
      margin: "0",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000000",
      // textAlign: "center",
      fontFamily: "Montserrat",
      fontWeight: 400,
      fontSize: "22px",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#333333"
        : state.isFocused
        ? "#f0f0f0"
        : "transparent",
      color: state.isSelected ? "#ffffff" : "#000000",
    }),
    // ****** this indecator is to hide the saprator of value and logo
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Hide the indicator separator
    }),
  };

  // ********* coworker start ***********
  const { data: coworkerListData } = useQuery(
    ["coworkerData"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );

  // ********* coworker end ***********
  const userId = coworkerListData?.Id;

  // listing Api of day

  const { data: dayPassListingDataa, isLoading: dayPassListingLoading } =
    useQuery(
      [
        "bookingDayPassLDataNew",
        userId,
        stateOfDropDownMeeting?.value,
        filterState?.value,
        showModel,
      ],
      () =>
        bookDayPassApi.getDayPassFilteredlisting(
          userId,
          stateOfDropDownMeeting,
          filterState,
          rangeState
        ),
      {
        retry: 0,
      }
    );
  const daypassListingData = dayPassListingDataa?.data?.data;

  useEffect(() => {
    {
      localStorage.setItem(
        "filterForReControlDayPass",
        stateOfDropDownMeeting?.value
      );
    }
  }, [stateOfDropDownMeeting]);

  return {
    dayPassListingLoading,
    stateOfDropDownMeeting,
    setStateOfDropDownMeeting,
    customStyles1,
    RangeCalenderFunc,
    daypassListingData,
    setFilterState,
    customStyles,
    TextExtractor,
    filterState,
    rangeState,
    showModel,
    DropdownIndicator,
    showModel,
    setShowModel,
  };
};

export default UseViewAllDaypass;
