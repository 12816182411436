import { useFAQInfo } from "./useFAQ";
import React, { useState } from "react";
import { Col, Row, Card, Spinner } from "reactstrap";
import Arrow from "../../images/dwnArrow.svg";
import NotFound from "../../images/notFound.svg";
import UpArrow from "../../images/arrow_forward.svg";
import "./css/styles.css";

const FAQPage = (props) => {
  const {
    fqaDataLoading,
    fqaData,
    toggleCard,
    renderCardDescription,
    isCardExpanded,
  } = useFAQInfo(props);

  return (
    <>
      {!fqaDataLoading ? (
        fqaData.length === 0 ? (
          <Row className='faq-not-found mt-5'>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className='notfoundRow mt-5'>
                <img src={NotFound} alt='ImageNotFound' width={150} />
              </div>
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className='content-faqNotFound pt-4'>
                <h3>
                  We don’t have any frequently asked questions yet, but we’d
                  love to hear from you.
                </h3>
                <p>
                  Please feel free to contact us via Help Desk, We’ll get back
                  to you as soon as possible
                </p>
              </div>
            </Col>
          </Row>
        ) : (
          <Row className='faqContainer'>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {fqaData?.map((faq, index) => {
                return (
                  <div className='mt-4' key={index}>
                    <Row>
                      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <h3>{faq?.title}</h3>
                      </Col>
                    </Row>
                    {faq?.data?.map((list, index) => {
                      return (
                        <Row key={list?.Id}>
                          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Card
                              className={`faqCardContainer p-3 mt-3 ${
                                isCardExpanded(list?.Id) ? "expanded" : ""
                              }`}
                              onClick={() => toggleCard(list?.Id)}
                              role='button'
                            >
                              <div className='d-flex justify-content-between'>
                                <h4>{list?.Title}</h4>
                                <img
                                  src={
                                    isCardExpanded(list?.Id) ? UpArrow : Arrow
                                  }
                                  alt='arrow'
                                  className='mx-2'
                                />
                              </div>

                              <div className='descriptionContainer'>
                                {renderCardDescription(
                                  list?.FullText,
                                  list?.Id
                                )}
                              </div>
                            </Card>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                );
              })}
            </Col>
          </Row>
        )
      ) : (
        <Row className='h-100 mt-5'>
          <Col className='h-100 mt-5'>
            <div className='d-flex align-items-center justify-content-center  w-100 h-100 mt-5'>
              <div className='mt-5'>
                <Spinner />
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default FAQPage;
