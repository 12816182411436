import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import BookMeetingRoom from "../../../services/bookMeetingRoomApi";

import { useLoginMutation } from "../../../rc-query/loginHook";

import { useNavigate } from "react-router-dom";
export const useViewPasswordInfo = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [password, setPassword] = useState(false);
  const navigate = useNavigate();
  const loginMutation = useLoginMutation(props);
  const [validEye, setValidEye] = useState("eye1");

  // ********* coworker api to get email ***********
  const { data: coworkerListData } = useQuery(
    ["coworkerdata"],
    () => BookMeetingRoom.coworkerIdGetApi(),
    {
      retry: 0,
    }
  );

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updatePassword = () => {
    const data = {
      username: coworkerListData?.Email,
      password: password,
    };
    loginMutation
      .mutateAsync({ ...data })
      .then((data) => {
        if (data?.data?.access_token && data?.status === 200) {
          navigate("/change-password", {
            state: {
              data: {
                userId: Number(coworkerListData?.Id),
              },
            },
          });
        } else if (
          data?.data?.error?.error === "must_reset_password" &&
          data?.status === 200
        ) {
          localStorage.setItem(
            "loginToken",
            data?.data?.error?.error_description
          );

          if (
            data?.data?.error?.error_description ===
            "Your password is incorrect."
          ) {
            toast.error("Your password is incorrect.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Your password is incorrect.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          if (
            data?.data?.error?.error_description ===
            "Your password is incorrect."
          ) {
            toast.error("Your password is incorrect.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Your password is incorrect.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      })
      .catch((err) => {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return {
    passwordShown,
    validEye,
    password,
    loginMutation,
    setPassword,
    updatePassword,
    togglePassword,
  };
};
