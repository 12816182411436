import React from "react";
import UseDaypassBeforeDashboard from "./useDaypassBeforeDashboard";
import { Card, Col, Row, Label } from "reactstrap";
import PrivateOfficeImg from "./../../.././images/private_office.svg";
import { useNavigate } from "react-router-dom";
import "./css/styles.css";

const DaypassBeforeDashboard = () => {
  const Navigate = useNavigate();
  const {} = UseDaypassBeforeDashboard();
  return (
    <div className=''>
      <Row>
        <Col>
          <Row>
            <Col className='mt-3 ' xl={5} lg={5} md={5} sm={12} xs={12}>
              <Label className='m_rB_l1'>
                <b>Book a Meeting Room</b>
              </Label>
            </Col>
            <Col className='mt-3 ' xl={5} lg={5} md={5} sm={12} xs={12}>
              <Label className='m_rB_l1'>
                <b>Buy a Day Pass</b>
              </Label>
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col xl={5} lg={5} md={5} sm={12} xs={12} className='pb-3'>
              <Card
                className='p-2 s_o_cc  w-100 h-100  '
                onClick={() => Navigate("/meeting-room-app/meeting-room")}
              >
                <div style={{ width: "100%", overflow: "hidden" }}>
                  <img
                    src={PrivateOfficeImg}
                    alt='Booking Room Image'
                    className='w-100 h-100'
                  />
                </div>
                <span className='d-flex pos_cardusersicon mt-3 '>
                  <Label className='so_h2  '>Meeting Room</Label>
                </span>
                <Label className='m_rB_l3'>
                  A room has a big table in the middle with Smart TV
                </Label>
              </Card>
            </Col>

            <Col xl={5} lg={5} md={5} sm={12} xs={12} className='pb-3'>
              <Card
                className='p-2 s_o_cc  w-100  h-100'
                onClick={() => Navigate("/day-pass-app/day-pass-dashboard")}
              >
                <div style={{ width: "100%", overflow: "hidden" }}>
                  <img
                    src={PrivateOfficeImg}
                    alt='Booking Room Image'
                    className='w-100 h-100'
                  />
                </div>
                <span className='d-flex pos_cardusersicon mt-3 '>
                  <Label className='so_h2  '>Day Pass</Label>
                </span>
                <span className='d-flex justify-content-between'>
                  <Label className='m_rB_l3'>
                    A one day pass to explore office and to schedule your
                    meeting
                  </Label>
                  <Label
                    className='buy-now-label mx-2'
                    onClick={() => Navigate("/day-pass-app/day-pass-dashboard")}
                  >
                    Buy now
                  </Label>
                </span>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DaypassBeforeDashboard;
