import React from "react";
import { Col, Row, Card, Label, Spinner } from "reactstrap";
import PrivateOfficeImg from "./../../.././images/private_office.svg";
import UsersImgBrown from "./../../.././images/brownusers.svg";
import CardPolygonwhite from "./../../.././images/daypassRightLogo.svg";
import CardPolygonblack from "./../../.././images/cardPolygonBlack.svg";
import TodayClock from "./../../.././images/today.svg";
import DurationClock from "./../../.././images/durationclock.svg";
import { Image, Breathing } from "react-shimmer";
import { useNavigate } from "react-router-dom";
import Zero_meeting_room_book from "./../../.././images/zero_meeting_room_book.svg";
import UseDaypassDashboard from "./useDaypassDashboard";
import moment from "moment";
import "./css/style.css";
import UseMeetingRoom from "../../meetingRoomApp/meetingRoom/useMeetingRoom";
import { useMainPageInfo } from "../../myTeam/mainPage/useMainPage";

const DayPassDashboard = () => {
  const Navigate = useNavigate();
  const {
    bookingDayPassData,
    bookingDayPassLoading,
    textDesp,
    findUserNexudus,
    daypassListingData,
    dayPassListingLoading,
  } = UseDaypassDashboard();
  const { bookingroomsData } = UseMeetingRoom();
  const userType = localStorage.getItem("userTypeRedir");

  // Get the current date and time
  const currentDate = new Date();
  const filteredArray = daypassListingData?.filter(
    (obj) => new Date(obj.ToTime) >= currentDate
  );
  const daypassListinggData = filteredArray?.slice(0, 2);

  const hoursDur1 = moment.duration(
    moment(daypassListinggData ? daypassListinggData[0]?.ToTime : "").diff(
      moment(daypassListinggData ? daypassListinggData[0]?.FromTime : ""),
      "minutes"
    ),
    "minutes"
  );

  const hoursDur2 = moment.duration(
    moment(daypassListinggData ? daypassListinggData[1]?.ToTime : "").diff(
      moment(daypassListinggData ? daypassListinggData[1]?.FromTime : ""),
      "minutes"
    ),
    "minutes"
  );

  return (
    <div className=''>
      <Row>
        <Col>
          <Row>
            <Col className='my-3' xl={5} lg={5} md={5} sm={12} xs={12}>
              <Label className='m_rB_l1'>
                <b>Products</b>
              </Label>
              {findUserNexudus?.userExits ? (
                ""
              ) : (
                <span className='disbaleVisitor'>
                  You cannot book a product yet, please apply{" "}
                  <a href={process.env.REACT_APP_WEB_FORMS} target='blank'>
                    here
                  </a>
                  .
                </span>
              )}
            </Col>
            
            <Col
              className='bx-3 my-3 d-flex m_rB_space'
              xl={5}
              lg={5}
              md={5}
              sm={12}
              xs={12}
            >
              <Label className='m_rB_l1'>
                <b>Your purchases</b>
              </Label>
              <span
                onClick={() => {
                  Navigate("/view-all-daypass-meetingsplus", {
                    state: {
                      daypassListingData: daypassListingData,
                    },
                  });
                }}
              >
                <Label className='m_rB_l2' role='button'>
                  See All
                </Label>
              </span>
            </Col>
          </Row>

          <Row className='mb-5'>
            <Col xl={5} lg={5} md={5} sm={12} xs={12} className='pb-3'>
              {bookingDayPassLoading ? (
                <div className='d-flex justify-content-center mb-4'>
                  <Spinner
                    type='grow'
                    color='warning'
                    className='justify-item-center mx-1'
                  />
                  <Spinner
                    type='grow'
                    color='warning'
                    className='justify-item-center mx-1'
                  />
                  <Spinner
                    type='grow'
                    color='warning'
                    className='justify-item-center mx-1'
                  />
                </div>
              ) : (
                <Card
                  role='button'
                  className={
                    findUserNexudus?.userExits
                      ? `p-2 s_o_cc  w-100 `
                      : `p-2 s_o_cc  w-100 disabled-card `
                  }
                  onClick={() => {
                    localStorage.setItem("daypassMode", "modeIsDaypass");
                    if (findUserNexudus?.userExits) {
                      localStorage.setItem("reschduleMode", "false");
                      Navigate("/day-pass-app/day-pass-availability", {
                        state: {
                          bookingDayPassData: bookingDayPassData,
                          textDesp: textDesp,
                        },
                      });
                    }
                  }}
                >
                  <div style={{ width: "100%", overflow: "hidden" }}>
                    <Image
                      src={`https://nexudus.spaces.nexudus.com//en/publicresources/getimage/${bookingDayPassData?.Id}?w=565&h=210&anchor=middlecenter&cache=2023-03-16T07:23:02Z`}
                      fallback={<Breathing width='100' />}
                      alt='Booking Room Image'
                      className='w-100 h-100'
                    />
                  </div>
                  <span className='d-flex pos_cardusersicon mt-3 '>
                    <Label className='so_h2  '>
                      Pass Desk
                      {/* {bookingDayPassData?.Title} */}
                    </Label>
                  </span>
                  <Label className='m_rB_l3'>
                    {textDesp}
                    {/* Day pass to visit the hub, and having a dedicated desk */}
                  </Label>
                </Card>
              )}

              {/* <Col xl={5} lg={5} md={5} sm={12} xs={12} className='pb-3'> */}
              <Card
                className={
                  findUserNexudus?.userExits
                    ? `p-2 s_o_cc  w-100 `
                    : `p-2 s_o_cc  w-100 disabled-card `
                }
                onClick={() => {
                  if (findUserNexudus?.userExits) {
                    // Navigate("/meeting-room-app/meeting-room");
                    // exp
                    localStorage.setItem("daypassMode", "modeIsMeetingroom");

                    if (userType === "daypassUser") {
                      localStorage.setItem("reschduleMode", "false");
                      Navigate("/schdule-meeting-room", {
                        state: {
                          bookingroomsData: bookingroomsData[0]
                            ? bookingroomsData
                            : "",
                        },
                      });
                    }
                  }
                }}
                role='button'
              >
                <div style={{ width: "100%", overflow: "hidden" }}>
                  <img
                    src={PrivateOfficeImg}
                    alt='Booking Room Image'
                    className='w-100 h-100'
                  />
                </div>
                <span className='d-flex pos_cardusersicon mt-3 '>
                  <Label className='so_h2  '>Meeting Room</Label>
                </span>
                <Label className='m_rB_l3'>
                  A room has a big table in the middle with Smart TV
                </Label>
              </Card>
              {/* </Col> */}
            </Col>

            <Col xl={5} lg={5} md={5} sm={12} xs={12} className='pb-3'>
              {dayPassListingLoading ? (
                <div className='d-flex flex-wrap justify-content-center my-5'>
                  <Spinner className='' />
                </div>
              ) : (
                <>
                  {/* // if thier is zero booking of meeting room  start*/}

                  {daypassListingData?.length < 1 ? (
                    <div className='marginless my-4'>
                      <Row className=''>
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className=''
                        >
                          <img
                            src={Zero_meeting_room_book}
                            alt='zerobooking'
                            className='w-100 h-100'
                          />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <>
                      {daypassListinggData?.map((ele) => {
                        // if (ele?.BookingVisitors?.length > 0)
                        if (
                          moment(ele?.FromTime).format("DD-MM-YYYY") ===
                          moment().format("DD-MM-YYYY")
                        )
                          return (
                            <>
                              <div className='marginless'>
                                <Card
                                  className=' m_rb_c1  w-100 h-100 mb-3'
                                  onClick={() => {
                                    ele?.BookingVisitors
                                      ? Navigate(
                                          "/daypass-reschedule-meeting-room",
                                          {
                                            state: {
                                              dataOfSelectedObject: ele,
                                            },
                                          }
                                        )
                                      : Navigate(
                                          "/daypass-reschedule-daypass",
                                          {
                                            state: {
                                              dataOfSelectedObject: ele,
                                            },
                                          }
                                        );
                                  }}
                                >
                                  <Row>
                                    <Col
                                      className='p-3 mt-1'
                                      xl={9}
                                      lg={9}
                                      md={9}
                                      sm={12}
                                      xs={12}
                                    >
                                      <Label className='mx-2 m_rb_c1_l2'>
                                        {ele?.ResourceName}
                                      </Label>
                                      {ele?.BookingVisitors?.length > 0 ? (
                                        <p className='m_rb_c1_l1'>
                                          <img
                                            alt='img'
                                            className='mx-2 '
                                            src={UsersImgBrown}
                                          />
                                          <span className='mx-1 '>
                                            {ele?.BookingVisitors?.length}{" "}
                                          </span>
                                          <span> member invited</span>
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                    <Col
                                      xl={3}
                                      lg={3}
                                      md={3}
                                      sm={3}
                                      xs={3}
                                      className='mr_duration_bor'
                                    >
                                      <span className='mr_coming_font'>
                                        Upcoming
                                      </span>
                                    </Col>
                                  </Row>

                                  <Row className=''>
                                    <Col xl={5} lg={5} md={5} sm={12} xs={12}>
                                      <img
                                        src={TodayClock}
                                        alt='today'
                                        className='mr_cal'
                                      />
                                      <Label className='mx-2 mr_today_dur'>
                                        {moment(ele?.FromTime).format(
                                          "DD-MM-YYYY"
                                        )}
                                        <p className='mr_Time '>
                                          <p className='mr_Time '>
                                            {moment(ele?.FromTime).format(
                                              "hh:mm a"
                                            )}{" "}
                                            -{" "}
                                            {moment(ele?.ToTime).format(
                                              "hh:mm a"
                                            )}
                                          </p>
                                        </p>
                                      </Label>
                                    </Col>
                                    <Col
                                      className=''
                                      xl={4}
                                      lg={4}
                                      md={4}
                                      sm={12}
                                      xs={12}
                                    >
                                      <img
                                        src={DurationClock}
                                        alt='duration'
                                        className='mr_cal1 mr_cal_sm_space'
                                      />

                                      <Label className='mx-2 mr_today_dur'>
                                        {" "}
                                        Duration
                                        <p className='mr_Time '>
                                          {`${hoursDur1.hours()}h   ${hoursDur1.minutes()}`}
                                          m
                                        </p>
                                      </Label>
                                    </Col>

                                    <Col
                                      className=''
                                      xl={3}
                                      lg={3}
                                      md={3}
                                      sm={12}
                                      xs={12}
                                    >
                                      <img
                                        src={CardPolygonblack}
                                        alt='ploygon'
                                        className='mr_mettingRoomImg'
                                      />
                                    </Col>
                                  </Row>
                                </Card>
                              </div>
                            </>
                          );
                        else {
                          return (
                            <>
                              <Row className='my-3'>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                  <Card
                                    className=' s_o_cc6   w-100 h-100 '
                                    // onClick={() => {}}
                                    onClick={() => {
                                      ele?.BookingVisitors
                                        ? Navigate(
                                            "/daypass-reschedule-meeting-room",
                                            {
                                              state: {
                                                dataOfSelectedObject: ele,
                                              },
                                            }
                                          )
                                        : Navigate(
                                            "/daypass-reschedule-daypass",
                                            {
                                              state: {
                                                dataOfSelectedObject: ele,
                                              },
                                            }
                                          );
                                    }}
                                  >
                                    <Row>
                                      <Col
                                        className='p-3 mt-1'
                                        xl={9}
                                        lg={9}
                                        md={9}
                                        sm={12}
                                        xs={12}
                                      >
                                        <Label className='mx-2  m_rb_c1_l22 '>
                                          {ele?.ResourceName}
                                        </Label>
                                        {ele?.BookingVisitors?.length > 0 ? (
                                          <Label className='m_rb_c1_l1'>
                                            <img
                                              alt='img'
                                              className='mx-2 '
                                              src={UsersImgBrown}
                                            />
                                            <span
                                              className='mx-1 '
                                              style={{ color: "black" }}
                                            >
                                              {ele?.BookingVisitors?.length}{" "}
                                            </span>
                                            <span style={{ color: "black" }}>
                                              {" "}
                                              member invited
                                            </span>
                                          </Label>
                                        ) : (
                                          ""
                                        )}
                                      </Col>

                                      <Col
                                        xl={3}
                                        lg={3}
                                        md={3}
                                        sm={3}
                                        xs={3}
                                        className='mr_duration_bor'
                                      >
                                        <span className='mr_coming_font'>
                                          Upcoming
                                        </span>
                                      </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                      <Col
                                        className=''
                                        xl={5}
                                        lg={5}
                                        md={5}
                                        sm={12}
                                        xs={12}
                                      >
                                        <img
                                          src={TodayClock}
                                          alt='today'
                                          className='mr_cal '
                                        />

                                        <Label className='mx-2 mr_today_dur1'>
                                          {moment(ele?.FromTime).format(
                                            "DD-MM-YYYY"
                                          )}
                                          <p className='mr_Time1 '>
                                            <p className='mr_Time1 '>
                                              {moment(ele?.FromTime).format(
                                                "hh:mm a"
                                              )}{" "}
                                              -{" "}
                                              {moment(ele?.ToTime).format(
                                                "hh:mm a"
                                              )}
                                            </p>
                                          </p>
                                        </Label>
                                      </Col>
                                      <Col
                                        className=''
                                        xl={5}
                                        lg={5}
                                        md={5}
                                        sm={12}
                                        xs={12}
                                      >
                                        <img
                                          src={DurationClock}
                                          alt='duration'
                                          className='mr_cal1 mr_cal_sm_space'
                                        />

                                        <Label className='mx-2 mr_today_dur1'>
                                          {" "}
                                          Duration
                                          <p className='mr_Time1 '>
                                            {/* {moment(ele?.ToTime).diff(
                                              moment(ele?.FromTime),
                                              "minutes"
                                            )}{" "}
                                            mins */}
                                            {daypassListinggData[0] === ele
                                              ? `${hoursDur1.hours()}h  ${hoursDur1.minutes()}`
                                              : `${hoursDur2.hours()}h  ${hoursDur2.minutes()}`}
                                            m
                                          </p>
                                        </Label>
                                      </Col>

                                      <Col
                                        className=''
                                        xl={2}
                                        lg={2}
                                        md={2}
                                        sm={12}
                                        xs={12}
                                      >
                                        <img
                                          src={CardPolygonwhite}
                                          alt='ploygon'
                                          className='mr_mettingRoomImg'
                                        />
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                              </Row>
                            </>
                          );
                        }
                      })}
                    </>
                  )}
                </>
              )}
            </Col>

            <Col xl={2} lg={2} md={2} sm={12} xs={12}></Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DayPassDashboard;
