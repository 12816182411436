import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useUpdateProfileMutaion } from "../../rc-query/welcomeHook";

export const useUpdateProfileInfo = (props) => {
  const { state } = useLocation();
  const { data, tab } = state;
  const [confirmModel, setConfirmModel] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [companyName, setCompanyName] = useState();

  const updateMutation = useUpdateProfileMutaion(props);

  useEffect(() => {
    setName(data?.FullName);
    setEmail(data?.Email);
    setPhone(data?.MobilePhone === null ? "" : data?.MobilePhone);
    setCompanyName(data?.TeamNames);
  }, [data]);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleUpdateProfile = () => {
    const re = /\S+@\S+\.\S+/;

    if (!re.test(email)) {
      toast.warn("Email is not valid", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const userData = {
      userId: data?.Id,
      teamId: data?.IsTeamAdministrator === true ? "null" : data?.TeamIds,
      fullName: toCamelCase(name),
      email: email,
      phoneNumber: phone,
      companyName: companyName ? toCamelCase(companyName) : "",
    };

    updateMutation
      .mutateAsync(userData)
      .then((data) => {
        // setLoading(false);
        if (data?.data?.statusCode === 200) {
          setConfirmModel(true);
        } else {
          toast.error("User updated error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        // setLoading(false);

        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  function toCamelCase(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    });
    return capitalizedWords.join(" ");
  }

  return {
    name,
    email,
    phone,
    companyName,
    data,
    confirmModel,
    updateMutation,
    handleUpdateProfile,
    setConfirmModel,
    setName,
    setEmail,
    setPhone,
    setCompanyName,
  };
};
