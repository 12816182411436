import axios from "axios";
import { Suadi_fintech_URL, Saudi_App_Base_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import { stageToken } from "../constants/local";
import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_App_Base_URL,
});

const nexudusRequest = axios.create({
  baseURL: Suadi_fintech_URL,
});

const vatValueGetApi = {
  getVatValue: async () => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/booking-taxes`, {
      headers: headers,
    });

    return data?.data;
  },
};
export default apiWrapper(vatValueGetApi, ["getVatValue"]);
