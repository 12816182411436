import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const UseRequestSent = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/yearly-hybrid-request-sent") {
      // Prevent user from going back to previous page using the browser back button
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
      };
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return {};
};
export default UseRequestSent;
