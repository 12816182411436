import { useMutation } from "react-query";
import {
  POOL_TEAM_CREDITS_MUTATION,
  CREDIT_PERMISSIONS_MUTATION,
  TEAM_OTP_REQUEST,
  PATCH_TEAM_MANAGEMENT,
  REMOVE_TEAM_MEMBER_MUTATION,
  ADD_TEAM_MEMBER_MUTATION,
} from "./keys";

import authApi from "../services/loginApi";
import myTeam from "../services/myTeamApi";

export const usePoolTeamCreditMutaion = (history) => {
  const response = useMutation(myTeam.teamPoolCredit, {
    mutationKey: POOL_TEAM_CREDITS_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const useCreditPermissionMutaion = (history) => {
  const response = useMutation(myTeam.creditPermissions, {
    mutationKey: CREDIT_PERMISSIONS_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const useTeamOtpRequestMutaion = (history) => {
  const response = useMutation(myTeam.teamManagenmentOtp, {
    mutationKey: TEAM_OTP_REQUEST,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const useTeamManagenmentMutaion = (history) => {
  const response = useMutation(myTeam.changeTeamManagenment, {
    mutationKey: PATCH_TEAM_MANAGEMENT,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
export const useRemoveTeamMemberMutaion = (history) => {
  const response = useMutation(myTeam.removeMember, {
    mutationKey: REMOVE_TEAM_MEMBER_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};

export const useAddTeamMemberMutaion = (history) => {
  const response = useMutation(myTeam.addMember, {
    mutationKey: ADD_TEAM_MEMBER_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
