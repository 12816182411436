import React, { useState } from "react";
import { Button, Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./css/styles.css";

const RequestModel = (props) => {
  const navigate = useNavigate();

  const handleRequestModelFunc = () => {
    if (props.request === "addMember") {
      props.cancelModel(false);
      navigate(-1);
    } else if (props.request === "removeMember") {
      props.cancelModel(false);
      navigate(-1);
    } else if (props.request === "removeMember") {
      props.cancelModel(false);
    } else if (props.request === "notiSetting") {
      props.cancelModel(false);
    } else if (props.request === "profileUpdate") {
      navigate(-1);
      props.cancelModel(false);
    }
  };
  return (
    <Modal isOpen={props.show} centered={true} className='fintechModel'>
      <Row className='model-body'>
        <Col md={12} xl={12} lg={12} sm={12} xs={12}>
          <ModalBody>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <div className='modelHeader'>
                  <h1>{props.header}</h1>
                  <p className='mt-3'>{props.body}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} xl={12} lg={12} sm={12} xs={12}>
                <Button
                  className='w-100 requestModelBtn'
                  onClick={() => handleRequestModelFunc()}
                >
                  Done
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Col>
      </Row>
    </Modal>
  );
};

export default RequestModel;
