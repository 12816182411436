import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoginMutation, useForgotMutation } from "../../rc-query/loginHook";
import { useMemo } from "react";
import { useEffect } from "react";

export const useLoginInfo = (props) => {
  const Navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [emailShowModel, setEmailShowModel] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validEye, setValidEye] = useState("eye1");
  const [emptyfields, setEmptyfields] = useState(true);
  const [loginLoader, setLoginLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const loginMutation = useLoginMutation(props);
  const forgotPassword = useForgotMutation(props);

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  // ************setFormValues**********
  const emailFunc = (e) => {
    setEmail(e.target.value);
  };
  const passwordFunc = (e) => {
    setPassword(e.target.value);
  };
  // ************End**********

  function toLowerCase(email) {
    return email.toLowerCase();
  }

  const loginFunc = async (e) => {
    e.preventDefault();
    setLoginLoader(true);
    // const payload = {
    //   username: email,
    //   password: password,
    // };

    // const params = new URLSearchParams();
    // for (const key in payload) {
    //   params.append(key, payload[key]);
    // }

    await loginMutation
      .mutateAsync({ username: toLowerCase(email), password: password })
      .then((data) => {
        setLoginLoader(false);
        if (data?.data?.access_token && data?.status === 200) {
          localStorage.setItem("access Token", data?.data?.access_token);
          localStorage.setItem("loginToken", data?.data?.access_token);

          // Navigate("/welcome");
          Navigate("/redirect-main-page");

          toast.success("login successfull.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (
          data?.data?.error?.error === "must_reset_password" &&
          data?.status === 200
        ) {
          localStorage.setItem(
            "loginToken",
            data?.data?.error?.error_description
          );
          Navigate("/change-password", {
            state: {
              data: "changePassword",
            },
          });

          if (
            data?.data?.error?.error_description ===
            "The email or password is incorrect."
          ) {
            toast.error(data?.data?.error?.error_description, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(data?.data?.error?.error, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          if (
            data?.data?.error?.error_description ===
            "The email or password is incorrect."
          ) {
            toast.error(data?.data?.error?.error_description, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(data?.data?.error?.error, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      })
      .catch((err) => {
        setLoginLoader(false);

        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useMemo(() => {
    localStorage.setItem("loginToken", "");
    localStorage.setItem("access Token", "");
  }, []);

  useEffect(() => {
    if (email === " " || password === "") {
      setEmptyfields(true);
    } else {
      setEmptyfields(false);
    }
  }, [email, password]);

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleForgotFunc = async (reqMail) => {
    setLoader(true);
    await forgotPassword
      .mutateAsync(reqMail)
      .then((data) => {
        setLoader(false);

        if (data?.status === 200 && data?.data?.statusCode === 200) {
          setShowModel(false);
          setEmailShowModel(true);
        }
        else if (data?.data?.statusCode === 400) {
          toast.error(data?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      
      })
      .catch((err) => {
        setShowModel(false);
        setLoader(false);

        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return {
    emptyfields,
    loginLoader,
    passwordShown,
    validEye,
    showModel,
    emailShowModel,
    loader,
    handleForgotFunc,
    setEmailShowModel,
    setShowModel,
    togglePassword,
    emailFunc,
    passwordFunc,
    loginFunc,
  };
};
