import axios from "axios";
import { Saudi_Fintech_URL, Saudi_App_Base_URL } from "../constants/config";
import { apiWrapper } from "./interceptors";
import { stageToken } from "../constants/local";

import moment from "moment";

// const jwt = getVerifyToken();

const request = axios.create({
  baseURL: Saudi_App_Base_URL,
});

const welcomeApi = {
  welcomeList: async () => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/resource-plans`, {
      headers: headers,
    });

    return data;
  },
  updateProfile: async (body) => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.patch(
      `/update-customer/${body.teamId}/${body.userId}`,
      {
        fullName: body.fullName,
        email: body.email,
        phoneNumber: body.phoneNumber,
        companyName: body.companyName,
      },
      {
        headers: headers,
      }
    );

    return data;
  },

  fqaData: async () => {
    const jwt = localStorage.getItem("access Token");
    const headers = {
      Authorization: "Bearer " + jwt,
    };

    const data = await request.get(`/faqs`, {
      headers: headers,
    });
    const sections = Object.keys(data?.data?.faqs).map((title) => ({
      title,
      data: data?.data?.faqs[title],
    }));

    return sections;
  },
};

export default apiWrapper(welcomeApi, [
  "welcomeList",
  "updateProfile",
  "fqaData",
]);
