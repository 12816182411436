import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import selectOfficeApi from "../../../../.././services/selectPrivateOffice";
import { useNavigate } from "react-router-dom";

const UseSelectOffice = () => {
  const { state } = useLocation();
  const Navigate = useNavigate();
  const {
    hybridPlanData,
    selectedArr,
    selectedArrAdministrator,
    startDate,
    plansPayment,
    startDateState,
    monthNumbers,
    onlyTeam,
  } = state;

  const { data: selectedOfficeData, isLoading: officeLoading } = useQuery(
    ["teamListData"],
    () =>
      selectOfficeApi.selectMonthlyPrivateOffice(
        hybridPlanData[0]?.PrivateOfficeResourceId,
        selectedArr.length + 1
      ),
    {
      retry: 0,
    }
  );

  const selectedOfficeAllData = selectedOfficeData?.data?.data?.Records;




  const pOfficeFunc = (e) => {


    var officeNum = 0

 if(e?.ResourceName === "Private Office 1"){
  officeNum = 0
  
}
else if(e?.ResourceName === "Private Office 2"){
  officeNum = 1
  
}
else if(e?.ResourceName === "Private Office 3"){
  officeNum = 2
  
}
else if(e?.ResourceName === "Private Office 4"){
  officeNum = 3

}



    Navigate("/monthly-hybrid-select-team", {
      state: {
        selectedArr: selectedArr,
        selectedArrAdministrator: selectedArrAdministrator,
        startDate: startDate,
        plansPayment: plansPayment,
        hybridPlanData: hybridPlanData,
        selectedResourceId: e?.ResourceId,
        resourceId: e?.Id,
        selectedResourceName: e?.ResourceName,
        selectedResourceCapacity: e?.Capacity,
        startDateState: startDateState,
        monthNumbers: monthNumbers,
        // selectedOfficeAllData: selectedOfficeAllData,
        selectedOfficeAllData: e,
        officeNum:officeNum,
        onlyTeam: onlyTeam,
      },
    });
    localStorage.setItem("progressBarLength", 3);
    localStorage.setItem("progressBarStart", true);
  };

  return { selectedOfficeAllData, pOfficeFunc, officeLoading };
};

export default UseSelectOffice;
