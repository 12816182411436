import { useUploadInvoiceInfo } from "./useUploadInvoice";
import LoginImg from "../../../images/LoginImage.svg";
import Dropzone from "react-dropzone";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import CloseIcon from "../../../images/icons8-close-48.svg";
import { Card, Col, Row, Input, Form, Button } from "reactstrap";
import "../css/styles.css";

const UploadInvoice = (props) => {
  const {
    data,
    selectedFiles,
    photoDataByApi,
    updateMutation,
    setInvoiceComment,
    setSelectedFiles,
    handleAcceptedFiles,
    updateInvoiceFunc,
  } = useUploadInvoiceInfo(props);
  return (
    <>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} xl={12}>
          <Row>
            <Col
              md={5}
              lg={5}
              xl={5}
              sm={12}
              xs={12}
              className='loginImageCol '
            >
              <img src={LoginImg} alt='img' className='invoiceDetailImg ' />
            </Col>
            <Col md={7} lg={7} xl={7} sm={12} xs={12}>
              <div className='invoiceDetailPage'>
                <Row className='sideCardView '>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    <Row className='uploadInvoicePage'>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        <h1>Upload Invoice</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        <Card className='uploadInvoiceCard p-3'>
                          <div className='d-flex justify-content-between'>
                            <div>
                              <h1>Invoice ID</h1>
                              <h2>{data?.InvoiceNumber}</h2>
                            </div>
                            <div>
                              <h1>Due date</h1>
                              <h2>
                                {" "}
                                {moment(data?.DueDate)
                                  .format("DD MMMM, YYYY")
                                  .toString()}
                              </h2>
                            </div>
                            <div>
                              <h1>Total</h1>
                              <h2>{`${data?.CurrencyCode} ${data?.TotalAmount}`}</h2>
                            </div>
                            <div>
                              <h1>Status</h1>
                              <h2>Transfer Pending</h2>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <Row className='accountPay mt-4'>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        <h2>Accounts to pay</h2>
                        <Card className='accountPayCard p-3'>
                          <h1>Bank account</h1>
                          <div className='d-flex justify-content-between'>
                            <h2>Bank account</h2>
                            <h3>Fintech Saudi</h3>
                          </div>
                          <div className='d-flex justify-content-between'>
                            <h2>IBAN</h2>
                            <h3>SA40 0110 0001 2303 0500 0009</h3>
                          </div>
                          <h2>Sarie Details</h2>
                          <div className='mt-3'>
                            <h1>SARIE</h1>
                            <div className='d-flex justify-content-between'>
                              <h2>SARIE CODE</h2>
                              <h3>SAMASARI</h3>
                            </div>
                            <h2>Saudi Arabian Monetary Authority</h2>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <Row className='accountPay mt-4'>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        <div className='w-100' role='button'>
                          <Form
                            className='p_day_img_border mt-3  p-4 '
                            hidden={selectedFiles.length > 0}
                          >
                            <Dropzone
                              accept={".png ,.jpeg,.jpg,.pdf"}
                              onDrop={(acceptedFiles) => {
                                handleAcceptedFiles(acceptedFiles);
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div>
                                  <div
                                    className='dz-message needsclick'
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <p className='p_day_l8 mt-3 d-flex justify-content-center align-items-center'>
                                      Tab here to upload the receipt
                                    </p>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                          </Form>
                          <div
                            className='dropzone-previews mt-3'
                            id='file-previews'
                          >
                            {selectedFiles.length > 0 && (
                              <div className='mt-1  p_day_img_border'>
                                <div className='p-2'>
                                  <Row className='align-items-center'>
                                    <Col md={4}></Col>
                                    <Col
                                      md={7}
                                      className=' d-flex  justify-content-between align-items-center'
                                    >
                                      <img
                                        data-dz-thumbnail=''
                                        height='80'
                                        className='text-center avatar-sm rounded bg-light d-flex justify-content-center'
                                        alt={selectedFiles[0].name}
                                        src={selectedFiles[0].preview}
                                      />
                                      {/* </Col>
                          <Col md={1}> */}
                                      <span
                                        onClick={() => setSelectedFiles([])}
                                        role='button'
                                      >
                                        <img
                                          src={CloseIcon}
                                          alt='logo'
                                          width={24}
                                          height={24}
                                        />
                                      </span>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row className='commentArea mt-4'>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        <h3>Comments</h3>
                        <div>
                          <Input
                            type='textarea'
                            maxLength={700}
                            className='textAreaField'
                            onChange={(e) => setInvoiceComment(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        {updateMutation.isLoading ? (
                          <Button
                            className='loginBtn w-100 mt-4'
                            disabled={true}
                          >
                            <Spinner
                              as='span'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                              variant='light'
                            />{" "}
                            Upload & Submit
                          </Button>
                        ) : (
                          <Button
                            className='loginBtn w-100 mt-4'
                            onClick={() => updateInvoiceFunc()}
                            disabled={photoDataByApi ? false : true}
                          >
                            Upload & Submit
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default UploadInvoice;
