import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const UseHybridOffice = () => {
  const Navigate = useNavigate();
  const { state } = useLocation();
  const { hybridPlanData } = state;

  useEffect(() => {
    localStorage.setItem("progressBarLength", "");
    localStorage.setItem("progressBarStart", false);
    localStorage.setItem("hybridBar", false);
  });

  const monthlyFunc = (e) => {
    Navigate("/monthly-hybrid-select-duration", {
      state: { hybridPlanData: hybridPlanData, plansPayment: "monthly" },
    });
    localStorage.setItem("progressBarLength", 1);
    localStorage.setItem("progressBarStart", true);
    localStorage.setItem("hybridBar", true);
  };

  const yearlyFunc = (e) => {
    Navigate("/yearly-hybrid-select-duration", {
      state: { hybridPlanData: hybridPlanData, plansPayment: "yearly" },
    });
    localStorage.setItem("progressBarLength", 1);
    localStorage.setItem("progressBarStart", true);
    localStorage.setItem("hybridBar", true);
  };

  return {
    hybridPlanData,
    monthlyFunc,
    yearlyFunc,
  };
};

export default UseHybridOffice;
