import { useMutation } from "react-query";
import { UPDATE_PROFILE_MUTATION } from "./keys";

import authApi from "../services/loginApi";
import welcomeApi from "../services/welcomeApi";

export const useUpdateProfileMutaion = (history) => {
  const response = useMutation(welcomeApi.updateProfile, {
    mutationKey: UPDATE_PROFILE_MUTATION,
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  return response;
};
