import { useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import selectOfficeApi from "../../../../.././services/selectPrivateOffice";

const UseSelectOffice = () => {
  const { state } = useLocation();
  const Navigate = useNavigate();
  const {
    privateOfficeData,
    selectedArr,
    selectedArrAdministrator,
    startDate,
    plansPayment,
    startDateState,
    monthNumbers,
    onlyTeam,
  } = state;

  const { data: selectedOfficeData, isLoading: officeLoading } = useQuery(
    ["teamListData"],
    () =>
      selectOfficeApi.selectMonthlyPrivateOffice(
        privateOfficeData[0]?.ResourceId,
        selectedArr.length + 1
      ),
    {
      retry: 0,
    }
  );

  const selectedOfficeAllData = selectedOfficeData?.data?.data?.Records;

  const pOfficeFunc = (e) => {
    Navigate("/yearly-private-summary", {
      state: {
        selectedArr: selectedArr,
        selectedArrAdministrator: selectedArrAdministrator,
        startDate: startDate,
        plansPayment: plansPayment,
        privateOfficeData: privateOfficeData,
        selectedResourceId: e?.ResourceId,
        resourceId: e?.Id,
        selectedResourceName: e?.ResourceName,
        selectedResourceCapacity: e?.Capacity,
        startDateState: startDateState,
        monthNumbers: monthNumbers,
        onlyTeam: onlyTeam,
      },
    });
    localStorage.setItem("progressBarLength", 3);
  };
  return {
    selectedOfficeAllData,
    pOfficeFunc,
    officeLoading,
  };
};

export default UseSelectOffice;
