import { useEffect, useState } from "react";
import { useEditHelpDeskMutaion } from "../../../rc-query/membershipInvoiceHook";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

export const useEditPlanRequestInfo = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { Id } = location.state;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const editHelpDesk = useEditHelpDeskMutaion();

  const submitFunc = () => {
    const request = {
      subject: title,
      description: description,
      CoworkerId: Id,
    };
    editHelpDesk
      .mutateAsync(request)
      .then((data) => {
        if (data?.status === 200) {
          toast.success("Request submitted successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });

          navigate(-1);
        }
      })
      .catch((err) => {
        // err?.response?.data?.error?.message;
        toast.error(err?.response?.data?.error?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  // for page scroll on
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return { editHelpDesk, setTitle, setDescription, submitFunc };
};
