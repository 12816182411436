import React, { useState } from "react";
import { useQuery } from "react-query";
import durationApi from "../.././../services/selectDurationTeam";
import { useEffect } from "react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useMainPageInfo } from "../../myTeam/mainPage/useMainPage";
import { toast } from "react-toastify";
import { useCreditsHook } from "../../../rc-query/creditsHook";
// import durationApi from "../.././../services";

const UseCreditSummaryForAdmin = (props) => {
  const { state } = useLocation();
  const postCredit = useCreditsHook();
  const singlePersonData = state;
  const [selectedArr, setSelectedArr] = useState([]);
  const [selectedCceck, setSelectedCceck] = useState([]);
  const { coworkerListData } = useMainPageInfo(props);
  const [mutateArr, setMutateArr] = useState([]);
  const [tempvar, setTempvar] = useState(false);
  const [creditAddedModel, setCreditAddedModel] = useState(false);
  const [purchaseCreditState, setPurchaseCreditState] = useState(false);

  // ************modified team for backend **********
  const { data: teamListData, isLoading: teamLoading } = useQuery(
    ["teamListDatass"],
    () => durationApi.durationForMeetingRoomApiforCrdetis(),
    {
      retry: 0,
    }
  );

  const allteamListData = teamListData;

  // ********* end *********

  const checkFunc = (ele, index) => {
    const newArr = [...selectedCceck];
    newArr[index] = ele.target.checked;
    setSelectedCceck(newArr);
  };

  const selectedName = (e) => {
    let indexToDelete = -1;
    for (let i = 0; i < selectedArr.length; i++) {
      if (selectedArr[i].VisitorEmail === e?.VisitorEmail) {
        indexToDelete = i;
        break; // Stop looping since we found the matching object
      }
    }

    // If we found the matching object, delete it from the array
    if (indexToDelete !== -1) {
      const abc = selectedArr;
      abc.splice(indexToDelete, 1);
      setSelectedArr(abc);
      const coWorkers = abc.map((item) => {
        return { CoworkerId: item.Id, ProductId: singlePersonData?.Id };
      });
      setMutateArr(coWorkers);
    } else {
      // else add it into array
      setSelectedArr([
        ...selectedArr,
        {
          VisitorFullName: e?.VisitorFullName,
          // isAdministrator: e?.isAdministrator,
          VisitorEmail: e?.VisitorEmail,
          TypeName: "bookingVisitor",
          Id: e?.Id,
        },
      ]);
    }
  };

  useEffect(() => {
    // const timeout = setTimeout(() => {
    if (allteamListData && tempvar === false) {
      allteamListData?.map((e, i) => {
        if (e?.isAdministrator === true) {
          setSelectedArr([
            ...selectedArr,
            {
              VisitorFullName: e?.VisitorFullName,
              // isAdministrator: e?.isAdministrator,
              VisitorEmail: e?.VisitorEmail,
              TypeName: "bookingVisitor",
              Id: e?.Id,
            },
          ]);

          const newArr = [...selectedCceck];
          newArr[i] = true;
          setSelectedCceck(newArr);
          setTempvar(true);
        }
      });
    }
    // }, 500);
    // return () => clearTimeout(timeout);
  }, [allteamListData]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMutateArr([]);
      selectedArr?.forEach((ele) => {
        setMutateArr([
          ...mutateArr,
          {
            CoworkerId: ele?.Id,
            ProductId: singlePersonData?.Id,
          },
        ]);
      });
    }, 1000);
    return () => clearTimeout(timeout);
  }, [selectedArr]);

  const postCreditFunc = async () => {
    const promises = mutateArr?.map((item) => postCredit.mutateAsync(item));

    try {
      const responses = await Promise.all(promises);

      const allResponses200 = responses.every(
        (response) => response.status === 200
      );

      // Further handling based on the check
      if (allResponses200) {
        setPurchaseCreditState(false);
        setCreditAddedModel(true);
        // All responses have status code 200
      } else {
        // Some responses have status code other than 200
      }
    } catch (error) {
      // Handle the error here
    }
  };

  return {
    teamLoading,
    selectedArr,
    postCreditFunc,
    allteamListData,
    selectedCceck,
    checkFunc,
    selectedName,
    purchaseCreditState,
    singlePersonData,
    setPurchaseCreditState,
    creditAddedModel,
    setCreditAddedModel,
  };
};

export default UseCreditSummaryForAdmin;
